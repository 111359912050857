import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

// import { MarketListType } from "../../constants/enum/enum";

import InventoryItemIcon from "../InventoryItemIcon";
import { ListingTokenType } from "../MarketListItemPopup";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import config from "../../config/config";
import { GAME_CASH_ADDRESS } from "../../constants/const";
import { Market } from "../../constants/enum/market";
import { getInventoryItemName } from "../../helper/inventory";
import { getHistoryItems, setHistoryItems } from "../../reducers/market.slice";
import { formatDate, toUSDFormat } from "../../utils/utils";

import useStyles from "./index.styles";

interface MarketHistoryListProps {
  itemPerPage: number;
  itemFilter: number[];
}

const MarketHistoryList: React.FC<MarketHistoryListProps> = ({
  itemPerPage,
  itemFilter,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(1);

  const profileNames = useAppSelector((state) => state.profile.profileNames);
  const { historyItems, totalListedItems } = useAppSelector(
    (state) => state.market
  );

  const [showOnlyCompleted, setShowOnlyCompleted] = useState(false);

  useEffect(() => {
    dispatch(
      getHistoryItems({
        showOnlyCompleted: showOnlyCompleted,
        itemFilter: itemFilter.toString(),
        from: itemPerPage * (currentPage - 1),
        count: itemPerPage,
      })
    );
  }, [currentPage, dispatch, itemFilter, itemPerPage, showOnlyCompleted]);

  useEffect(() => {
    dispatch(setHistoryItems([]));
    setCurrentPage(1);
  }, [dispatch, itemFilter, itemPerPage]);

  const parsedItem = useMemo(() => {
    return historyItems.map((item) => {
      return {
        ...item,
        currency:
          item.listingInfo.token === config.mafiaAddress
            ? ListingTokenType.Mafia
            : item.listingInfo.token === GAME_CASH_ADDRESS
            ? ListingTokenType.GameCash
            : ListingTokenType.Native,
      };
    });
  }, [historyItems]);

  const handleNext = () => {
    setCurrentPage((page) => page + 1);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.list}>
        <Box className={classes.listHeader}>
          <Box>Item</Box>
          <Box>Seller</Box>
          <Box>Type</Box>
          <Box>Price/Bid</Box>
          <Box>USD</Box>
          <Box>Currency</Box>
          <Box>Buyer</Box>
          <Box>Status</Box>
          <Box>
            <Box>Date/Time</Box>
            <Box>
              <FormControlLabel
                onChange={() => {
                  setShowOnlyCompleted((prev: boolean) => !prev);
                }}
                value={showOnlyCompleted}
                control={<Checkbox />}
                label="Show only completed"
                sx={{
                  "& span": {
                    fontFamily: "Philosopher",
                    fontWeight: 900,
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.listBody} id="scrollable-div">
          <InfiniteScroll
            dataLength={parsedItem.length}
            hasMore={parsedItem.length !== totalListedItems}
            next={handleNext}
            scrollableTarget="scrollable-div"
            loader={<Box className={classes.loading}>Loading...</Box>}
            className={classes.scrollContainer}
          >
            {parsedItem
              .filter((item, index) =>
                showOnlyCompleted ? item.listingInfo.status === 1 : true
              )
              .map((item, index) => {
                const sellerProfile = profileNames.find(
                  (profile, index) =>
                    profile.address?.toLowerCase() ===
                    item.listingInfo.seller.toLowerCase()
                );

                const buyerProfile = profileNames.find(
                  (profile, index) =>
                    profile.address?.toLowerCase() ===
                    item.listingInfo.buyer.toLowerCase()
                );

                const itemName = getInventoryItemName(
                  item.categoryId,
                  item.typeId,
                  item.cityId
                );
                return (
                  <Box
                    className={classes.listItem}
                    key={index}
                    onClick={() => {
                      window.location.href = `/market/item/${
                        item.categoryId * 10 + item.typeId
                      }`;
                    }}
                  >
                    <Box className={classes.name}>
                      <InventoryItemIcon
                        categoryId={item.categoryId}
                        typeId={item.typeId}
                        className={classes.itemIcon}
                      />
                      {itemName}
                    </Box>

                    <Link
                      to={`/profile/${sellerProfile?.name}`}
                      target="_blank"
                    >
                      <Box className={classes.link}>{sellerProfile?.name}</Box>
                    </Link>

                    <Box>
                      {item.listingInfo.listingType === Market.ListType.FIXED
                        ? "Fixed"
                        : "Auction"}
                    </Box>

                    <Box className={classes.usdLabel}>
                      {item.listingInfo.token === GAME_CASH_ADDRESS
                        ? `${toUSDFormat(item.listingInfo.currentPrice)}`
                        : `$${toUSDFormat(item.listingInfo.currentPrice)}`}
                    </Box>

                    <Box className={classes.usdLabel}>
                      {item.listingInfo.token === GAME_CASH_ADDRESS
                        ? "-"
                        : `$${toUSDFormat(item.listingInfo.startingPrice)}`}
                    </Box>

                    <Box className={classes.currency}>
                      <Box
                        component="img"
                        className={classes.currencyIcon}
                        src={
                          item.currency === ListingTokenType.GameCash
                            ? "/assets/imgs/inventory/cashitem.png"
                            : `/assets/tokens/${item.currency.toLocaleLowerCase()}.png`
                        }
                      ></Box>
                      {item.currency}
                    </Box>

                    <Link to={`/profile/${buyerProfile?.name}`} target="_blank">
                      <Box className={classes.link}>{buyerProfile?.name}</Box>
                    </Link>

                    <Box>
                      {item.listingInfo.status === 0 ? (
                        <Box className={classes.listingStatusExpired}>
                          Expired
                        </Box>
                      ) : item.listingInfo.status === 1 ? (
                        <Box className={classes.listingStatusNormal}>
                          Completed
                        </Box>
                      ) : (
                        <Box className={classes.listingStatusExpired}>
                          Expired
                        </Box>
                      )}
                    </Box>

                    <Box>{formatDate(item.listingInfo.timestamp)}</Box>
                  </Box>
                );
              })}
          </InfiniteScroll>
        </Box>
      </Box>
    </Box>
  );
};

export default MarketHistoryList;
