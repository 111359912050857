import { ChainType } from "../constants/enum/enum";
import configBsc from "./config.bsc";
import configPulseChain from "./config.pulsechain";

export const CHAIN =
  process.env.REACT_APP_CHAIN === ChainType.BNB ? ChainType.BNB : ChainType.PLS;

const config = CHAIN === ChainType.PLS ? configPulseChain : configBsc;

export const SERVER_URL = config.serverEndpoint;

export default config;
