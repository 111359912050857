import { findFlagUrlByCountryName } from "country-flags-svg-v2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { validator } from "web3-validator";
import {
  VAULT_PAYOUT_RATIO,
  VaultDistributionRatio,
  WealthRange,
  missingFlags,
  shortCountryName,
} from "../constants/const";
import { FamilyRole } from "../constants/enum/enum";

export const toUSDFormat = (number: any, digit = 2) => {
  if (number)
    return parseFloat(number).toLocaleString("en-US", {
      maximumFractionDigits: digit,
    });
  return "0";
};

export const getWealthCategory = (amount: number) => {
  for (const range of WealthRange) {
    if (amount >= range.from && amount <= range.to) {
      return range.category;
    }
  }
  return "Category not found";
};

export const isLikelyEthereumAddress = (address: string) => {
  // Check if the address is hexadecimal and contains at least some common Ethereum address length
  const hexRegex = /^(0x)?[0-9a-fA-F]+$/;
  return hexRegex.test(address) && address.length >= 30; // Adjust this threshold as needed
};

export const shortAddress = (
  address: string,
  prefix: number = 6,
  suffix: number = 4
) => {
  return address.substring(0, prefix) + "..." + address.slice(suffix * -1);
};

export const shortName = (name: string) => {
  // Check if the input 'name' is a valid Ethereum address
  if (isLikelyEthereumAddress(name)) {
    return shortAddress(name);
  } else {
    return name.substring(0, 15) + "...";
  }
};

export const padWithZeros = (number: number, length: number) => {
  let str: string = "" + number;
  while (str.length < length) {
    str = "0" + str;
  }
  return str;
};

// Function to format bytes into a human-readable format
export const formatBytes = (bytes: number) => {
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return Math.round(100 * (bytes / Math.pow(k, i))) / 100 + " " + sizes[i];
};

export const toTitleCase = (str: string) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const arraysAreEqual = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

export const calculateFamilyPayout = (vaultBalance: number, rank: number) => {
  return ((vaultBalance / 10) * VaultDistributionRatio[rank - 1]) / 100;
};

export const calculatePayout = (
  vaultBalance: number,
  rank: number,
  topPercent: number,
  role: FamilyRole
) => {
  const teamPayout = calculateFamilyPayout(vaultBalance, rank);

  if (role === FamilyRole.Capodecina) {
    return (((teamPayout * VAULT_PAYOUT_RATIO[2]) / 100) * topPercent) / 100;
  } else if (role === FamilyRole.Consigliere) {
    return (((teamPayout * VAULT_PAYOUT_RATIO[1]) / 100) * topPercent) / 100;
  } else if (role === FamilyRole.Don) {
    return (((teamPayout * VAULT_PAYOUT_RATIO[0]) / 100) * topPercent) / 100;
  }

  return 0;
};

export const isStartWith0x = (name: string) => {
  return name.slice(0, 2) === "0x";
};

export const isEthereumAddress = (address: string) => {
  try {
    validator.validate(["address"], [address]);
    return true;
  } catch (error) {
    return false;
  }
};

export const getShortCountryName = (countryName: string) => {
  const index = shortCountryName[0].findIndex(
    (name: string) => name.trim() === countryName.trim()
  );
  if (index === -1) {
    return countryName;
  } else {
    return shortCountryName[1][index];
  }
};

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const convertDateTime = (sec: number) => {
  const oneDay = 24 * 3600;
  const oneHour = 3600;
  const days = Math.floor(sec / oneDay);
  const hours = Math.floor((sec % oneDay) / oneHour);
  const minutes = Math.floor((sec % oneHour) / 60);
  const seconds = Math.floor((sec % oneHour) % 60);
  if (days > 0) return `${days} days & ${hours} hours`;
  else if (hours > 0) return `${hours} hours  & ${minutes} mins`;
  else return ` ${minutes} mins & ${seconds} secs`;
};

export const convertDateTimeSimple = (sec: number) => {
  const oneDay = 24 * 3600;
  const oneHour = 3600;
  const days = Math.floor(sec / oneDay);
  const hours = Math.floor((sec % oneDay) / oneHour);
  const minutes = Math.floor((sec % oneHour) / 60);
  const seconds = Math.floor((sec % oneHour) % 60);
  if (days > 0) return `${days} days`;
  else if (hours > 0) return `${hours} hours`;
  else if (minutes > 0) return `${minutes} mins`;
  else return `${seconds} mins`;
};

export const convertDateExtremeSimple = (sec: number) => {
  const oneDay = 24 * 3600;
  const oneHour = 3600;
  const days = Math.floor(sec / oneDay)
    .toString()
    .padStart(2, "0");
  const hours = Math.floor((sec % oneDay) / oneHour)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((sec % oneHour) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor((sec % oneHour) % 60)
    .toString()
    .padStart(2, "0");

  return `${days}:${hours}:${minutes}:${seconds}`;
};

export const formatDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${day}-${month}-${year} - ${hours}:${minutes}:${seconds}`;
};

export const toastSuccess = (
  messageTemplate: string,
  params?: { [key: string]: string }
): void => {
  let message = messageTemplate;
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const placeholder = `{${key}}`;
      message = message.replace(placeholder, params[key]);
    }
  }
  toast.success(message);
};

export const toastInfo = (
  messageTemplate: string,
  params?: { [key: string]: string }
): void => {
  let message = messageTemplate;
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const placeholder = `{${key}}`;
      message = message.replace(placeholder, params[key]);
    }
  }
  toast.info(message);
};

export const toastError = (
  messageTemplate: { code: string; message: string },
  params?: { [key: string]: string }
): void => {
  let message = messageTemplate.message;
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const placeholder = `{${key}}`;
      message = message.replace(placeholder, params[key]);
    }
  }
  toast.error(`${message} ErrorCodes: ${messageTemplate.code}`);
};

export const getFlagUrl = (countryName: string) => {
  const isMissingFlag = missingFlags[countryName];
  return isMissingFlag ? isMissingFlag : findFlagUrlByCountryName(countryName);
};
