import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Checkbox, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import useStyles from "./CustomDropDown.styles";

interface ItemFilterDropDownProps {
  readonly open?: boolean;
  readonly sx?: React.CSSProperties;
  readonly items?: number[] | string[];
  readonly value?: string[];
  readonly disabled?: boolean;
  readonly handleChange?: (event: SelectChangeEvent<string[]>) => void;
  readonly placeholder?: string;
  readonly setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ItemFilterDropDown({
  open = false,
  disabled = false,
  setOpen,
  placeholder = "",
  sx,
  items,
  value,
  handleChange,
}: ItemFilterDropDownProps) {
  const { classes } = useStyles();
  const handleClose = () => {
    setOpen && setOpen(false);
  };

  const handleOpen = () => {
    setOpen && setOpen(true);
  };

  return (
    <FormControl
      className={classes.formControl}
      sx={{
        ...sx,
      }}
    >
      {!value && <Box className={classes.placeholder}>{placeholder}</Box>}
      <Select
        multiple
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        displayEmpty
        IconComponent={ExpandMoreIcon}
        inputProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                display: "block",
                padding: 0,
                backgroundColor: "#282828",
                color: "white",
              },
            },
            children: {
              sx: {
                fontSize: "12px",
              },
            },
          },
        }}
      >
        {items?.map((item, index) => (
          <MenuItem key={index} value={item} className={classes.menuItem}>
            <Checkbox checked={value && value?.indexOf(item.toString()) > -1} />
            <Box className={classes.detail}>{item}</Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
