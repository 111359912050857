import DoneIcon from "@mui/icons-material/Done";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Box, IconButton, Typography } from "@mui/material";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useMemo, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  MAX_PROFILE_DESCRIPTION_LEN,
  PREMIUM_KEY_CLAIM_CYCLE,
} from "../../constants/const";
import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";
import useCurrentTime from "../../hook/useCurrentTime";
import {
  signData,
  updateProfileDescription,
} from "../../reducers/profile.slice";
import { UserProfile } from "../../types/UserProfile";
import { toastError, toastSuccess } from "../../utils/utils";

import useStyles from "./index.styles";

const ProfileDescription = ({
  profile,
  editable,
}: {
  profile: UserProfile;
  editable?: boolean;
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { premiumInfo } = useAppSelector((state) => state.profile);
  const currentTime = useCurrentTime();

  const [isEditing, setIsEditing] = useState(false);
  const [length, setLength] = useState(0);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsEditing(true);
  };

  const handleConfirmClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const signMsg = {
      address: profile.address,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    };

    dispatch(signData({ signMsg }))
      .unwrap()
      .then((signature) => {
        if (profile.id)
          dispatch(
            updateProfileDescription({ signMsg, signature, userId: profile.id })
          )
            .unwrap()
            .then(() => {
              toastSuccess(Messages.GLOBAL.PROFILE.DESCRIPTION_EDIT_SUCCESS);
              setIsEditing(false);
            })
            .catch(() => {
              toastError(Errors.GLOBAL.PROFILE.DESCRIPTION_EDIT_FAILED);
            });
      })
      .catch(() => {
        toastError(Errors.GLOBAL.WEB3.REJECTED);
      });
  };

  const onEditorStateChange = (newEditorState: EditorState) => {
    const currentContent = newEditorState.getCurrentContent();
    const newDescription = draftToHtml(convertToRaw(currentContent));
    if (newDescription.length <= MAX_PROFILE_DESCRIPTION_LEN) {
      setEditorState(newEditorState);
      setLength(newDescription.length);
    }
  };

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(profile.description || "");
    const contentState = ContentState.createFromBlockArray(
      blocksFromHtml.contentBlocks,
      blocksFromHtml.entityMap
    );
    setEditorState(EditorState.createWithContent(contentState));
    setLength(profile.description?.length || 0);
  }, [profile.description]);

  const premiumEnabled = useMemo(() => {
    return (
      profile.premium === 1 &&
      premiumInfo.userInfo.startedAt + PREMIUM_KEY_CLAIM_CYCLE * 3 > currentTime
    );
  }, [currentTime, premiumInfo.userInfo.startedAt, profile.premium]);

  return (
    <Box className={classes.body}>
      <Box className={classes.container}>
        <Box
          className={classes.card}
          sx={{
            opacity: premiumEnabled ? 1 : 0.3,
            maxHeight: isEditing ? 400 : 240,
          }}
        >
          <Box className={classes.cardHeader}>
            <Typography className={classes.cardHeaderTitle}>
              Profile Description
            </Typography>
            {editable &&
              premiumEnabled &&
              (!isEditing ? (
                <IconButton onClick={handleEditClick} aria-label="Edit">
                  <EditNoteIcon />
                </IconButton>
              ) : (
                <IconButton onClick={handleConfirmClick} aria-label="Apply">
                  <DoneIcon />
                </IconButton>
              ))}
          </Box>

          <Box className={classes.cardContent}>
            {isEditing ? (
              <>
                <Editor
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "fontSize",
                      "fontFamily",
                      "list",
                      "textAlign",
                      "link",
                      "embedded",
                      "emoji",
                    ],
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                  }}
                />
              </>
            ) : (
              <Typography
                component={"div"}
                dangerouslySetInnerHTML={{
                  __html:
                    draftToHtml(
                      convertToRaw(editorState.getCurrentContent())
                    ) || "",
                }}
                className={classes.description}
                sx={{ maxHeight: isEditing ? 300 : 150 }}
              />
            )}
          </Box>

          {isEditing && (
            <Box
              className={classes.lettersLength}
            >{`${length} / ${MAX_PROFILE_DESCRIPTION_LEN}`}</Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileDescription;
