import {
  approveToken,
  getProvider,
  getTokenAllowance,
  getWeb3,
  waitForConfirmation,
} from ".";
import MafiaFamily from "../../abis/MafiaFamily.json";
import config from "../../config/config";
import { FamilyInfo } from "../../types/Contract/Family/FamilyInfo";
import { PlayerInfo } from "../../types/Contract/Family/PlayerInfo";

export const getMafiaFamilyContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.mafiaFamilyAddress;
  const MafiaFamilyContract = new web3.eth.Contract(
    MafiaFamily,
    contractAddress
  );
  return MafiaFamilyContract;
};

export const hasFamily = async (account: string) => {
  const MafiaFamilyContract = getMafiaFamilyContract();

  const result: boolean = await MafiaFamilyContract.methods
    .hasFamily(account)
    .call();

  return result;
};

export const isFamilyMember = async (account: string, familyId: number) => {
  const MafiaFamilyContract = getMafiaFamilyContract();

  const result: boolean = await MafiaFamilyContract.methods
    .isFamilyMember(account, familyId)
    .call();

  return result;
};

export const getFamilyId = async (account: string) => {
  const MafiaFamilyContract = getMafiaFamilyContract();
  const result: PlayerInfo = await MafiaFamilyContract.methods
    .getPlayerInfo(account)
    .call();

  return Number(result.familyId);
};

export const getFamilyInfo = async (familyId: number) => {
  const MafiaFamilyContract = getMafiaFamilyContract();
  const result: FamilyInfo = await MafiaFamilyContract.methods
    .getFamily(familyId)
    .call();

  return result;
};

export const getFamilyInvitationLogs = async (familyId: number, id: number) => {
  const MafiaFamilyContract = getMafiaFamilyContract();
  const result = await MafiaFamilyContract.methods
    .familyInvitationLogs(familyId, id)
    .call();

  return result;
};

export const getMinFamilyLeaveFee = async () => {
  const MafiaFamilyContract = getMafiaFamilyContract();
  const result: number = await MafiaFamilyContract.methods.minLeaveFee().call();

  return result;
};

export const getMaxFamilyLeaveFee = async () => {
  const MafiaFamilyContract = getMafiaFamilyContract();
  const result: number = await MafiaFamilyContract.methods.maxLeaveFee().call();

  return result;
};

export const createFamily = async (account: string) => {
  const MafiaFamilyContract = getMafiaFamilyContract(true);

  const data = await MafiaFamilyContract.methods
    .createFamily()
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const approveFamilyContract = async (
  account: string,
  approveAmount: number
) => {
  const tokenAddress = config.mafiaAddress;
  const contractAddress = config.mafiaFamilyAddress;

  const allowance = await getTokenAllowance(
    tokenAddress,
    account,
    contractAddress
  );

  if (Number(allowance) < approveAmount) {
    return await approveToken(
      tokenAddress,
      approveAmount,
      contractAddress,
      account
    );
  }
};

export const inviteMember = async (
  account: string,
  familyId: number,
  invitee: string
) => {
  const MafiaFamilyContract = getMafiaFamilyContract(true);

  const data = await MafiaFamilyContract.methods
    .inviteMember(familyId, invitee)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const acceptInvitation = async (
  account: string,
  invitationId: number
) => {
  const MafiaFamilyContract = getMafiaFamilyContract(true);

  const data = await MafiaFamilyContract.methods
    .acceptInvitation(invitationId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const rejectInvitation = async (
  account: string,
  invitationId: number
) => {
  const MafiaFamilyContract = getMafiaFamilyContract(true);

  const data = await MafiaFamilyContract.methods
    .rejectInvitation(invitationId)
    .send({
      from: account,
    });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const cancelInvitation = async (
  account: string,
  invitationId: number
) => {
  const MafiaFamilyContract = getMafiaFamilyContract(true);

  const data = await MafiaFamilyContract.methods
    .cancelInvitation(invitationId)
    .send({
      from: account,
    });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const updateLeader = async (
  account: string,
  familyId: number,
  memberAddress: string,
  leaderIndex: number
) => {
  const MafiaFamilyContract = getMafiaFamilyContract(true);

  const data = await MafiaFamilyContract.methods
    .updateFamilyLeader(familyId, memberAddress, leaderIndex)
    .send({
      from: account,
    });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const setSuccessor = async (
  account: string,
  familyId: number,
  successor: string
) => {
  const MafiaFamilyContract = getMafiaFamilyContract(true);

  const data = await MafiaFamilyContract.methods
    .setSuccessor(familyId, successor)
    .send({
      from: account,
    });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const leaveFamily = async (account: string, familyId: number) => {
  const MafiaFamilyContract = getMafiaFamilyContract(true);

  const data = await MafiaFamilyContract.methods.leaveFamily(familyId).send({
    from: account,
  });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const kickMember = async (
  account: string,
  familyId: number,
  memberAddress: string
) => {
  const MafiaFamilyContract = getMafiaFamilyContract(true);

  const data = await MafiaFamilyContract.methods
    .kickMember(familyId, memberAddress)
    .send({
      from: account,
    });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const updateFamilyLeaveFee = async (
  account: string,
  familyId: number,
  value: number
) => {
  const web3 = getWeb3();
  const MafiaFamilyContract = getMafiaFamilyContract(true);

  const data = await MafiaFamilyContract.methods
    .updateFamilyLeaveFee(familyId, web3.utils.toWei(value.toString(), "ether"))
    .send({
      from: account,
    });

  await waitForConfirmation(data.transactionHash);

  return data;
};
