import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { SERVER_URL } from "../config/config";
import { Player } from "../types/PlayerInfo";

export interface PlayerState {
  playerList: Player[];
  isLoadingPlayerList: boolean;
}

const initialState: PlayerState = {
  playerList: [],
  isLoadingPlayerList: false,
};

export const getPlayerList = createAsyncThunk(
  "player/getPlayerList",
  async ({
    from,
    count,
    keyword,
  }: {
    from: number;
    count: number;
    keyword: string;
  }) => {
    const response = await axios.get(
      `${SERVER_URL}/profile/all?from=${from}&count=${count}&key=${keyword}`
    );

    return response.data;
  }
);

export const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPlayerList.pending, (state) => {
      state.isLoadingPlayerList = true;
    });
    builder.addCase(getPlayerList.fulfilled, (state, { payload }) => {
      state.playerList = payload;
      state.isLoadingPlayerList = false;
    });
    builder.addCase(getPlayerList.rejected, (state, { error }) => {
      state.isLoadingPlayerList = false;
    });
  },
});

export default playerSlice.reducer;
