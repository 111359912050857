import { ethers } from "ethers";
import Web3 from "web3";

import config from "../../config/config";

import ERC20Abi from "../../abis/ERC20.json";
import ERC1155Abi from "../../abis/OGCrate(ERC1155).json";

export const getWeb3 = () =>
  new Web3(
    config.providerList[Math.floor(Math.random() * config.providerList.length)]
  );
export const getProvider = () => new Web3((window as any).provider);

export const getTokenBalance = async (
  tokenAddress: string,
  userAddress: string
) => {
  const web3 = getWeb3();
  const tokenContract = new web3.eth.Contract(ERC20Abi, tokenAddress);
  let balance: any, decimal: string;

  if (tokenAddress === ethers.constants.AddressZero) {
    balance = await web3.eth.getBalance(userAddress);
    return ethers.utils.formatEther(balance);
  } else {
    balance = await tokenContract.methods.balanceOf(userAddress).call();
    decimal = await tokenContract.methods.decimals().call();
    return ethers.utils.formatUnits(balance, decimal);
  }
};

export const getTokenAllowance = async (
  tokenAddress: string,
  userAddress: string,
  contractAddress: string
) => {
  const web3 = getWeb3();
  const tokenContract = new web3.eth.Contract(ERC20Abi, tokenAddress);
  const allowance: string = await tokenContract.methods
    .allowance(userAddress, contractAddress)
    .call();
  const decimal: string = await tokenContract.methods.decimals().call();

  return ethers.utils.formatUnits(allowance, decimal);
};

export const approveToken = async (
  tokenAddress: string,
  amount: number,
  to: string,
  account: string
) => {
  const web3 = getProvider();
  const tokenContract = new web3.eth.Contract(ERC20Abi, tokenAddress);
  const allowanceBN = (await tokenContract.methods
    .allowance(account, to)
    .call()) as string;
  const decimals = Number(await tokenContract.methods.decimals().call());
  const allowance = Number(ethers.utils.formatUnits(allowanceBN, decimals));

  if (allowance < amount) {
    const data = await tokenContract.methods
      .approve(
        to,
        ethers.utils.parseUnits(amount.toFixed(decimals), decimals).toString()
      )
      .send({ from: account });

    await waitForConfirmation(data.transactionHash);
  }
};

export const signMessage = async (message: any) => {
  const provider = (window as any).wallet.provider;

  const ethersProvider = new ethers.providers.Web3Provider(provider, "any");

  const signer = ethersProvider.getSigner();

  return await signer.signMessage(JSON.stringify(message));
};

export const waitForConfirmation = async (txHash: string) => {
  let receipt = null;
  const web3 = getProvider();

  while (receipt === null) {
    try {
      receipt = await web3.eth.getTransactionReceipt(txHash);
      if (receipt === null) {
        // Receipt not found, wait 1 second before the next poll
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    } catch (err) {
      console.error("Error fetching transaction receipt:", err);
      // Wait 1 second after an error before trying again
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }

  console.log("Transaction confirmed:", receipt);
};

export const transferErc1155 = async (
  from: string,
  to: string,
  amount: number,
  tokenAddress: string
) => {
  const web3 = getProvider();
  const tokenContract = new web3.eth.Contract(ERC1155Abi, tokenAddress);

  const gas = await tokenContract.methods
    .safeTransferFrom(from, to, 0, amount, "0x")
    .estimateGas({ from });

  const receipt = await tokenContract.methods
    .safeTransferFrom(from, to, 0, amount, "0x")
    .send({ from, gas: gas.toString() });

  console.log(receipt);
};
