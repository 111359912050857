import gsap from "gsap";

export const movieFadeInAnimation = (
  target: gsap.TweenTarget,
  duration: number
) => {
  return gsap.fromTo(
    target,
    {
      alpha: 0,
    },
    { alpha: 1, visibility: "visible", duration: duration }
  );
};

export const buttonFadeOut = (target: gsap.TweenTarget, duration: number) => {
  return gsap.fromTo(
    target,
    { alpha: 1, scale: 1 },
    {
      alpha: 0,
      scale: 0,
    }
  );
};

export const buttonFadeIn = (target: gsap.TweenTarget, duration: number) => {
  return gsap.fromTo(
    target,
    {
      alpha: 0,
      scale: 0,
    },
    { alpha: 1, scale: 1 }
  );
};

export const movieFadeOutAnimation = (
  target: gsap.TweenTarget,
  duration: number
) => {
  return gsap.fromTo(
    target,
    {
      alpha: 1,
    },
    { alpha: 0, duration: duration }
  );
};
