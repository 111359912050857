import { Box, Button, Theme, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CRATE_PRICE } from "../../constants/const";
import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";
import { dispatchTxAction } from "../../helper/dispatchTxAction";
import {
  buyCratesAction,
  getSwapTokenBalance,
  getSwapTokenInfo,
} from "../../reducers/crate.slice";
import { getCrateBalance } from "../../reducers/profile.slice";
import { CustomTypeBackground, CustomTypeText } from "../../theme";
import { toastError, toastSuccess, toUSDFormat } from "../../utils/utils";

import AvailableTokenList from "../AvailableTokenList";

import moneyIcon from "../../assets/imgs/exchange/money.png";
import keyIcon from "../../assets/imgs/inventory/key.png";

import useStyles from "./index.styles";

const keyVolumeBonus = [
  {
    type: "Cash",
    amount: 10000,
    usdAmount: 20,
  },
  {
    type: "Cash",
    amount: 200000,
    usdAmount: 120,
  },
  {
    type: "Cash",
    amount: 400000,
    usdAmount: 220,
  },
  {
    type: "Key",
    amount: 5,
    usdAmount: 400,
  },
  {
    type: "Key",
    amount: 10,
    usdAmount: 1000,
  },
  {
    type: "Key",
    amount: 15,
    usdAmount: 2000,
  },
  {
    type: "Key",
    amount: 20,
    usdAmount: 5000,
  },
];

const usdAmountSteps = [20, 100, 500];

const KeyPanel = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const theme = useTheme<Theme>();
  const text = theme.palette.text as CustomTypeText;
  const background = theme.palette.background as CustomTypeBackground;
  const { myProfile } = useAppSelector((state) => state.profile);
  const { swapTokenInfo } = useAppSelector((state) => state.crate);

  const [usdAmount, setUsdAmount] = useState(0);
  const [keyAmount, setKeyAmount] = useState(0);
  const [selectedTokenId, setSelectedTokenId] = useState(-1);

  const handleIncreaseUsd = (amount: number) => {
    setUsdAmount((prev) => prev + amount);
  };

  const selectedBonusIndex = useMemo(() => {
    let bonusIndex = 0;
    keyVolumeBonus.forEach((bonus, index) => {
      if (bonus.usdAmount <= usdAmount) {
        bonusIndex = index;
      }
    });
    const percentage = bonusIndex >= 3 ? keyVolumeBonus[bonusIndex].amount : 0;
    const keyAmount = Math.floor(
      (usdAmount * (100 + percentage)) / 100 / CRATE_PRICE
    );

    setKeyAmount(keyAmount);

    return bonusIndex;
  }, [usdAmount]);

  const handleSelectToken = (swapTokenId: number) => {
    setSelectedTokenId(swapTokenId);
  };

  const handlePurchaseCrate = () => {
    const slippage =
      swapTokenInfo[0][selectedTokenId].name === "MAFIA" ? 0.2 : 0.05;
    const tokenAmount =
      (usdAmount * (1 + slippage)) / swapTokenInfo[1][selectedTokenId];

    if (!myProfile.name) {
      toastError(Errors.GLOBAL.PROFILE.NOT_CREATED);
      return;
    }

    if (myProfile.address) {
      dispatchTxAction(
        dispatch,
        buyCratesAction({
          swapTokenId: selectedTokenId,
          tokenAmount: tokenAmount,
          tokenAddress: swapTokenInfo[0][selectedTokenId].tokenAddress,
          amount: Math.floor(usdAmount / CRATE_PRICE),
          account: myProfile.address,
        }),
        () => {
          toastSuccess(Messages.FAMILY.INVENTORY.PURCHASED_SUCCESS);
          dispatch(getCrateBalance({ account: myProfile.address || "" }));
        }
      );
    }
  };

  useEffect(() => {
    dispatch(getSwapTokenInfo());
  }, [dispatch]);

  useEffect(() => {
    if (!myProfile.address) return;
    if (swapTokenInfo[0].length === 0) return;

    dispatch(getSwapTokenBalance({ account: myProfile.address }));
  }, [dispatch, myProfile.address, swapTokenInfo]);

  return (
    <>
      <Box className={classes.exchangeInfo}>
        <Box
          className={classes.exchangeCard}
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: 480,
            gap: 4,
            alignItems: "center",
          }}
        >
          <Typography
            fontFamily={"Philosopher"}
            color={"white"}
            textAlign={"center"}
            fontSize={24}
            fontWeight={700}
          >
            Insert amount
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Typography
              fontFamily={"Philosopher"}
              color={"white"}
              textAlign={"center"}
              fontSize={24}
              fontWeight={700}
              whiteSpace={"nowrap"}
            >
              $ {usdAmount}
            </Typography>

            <Button
              sx={{
                position: "absolute",
                right: "-72px",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Philosopher",
                fontWeight: 700,
                color: "#cc111190",
              }}
              onClick={() => {
                setUsdAmount(0);
              }}
            >
              Clear
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            {usdAmountSteps.map((step, index) => {
              return (
                <Button
                  sx={{
                    color: "white",
                    fontSize: 16,
                    fontFamily: "Philosopher",
                    fontWeight: 700,
                    backgroundColor: background.lightGray,
                    borderRadius: "16px",
                    minWidth: "80px",
                    padding: "4px 16px",
                  }}
                  key={index}
                  onClick={() => {
                    handleIncreaseUsd(step);
                  }}
                >
                  +${step}
                </Button>
              );
            })}
          </Box>

          <Box>
            <AvailableTokenList
              usdAmount={usdAmount}
              selectedTokenId={selectedTokenId}
              handleSelectToken={handleSelectToken}
            />
          </Box>
        </Box>

        <Box className={classes.fees}>
          <Box
            className={classes.feeInfo}
            sx={{
              gap: 1,
            }}
          >
            <Typography
              fontFamily={"Philosopher"}
              color={"white"}
              textAlign={"center"}
              fontSize={18}
              fontWeight={700}
            >
              Keys
            </Typography>

            <Box
              component={"img"}
              src={keyIcon}
              sx={{
                width: 60,
                height: 60,
                top: 42,
                filter: "blur(10px)",
                position: "absolute",
              }}
            ></Box>

            <Box
              component={"img"}
              src={keyIcon}
              sx={{
                width: 60,
                height: 60,
                zIndex: 1,
              }}
            ></Box>

            <Typography
              fontFamily={"Philosopher"}
              color={text.grayColor}
              textAlign={"center"}
              fontSize={14}
              fontWeight={700}
            >
              0% fee
            </Typography>
          </Box>
        </Box>

        <Box className={classes.exchangeCard}>
          <Typography
            fontFamily={"Philosopher"}
            color={"white"}
            textAlign={"center"}
            fontSize={24}
            fontWeight={700}
            mt={1}
          >
            Here's your offer!
          </Typography>

          <Typography
            fontFamily={"Philosopher"}
            color={text.grayColor}
            textAlign={"center"}
            fontSize={12}
            fontWeight={700}
            mt={1}
          >
            Take it or leave it
          </Typography>

          <Box
            sx={{
              mt: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                backgroundColor: "#121415",
                padding: "8px 32px 8px 24px",
                borderRadius: "16px",
                border: "1px solid #040405",
                boxShadow:
                  "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
              }}
            >
              <Box
                component="img"
                src={keyIcon}
                sx={{
                  width: 64,
                  height: 64,
                }}
              ></Box>
              <Typography
                fontSize={20}
                color={"white"}
                fontWeight={700}
                fontFamily={"Philosopher"}
              >
                {keyAmount} Keys
              </Typography>
            </Box>

            <Button
              className={classes.confirm}
              sx={{
                textTransform: "none",
              }}
              onClick={handlePurchaseCrate}
              disabled={keyAmount === 0 || selectedTokenId === -1}
            >
              Confirm purchase
            </Button>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
              }}
            >
              <Typography
                fontFamily={"Philosopher"}
                color={text.grayColor}
                textAlign={"center"}
                fontSize={20}
                fontWeight={700}
              >
                Volume bonus
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                {keyVolumeBonus.map((volume, index) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        borderRadius: 1,
                        userSelect: "none",
                        backgroundColor:
                          index === selectedBonusIndex
                            ? background.lightGray
                            : "",
                      }}
                      key={index}
                    >
                      <Box
                        component="img"
                        src={volume.type === "Key" ? keyIcon : moneyIcon}
                        sx={{
                          width: 16,
                          height: 16,
                        }}
                      ></Box>
                      <Typography
                        fontFamily={"Philosopher"}
                        color={
                          index === selectedBonusIndex
                            ? "white"
                            : text.grayColor
                        }
                        textAlign={"center"}
                        fontSize={18}
                        fontWeight={700}
                      >
                        {volume.type === "Key"
                          ? `${volume.amount}% Extra Keys`
                          : toUSDFormat(volume.amount)}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default KeyPanel;
