import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Logo from "../Logo";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import config from "../../config/config";
import {
  CONFIRMATION_DELAY,
  City,
  FAMILY_PRICE_IN_MAFIA,
  MAX_FAMILY_NAME_LEN,
} from "../../constants/const";
import {
  formatBytes,
  toUSDFormat,
  toastError,
  toastSuccess,
} from "../../utils/utils";

import {
  getFamilyId,
  hasFamily,
} from "../../helper/contractFunctions/MafiaFamily";

import useWallet from "../../hook/useWallet";
import {
  approveFamilyAction,
  createFamilyAction,
  registerFamilyCreateAction,
  signFamilyCreateAction,
} from "../../reducers/family.slice";

import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";
import { dispatchTxAction } from "../../helper/dispatchTxAction";
import useStyles from "./index.styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FamilyCreate = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { myProfile } = useAppSelector((state) => state.profile);
  const { isCreatingFamily } = useAppSelector((state) => state.family);

  const { account, connectWallet } = useWallet();

  const [familyName, setFamilyName] = useState("");
  const [city, setCity] = useState("0");
  const [avatar, setAvatar] = useState("");
  const [file, setFile] = useState<File>();

  const handleChange = (event: SelectChangeEvent) => {
    setCity(event.target.value);
  };

  const handleFamilyNameChange = (event: any) => {
    const familyName = event.target.value;

    // Regex to match only alphabetic characters and up to two spaces
    let validFamilyName = familyName.match(
      /^[A-Za-z]+(?:\s[A-Za-z]+)?(?:\s[A-Za-z]+)?$/
    );

    if (validFamilyName) {
      validFamilyName[0] =
        validFamilyName[0].charAt(0).toUpperCase() +
        validFamilyName[0].slice(1);

      if (validFamilyName[0].length > MAX_FAMILY_NAME_LEN) {
        validFamilyName[0] = validFamilyName[0].slice(0, 20);
      }

      setFamilyName(validFamilyName[0]); // Sets the valid family name if it matches the pattern
    } else if (
      familyName === "" ||
      /^[A-Za-z]+(?:\s[A-Za-z]+)?(?:\s?)?$/.test(familyName)
    ) {
      // Allow incomplete valid input (like when typing a new word after a space)
      setFamilyName(familyName);
    } else {
      // Optionally, handle invalid input case, e.g., by showing an error message
      console.error("Invalid family name. Please enter up to three words.");
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length === 0 || !e.target.files) return;
    const file = e.target.files[0];

    // Set the maximum allowed file size in bytes (e.g., 2 MB)
    const maxFileSize = 500 * 1024;
    const maxWidth = 1024;
    const maxHeight = 1024;

    if (file.type === "image/png" || file.type === "image/jpeg") {
      // Check if the file size is within the allowed limit
      if (file.size <= maxFileSize) {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          if (img.naturalWidth <= maxWidth && img.naturalHeight <= maxHeight) {
            setAvatar(URL.createObjectURL(file));
            setFile(file);
          } else {
            alert(
              `Image dimensions exceed the maximum allowed limit of ${maxWidth}x${maxHeight}.`
            );
          }
        };
      } else {
        alert(
          `File size exceeds the maximum allowed limit of ${formatBytes(
            maxFileSize
          )}.`
        );
      }
    } else {
      alert("File is not a PNG or JPG image.");
    }
  };

  const handleConfirmCreate = async () => {
    if (!account) {
      toastError(Errors.GLOBAL.ACCOUNT.NOT_CONNECTED);
      connectWallet();
      return;
    }

    if (Object.keys(myProfile).length === 0) {
      toastError(Errors.GLOBAL.PROFILE.NOT_CREATED);
      return;
    }

    if (familyName.length === 0) {
      toastError(Errors.FAMILY.VALIDATE.EMPTY_NAME);
      return;
    }

    if (familyName === familyName.toUpperCase()) {
      toastError(Errors.FAMILY.VALIDATE.NOT_UPPERCASE);
      return;
    }

    const isInFamily = await hasFamily(account);

    if (isInFamily) {
      toastError(Errors.FAMILY.VALIDATE.IS_IN_FAMILY);
      return;
    }

    const register = async () => {
      const familyId = await getFamilyId(account);
      const signMsg = {
        id: familyId,
        name: familyName,
        city: parseInt(city),
        logo: "",
        address: account,
      };

      dispatch(signFamilyCreateAction({ signMsg }))
        .unwrap()
        .then((signature) => {
          dispatch(registerFamilyCreateAction({ signMsg, signature }))
            .unwrap()
            .then((name) => {
              toastSuccess(Messages.FAMILY.BASIC.CREATED_SUCCESS);

              setTimeout(() => {
                navigate(`/family/${name}`);
              }, CONFIRMATION_DELAY);
            })
            .catch((error) => {
              toastError(Errors.FAMILY.VALIDATE.CREATING_FAILED);
              console.log(error);
            });
        })
        .catch(() => {});
    };

    const create = () => {
      dispatchTxAction(
        dispatch,
        createFamilyAction(account),
        () => {
          register();
        },
        CONFIRMATION_DELAY
      );
    };

    const approve = () => {
      dispatchTxAction(
        dispatch,
        approveFamilyAction({ account, approveAmount: FAMILY_PRICE_IN_MAFIA }),
        () => {
          create();
        },
        3000
      );
    };

    approve();
  };

  return (
    <Box className={classes.body}>
      <Box className={classes.familyCreate}>
        <Box className={classes.familyCreateTitle}>Create new family</Box>

        <Box className={classes.familyCreateCost}>
          <Box>Cost:</Box>
          <Logo />
          <Box>
            {toUSDFormat(FAMILY_PRICE_IN_MAFIA)} {config.symbol}
          </Box>
        </Box>

        <Box className={classes.familyInfo}>
          <Box className={classes.infoInput}>
            <TextField
              placeholder="Input family name"
              variant="standard"
              className={classes.inputBox}
              InputProps={{
                disableUnderline: true, // Disable the underline
              }}
              value={familyName}
              onChange={handleFamilyNameChange}
              autoComplete="off"
              sx={{
                input: {
                  "&::placeholder": {
                    opacity: 1,
                  },
                },
              }}
            />
            <EditIcon htmlColor="gray" />
          </Box>

          <Box className={classes.infoOption}>
            <Select
              id="family-create-city-select"
              value={city}
              onChange={handleChange}
              className={classes.infoOptionSelect}
              IconComponent={KeyboardArrowDownIcon}
              MenuProps={{
                classes: {
                  paper: classes.infoOptionSelectPaper,
                  list: classes.infoOptionSelectList,
                },
              }}
            >
              {City.map((city, index) => {
                return (
                  <MenuItem value={index} key={index}>
                    {city}
                  </MenuItem>
                );
              })}
            </Select>

            <LocationOnIcon htmlColor="gray" />
          </Box>

          <Box className={classes.infoButton}>
            <Button
              component="label"
              endIcon={<FileUploadIcon htmlColor="gray" />}
            >
              <Typography
                fontFamily={"Philosopher"}
                fontWeight={900}
                lineHeight={"25px"}
                padding={"4px 0 "}
              >
                {file ? file.name : "Upload Logo"}
              </Typography>

              {file ? (
                <Box component="img" src={avatar}></Box>
              ) : (
                <Typography
                  fontFamily={"Philosopher"}
                  fontWeight={900}
                  fontSize={10}
                  color={"gray"}
                  lineHeight={"25px"}
                  padding={"4px 0 "}
                >
                  (optional)
                </Typography>
              )}

              <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            </Button>
          </Box>
        </Box>

        <Box className={classes.buttonSection}>
          <Button
            onClick={handleConfirmCreate}
            startIcon={isCreatingFamily ? "" : <CheckIcon />}
            disabled={isCreatingFamily}
          >
            {isCreatingFamily ? (
              <CircularProgress size={24} />
            ) : (
              "Confirm creation"
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FamilyCreate;
