import { Box } from "@mui/material";

import LandingVault from "../../components/LandingVault";

const Vault = () => {
  return (
    <Box>
      <LandingVault />
    </Box>
  );
};

export default Vault;
