import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";
import MainLayout from "./layout/MainLayout";
import CrateOpening from "./pages/CrateOpening";
import Family from "./pages/Family";
import FamilyCreatePage from "./pages/FamilyCreatePage";
import FamilyLeaderBoard from "./pages/FamilyLeaderBoard";
import BuyPage from "./pages/Landing";
import MarketPage from "./pages/Market";
import NewLanding from "./pages/NewLanding";
import Profile from "./pages/Profile";
import Vault from "./pages/Vault";
import Map from "./pages/Map";
import Exchange from "./pages/Exchange";

import "./app/axiosSetup"; // Make sure to import the interceptor setup
import { useAppDispatch } from "./app/hooks";
import { activityTracking } from "./reducers/auth.slice";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(activityTracking());
  }, [location, dispatch]);

  return (
    <Box>
      <MainLayout>
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<NewLanding />} />
          <Route path="/buy" element={<BuyPage />} />
          <Route path="/profile" element={<NewLanding />} />
          <Route path="/profile/:profileName" element={<Profile />} />
          <Route path="/vault" element={<Vault />} />

          <Route path="/family/" element={<FamilyLeaderBoard />} />
          <Route path="/familycreate" element={<FamilyCreatePage />} />
          <Route path="/family/:familyName" element={<Family />} />

          <Route path="/crate" element={<CrateOpening />} />

          <Route path="/market" element={<MarketPage />} />
          <Route path="/market/itemList" element={<MarketPage />} />
          <Route path="/market/history" element={<MarketPage />} />
          <Route path="/market/myListings" element={<MarketPage />} />
          <Route path="/market/item/:itemId" element={<MarketPage />} />

          <Route path="/exchange" element={<Exchange />} />

          <Route path="/map" element={<Map />} />
        </Routes>
      </MainLayout>
    </Box>
  );
};

export default App;
