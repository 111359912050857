import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  getMyInventoryItems,
  getMyProfile,
  getProfileNames,
  clearMyProfile,
} from "../reducers/profile.slice";
import { clearNotifications } from "../reducers/notification.slice";
import { UserProfile } from "../types/UserProfile";

const useProfile = (account: string) => {
  const dispatch = useAppDispatch();
  const [profileName, setProfileName] = useState("");

  const myProfile = useAppSelector((state) => state.profile.myProfile);

  useEffect(() => {
    if (!account) return;

    const fetchProfileNames = async () => {
      try {
        const profileNames = (await dispatch(
          getProfileNames()
        ).unwrap()) as UserProfile[];
        const profile = profileNames.find(
          (name) => name.address?.toLowerCase() === account.toLowerCase()
        );
        const profileName = profile?.name || "";
        const profileId = profile?.id || 0;

        if (profileName && profileId) {
          setProfileName(profileName);
          dispatch(getMyProfile(profileName));
          dispatch(getMyInventoryItems({ userId: profileId }));
          dispatch(clearNotifications());
        } else {
          dispatch(clearMyProfile());
          dispatch(clearNotifications());
        }
      } catch (error) {
        console.log("Failed to fetch profile names:", error);
        dispatch(clearMyProfile());
        dispatch(clearNotifications());
      }
    };

    fetchProfileNames();
  }, [account, dispatch]);

  return { profileName, myProfile };
};

export default useProfile;
