import { useAppDispatch } from "../app/hooks";
import { WalletErrorMsg } from "../constants/const";
import { Errors } from "../constants/errors";
import { toastError } from "../utils/utils";

// FIXME: Update all tx dispatch actions with this function
export const dispatchTxAction = (
  dispatch: ReturnType<typeof useAppDispatch>,
  action: any,
  callback: () => void,
  timeout?: number,
  messageTemplate?: { code: string; message: string }
) => {
  dispatch(action)
    .unwrap()
    .then(() => {
      callback();
    })
    .catch((error: any) => {
      if (error.message === WalletErrorMsg.Indexing) {
        if (!timeout) callback();
        else {
          setTimeout(() => {
            callback();
          }, timeout);
        }
      } else if (error.message === WalletErrorMsg.Rejected) {
        toastError(Errors.GLOBAL.WEB3.REJECTED);
      } else {
        toastError(
          messageTemplate ? messageTemplate : Errors.GLOBAL.WEB3.UNKNOWN
        );
      }
    });
};
