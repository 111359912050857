import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Logo from "../Logo";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { dispatchTxAction } from "../../helper/dispatchTxAction";

import {
  acceptFamilyInvitationAction,
  getFamilyDetailById,
  getFamilyDetailByName,
  rejectFamilyInvitationAction,
} from "../../reducers/family.slice";

import { FamilyDetail } from "../../types/FamilyDetails";
import { FamilyInfo } from "../../types/FamilyInfo";
import { FamilyInvitation } from "../../types/FamilyInvitation";

import { Messages } from "../../constants/messages";
import { toastInfo, toastSuccess } from "../../utils/utils";

import useStyles from "./index.styles";

interface FamilyInvitationMemberPopupProps {
  openInviteModal: boolean;
  setOpenInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
  invitationInfo?: FamilyInvitation;
}

const FamilyInvitationMemberPopup: React.FC<
  FamilyInvitationMemberPopupProps
> = ({ openInviteModal, setOpenInviteModal, invitationInfo }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const { myProfile, profileNames } = useAppSelector((state) => state.profile);

  const [inviter, setInviter] = useState("");
  const [familyInfo, setFamilyInfo] = useState<FamilyInfo>();

  const handleClose = () => {
    setOpenInviteModal(false);
  };

  const handleAcceptInvitation = () => {
    if (!myProfile.address || !invitationInfo) return;

    dispatchTxAction(
      dispatch,
      acceptFamilyInvitationAction({
        account: myProfile.address,
        invitationId: invitationInfo?.id,
      }),
      () => {
        toastSuccess(Messages.FAMILY.INVITE.JOINED_SUCCESS);
        setTimeout(() => {
          setOpenInviteModal(false);

          dispatch(getFamilyDetailByName(familyInfo?.name || ""));
        }, 2000);
      }
    );
  };

  const handleRejectInvitation = () => {
    if (!myProfile.address || !invitationInfo) return;

    dispatchTxAction(
      dispatch,
      rejectFamilyInvitationAction({
        account: myProfile.address,
        invitationId: invitationInfo?.id,
      }),
      () => {
        toastInfo(Messages.FAMILY.INVITE.REJECTED);

        setOpenInviteModal(false);
      }
    );
  };

  useEffect(() => {
    if (!invitationInfo) return;
    const fetchData = () => {
      const inviter = profileNames.find(
        (profile, index) =>
          profile.address?.toLowerCase() ===
          invitationInfo?.inviter.toLowerCase()
      );

      dispatch(getFamilyDetailById(invitationInfo?.familyId))
        .unwrap()
        .then((familyInfo: FamilyDetail) => {
          setFamilyInfo(familyInfo.familyInfo);
        })
        .catch(() => {});
      setInviter(inviter?.name || "");
    };

    fetchData();
  }, [invitationInfo, dispatch, profileNames]);

  return (
    <Dialog
      open={openInviteModal}
      onClose={handleClose}
      className={classes.body}
    >
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        <Box className={classes.contentBody}>
          <Box className={classes.inviteText}>Family invitation</Box>
          <Box className={classes.inviteDescription}>
            You have been invited to join the family by{" "}
            <Link
              className={classes.link}
              to={`/profile/${inviter}`}
              target="_blank"
            >
              {inviter}
            </Link>
          </Box>
          <Box className={classes.logoSection}>
            {familyInfo?.logo ? (
              <Box
                component="img"
                src={familyInfo.logo}
                className={classes.logoImg}
              ></Box>
            ) : (
              <Logo classNames={classes.logoImg} />
            )}

            <Link className={classes.link} to={`/family/${familyInfo?.name}`}>
              {familyInfo?.name}
            </Link>
          </Box>
          <Box className={classes.buttonSection}>
            <Button
              className={classes.acceptBut}
              onClick={handleAcceptInvitation}
            >
              Accept
            </Button>
            <Button
              className={classes.declineBut}
              onClick={handleRejectInvitation}
            >
              Decline
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default FamilyInvitationMemberPopup;
