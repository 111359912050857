import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    fontFamily: "Philosopher",
    justifyContent: "center",

    backgroundSize: "auto 100%",
    backgroundPosition: "100%",

    maxWidth: "1920px",
    margin: "auto",
    boxSizing: "border-box",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      padding: "16px 16px",
    },
  },

  container: {
    gap: "48px",
    display: "flex",
    // margin: "auto",
    marginBottom: 16,
    marginTop: 16,

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginBottom: 16,
      marginTop: 16,
      width: "100%",
    },
  },

  card: {
    width: "100%",

    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    backgroundColor: theme.palette.background.cardBgTransparent,
    border: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    height: "100%",
    minHeight: 240,
    padding: "24px 24px",
    position: "relative",
    gap: "12px",
    justifyContent: "flex-start",
    boxShadow:
      "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",

    boxSizing: "border-box",

    [theme.breakpoints.down("md")]: {
      padding: "16px 16px",
    },

    [theme.breakpoints.down("sm")]: {
      gap: 12,
    },
  },

  cardHeader: {
    display: "flex",
    justifyContent: "space-between",

    "& button": {
      "& svg": {
        color: theme.palette.text.primary,
      },

      "&:disabled": {
        "& svg": {
          color: theme.palette.text.secondary,
        },
      },
    },
  },

  cardHeaderTitle: {
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontSize: 24,
    fontWeight: 900,

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  cardContent: {
    color: theme.palette.text.secondary,

    "& .rdw-editor-main": {
      maxHeight: 180,
      overflowY: "auto",
    },

    "& .rdw-editor-toolbar": {
      backgroundColor: "#0f1111",
      border: "none",
    },

    ", & .rdw-option-wrapper": {
      backgroundColor: "#646464",
      border: "none",
      outline: "none",
      color: "black",

      [theme.breakpoints.down("sm")]: {
        minWidth: 10,
        height: 14,
      },
    },

    "& .rdw-dropdown-wrapper": {
      backgroundColor: "#646464",
      border: "none",
      outline: "none",
      color: "black",

      boxShadow: "0px 0px 0px #BFBDBD",
      transition: "300ms",

      "&:hover": {
        backgroundColor: "#858585",
      },
    },

    "& .rdw-dropdown-optionwrapper": {
      backgroundColor: "#646464",
      border: "none",
      padding: 8,
      marginTop: 4,
      borderRadius: 2,
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      boxShadow: "0px 0px 0px #BFBDBD",

      "& .rdw-dropdownoption-highlighted": {
        backgroundColor: "#909090",
      },
    },
  },

  lettersLength: {
    position: "absolute",
    textAlign: "right",
    bottom: 10,
    right: 10,
    color: "white",
  },

  description: {
    overflowY: "auto",
  },
}));

export default styles;
