import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
    },

    "& .MuiDialog-paper": {
      overflowY: "initial",
    },
  },

  modalContent: {
    position: "relative",
    overflowY: "scroll",
  },

  closeIconBody: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 25,
    top: 25,
    zIndex: 1,

    "&>button": {
      padding: 0,
      minWidth: "auto",
      "&>svg": {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        transition: ".3s",
        fontSize: "1.5rem",
        "&:hover": {
          color: theme.palette.text.primary,
          transition: ".3s",
        },
      },
    },
  },

  contentBody: {
    display: "flex",
    flexDirection: "column",
    gap: 32,
    padding: "48px 60px 28px 60px",
    minWidth: 320,
    backgroundSize: "auto 100%",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "48px 16px 28px 16px",
    },
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 16,

    "& img": {
      width: 60,
      height: 54,
      // padding: "4px 8px",
      borderRadius: 8,
      border: "1px solid",
      borderColor: theme.palette.background.defaultBorder,

      background: theme.palette.background.cardBgTransparent,
      boxShadow:
        "inset 0 -3px 10px 0px rgb(0 0 0 / 15%), inset 3px 0 10px 0px rgb(0 0 0 / 15%), inset 0 3px 10px 0px rgb(0 0 0 / 15%), inset -3px 0 10px 0px rgb(0 0 0 / 15%)",
    },
  },

  expireDescription: {
    color: theme.palette.text.secondary,
    fontSize: 12,

    display: "flex",
    alignItems: "center",
    gap: 8,
  },

  feeDescription: {
    color: theme.palette.text.secondary,
    fontSize: 12,
  },

  bidSection: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },

  bidInfo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 16,

    [theme.breakpoints.down("sm")]: { gap: 4 },
  },

  bidFeeInfo: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    color: theme.palette.text.secondary,
    fontSize: 12,
    width: 96,
  },

  bidInfoLabel: {
    color: theme.palette.text.secondary,
    width: 96,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  bitButton: {
    width: 96,
    fontFamily: "Philosopher",
    fontWeight: "bold",
    fontSize: 12,
    padding: "6px 24px",
    background: theme.palette.background.defaultButton,
    color: theme.palette.text.primary,
    transition: "300ms",

    "&:hover": {
      background: theme.palette.background.defaultButton,
      filter: "brightness(1.2)",
    },
  },

  inputBox: {
    borderRadius: "12px",
    boxSizing: "border-box",
    width: 140,
    padding: "4px 16px",
    border: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    color: theme.palette.text.primary,
    outline: "none",

    "& input": {
      outline: "none!important",
      border: "none",
      color: theme.palette.text.primary,
      fontFamily: "Philosopher",
      fontSize: "18px",
      fontWeight: 900,

      "&::placeholder": {
        fontSize: 12,
        color: theme.palette.text.secondary,
      },

      "&:disabled": {
        color: theme.palette.text.primary,
        WebkitTextFillColor: theme.palette.text.primary,
      },
    },

    "& .MuiTextField-root::after": {
      content: "initial",
    },
  },

  historySection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 32,
  },

  bidHistoryList: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "120px",
    overflowY: "scroll",
  },

  bidHistory: {
    padding: "8px 16px",
    display: "grid",
    gridTemplateColumns: "1fr 2.5fr 1fr",
    borderBottom: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    alignItems: "center",
    gap: 16,
  },

  link: {
    textDecoration: "none",
    color: theme.palette.text.linkColor,
  },

  avatar: {
    width: 48,
    height: 24,
    objectFit: "cover",
    objectPosition: "top",
    borderRadius: 4,
  },
}));

export default styles;
