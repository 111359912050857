import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    marginTop: -64,

    background: "url(/assets/imgs/family/bg1.png) no-repeat",
    backgroundSize: "100% auto",
    // backgroundPosition: "center",
    [theme.breakpoints.down("lg")]: {
      backgroundPosition: "top",
    },
  },
}));

export default styles;
