import { Box } from "@mui/material";

import bnbIcon from "../../assets/imgs/bnb.png";
import plsIcon from "../../assets/imgs/pls.png";
import { CHAIN } from "../../config/config";
import { ChainType } from "../../constants/enum/enum";

interface TokenImageProps {
  classNames?: string;
  sx?: object;
}

export default function NativeIcon(props: TokenImageProps) {
  return (
    <Box
      sx={{
        ...props.sx,
      }}
      className={props.classNames}
      component="img"
      src={CHAIN === ChainType.PLS ? plsIcon : bnbIcon}
    ></Box>
  );
}
