import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
    },

    "& .MuiDialog-paper": {
      overflowY: "initial",
    },
  },

  modalContent: {
    position: "relative",
  },

  closeIconBody: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 25,
    top: 25,
    zIndex: 1,

    "&>button": {
      padding: 0,
      minWidth: "auto",
      "&>svg": {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        transition: ".3s",
        fontSize: "1.5rem",
        "&:hover": {
          color: theme.palette.text.primary,
          transition: ".3s",
        },
      },
    },
  },

  contentBody: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    padding: "24px 16px 24px 16px",
    minWidth: 320,
    backgroundSize: "auto 100%",

    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px 24px 16px",
    },
  },

  tokenListHeader: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    alignItems: "center",
    justifyItems: "grid-start",
    gap: 8,
    fontFamily: "Philosopher",
    padding: "8px 16px",
    borderRadius: 4,
    transition: "300ms",
    cursor: "pointer",
    justifyContent: "space-between",

    color: theme.palette.text.secondary,
  },

  tokenListItem: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    alignItems: "center",
    justifyItems: "grid-start",
    gap: 8,

    padding: "8px 16px",
    borderRadius: 4,
    transition: "300ms",
    cursor: "pointer",
    justifyContent: "space-between",
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 14,

    "& img": {
      width: 20,
      height: 20,
    },

    "&:hover": {
      backgroundColor: theme.palette.background.whiteTransparent1,
    },
  },

  tokenListToken: {
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    gap: 8,
  },
}));

export default styles;
