import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage";
import { initializeApp } from "firebase/app";

import config from "../config/config";

const app = initializeApp(config.newFirebaseConfig);

// Initialize Firebase Storage and export it
const storage = getStorage(app, config.storageBucket);

export const listAllFiles = async (bucket: string) => {
  const storage = getStorage();

  // Create a reference under which you want to list
  const listRef = ref(storage, bucket);

  const downloadUrls: string[] = [];

  try {
    const res = await listAll(listRef);
    const items = res.items;

    // Process items in chunks of 200
    const chunkSize = 200;
    for (let i = 0; i < items.length; i += chunkSize) {
      const chunk = items.slice(i, i + chunkSize);

      const promises = chunk.map((item) => {
        const itemRef = ref(storage, `${item.fullPath}`);
        return getDownloadURL(itemRef);
      });

      const urls = await Promise.all(promises);
      downloadUrls.push(...urls);

      if (i + chunkSize < items.length) {
        // Wait for 1 second before processing the next chunk
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }

    console.log(downloadUrls);
  } catch (error) {
    console.error("Error listing files:", error);
  }
};

export { app as firebaseApp, storage };
