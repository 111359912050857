import { Box } from "@mui/material";

interface DotProps {
  sx?: React.CSSProperties;
  width: number;
  color: string;
  isShadow?: boolean;
}

const Dot = (props: DotProps) => {
  return (
    <Box
      sx={{
        borderRadius: "50%",
        width: props.width,
        height: props.width,
        backgroundColor: props.color,
        boxShadow: props.isShadow
          ? `${props.color} 0px 0px 8px, ${props.color} 0px 0px 16px, ${props.color} 0px 0px 24px, ${props.color} 0px 0px 56px`
          : null,
        ...props.sx,
      }}
    ></Box>
  );
};

export default Dot;
