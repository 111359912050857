import axios from "axios";
import { store } from "../app/store";

axios.interceptors.request.use(
  (config) => {
    const { signMsg, signature } = store.getState().auth;

    // Ensure signMsg and signature are correctly formatted
    if (typeof signMsg !== "string" || typeof signature !== "string") {
      console.error("Invalid signMsg or signature format");
      return config;
    }

    config.headers.signMsg = signMsg;
    config.headers.signature = signature;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
