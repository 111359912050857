import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
    },
  },

  modalContent: {
    position: "relative",
  },

  contentBody: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: 48,
    minWidth: 320,

    "& img": {
      width: 60,
    },

    [theme.breakpoints.down("sm")]: {
      padding: "48px 0px",
    },
  },

  logoSection: {
    display: "flex",
    justifyContent: "center",
  },

  logoImg: {
    minWidth: "100px",
  },

  closeIconBody: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 25,
    top: 25,
    "&>button": {
      padding: 0,
      minWidth: "auto",
      "&>svg": {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        transition: ".3s",
        fontSize: "1.5rem",
        "&:hover": {
          color: theme.palette.text.primary,
          transition: ".3s",
        },
      },
    },
  },
  inviteText: {
    fontWeight: 800,
    display: "flex",
    fontSize: "25px",
    justifyContent: "center",
    marginTop: "8px",
  },
  inviteInput: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 8,

    "& .MuiInputBase-input": {
      padding: "8px 0",
      color: theme.palette.text.primary,
      textAlign: "center",
      minWidth: "200px",
      width: "100%",
      backgroundColor: "#282828",
      borderRadius: "8px",
      marginTop: "16px",
      fontFamily: "Philosopher",
    },
    "& fieldset": {
      outline: "none",
      border: "none",
    },

    "& label": {
      color: theme.palette.text.secondary,
    },
  },
  buttonSection: {
    display: "flex",
    justifyContent: "center",

    "&>button": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.defaultButton,
      borderRadius: "8px",
      padding: "8px 16px",
      textTransform: "none",
      minWidth: "150px",
      fontFamily: "Philosopher",
      transitionDuration: "300ms",

      "&:disabled": {
        opacity: "0.8",
      },
    },

    "& span": {
      color: "white",
    },
  },
}));

export default styles;
