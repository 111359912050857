import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {},
  startedBut: {
    textTransform: "none",
    color: "white",
    backgroundColor: "#1daa70",
    padding: "16px 32px",
    fontSize: "20px",
    fontFamily: "philosopher",
    borderRadius: "16px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 16px",
      fontSize: "16px",
    },
  },
  createContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    alignItems: "center",
  },

  inputNameContainer: {
    display: "flex",
    gap: "8px",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },

  validationMessage: {
    position: "absolute",
    textAlign: "center",
    transitionDuration: "0.3s",
    bottom: "-24px",
    width: "150%",
  },

  countrySelectBox: {
    minWidth: 200,
    width: "100%",
    margin: 0,
    backgroundColor: "#16191a",
    borderRadius: "20px",
    fontSize: "20px",

    "& .MuiInputBase-root": {
      height: "50px",
      color: "white",
      width: "100%",

      "& .MuiSelect-select": {
        textAlign: "center",
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiSvgIcon-root": {
        color: "white",
      },
    },
  },

  countrySelect: {
    border: "2px solid #202527",
    borderRadius: "12px",

    padding: "16px 32px",
    backgroundColor: theme.palette.background.containerBg,
    color: theme.palette.text.containerColor,
    outline: "none",
    maxWidth: "198px",

    boxSizing: "content-box",

    "& option": {
      color: "white",
      padding: "4px",
    },
  },

  genderSwitch: {
    display: "flex",
    gap: "32px",
    justifyContent: "space-around",
    width: "100%",
    maxWidth: "240px",
  },

  genderContainer: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    gap: "8px",

    "& div": {
      color: theme.palette.text.primary,
      width: "100%",
      textAlign: "center",
    },
  },

  genderButton: {
    borderRadius: "50%",
    backgroundColor: theme.palette.background.gray,
    padding: "16px",

    "&:hover": {
      backgroundColor: theme.palette.background.grayHover,
    },
  },

  genderSelected: {
    backgroundColor: theme.palette.background.graySelected,
  },

  genderIcon: {
    width: "32px",
    height: "32px",
  },

  createAccountButton: {
    backgroundColor: theme.palette.background.createButtonBg,
    padding: "16px",
    borderRadius: "8px",
    maxWidth: "160px",
    fontFamily: "Philosopher",
    fontWeight: 900,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  createCostInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    color: theme.palette.text.secondary,
    fontWeight: 900,
    fontSize: "12px",
    alignItems: "center",
  },

  createCostToken: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.primary,
    fontSize: "20px",

    "& img": {
      width: "28px",
      height: "28px",
    },
  },

  createCostUSD: {
    fontSize: "16px",
    color: theme.palette.text.containerColor,
  },

  inputBox: {
    border: "2px solid #202527",
    borderRadius: "12px",

    padding: "8px 32px",
    backgroundColor: theme.palette.background.containerBg,
    color: theme.palette.text.containerColor,
    outline: "none",
    maxWidth: "198px",

    "& input": {
      outline: "none!important",
      border: "none",
      color: theme.palette.text.primary,
      fontFamily: "Philosopher",
      fontSize: "18px",
    },

    "& .MuiTextField-root::after": {
      content: "initial",
    },
  },
}));

export default styles;
