import { Box, ButtonBase } from "@mui/material";

import checkmark from "../../assets/imgs/profile/mark.png";
import useStyles from "./CustomComboBox.styles";

const CustomComboBox = ({
  label,
  checked,
  disabled = false,
  handleChange,
}: {
  label: string;
  checked: boolean;
  disabled?: boolean;
  handleChange: () => void;
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.customCheckBox}>
      <Box>{label}</Box>

      <ButtonBase disabled={disabled}>
        <Box
          className={classes.checkBox}
          onClick={() => {
            handleChange();
          }}
        >
          {checked && (
            <Box
              component="img"
              src={checkmark}
              className={classes.checkmark}
            ></Box>
          )}
        </Box>
      </ButtonBase>
    </Box>
  );
};

export default CustomComboBox;
