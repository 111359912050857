import { Box } from "@mui/material";

import useStyles from "./index.styles";
import mapLogo from "../../assets/imgs/map/maps.png";

const Map = () => {
  const { classes } = useStyles();

  return (
    <Box>
      <Box className={classes.body}>
        <Box className={classes.comingsoon}>
          <Box className={classes.mapLogoSection}>
            <Box
              component={"img"}
              className={classes.mapLogo}
              src={mapLogo}
            ></Box>
            The Map
          </Box>
          <Box className={classes.desSection}>
            <Box className={classes.comingSoonText}>Coming soon</Box>
            <Box className={classes.description}>
              Get ready to build your empire
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Map;
