import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
    },

    "& .MuiDialog-paper": {
      overflowY: "initial",
    },
  },

  modalContent: {
    position: "relative",
    overflowY: "scroll",
  },

  closeIconBody: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 25,
    top: 25,
    zIndex: 1,

    "&>button": {
      padding: 0,
      minWidth: "auto",
      "&>svg": {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        transition: ".3s",
        fontSize: "1.5rem",
        "&:hover": {
          color: theme.palette.text.primary,
          transition: ".3s",
        },
      },
    },
  },

  contentBody: {
    display: "flex",
    flexDirection: "column",
    gap: 48,
    padding: "48px 60px 28px 60px",
    minWidth: 320,
    backgroundSize: "auto 100%",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "48px 16px 28px 16px",
    },
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 16,
  },

  description: {
    color: theme.palette.text.secondary,
    textAlign: "center",
  },

  durationInfoSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 24,
  },

  durationOptionList: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 16,

    [theme.breakpoints.down("sm")]: {
      gap: 8,
    },
  },

  durationOption: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    color: theme.palette.text.secondary,
    textAlign: "center",
    padding: "8px 8px",
    border: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    borderRadius: 8,
  },

  payoutInfoSection: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    textAlign: "center",
  },

  payoutOptionList: {
    display: "flex",
    justifyContent: "center",
    gap: 48,
  },

  payoutOption: {
    backgroundColor: theme.palette.background.lightGray,
    padding: "8px 16px",
    width: 90,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    boxSizing: "border-box",
    gap: 8,

    color: theme.palette.text.secondary,

    cursor: "pointer",
  },

  switchOptionIcon: {
    width: 36,
    height: 36,
    opacity: 0.2,
  },

  switchOptionIconSymbol: {
    width: 24,
    height: 24,
  },

  symbolName: {
    color: theme.palette.text.primary,
  },

  feeInfo: {
    color: theme.palette.text.secondary,
    fontSize: 12,
  },

  feeDescription: {
    marginTop: 16,
    color: theme.palette.text.secondary,
    fontSize: 12,
  },
}));

export default styles;
