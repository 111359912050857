import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Typography,
  Collapse,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Logo from "../../components/Logo";
import config, { CHAIN } from "../../config/config";
import { NavBarPages } from "../../constants/const";
import { ChainType } from "../../constants/enum/enum";
import useWallet from "../../hook/useWallet";
import { useStyles } from "./index.styles";
import useProfile from "../../hook/useProfile";
import { shortAddress } from "../../utils/utils";

const SideBar = ({
  toggleSideBar,
  onConnectWallet,
}: {
  toggleSideBar: React.Dispatch<React.SetStateAction<boolean>>;
  onConnectWallet: () => void;
}) => {
  const { classes } = useStyles();
  const { disconnectWallet, account, isConnected } = useWallet();
  const { profileName } = useProfile(account);
  const currentLink = useLocation();
  const [activeSubMenu, setActiveSubMenu] = useState<boolean>(false);

  const handleSubMenuToggle = () => {
    setActiveSubMenu((prev) => !prev); // Toggle submenu
  };

  const NewBadge = () => {
    return <Box className={classes.badge}>NEW</Box>;
  };

  return (
    <Box className={classes.sidebar} role="presentation">
      <List
        subheader={
          <ListSubheader
            className={classes.sidebarHeader}
            component="div"
            id="nested-list-subheader"
          >
            <Logo classNames={classes.sidebarLogo} />
            <NavLink to="/"> {config.title}</NavLink>
          </ListSubheader>
        }
      >
        {NavBarPages.map(
          (item, index) =>
            (!item.plsDisabled ||
              (item.plsDisabled && CHAIN === ChainType.BNB)) && (
              <Box key={index}>
                <ListItem key={item.path}>
                  <ListItemButton
                    onClick={
                      () =>
                        item.subNav
                          ? handleSubMenuToggle()
                          : toggleSideBar(false) // Toggle sidebar only for non-submenu items
                    }
                  >
                    <NavLink
                      className={classes.sidebarNavLink}
                      to={item.subNav ? currentLink : item.path || ""}
                    >
                      <Box className={classes.mobileSidebarList}>
                        {item.title}
                        {item.new && <NewBadge />}
                        {item.subNav && (
                          <ExpandMoreIcon
                            sx={{
                              transform: activeSubMenu
                                ? "rotate(180deg)"
                                : "rotate(0)",
                              transition: "transform 0.3s",
                            }}
                          />
                        )}
                      </Box>
                    </NavLink>
                  </ListItemButton>
                </ListItem>
                {item.subNav && (
                  <Collapse in={activeSubMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.subNav.map((sub) => (
                        <ListItem key={sub.path} sx={{ pl: 4 }}>
                          <ListItemButton onClick={() => toggleSideBar(false)}>
                            <NavLink
                              className={classes.sidebarNavLink}
                              to={sub.path || ""}
                              onClick={(e) => {
                                if (sub.blank) {
                                  e.preventDefault();
                                  window.open(sub.path, "_blank");
                                }
                              }}
                            >
                              <Box
                                component={"img"}
                                src={`/assets/imgs/layout/${sub.icon}`}
                                width={21}
                              />
                              {sub.title}
                            </NavLink>
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </Box>
            )
        )}
      </List>
      <Divider />
      <List>
        {isConnected && (
          <ListItem>
            <ListItemButton className={classes.sidebarNavLink}>
              <NavLink
                className={classes.sidebarNavLink}
                to={`/profile/${profileName}`}
              >
                <Typography component="span" sx={{ fontWeight: 700 }}>
                  {shortAddress(account)}
                </Typography>
                <Box
                  component="img"
                  width={18}
                  sx={{ marginLeft: "8px" }}
                  src="/assets/imgs/layout/user.png"
                ></Box>
              </NavLink>
            </ListItemButton>
          </ListItem>
        )}

        <ListItem>
          <ListItemButton
            className={classes.sideBarConnectWalletBtn}
            onClick={() =>
              isConnected ? disconnectWallet() : onConnectWallet()
            }
          >
            <Typography component="span" sx={{ fontWeight: 700 }}>
              {isConnected ? "Disconnect" : "Connect Wallet"}
            </Typography>
            {isConnected && (
              <Box
                component="img"
                width={18}
                sx={{ marginLeft: "8px" }}
                src="/assets/imgs/layout/power-off.png"
              ></Box>
            )}
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

export default SideBar;
