import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import InventoryItemIcon from "../InventoryItemIcon";

import { ItemInfo } from "../../types/ItemInfo";

import { getInventoryItemInfo } from "../../helper/inventory";

import useStyles from "./CustomDropDown.styles";

interface ListingItemDropDownProps {
  readonly sx?: React.CSSProperties;
  readonly items: ItemInfo[];
  readonly value?: string;
  readonly disabled?: boolean;
  readonly handleChange?: (event: SelectChangeEvent) => void;
  readonly placeholder?: string;
}

export default function ListingItemDropDown({
  disabled = false,
  placeholder = "",
  sx,
  items,
  value,
  handleChange,
}: ListingItemDropDownProps) {
  const { classes } = useStyles();

  return (
    <FormControl
      className={classes.formControl}
      sx={{
        ...sx,
      }}
    >
      {!value && <Box className={classes.placeholder}>{placeholder}</Box>}
      <Select
        value={value}
        disabled={disabled}
        onChange={handleChange}
        displayEmpty
        IconComponent={ExpandMoreIcon}
        inputProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: 320,
              },
              sx: {
                display: "block",
                padding: 0,
                backgroundColor: "#282828",
                color: "white",
              },
            },
            children: {
              sx: {
                fontSize: "12px",
              },
            },
          },
        }}
      >
        {items?.map((item, index) => {
          const itemInfo = getInventoryItemInfo(
            item.categoryId,
            item.typeId,
            item.cityId
          );

          return (
            <MenuItem
              key={index}
              value={item.itemId}
              className={classes.menuItem}
            >
              <InventoryItemIcon
                categoryId={item.categoryId}
                typeId={item.typeId}
                sx={{
                  width: 24,
                  height: 24,
                  borderRadius: "4px",
                  marginRight: "8px",
                }}
              />
              <Box className={classes.detail}>{itemInfo.itemName}</Box>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
