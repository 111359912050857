import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAppDispatch } from "../../app/hooks";
import {
  getFamilyDetailByName,
  signData,
  updateCapoRegimeAction,
} from "../../reducers/family.slice";
import { Capo } from "../../types/Capo";
import useStyles from "./index.styles";
import { Messages } from "../../constants/messages";
import { Errors } from "../../constants/errors";
import { toastError, toastSuccess } from "../../utils/utils";

interface CapoDropDownProps {
  readonly sx?: React.CSSProperties;
  readonly items?: string[];
  readonly value?: string;
  readonly disabled?: boolean;
  readonly address: string;
  readonly capoRegime: Capo[];
}

export default function CapoDropDown({
  sx,
  items,
  value,
  address,
  capoRegime,
  disabled = false,
}: CapoDropDownProps) {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { familyName } = useParams();

  const [capoName, setCapoName] = useState(value);

  const handleChange = (event: SelectChangeEvent) => {
    const capoName = event.target.value;
    const capoId = capoRegime.find(
      (capo, index) => capo.capoBoss === capoName
    )?.capoId;

    const signMsg = {
      address,
      capoId: capoId || 0,
    };

    dispatch(signData({ signMsg }))
      .unwrap()
      .then((signature) => {
        dispatch(
          updateCapoRegimeAction({
            signature,
            signMsg,
          })
        )
          .unwrap()
          .then(() => {
            toastSuccess(Messages.FAMILY.CAPO_REGIME.MEMBER_UPDATED);
            setCapoName(capoName);
            dispatch(getFamilyDetailByName(familyName || ""));
          })
          .catch(() => {
            toastError(Errors.FAMILY.CAPO_REGIME.UPDATE_MEMBER_FAILED);
          });
      })
      .catch(() => {
        toastError(Errors.GLOBAL.ACCOUNT.SIGNING_FAILED);
      });
  };

  useEffect(() => {
    setCapoName(value);
  }, [value]);

  return (
    <FormControl
      className={classes.formControl}
      sx={{
        ...sx,
      }}
    >
      <Select
        disabled={disabled}
        value={capoName}
        onChange={handleChange}
        displayEmpty
        IconComponent={ExpandMoreIcon}
        inputProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                display: "block",
                padding: 0,
                backgroundColor: "#282828",
                color: "white",
              },
            },
            children: {
              sx: {
                fontSize: "12px",
              },
            },
          },
        }}
      >
        {items?.map((item, index) => (
          <MenuItem key={index} value={item} className={classes.menuItem}>
            <Box className={classes.detail}>{item}</Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
