import { City, ItemCategoryInfoList } from "../constants/const";
import { ItemCategory } from "../constants/enum/enum";
import { toUSDFormat } from "../utils/utils";

export const getItemCategory = (categoryId: number) => {
  return ItemCategoryInfoList[categoryId];
};

export const getInventoryItemInfo = (
  categoryId: number,
  typeId: number,
  cityId?: number
) => {
  return {
    itemName: getInventoryItemName(categoryId, typeId, cityId),
    itemIcon: getInventoryItemIcon(categoryId, typeId),
    itemValue: getInventoryItemValue(categoryId, typeId),
  };
};

export const getInventoryItemName = (
  categoryId: number,
  typeId: number,
  cityId?: number
) => {
  const category = getItemCategory(categoryId);
  const value = getInventoryItemValue(categoryId, typeId);

  return typeof category.values[typeId] === "number"
    ? `${category.name} - ${value}`
    : category.id === ItemCategory.BUSINESS
    ? category.values[typeId] +
      (cityId !== undefined ? " - " + City[cityId] : "")
    : category.values[typeId];
};

export const getInventoryItemIcon = (categoryId: number, typeId: number) => {
  const category = getItemCategory(categoryId);
  const icon =
    category.icons.length === 0 ? category.icon : category.icons[typeId];

  return `/assets/imgs/inventory/${icon}`;
};

export const getInventoryItemValue = (categoryId: number, typeId: number) => {
  const category = getItemCategory(categoryId);
  const value = category.values[typeId];

  return typeof value === "number"
    ? category.currency
      ? `${category.currency} ${toUSDFormat(value)}`
      : `${toUSDFormat(value)}`
    : value;
};
