import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  TextField,
} from "@mui/material";
import { useState } from "react";

import Logo from "../Logo";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { INVITE_LIMIT_COUNT } from "../../constants/const";
import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";
import useWallet from "../../hook/useWallet";

import {
  getFamilyInvitationLogs,
  hasFamily,
} from "../../helper/contractFunctions/MafiaFamily";
import { dispatchTxAction } from "../../helper/dispatchTxAction";

import { inviteFamilyMemberAction } from "../../reducers/family.slice";
import {
  isEthereumAddress,
  isStartWith0x,
  shortAddress,
  toastError,
  toastSuccess,
} from "../../utils/utils";

import useStyles from "./index.styles";

interface FamilyInviteMemberPopupProps {
  openInviteModal: boolean;
  setOpenInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const FamilyInviteMemberPopup: React.FC<FamilyInviteMemberPopupProps> = ({
  openInviteModal,
  setOpenInviteModal,
}) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { profileNames } = useAppSelector((state) => state.profile);
  const { familyDetail, isInvitingFamilyMember } = useAppSelector(
    (state) => state.family
  );

  const { account } = useWallet();

  const [playerName, setPlayerName] = useState("");
  const [label, setLabel] = useState("Input player name");

  const handleOnChangeName = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const playerName = e.target.value;
    setPlayerName(playerName);

    if (isStartWith0x(playerName)) {
      if (isEthereumAddress(playerName)) {
        const profile = profileNames.find(
          (profileName, index) => profileName.address === playerName
        );

        if (profile) {
          setLabel(profile.name || "");
        } else {
          setLabel("No profile exist.");
        }
      } else {
        setLabel("Invalid address.");
      }
    } else {
      const profile = profileNames.find(
        (profileName, index) => profileName.name === playerName
      );

      if (profile) {
        setLabel(shortAddress(profile.address || ""));
      } else {
        setLabel("");
      }
    }
  };

  const handleConfirm = async () => {
    let lastInviteTimeStamp = -1;
    try {
      const timestamp = await getFamilyInvitationLogs(
        familyDetail.id || 0,
        INVITE_LIMIT_COUNT - 1 // Get last timestamp of last index
      );
      lastInviteTimeStamp = Number(timestamp);
    } catch (error) {}

    // Check if invite limit was already exceeded
    if (lastInviteTimeStamp > 0) {
      try {
        const timestamp = await getFamilyInvitationLogs(
          familyDetail.id || 0,
          0 // Get first timestamp
        );
        lastInviteTimeStamp = Number(timestamp);
      } catch (error) {}
    }

    if (lastInviteTimeStamp > new Date().getTime() / 1000 - 3600) {
      toastError(Errors.FAMILY.INVITE.LIMIT_EXCEEDED);
      return;
    }

    const profile = profileNames.find(
      (profileName, index) =>
        profileName.name === playerName || profileName.address === playerName
    );

    if (profile && profile.address) {
      if (!isStartWith0x(playerName))
        setLabel(shortAddress(profile.address || ""));

      const isInFamily: boolean = await hasFamily(profile.address);

      if (isInFamily) {
        setLabel(`The player is already in a family.`);
      } else {
        const familyId = familyDetail.id;

        dispatchTxAction(
          dispatch,
          inviteFamilyMemberAction({
            account,
            familyId: familyId || 0,
            invitee: profile.address,
          }),
          () => {
            toastSuccess(Messages.FAMILY.INVITE.INVITED, {
              name: profile.name || "",
            });
            setPlayerName("");
            setLabel("");
          }
        );
      }
    } else {
      if (isStartWith0x(playerName))
        setLabel(`No player exist with the address.`);
      else setLabel(`No player exist with the name.`);
    }
  };

  const handleClose = () => {
    setOpenInviteModal(false);
  };

  return (
    <Dialog
      open={openInviteModal}
      onClose={handleClose}
      className={classes.body}
    >
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        <Box className={classes.contentBody}>
          <Box className={classes.logoSection}>
            {familyDetail.logo ? (
              <Box
                component="img"
                src={familyDetail.logo}
                className={classes.logoImg}
              ></Box>
            ) : (
              <Logo classNames={classes.logoImg} />
            )}
          </Box>
          <Box className={classes.inviteText}>Invite member</Box>
          <Box className={classes.inviteInput}>
            <TextField
              placeholder="Username"
              onChange={handleOnChangeName}
              value={playerName}
            />

            <label>{label}</label>
          </Box>
          <Box className={classes.buttonSection}>
            <Button onClick={handleConfirm} disabled={isInvitingFamilyMember}>
              {isInvitingFamilyMember ? (
                <CircularProgress size={24} />
              ) : (
                "Confirm"
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default FamilyInviteMemberPopup;
