import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../app/hooks";
import { getMafiaGameBankBalanceWithSig } from "../helper/contractFunctions/MafiaGameBank";
import { setCashBalance } from "../reducers/profile.slice";
import useWallet from "./useWallet";

const useGameBankBalance = (
  address: string,
  signMsg: string,
  signature: string
) => {
  const dispatch = useAppDispatch();
  const { account } = useWallet();
  const [balance, setBalance] = useState(0);

  const fetchBalance = useCallback(
    async (address: string, signMsg: string, signature: string) => {
      if (!address || !signMsg || !signature) return;

      try {
        const balance = await getMafiaGameBankBalanceWithSig(
          address,
          signMsg,
          signature
        );
        setBalance(parseFloat(balance));
        if (account === address) dispatch(setCashBalance(balance));
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
    [account, dispatch]
  );

  useEffect(() => {
    // Fetch balance initially
    fetchBalance(address, signMsg, signature);
  }, [address, fetchBalance, signMsg, signature]);

  return { balance, updateBalance: fetchBalance };
};

export default useGameBankBalance;
