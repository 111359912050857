import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  reelStop: {
    width: 2,
    height: "120px",
    position: "absolute",
    left: "50%",
    top: "50%",
    translate: "-50% -50%",
    background: theme.palette.background.brightRed,
    borderRadius: "50%",
  },

  reel: {},

  prizeItemList: {
    display: "flex",
    gap: 16,

    [theme.breakpoints.down("sm")]: {
      gap: 8,
    },
  },

  prizeItem: {
    background: theme.palette.background.gray,
    border: "1px solid",
    borderColor: theme.palette.background.blackBorder,
    borderRadius: 8,
    padding: 13,
    boxShadow:
      "inset 0 -3px 6px 0px rgb(0 0 0 / 35%), inset 3px 0 6px 0px rgb(0 0 0 / 35%), inset 0 3px 6px 0px rgb(0 0 0 / 35%), inset -3px 0 6px 0px rgb(0 0 0 / 35%)",

    "& img": {
      width: 72,
      height: 72,
      borderRadius: 8,
    },

    [theme.breakpoints.down("sm")]: {
      "& img": {
        width: 48,
        height: 48,
      },
    },
  },
}));

export default styles;
