import SearchIcon from "@mui/icons-material/Search";
import { Box, Pagination, Skeleton, TextField } from "@mui/material";
import millify from "millify";
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";

import NativeIcon from "../NativeIcon";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import config from "../../config/config";
import { menLinksSmall, womenLinksSmall } from "../../constants/avatarLinks";
import { RankCategory } from "../../constants/const";
import { getPlayerList } from "../../reducers/player.slice";
import { Player } from "../../types/PlayerInfo";
import { toUSDFormat } from "../../utils/utils";

import femaleIcon from "../../assets/imgs/landing/female.png";
import maleIcon from "../../assets/imgs/landing/male.png";
import goldenPlus from "../../assets/imgs/profile/GoldenPlus.png";

import useStyles from "./index.styles";

interface LandingPlayerListProps {
  playerCount: number;
  vault: number;
}

const LandingPlayerListItem = ({
  player,
  vault,
}: {
  player: Player;
  vault: number;
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.detail}>
      <Box>#{player.rank}</Box>

      {player.premium === 1 ? (
        <Box className={classes.goldenText}>{toUSDFormat(player.worth)}</Box>
      ) : (
        <Box sx={{ color: "white" }}>{toUSDFormat(player.worth)}</Box>
      )}

      <Box>
        <Box
          component={"img"}
          src={
            player.gender === 0
              ? menLinksSmall[player.imageId || 0]
              : womenLinksSmall[player.imageId || 0]
          }
        ></Box>
        <Link to={`/profile/${player.name}`}>
          <Box>{player.name}</Box>
        </Link>
        {player.premium === 1 && (
          <Box
            component="img"
            src={goldenPlus}
            className={classes.goldenPlus}
          ></Box>
        )}
      </Box>

      <Box
        component="img"
        src={player.gender === 0 ? maleIcon : femaleIcon}
        className={classes.gender}
      ></Box>

      <Box>{RankCategory[player.rankCategory || 0]}</Box>

      <Box className={classes.familyLogo}>
        {player.userFamily?.logo && (
          <Box component="img" src={player.userFamily?.logo}></Box>
        )}

        <Link to={`/family/${player.family}`}>
          <Box>{player.family}</Box>
        </Link>
      </Box>

      <Box>{millify(player.mafia || 0)}</Box>

      <Box>{millify(player.stMafia || 0)}</Box>

      <Box>
        <NativeIcon classNames={classes.vaultIcon} />
        <Box sx={{ color: "white" }} className={classes.vaultAmount}>
          {millify(player.vaultPercent * (vault || 0), {
            precision: 2,
          })}{" "}
          {config.chainSymbol}
        </Box>
      </Box>
    </Box>
  );
};

const LandingPlayerListSkeleton = () => {
  const { classes } = useStyles();
  const skeletonWidth = [32, 64, 160, 32, 48, 100, 32, 32, 64];
  return (
    <Box className={classes.detail}>
      {skeletonWidth.map((width, index) => {
        return (
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={{ backgroundColor: "#ffffff1a" }}
            height={32}
            width={width}
            key={index}
          ></Skeleton>
        );
      })}
    </Box>
  );
};

const LandingPlayerList = (props: LandingPlayerListProps) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const { playerList, isLoadingPlayerList } = useAppSelector(
    (state) => state.player
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [realSearch, setRealSearch] = useState("");
  const playerPerPage = 15;

  const handleChangeSearchField = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSearchWithKeyBoard = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        setRealSearch(search);
      }
    },
    [search]
  );

  const handleSearchWithClick = () => {
    !isLoadingPlayerList && setRealSearch(search);
  };

  useEffect(() => {
    dispatch(
      getPlayerList({
        from: (currentPage - 1) * playerPerPage,
        count: playerPerPage,
        keyword: realSearch,
      })
    );
  }, [currentPage, dispatch, realSearch]);

  return (
    <Box className={classes.container}>
      <Box className={classes.body}>
        <Box className={classes.header}>
          <Box className={classes.title}>
            <Box>Players</Box>
            <Box>{toUSDFormat(props.playerCount)} total players</Box>
          </Box>
          <Box className={classes.searchFieldSection}>
            <TextField
              placeholder="Search name or wallet"
              className={classes.searchField}
              onChange={handleChangeSearchField}
              onKeyPress={handleSearchWithKeyBoard}
              value={search}
              disabled={isLoadingPlayerList}
            />
            <SearchIcon
              onClick={handleSearchWithClick}
              sx={{
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>

        <Box className={classes.tableContainer}>
          <Box className={classes.tableBody}>
            <Box className={classes.tableHeader}>
              <Box>Rank</Box>
              <Box>Worth</Box>
              <Box>Name</Box>
              <Box>Gender</Box>
              <Box>Rank</Box>
              <Box>Family</Box>
              <Box>MAFIA</Box>
              <Box>stMAFIA</Box>
              <Box>Weekly Payout</Box>
            </Box>
            <Box className={classes.tableDetailsBody}>
              {isLoadingPlayerList
                ? [...new Array(playerPerPage)].map((value, index) => {
                    return <LandingPlayerListSkeleton key={index} />;
                  })
                : playerList.map((player, index) => {
                    return (
                      <LandingPlayerListItem
                        vault={props.vault}
                        player={player}
                        key={index}
                      />
                    );
                  })}
            </Box>
          </Box>
        </Box>
        <Box>
          <Pagination
            page={currentPage}
            count={Math.ceil(props.playerCount / playerPerPage)}
            variant="outlined"
            size="large"
            className={classes.pagination}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => {
              setCurrentPage(value);
            }}
          ></Pagination>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPlayerList;
