import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  promotionCard: {
    width: "100%",
    margin: "auto",
    height: "350px",
    backgroundColor: theme.palette.background.cardBg,
    borderRadius: 16,
    marginTop: 32,
    padding: 32,
    boxSizing: "border-box",
    border: "1px solid",
    boxShadow:
      "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",

    fontFamily: "Philosopher",
    fontWeight: 900,

    [theme.breakpoints.down("sm")]: {
      marginTop: 32,
      height: "auto",
      padding: "32px 16px",
    },
  },

  promotionCardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  cardHeader: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    color: theme.palette.text.primary,

    "& img": {
      width: 24,
      height: 24,
    },
    [theme.breakpoints.down("sm")]: {
      gap: 6,
    },
  },

  leaveFeeSection: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "right",
    },
    "&>div:nth-of-type(1)": {
      color: "#646464",
    },
    "&>div:nth-of-type(2)": {
      color: "white",
    },
    "&>img": {
      width: "20px",
      marginLeft: "8px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0",
      },
    },
  },

  promotionContent: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "24px",
    height: "100%",
    alignItems: "center",
    "&>div:nth-of-type(1)": {
      borderRight: "1px solid #64646455",
      [theme.breakpoints.down("sm")]: {
        borderRight: "none",
      },
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      marginTop: "20px",
      gap: "7px",
    },
  },

  promotionHalfSection: {
    display: "flex",
    flexDirection: "column",
    gap: "7px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      alignItems: "center",
    },
  },

  detail: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    fontSize: "17px",
    "&>div:nth-of-type(1)": {
      color: "#646464",
    },
    "&>div:nth-of-type(2)": {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down("sm")]: {
      width: "200px",
      gap: "16px",
      gridTemplateColumns: "2fr 1fr",
    },
  },
}));

export default styles;
