import { Box, Button, Skeleton, Typography, useTheme } from "@mui/material";
import isMobile from "is-mobile";

import { CustomTypeText } from "../../theme";

import allegianceIcon from "../../assets/imgs/family/allegiance.png";

import { AllegiancePopupContent } from "../../constants/enum/enum";

import { useAppSelector } from "../../app/hooks";

import useStyles from "./index.styles";
import { Messages } from "../../constants/messages";
import { toastSuccess } from "../../utils/utils";

interface JoinAllegiancePopupProps {
  setCurrentContent: React.Dispatch<
    React.SetStateAction<AllegiancePopupContent>
  >;

  handleRequestAllegiance: (allegianceId: number, callback: () => void) => void;
}

const JoinAllegiance: React.FC<JoinAllegiancePopupProps> = ({
  setCurrentContent,
  handleRequestAllegiance,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();

  const { allegianceList, isLoadingAllegianceList } = useAppSelector(
    (state) => state.family
  );

  const text = theme.palette.text as CustomTypeText;

  const requestAllegiance = (allegianceId: number) => {
    handleRequestAllegiance(allegianceId, () => {
      toastSuccess(Messages.FAMILY.ALLEGIANCE.REQUEST_SUCCESS);
    });
  };

  return (
    <Box className={classes.contentBody}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box component="img" src={allegianceIcon} sx={{ height: 32, mb: 1 }} />
        <Typography
          align="center"
          sx={{
            mb: 2,
            fontFamily: "Philosopher",
            fontSize: 24,
            fontWeight: 700,
          }}
        >
          Allegiance
        </Typography>
      </Box>

      <Typography
        align="center"
        sx={{
          mb: 2,
          fontFamily: "Philosopher",
          fontSize: 18,
          fontWeight: 700,
          color: text.secondary,
        }}
      >
        Your family is currently not a part of any allegiance
      </Typography>
      <Typography
        align="center"
        sx={{
          mb: 2,
          fontFamily: "Philosopher",
          fontSize: 18,
          color: text.primary,
        }}
      >
        Available allegiances
      </Typography>

      <Box
        sx={{
          minHeight: 160,
        }}
      >
        {isLoadingAllegianceList
          ? [1, 2, 3].map((_, index) => {
              return (
                <Box key={index} className={classes.allegianceItem}>
                  <Box display="flex" alignItems="center">
                    <Skeleton
                      width={24}
                      height={24}
                      animation={"wave"}
                      variant="rounded"
                      sx={{ bgcolor: "#ffffff1a", mr: 1 }}
                    />

                    <Skeleton
                      width={120}
                      height={24}
                      animation={"wave"}
                      variant="rounded"
                      sx={{ bgcolor: "#ffffff1a", mr: 1 }}
                    />
                  </Box>
                  <Button
                    variant="outlined"
                    size="small"
                    disabled
                    className={classes.requestButton}
                  >
                    {isMobile() ? "Request" : "Request to join"}
                  </Button>
                </Box>
              );
            })
          : allegianceList.map((allegiance, index) => (
              <Box key={index} className={classes.allegianceItem}>
                <Box display="flex" alignItems="center">
                  {allegiance.symbol ? (
                    <Box
                      component="img"
                      src={allegiance.symbol ? allegiance.symbol : ""}
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: "50%",
                        display: "inline-block",
                        mr: 1,
                      }}
                    />
                  ) : (
                    <Box
                      component="span"
                      sx={{
                        backgroundColor: "#2f2f2f",
                        width: 24,
                        height: 24,
                        borderRadius: "50%",
                        display: "inline-block",
                        mr: 1,
                      }}
                    />
                  )}
                  {allegiance.name}
                </Box>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.requestButton}
                  onClick={() => {
                    requestAllegiance(allegiance.allegianceId);
                  }}
                >
                  {isMobile() ? "Request" : "Request to join"}
                </Button>
              </Box>
            ))}
      </Box>
      <Typography
        align="center"
        sx={{
          mt: 2,
          fontFamily: "Philosopher",
          fontSize: 14,
          fontWeight: 700,
          color: text.secondary,
        }}
      >
        or
      </Typography>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="contained"
          className={classes.createButton}
          onClick={() => {
            setCurrentContent(AllegiancePopupContent.Create);
          }}
        >
          Create new
        </Button>
      </Box>
    </Box>
  );
};

export default JoinAllegiance;
