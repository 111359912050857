import { Box, Skeleton } from "@mui/material";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

import { getInventoryItemName } from "../../helper/inventory";

import InventoryItemIcon from "../InventoryItemIcon";

import useStyles from "./ItemDetailPanel.styles";
import { useAppSelector } from "../../app/hooks";
import { toUSDFormat } from "../../utils/utils";
import millify from "millify";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#2d2d2d",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#455d22",
  },
}));

export default function ItemDetailPanel({ itemId }: { itemId: number }) {
  const { classes } = useStyles();

  const { itemDetail, isLoadingItemDetail } = useAppSelector(
    (state) => state.market
  );

  const categoryId = Math.floor(itemId / 10);
  const typeId = itemId % 10;

  return (
    <Box className={classes.panel}>
      <Box
        sx={{
          display: "flex",
          gap: "16px",
        }}
      >
        <InventoryItemIcon
          className={classes.itemIcon}
          categoryId={categoryId}
          typeId={typeId}
        />

        <Box className={classes.itemNameContainer}>
          <Box className={classes.itemLabel}>ITEM</Box>
          <Box className={classes.itemName}>
            {getInventoryItemName(categoryId, typeId)}
          </Box>
        </Box>
      </Box>

      <Box className={classes.itemInfoBlockList}>
        <Box className={classes.itemInfoBlock}>
          <Box>PRICE USD</Box>
          {isLoadingItemDetail ? (
            <Skeleton
              variant="rounded"
              animation="wave"
              width={48}
              height={18}
              sx={{ bgcolor: "#ffffff1a" }}
            />
          ) : (
            <Box>${toUSDFormat(itemDetail.priceUSD)}</Box>
          )}
        </Box>

        <Box className={classes.itemInfoBlock}>
          <Box>24H</Box>

          {isLoadingItemDetail ? (
            <Skeleton
              variant="rounded"
              animation="wave"
              width={48}
              height={18}
              sx={{ bgcolor: "#ffffff1a" }}
            />
          ) : (
            <Box
              className={
                itemDetail.priceChangePercent > 0
                  ? classes.itemInfoPlus
                  : classes.itemInfoMinus
              }
            >
              {itemDetail.priceChangePercent.toFixed(2)}%
            </Box>
          )}
        </Box>

        <Box className={classes.itemInfoBlock}>
          <Box>MKT CAP</Box>
          {isLoadingItemDetail ? (
            <Skeleton
              variant="rounded"
              animation="wave"
              width={48}
              height={18}
              sx={{ bgcolor: "#ffffff1a" }}
            />
          ) : (
            <Box>${millify(itemDetail.priceUSD * itemDetail.totalSupply)}</Box>
          )}
        </Box>
      </Box>

      <Box className={classes.itemInfoPercents}>
        <Box className={classes.itemInfoSlider}>
          <Box>
            <Box>COMPLETED</Box>
            <Box>EXPIRED</Box>
          </Box>

          <Box>
            {isLoadingItemDetail ? (
              <Skeleton
                variant="rounded"
                animation="wave"
                width={48}
                height={18}
                sx={{ bgcolor: "#ffffff1a" }}
              />
            ) : (
              <Box>{itemDetail.soldItems.length}</Box>
            )}

            {isLoadingItemDetail ? (
              <Skeleton
                variant="rounded"
                animation="wave"
                width={48}
                height={18}
                sx={{ bgcolor: "#ffffff1a" }}
              />
            ) : (
              <Box>{itemDetail.expiredItems.length}</Box>
            )}
          </Box>

          <BorderLinearProgress
            variant="determinate"
            value={
              (itemDetail.soldItems.length /
                (itemDetail.expiredItems.length +
                  itemDetail.soldItems.length)) *
              100
            }
          />
        </Box>

        <Box className={classes.itemInfoSlider}>
          <Box>
            <Box>FIXED</Box>
            <Box>AUCTION</Box>
          </Box>

          <Box>
            <Box>${millify(itemDetail.fixedVolume)}</Box>
            <Box>${millify(itemDetail.auctionVolume)}</Box>
          </Box>

          <BorderLinearProgress variant="determinate" value={50} />
        </Box>
      </Box>

      <Box className={classes.itemInfoTotalSupply}>
        Total supply:
        <span>{itemDetail.totalSupply}</span>
      </Box>
    </Box>
  );
}
