import { Box, Button, Theme, Typography, useTheme } from "@mui/material";
import { CustomTypeText } from "../../theme";
import useStyles from "./index.styles";

import bulletIcon from "../../assets/imgs/inventory/bulletitem.png";
import cashIcon from "../../assets/imgs/inventory/cashitem.png";
import healthIcon from "../../assets/imgs/inventory/healthitem.png";

const feeInfos = [
  {
    icon: cashIcon,
    name: "Cash",
    fee: 0,
  },
  {
    icon: bulletIcon,
    name: "Bullets",
    fee: 15,
  },
  {
    icon: healthIcon,
    name: "Health",
    fee: 20,
  },
];

const DepositPanel = () => {
  const { classes } = useStyles();
  const theme = useTheme<Theme>();
  const text = theme.palette.text as CustomTypeText;
  return (
    <>
      <Box className={classes.exchangeInfo}>
        <Box className={classes.exchangeCard}>
          <Typography
            fontFamily={"Philosopher"}
            color={text.grayColor}
            textAlign={"center"}
            fontSize={24}
            fontWeight={700}
          >
            You deposit
          </Typography>
        </Box>

        <Box className={classes.fees}>
          {feeInfos.map((feeInfo, index) => {
            return (
              <Box className={classes.feeInfo} key={index}>
                <Typography
                  fontFamily={"Philosopher"}
                  color={"white"}
                  textAlign={"center"}
                  fontSize={18}
                  fontWeight={700}
                >
                  {feeInfo.name}
                </Typography>

                <Box
                  component={"img"}
                  src={feeInfo.icon}
                  sx={{
                    width: 64,
                    height: 64,
                    top: 36,
                    filter: "blur(10px)",
                    position: "absolute",
                  }}
                ></Box>

                <Box
                  component={"img"}
                  src={feeInfo.icon}
                  sx={{
                    width: 64,
                    height: 64,
                    zIndex: 1,
                  }}
                ></Box>

                <Typography
                  fontFamily={"Philosopher"}
                  color={text.grayColor}
                  textAlign={"center"}
                  fontSize={14}
                  fontWeight={700}
                >
                  {feeInfo.fee}% fee
                </Typography>
              </Box>
            );
          })}
        </Box>

        <Box className={classes.exchangeCard}>
          <Typography
            fontFamily={"Philosopher"}
            color={text.grayColor}
            textAlign={"center"}
            fontSize={24}
            fontWeight={700}
          >
            You receive
          </Typography>
        </Box>
      </Box>

      <Button className={classes.confirm}>Confirm Change</Button>
    </>
  );
};
export default DepositPanel;
