import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  acceptOTCTrade,
  cancelExpiredOTCOffers,
  cancelOTCTrade,
  convertItems,
  createOTCTrade,
} from "../helper/contractFunctions/MafiaExchange";
import { OTCRequestItem } from "../types/Contract/Exchange/OTCRequestItem";

export interface ExchangeState {
  isConvertingItems: boolean;
  isCreatingOTC: boolean;
  isAcceptingOTC: boolean;
  isCancelingOTC: boolean;
}

const initialState: ExchangeState = {
  isConvertingItems: false,
  isCreatingOTC: false,
  isAcceptingOTC: false,
  isCancelingOTC: false,
};

export const convertItemsAction = createAsyncThunk(
  "exchange/convertItemsAction",
  async ({ itemIds, account }: { itemIds: number[]; account: string }) => {
    await convertItems(itemIds, account);
  }
);

export const createOTCTradeAction = createAsyncThunk(
  "exchange/createOTCTradeAction",
  async ({
    itemIds,
    requestItems,
    account,
  }: {
    itemIds: number[];
    requestItems: OTCRequestItem[];
    account: string;
  }) => {
    await createOTCTrade(itemIds, requestItems, account);
  }
);

export const acceptOTCTradeAction = createAsyncThunk(
  "exchange/acceptOTCTradeAction",
  async ({
    offerId,
    itemIds,
    account,
  }: {
    offerId: number;
    itemIds: number[];
    account: string;
  }) => {
    await acceptOTCTrade(offerId, itemIds, account);
  }
);

export const cancelOTCTradeAction = createAsyncThunk(
  "exchange/cancelOTCTradeAction",
  async ({ offerId, account }: { offerId: number; account: string }) => {
    await cancelOTCTrade(offerId, account);
  }
);

export const cancelBulkOTCAction = createAsyncThunk(
  "exchange/cancelBulkOTCAction",
  async ({ offerIds, account }: { offerIds: number[]; account: string }) => {
    await cancelExpiredOTCOffers(offerIds, account);
  }
);

export const exchangeSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(convertItemsAction.pending, (state) => {
      state.isConvertingItems = true;
    });
    builder.addCase(convertItemsAction.fulfilled, (state, { payload }) => {
      state.isConvertingItems = false;
    });
    builder.addCase(convertItemsAction.rejected, (state, { error }) => {
      state.isConvertingItems = false;
    });

    builder.addCase(createOTCTradeAction.pending, (state) => {
      state.isCreatingOTC = true;
    });
    builder.addCase(createOTCTradeAction.fulfilled, (state, { payload }) => {
      state.isCreatingOTC = false;
    });
    builder.addCase(createOTCTradeAction.rejected, (state, { error }) => {
      state.isCreatingOTC = false;
    });

    builder.addCase(acceptOTCTradeAction.pending, (state) => {
      state.isAcceptingOTC = true;
    });
    builder.addCase(acceptOTCTradeAction.fulfilled, (state, { payload }) => {
      state.isAcceptingOTC = false;
    });
    builder.addCase(acceptOTCTradeAction.rejected, (state, { error }) => {
      state.isAcceptingOTC = false;
    });

    builder.addCase(cancelOTCTradeAction.pending, (state) => {
      state.isCancelingOTC = true;
    });
    builder.addCase(cancelOTCTradeAction.fulfilled, (state, { payload }) => {
      state.isCancelingOTC = false;
    });
    builder.addCase(cancelOTCTradeAction.rejected, (state, { error }) => {
      state.isCancelingOTC = false;
      console.log(error);
    });

    builder.addCase(cancelBulkOTCAction.pending, (state) => {
      state.isCancelingOTC = true;
    });
    builder.addCase(cancelBulkOTCAction.fulfilled, (state, { payload }) => {
      state.isCancelingOTC = false;
    });
    builder.addCase(cancelBulkOTCAction.rejected, (state, { error }) => {
      state.isCancelingOTC = false;
      console.log(error);
    });
  },
});

export default exchangeSlice.reducer;
