import { Box } from "@mui/material";

import LandingInfo from "../../components/LandingTokenInfo";

const Landing = () => {
  return (
    <Box>
      <LandingInfo />
    </Box>
  );
};

export default Landing;
