import {
  Box,
  Button,
  Skeleton,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import isMobile from "is-mobile";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { CustomTypeText } from "../../../theme";

import InventoryItemIcon from "../../InventoryItemIcon";
import InventoryItemName from "../../InventoryItemName";
import OTCAcceptTrade from "./OTCAcceptTrade";

import moneyIcon from "../../../assets/imgs/exchange/money.png";
import tradeIcon from "../../../assets/imgs/exchange/transfer.png";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  CONFIRMATION_DELAY,
  OTC_TRADE_MAX_ITEM,
} from "../../../constants/const";
import { getInventoryItemName } from "../../../helper/inventory";
import useCurrentTime from "../../../hook/useCurrentTime";
import {
  getExchangeOfferList,
  getMyInventoryItems,
} from "../../../reducers/profile.slice";
import { OTCOfferInfo } from "../../../types/OTCOfferInfo";
import {
  convertDateTime,
  toastSuccess,
  toUSDFormat,
} from "../../../utils/utils";

import clsx from "clsx";
import { Messages } from "../../../constants/messages";
import { getExchangeTotalFee } from "../../../helper/contractFunctions/MafiaExchange";
import { dispatchTxAction } from "../../../helper/dispatchTxAction";
import useWallet from "../../../hook/useWallet";
import {
  cancelBulkOTCAction,
  cancelOTCTradeAction,
} from "../../../reducers/exchange.slice";
import useStyles from "./index.styles";

interface OTCDeskItemProps {
  offerInfo?: OTCOfferInfo;
  handleShowAcceptOTCOffer: (offer?: OTCOfferInfo) => void;
  activeTab: 0 | 1 | 2;
  isMyItem?: boolean;
  handleCancelOTCOffer: (offerInfo?: OTCOfferInfo) => void;
}

const OfferItems = ({
  offerInfo,
  itemType,
}: {
  offerInfo?: OTCOfferInfo;
  itemType: number; // OfferItems, RequestItems
}) => {
  const { classes } = useStyles();

  return offerInfo ? (
    <>
      {(itemType === 0
        ? offerInfo.offerItemDataList
        : offerInfo.requestItems
      ).map((itemData, index) => {
        const categoryId = itemData.categoryId;
        const typeId = itemData.typeId;
        const cityId = itemData.cityId;
        const itemName = getInventoryItemName(categoryId, typeId, cityId);

        return (
          <Tooltip title={itemName} key={index}>
            <Box
              className={classes.itemIcon}
              key={index}
              sx={{
                cursor: "pointer",
              }}
            >
              <InventoryItemIcon categoryId={categoryId} typeId={typeId} />
              <Box
                sx={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100%",
                  userSelect: "none",
                }}
              >
                <InventoryItemName categoryId={categoryId} typeId={typeId} />
              </Box>
            </Box>
          </Tooltip>
        );
      })}
    </>
  ) : (
    <></>
  );
};

const OfferItemsSkeletons = ({ count }: { count: number }) => {
  const { classes } = useStyles();

  return (
    <>
      {[...new Array(count)].map((_, index) => {
        return (
          <Box
            className={classes.itemIcon}
            key={index}
            sx={{
              width: 32,
              height: 35,
            }}
          ></Box>
        );
      })}
    </>
  );
};

const OTCDeskOffer = ({
  offerInfo,
  isMyItem,
  handleCancelOTCOffer,
  handleShowAcceptOTCOffer,
  activeTab,
}: OTCDeskItemProps) => {
  const { profileNames } = useAppSelector((state) => state.profile);
  const currentTime = useCurrentTime();
  const { classes } = useStyles();
  const theme = useTheme<Theme>();
  const text = theme.palette.text as CustomTypeText;
  const acceptor = profileNames.find(
    (profile) =>
      profile.address?.toLowerCase() === offerInfo?.acceptor?.toLowerCase()
  );

  return (
    <Box className={classes.detail}>
      <Link to={`/profile/${offerInfo?.creatorName}`}>
        <Box>{offerInfo?.creatorName}</Box>
      </Link>

      <Box
        sx={{
          display: "flex",
          gap: "4px",
        }}
      >
        <OfferItems offerInfo={offerInfo} itemType={0} />
        <OfferItemsSkeletons
          count={
            OTC_TRADE_MAX_ITEM - (offerInfo?.offerItemDataList.length || 0)
          }
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={tradeIcon}
          sx={{
            width: 32,
            height: 32,
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "4px",
        }}
      >
        <OfferItems offerInfo={offerInfo} itemType={1} />
        <OfferItemsSkeletons
          count={OTC_TRADE_MAX_ITEM - (offerInfo?.requestItems.length || 0)}
        />
      </Box>

      <Typography
        fontFamily={"Philosopher"}
        color={text.grayColor}
        fontSize={14}
        fontWeight={700}
      >
        {activeTab === 0 ? (
          (offerInfo?.expireAt || 0) - currentTime < 0 ? (
            "Expired"
          ) : (
            convertDateTime((offerInfo?.expireAt || 0) - currentTime)
          )
        ) : activeTab === 1 ? (
          <Link to={`/profile/${acceptor?.name}`}>
            <Box>{acceptor?.name}</Box>
          </Link>
        ) : undefined}
      </Typography>

      <Button
        className={
          activeTab === 0 && isMyItem ? classes.cancel : classes.accept
        }
        sx={{
          textTransform: "none",
        }}
        onClick={() => {
          activeTab === 0 &&
            (isMyItem
              ? handleCancelOTCOffer(offerInfo)
              : handleShowAcceptOTCOffer(offerInfo));
        }}
      >
        {activeTab === 0
          ? isMyItem
            ? "Cancel"
            : "View Details"
          : activeTab === 1
          ? "Completed"
          : undefined}
      </Button>
    </Box>
  );
};

const OTCDeskOfferMobile = ({
  offerInfo,
  isMyItem,
  handleCancelOTCOffer,
  handleShowAcceptOTCOffer,
  activeTab,
}: OTCDeskItemProps) => {
  const { profileNames } = useAppSelector((state) => state.profile);
  const currentTime = useCurrentTime();
  const { classes } = useStyles();
  const theme = useTheme<Theme>();
  const text = theme.palette.text as CustomTypeText;
  const acceptor = profileNames.find(
    (profile) =>
      profile.address?.toLowerCase() === offerInfo?.acceptor?.toLowerCase()
  );

  return (
    <Box className={classes.detailMobile}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Link to={`/profile/${offerInfo?.creatorName}`}>
          <Box>{offerInfo?.creatorName}</Box>
        </Link>
        :
        <Box>
          {activeTab === 0 ? (
            <Typography
              fontFamily={"Philosopher"}
              color={text.grayColor}
              fontSize={14}
              fontWeight={700}
            >
              {(offerInfo?.expireAt || 0) - currentTime < 0
                ? "Expired"
                : convertDateTime((offerInfo?.expireAt || 0) - currentTime)}
            </Typography>
          ) : activeTab === 1 ? (
            <Link
              to={`/profile/${acceptor?.name}`}
              style={{ fontSize: "18px" }}
            >
              <>{acceptor?.name}</>
            </Link>
          ) : undefined}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "4px",
        }}
      >
        <OfferItems offerInfo={offerInfo} itemType={0} />
        <OfferItemsSkeletons
          count={
            OTC_TRADE_MAX_ITEM - (offerInfo?.offerItemDataList.length || 0)
          }
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={tradeIcon}
          sx={{
            width: 32,
            height: 32,
            transform: "rotate(90deg)",
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "4px",
        }}
      >
        <OfferItems offerInfo={offerInfo} itemType={1} />
        <OfferItemsSkeletons
          count={OTC_TRADE_MAX_ITEM - (offerInfo?.requestItems.length || 0)}
        />
      </Box>

      <Button
        className={isMyItem ? classes.cancel : classes.accept}
        sx={{
          textTransform: "none",
        }}
        onClick={() => {
          activeTab === 0 &&
            (isMyItem
              ? handleCancelOTCOffer(offerInfo)
              : handleShowAcceptOTCOffer(offerInfo));
        }}
      >
        {activeTab === 0
          ? isMyItem
            ? "Cancel"
            : "View Details"
          : activeTab === 1
          ? "Completed"
          : undefined}
      </Button>
    </Box>
  );
};

const OTCDeskOfferSkeleton = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.detail}>
      <Skeleton width={100} height={18} variant="rounded" animation="wave" />

      <Box
        sx={{
          display: "flex",
          gap: "4px",
        }}
      >
        <OfferItemsSkeletons count={OTC_TRADE_MAX_ITEM} />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={tradeIcon}
          sx={{
            width: 32,
            height: 32,
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "4px",
        }}
      >
        <OfferItemsSkeletons count={OTC_TRADE_MAX_ITEM} />
      </Box>

      <Skeleton width={200} height={21} variant="rounded" animation="wave" />

      <Button
        className={classes.accept}
        sx={{
          textTransform: "none",
        }}
        disabled
      >
        View Details
      </Button>
    </Box>
  );
};

const OTCDeskOfferMobileSkeleton = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.detailMobile}>
      <Box>
        <Skeleton width={60} height={21} variant="rounded" animation="wave" />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "4px",
        }}
      >
        <OfferItemsSkeletons count={OTC_TRADE_MAX_ITEM} />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={tradeIcon}
          sx={{
            width: 32,
            height: 32,
            transform: "rotate(90deg)",
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "4px",
        }}
      >
        <OfferItemsSkeletons count={OTC_TRADE_MAX_ITEM} />
      </Box>

      <Button
        className={classes.accept}
        sx={{
          textTransform: "none",
        }}
        disabled
      >
        View Details
      </Button>
    </Box>
  );
};

interface OTCDeskProps {
  handleCreateOTC: () => void;
}

const OTCDesk = ({ handleCreateOTC }: OTCDeskProps) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const theme = useTheme<Theme>();
  const { account } = useWallet();
  const currentTime = useCurrentTime();
  const text = theme.palette.text as CustomTypeText;

  const { isLoadingExchangeOffers, exchangeOffers, myProfile } = useAppSelector(
    (state) => state.profile
  );

  const [acceptingOffer, setAcceptingOffer] = useState<OTCOfferInfo>();
  const [showAcceptTrade, setShowAcceptTrade] = useState(false);
  const [activeTab, setActiveTab] = useState<0 | 1 | 2>(0); // 0: avail, 1: complted, 2: cancelled
  const [feePaid, setFeePaid] = useState(0);

  const handleShowAcceptOTCOffer = (offerInfo?: OTCOfferInfo) => {
    setShowAcceptTrade(true);
    setAcceptingOffer(offerInfo);
  };

  const handleCancelOTCOffer = (offerInfo?: OTCOfferInfo) => {
    if (!offerInfo) return;

    dispatchTxAction(
      dispatch,
      cancelOTCTradeAction({ account, offerId: offerInfo.id }),
      () => {
        toastSuccess(Messages.EXCHANGE.OTC.TRADE_CANCELED);
        dispatch(getMyInventoryItems({ userId: myProfile.id || 0 }));
        dispatch(getExchangeOfferList(activeTab));
      },
      CONFIRMATION_DELAY * 2
    );
  };

  const handleCancelBulkOTC = () => {
    const offerIds = exchangeOffers
      .filter((offer) => offer.expireAt - currentTime < 0)
      .map((offer) => offer.id);

    dispatchTxAction(
      dispatch,
      cancelBulkOTCAction({ account, offerIds: offerIds }),
      () => {
        toastSuccess(Messages.EXCHANGE.OTC.TRADE_CANCELED);
        dispatch(getMyInventoryItems({ userId: myProfile.id || 0 }));
        dispatch(getExchangeOfferList(activeTab));
      },
      CONFIRMATION_DELAY * 2
    );
  };

  const closeAcceptTrade = () => {
    setShowAcceptTrade(false);
  };

  useEffect(() => {
    dispatch(getExchangeOfferList(activeTab));
  }, [dispatch, activeTab]);

  useEffect(() => {
    const fetchPaidFee = async () => {
      const paidFee = await getExchangeTotalFee();

      setFeePaid(parseFloat(paidFee));
    };

    fetchPaidFee();
  }, []);

  const expiredOfferIds = useMemo(() => {
    const offerIds = exchangeOffers
      .filter((offer) => offer.expireAt - currentTime < 0)
      .map((offer) => offer.id);

    return offerIds;
  }, [currentTime, exchangeOffers]);

  return (
    <Box className={classes.container}>
      <Box className={classes.body}>
        <Box className={classes.header}>
          <Typography
            fontFamily={"Philosopher"}
            color={"white"}
            textAlign={"center"}
            fontSize={28}
            fontWeight={700}
            noWrap
          >
            OTC Desk
          </Typography>
          <Box className={classes.dealType}>
            <Box
              className={clsx(
                classes.dealTabItem,
                activeTab === 0 ? classes.tabActive : undefined
              )}
              onClick={() => setActiveTab(0)}
            >
              Available deals
            </Box>
            <Box
              className={clsx(
                classes.dealTabItem,
                activeTab === 1 ? classes.tabActive : undefined
              )}
              onClick={() => setActiveTab(1)}
            >
              Completed deals
            </Box>
          </Box>
          <Box className={classes.headerDetail}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                }}
              >
                <Typography
                  fontFamily={"Philosopher"}
                  color={text.grayColor}
                  textAlign={"center"}
                  fontSize={14}
                  fontWeight={700}
                  width={"80px"}
                  noWrap
                >
                  Fee's paid
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Box
                    component="img"
                    src={moneyIcon}
                    sx={{
                      width: 16,
                      height: 16,
                    }}
                  />

                  {isLoadingExchangeOffers ? (
                    <Skeleton
                      width={80}
                      height={21}
                      variant="rounded"
                      animation="wave"
                    />
                  ) : (
                    <Typography
                      fontFamily={"Philosopher"}
                      color={"white"}
                      textAlign={"center"}
                      fontSize={14}
                      fontWeight={700}
                      noWrap
                    >
                      $ {toUSDFormat(feePaid)}
                    </Typography>
                  )}
                </Box>
              </Box>

              {isLoadingExchangeOffers ? (
                <Skeleton
                  width={120}
                  height={21}
                  variant="rounded"
                  animation="wave"
                />
              ) : (
                <Typography
                  fontFamily={"Philosopher"}
                  color={text.grayColor}
                  textAlign={"center"}
                  fontSize={14}
                  fontWeight={700}
                  noWrap
                >
                  {exchangeOffers.length}{" "}
                  {activeTab === 0
                    ? "available"
                    : activeTab === 1
                    ? "completed"
                    : undefined}{" "}
                  deals
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "4px",
              }}
            >
              <Button
                className={classes.confirm}
                sx={{
                  textTransform: "none",
                }}
                onClick={handleCreateOTC}
              >
                Create OTC
              </Button>

              {expiredOfferIds.length > 0 && activeTab === 0 && (
                <Button
                  className={classes.cancelBulk}
                  sx={{
                    textTransform: "none",
                  }}
                  onClick={handleCancelBulkOTC}
                >
                  Cancel Expired
                </Button>
              )}
            </Box>
          </Box>
        </Box>

        <Box className={classes.tableContainer}>
          <Box className={classes.tableBody}>
            {!isMobile() && (
              <Box className={classes.tableHeader}>
                <Box>Creator</Box>
                <Box>Offer</Box>
                <Box></Box>
                <Box>Request</Box>
                <Box>{activeTab === 0 ? "Expires" : "Taker"}</Box>
                <Box>Actions</Box>
              </Box>
            )}

            <Box className={classes.tableDetailsBody}>
              {isLoadingExchangeOffers ? (
                [...new Array(5)].map((_, index) => {
                  return isMobile() ? (
                    <OTCDeskOfferMobileSkeleton key={index} />
                  ) : (
                    <OTCDeskOfferSkeleton key={index} />
                  );
                })
              ) : (
                <>
                  {exchangeOffers.map((offerInfo, index) => {
                    return isMobile() ? (
                      <OTCDeskOfferMobile
                        key={index}
                        isMyItem={myProfile.name === offerInfo.creatorName}
                        offerInfo={offerInfo}
                        handleCancelOTCOffer={handleCancelOTCOffer}
                        handleShowAcceptOTCOffer={handleShowAcceptOTCOffer}
                        activeTab={activeTab}
                      />
                    ) : (
                      <OTCDeskOffer
                        key={index}
                        isMyItem={myProfile.name === offerInfo.creatorName}
                        offerInfo={offerInfo}
                        handleCancelOTCOffer={handleCancelOTCOffer}
                        handleShowAcceptOTCOffer={handleShowAcceptOTCOffer}
                        activeTab={activeTab}
                      />
                    );
                  })}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <OTCAcceptTrade
        acceptingOffer={acceptingOffer}
        openOTCAcceptTrade={showAcceptTrade}
        handleClose={closeAcceptTrade}
        activeTab={activeTab}
      />
    </Box>
  );
};
export default OTCDesk;
