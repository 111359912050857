import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  row: {
    maxWidth: 920,
    margin: "auto",
    display: "flex",
    gap: 32,
    position: "relative",

    [theme.breakpoints.down("md")]: {
      gap: 0,
      flexDirection: "column",
    },
  },

  third: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr",

    [theme.breakpoints.down("md")]: {
      gap: 0,
      display: "flex",
      flexDirection: "column",
    },
  },

  upgradeButton: {
    color: theme.palette.text.primary,
    background: theme.palette.background.orangeGradient,
    borderRadius: "8px",
    padding: "8px 16px",
    textTransform: "none",
    minWidth: "150px",
    fontFamily: "Philosopher",

    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",

    "&:disabled": {
      background: theme.palette.background.grayGradient,
      color: theme.palette.text.secondary,
      opacity: 1,
    },
  },
}));

export default styles;
