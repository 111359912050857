import { Box } from "@mui/material";
import logoBnbIcon from "../../assets/imgs/logo_bnb.png";
import logoPlsIcon from "../../assets/imgs/logo_pls.png";
import { CHAIN } from "../../config/config";
import { ChainType } from "../../constants/enum/enum";

interface LogoProps {
  classNames?: string;
  sx?: object;
  onClick?: () => void;
}
export default function Logo(props: LogoProps) {
  return (
    <Box
      onClick={props.onClick}
      sx={{
        ...props.sx,
      }}
      className={props.classNames}
      component="img"
      src={CHAIN === ChainType.PLS ? logoPlsIcon : logoBnbIcon}
    ></Box>
  );
}
