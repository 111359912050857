import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
    },
  },

  modalContent: {
    position: "relative",
  },

  contentBody: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: "32px 48px",
    [theme.breakpoints.down("sm")]: {
      padding: "32px 48px",
    },
  },

  logoSection: {
    display: "flex",
    justifyContent: "center",
  },

  logoImg: {
    minWidth: "40px",
    width: "40px",
  },

  closeIconBody: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 25,
    top: 25,
    "&>button": {
      padding: 0,
      minWidth: "auto",
      "&>svg": {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        transition: ".3s",
        fontSize: "1.5rem",
        "&:hover": {
          color: theme.palette.text.primary,
          transition: ".3s",
        },
      },
    },
  },
  stakingText: {
    fontWeight: 800,
    display: "flex",
    fontSize: "24px",
    justifyContent: "center",
  },
  amountInputSection: {
    display: "flex",
    justifyContent: "center",
    gap: "16px",
    alignItems: "center",
    marginTop: "32px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },

    "& input": {
      "&::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },

      "&::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
    },
  },
  stakingInput: {
    "& .MuiInputBase-input": {
      padding: "8px 0",
      color: theme.palette.text.primary,
      textAlign: "center",
      minWidth: "150px",
      width: "100%",
      backgroundColor: "#282828",
      borderRadius: "8px",
      fontFamily: "Philosopher",
    },
    "& fieldset": {
      outline: "none",
      border: "none",
    },
  },
  availableValue: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color: "#595959",
    cursor: "pointer",
  },
  receiveText: {
    color: "#595959",
    marginTop: "32px",
    display: "flex",
    justifyContent: "center",
  },
  receiveSection: {
    display: "grid",
    gap: "24px",
    alignItems: "center",
    justifyContent: "center",
    gridTemplateColumns: "1fr 1fr 1fr",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      gap: "8px",
    },
  },
  moneyIcon: {
    display: "flex",
    justifyContent: "right",
  },
  receiveAmount: {
    fontSize: "22px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  dailyIncreaseText: {
    color: "#808080",
  },
  buttonSection: {
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",
    "&>button": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.defaultButton,
      borderRadius: "8px",
      padding: "8px 16px",
      textTransform: "none",
      minWidth: "150px",
      fontFamily: "Philosopher",
    },

    "& span": {
      color: "white",
    },
  },
  noteText: {
    color: "#808080",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    marginTop: "40px",
    "&>div": {
      maxWidth: "350px",
    },
  },

  stakingWarn: {
    display: "flex",
    justifyContent: "center",
  },
  stakingWarnBody: {
    backgroundColor: "#883a3a",
    border: "3px solid #671919",
    maxWidth: "220px",
    padding: "15px 30px",
    textAlign: "center",
  },
  notText: {
    textDecoration: "underline",
  },
}));

export default styles;
