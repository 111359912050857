import { Box } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

import LandingHeader from "../../components/LandingHeader";
import LandingPlayerList from "../../components/LandingPlayerList";

import { SERVER_URL } from "../../config/config";
import { GeneralInfo } from "../../types/GeneralInfo";

import useVaultBalance from "../../hook/useVaultBalance";
import useStyles from "./index.styles";
import { listAllFiles } from "../../utils/firebase";

const NewLanding = () => {
  const { classes } = useStyles();
  const vault = useVaultBalance();

  const [playerCount, setPlayerCount] = useState(0);
  const [familyCount, setFamilyCount] = useState(0);

  useEffect(() => {
    const fetchGeneralInfo = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/family/generalInfo`);
        const generalInfo: GeneralInfo = response.data;

        setPlayerCount(generalInfo.userCount || 0);
        setFamilyCount(generalInfo.familyCount || 0);
      } catch (error) {
        console.log("Failed to fetch general information:", error);
        setPlayerCount(0);
        setFamilyCount(0);
      }
    };

    fetchGeneralInfo();
  }, []);

  // listAllFiles("/movie");

  return (
    <Box className={classes.body}>
      <LandingHeader
        playerCount={playerCount}
        familyCount={familyCount}
        vault={vault}
      />

      <LandingPlayerList playerCount={playerCount} vault={vault} />
    </Box>
  );
};

export default NewLanding;
