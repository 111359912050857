import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { countries } from "country-flags-svg-v2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Logo from "../Logo";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import config from "../../config/config";
import {
  MAX_PROFILE_NAME_LEN,
  PROFILE_PRICE_IN_MAFIA,
} from "../../constants/const";
import { getTokenAllowance } from "../../helper/contractFunctions";
import useMafiaPrice from "../../hook/useMafiaPrice";
import {
  getProfileNames,
  getUserBoughtTicket,
  getUserTicketId,
  approveProfileTicketAction,
  purchaseProfileTicketAction,
  registerProfile,
  setIsCreating,
  signProfile,
} from "../../reducers/profile.slice";
import { UserProfile } from "../../types/UserProfile";
import { toastError, toastSuccess, toUSDFormat } from "../../utils/utils";
import { Messages } from "../../constants/messages";

import femaleIcon from "../../assets/imgs/landing/female.png";
import maleIcon from "../../assets/imgs/landing/male.png";

import useWallet from "../../hook/useWallet";
import useStyles from "./index.styles";
import { dispatchTxAction } from "../../helper/dispatchTxAction";
import { Errors } from "../../constants/errors";

interface CreateProfileProps {
  showCreate: boolean;
  setShowCreate: () => void;
}

const CreateProfile = (props: CreateProfileProps) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { isCreatingProfile, profileNames } = useAppSelector(
    (store) => store.profile
  );

  const [alreadyCreated, setAlreadyCreated] = useState(false);
  const [profileName, setProfileName] = useState("");
  const [selectedGender, setSelectedGender] = useState(0);
  const mafiaPrice = useMafiaPrice();
  const [validationMessage, setValidationMessage] = useState("");
  const [country, setCountry] = useState("");

  const { account, connectWallet, disconnectWallet } = useWallet();

  const selectGender = (gender: number) => {
    setSelectedGender(gender);
  };

  const checkDuplicated = (value: string) => {
    const duplicated =
      profileNames.find((profile, index) => profile.name === value) !==
      undefined;

    if (duplicated) {
      setValidationMessage("Duplicated Profile Name");
    }

    return duplicated;
  };

  const checkDuplicatedWithLatestProfiles = async () => {
    const latestProfileNames = (await dispatch(
      getProfileNames()
    ).unwrap()) as UserProfile[];

    const duplicated =
      latestProfileNames.find(
        (profile, index) => profile.name === profileName
      ) !== undefined;

    if (duplicated) {
      setValidationMessage("Duplicated Profile Name");
      return true;
    }

    return false;
  };

  const validateInput = (profileName: string, country: string) => {
    if (!profileName.trim()) return "Profile name cannot be empty.";
    if (profileName.length < 3)
      return "Minimum profile name length is 3 letters.";
    if (!country) return "Please select country";
    return null;
  };

  const createSignMsg = (
    ticketId: number,
    country: string,
    address: string,
    name: string,
    gender: number
  ) => ({
    ticketId,
    country,
    address,
    name,
    gender: gender === 0 ? "Male" : "Female",
    domain: config.domain,
    uri: `https://${config.domain}`,
  });

  const registerUserProfile = async ({
    signature,
    signMsg,
  }: {
    signature: string;
    signMsg: any;
  }) => {
    try {
      const name = await dispatch(
        registerProfile({ signature, signMsg })
      ).unwrap();
      toastSuccess(Messages.GLOBAL.ACCOUNT.CREATED_SUCCESS);
      setTimeout(() => {
        navigate(`/profile/${name}`);
      }, 3000);
    } catch (error) {
      toastError(Errors.GLOBAL.PROFILE.CREATION_FAILED);
      throw error;
    } finally {
      dispatch(setIsCreating(false));
    }
  };

  const handleProfileNameChange = async (event: any) => {
    let value = event.target.value;

    if (value.length === 0) {
      setProfileName(value);
      return;
    } else {
      value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

      const regex = /^[A-Z][a-z]*$/;

      if (value.length > MAX_PROFILE_NAME_LEN) {
        setValidationMessage(
          `Maximum Profile name length is ${MAX_PROFILE_NAME_LEN} letters`
        );
        return;
      }

      if (regex.test(value)) {
        setProfileName(value);
        if (checkDuplicated(value)) return;

        setValidationMessage("");
      } else {
        setValidationMessage("Profile name must consist only of letters.");
      }
    }
  };

  const handleGoProfile = () => {
    if (alreadyCreated) {
      const name = profileNames.find(
        (name, index) => name.address?.toLowerCase() === account.toLowerCase()
      )?.name;
      navigate(`/profile/${name}`);
    }
  };

  const handleCreateProfile = async () => {
    props.setShowCreate();
  };

  const handleCreateAccount = async () => {
    if (!account) return;

    const validationMessage = validateInput(profileName, country);
    if (validationMessage) {
      setValidationMessage(validationMessage);
      return;
    }

    setValidationMessage("");

    const duplicated = await checkDuplicatedWithLatestProfiles();
    if (duplicated) return;
    let userTicketId = await dispatch(getUserTicketId(account)).unwrap();
    let bought = await dispatch(getUserBoughtTicket(account)).unwrap();

    // Register profile
    const registerProfile = () => {
      const signMsg = createSignMsg(
        userTicketId,
        country,
        account,
        profileName,
        selectedGender
      );

      dispatch(signProfile({ signMsg }))
        .unwrap()
        .then(async (signature) => {
          await registerUserProfile({ signature, signMsg });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    // Purchase Ticket
    const purchaseTicket = () => {
      dispatchTxAction(
        dispatch,
        purchaseProfileTicketAction(account),
        async () => {
          userTicketId = await dispatch(getUserTicketId(account)).unwrap();
          if (userTicketId !== 0) {
            registerProfile();
          }
        },
        3000
      );
    };

    // User hasn't purchased tickets, create profile first
    if (!bought) {
      dispatchTxAction(
        dispatch,
        approveProfileTicketAction(account),
        () => {
          purchaseTicket();
        },
        3000
      );
    } else {
      registerProfile();
    }
  };

  useEffect(() => {
    const alreadyCreated =
      profileNames.find(
        (name, index) => name.address?.toLowerCase() === account.toLowerCase()
      ) !== undefined;

    setAlreadyCreated(alreadyCreated);
  }, [profileNames, account]);

  return (
    <Box className={classes.body}>
      {!props.showCreate && (
        <Box>
          <Button
            className={classes.startedBut}
            onClick={() => {
              if (!account) {
                connectWallet();
              } else if (alreadyCreated) {
                handleGoProfile();
              } else {
                handleCreateProfile();
              }
            }}
          >
            {!account
              ? "Connect Wallet"
              : alreadyCreated
              ? "Get Started!"
              : "Create Profile!"}
          </Button>
        </Box>
      )}
      {props.showCreate && (
        <Box className={classes.createContainer}>
          <Box className={classes.inputNameContainer}>
            <TextField
              placeholder="Input profile name"
              variant="standard"
              className={classes.inputBox}
              InputProps={{
                disableUnderline: true, // Disable the underline
              }}
              value={profileName}
              onChange={handleProfileNameChange}
              autoComplete="off"
            />

            {validationMessage && (
              <Typography color="error" className={classes.validationMessage}>
                {validationMessage}
              </Typography>
            )}
          </Box>

          <Box className={classes.countrySelectBox}>
            <Select
              value={country}
              displayEmpty
              onChange={(event: SelectChangeEvent<string>) =>
                setCountry(event.target.value)
              }
              IconComponent={ExpandMoreIcon}
              inputProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      backgroundColor: "#16191a",
                      color: "white",
                    },
                  },
                  children: {
                    sx: {
                      fontSize: "12px",
                    },
                  },
                },
              }}
            >
              {countries.map((country, index) => (
                <MenuItem
                  key={index}
                  value={country.name}
                  sx={{ color: "white" }}
                >
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box className={classes.genderSwitch}>
            <Box className={classes.genderContainer}>
              <IconButton
                className={
                  selectedGender === 0
                    ? clsx(classes.genderButton, classes.genderSelected)
                    : classes.genderButton
                }
                color="primary"
                aria-label="Male"
                onClick={() => {
                  selectGender(0);
                }}
              >
                <Box
                  component="img"
                  src={maleIcon}
                  className={classes.genderIcon}
                />
              </IconButton>

              <Box>Male</Box>
            </Box>

            <Box className={classes.genderContainer}>
              <IconButton
                className={
                  selectedGender === 1
                    ? clsx(classes.genderButton, classes.genderSelected)
                    : classes.genderButton
                }
                color="primary"
                aria-label="Female"
                onClick={() => {
                  selectGender(1);
                }}
              >
                <Box
                  component="img"
                  src={femaleIcon}
                  className={classes.genderIcon}
                />
              </IconButton>

              <Box>Female</Box>
            </Box>
          </Box>

          <Button
            variant="contained"
            color="primary"
            className={classes.createAccountButton}
            onClick={handleCreateAccount}
            disabled={isCreatingProfile}
          >
            {isCreatingProfile ? (
              <>
                <CircularProgress
                  size={15}
                  sx={{ color: "white", marginRight: "4px" }}
                />
                <>Creating</>
              </>
            ) : (
              <>Create account</>
            )}
          </Button>

          <Box className={classes.createCostInfo}>
            <Box>Account registration costs:</Box>
            <Box className={classes.createCostToken}>
              <Logo />
              <Box>
                {toUSDFormat(PROFILE_PRICE_IN_MAFIA)} {config.symbol}
              </Box>
            </Box>
            <Box className={classes.createCostUSD}>
              ${toUSDFormat(mafiaPrice * PROFILE_PRICE_IN_MAFIA, 5)} USD
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CreateProfile;
