import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
    },

    "& .MuiDialog-paper": {
      overflowY: "initial",
    },
  },

  modalContent: {
    position: "relative",
    overflowY: "scroll",
  },

  closeIconBody: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 25,
    top: 25,
    zIndex: 1,

    "&>button": {
      padding: 0,
      minWidth: "auto",
      "&>svg": {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        transition: ".3s",
        fontSize: "1.5rem",
        "&:hover": {
          color: theme.palette.text.primary,
          transition: ".3s",
        },
      },
    },
  },

  contentBody: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: "48px 60px 28px 60px",
    minWidth: 320,
    backgroundSize: "auto 100%",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "48px 16px 28px 16px",
    },
  },

  itemIcon: {
    width: 60,
    height: 60,
    borderRadius: 4,
    border: "4px solid #424242",
  },

  listOption: {
    display: "flex",
    alignItems: "center",
    width: 320,
    justifyContent: "space-between",
  },

  listOptionLabelSmall: {
    color: theme.palette.text.linkColor,
    fontSize: 10,
    marginTop: 4,
    cursor: "pointer",
  },

  switch: {
    display: "grid",
    gap: 16,
    gridTemplateColumns: "1fr 1fr",
  },

  option: {
    backgroundColor: theme.palette.background.lightGray,
    padding: "8px 16px",
    width: 90,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    boxSizing: "border-box",
    gap: 8,

    color: theme.palette.text.secondary,
    transitionDuration: "300ms",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: theme.palette.background.lightGrayHover,
    },

    "& p": {
      fontFamily: "Philosopher",
      fontWeight: 700,
      userSelect: "none",

      "&:first-of-type": {
        color: theme.palette.text.primary,
      },

      "&:last-of-type": {
        color: theme.palette.text.secondary,
        fontSize: 12,
      },
    },
  },

  switchOptionIcon: {
    width: 36,
    height: 36,
    opacity: 0.2,
  },

  switchOptionIconSymbol: {
    width: 24,
    height: 24,
  },

  optionSelected: {
    color: theme.palette.text.primary,
    boxShadow: "rgb(0 0 0 / 54%) 0px 0px 10px 0px",
    background: theme.palette.background.blueGradient,

    "& img": {
      opacity: 1,
    },

    "& p": {
      "&:first-of-type": {
        color: theme.palette.text.primary,
      },

      "&:last-of-type": {
        color: theme.palette.text.primary,
        fontSize: 12,
      },
    },
  },

  input: {
    position: "relative",

    "& svg": {
      position: "absolute",
      right: 8,
      top: 10,
      color: "#207e4e",
    },
  },

  inputBox: {
    borderRadius: "12px",
    boxSizing: "border-box",
    width: 198,
    padding: "4px 16px",
    backgroundColor: theme.palette.background.lightGray,
    color: theme.palette.text.primary,
    outline: "none",

    "& input": {
      outline: "none!important",
      border: "none",
      color: theme.palette.text.primary,
      fontFamily: "Philosopher",
      fontSize: "18px",
      fontWeight: 900,

      "&::placeholder": {
        fontSize: 12,
        color: theme.palette.text.secondary,
      },
    },

    "& .MuiTextField-root::after": {
      content: "initial",
    },
  },

  confirmSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    gap: 16,

    "& button": {
      display: "flex",
      alignItems: "normal",
      gap: "8px",
      padding: "8px 32px",
      textTransform: "none",
      borderRadius: "8px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      marginTop: 8,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.defaultButton,

      "&:disabled": {
        backgroundColor: theme.palette.background.lightGray,
      },
    },
  },

  confirmDescription: {
    color: theme.palette.text.secondary,
  },
}));

export default styles;
