import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    // width: 752,
    // height: 466,
    backgroundColor: "#171a1b",
    borderRadius: "16px",
    position: "relative",
    padding: "24px 36px",
    paddingRight: "16px",
    paddingTop: "96px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: 320,
      padding: "64px 16px 16px 16px",
    },
  },

  chartBody: {
    position: "relative",
  },

  chartTitle: {
    position: "absolute",
    left: 36,
    top: 32,
    zIndex: 100,
    color: theme.palette.text.secondary,
    fontSize: "16px",
  },

  totUsd: {
    position: "absolute",
    right: 36,
    top: 32,
    color: theme.palette.text.primary,
  },

  valueLabel: {
    position: "absolute",
    right: 46,
    top: 70,
    zIndex: 100,
    color: "#535354",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  dateLabel: {
    position: "absolute",
    left: 36,
    bottom: 24,
    zIndex: 100,
    color: "#535354",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default styles;
