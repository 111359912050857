import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import useStyles from "./CustomDropDown.styles";

interface CustomDropDownProps {
  readonly sx?: React.CSSProperties;
  readonly items?: number[] | string[];
  readonly value?: string;
  readonly disabled?: boolean;
  readonly handleChange?: (event: SelectChangeEvent) => void;
  readonly placeholder?: string;
}

export default function CustomDropDown({
  disabled = false,
  placeholder = "",
  sx,
  items,
  value,
  handleChange,
}: CustomDropDownProps) {
  const { classes } = useStyles();

  return (
    <FormControl
      className={classes.formControl}
      sx={{
        ...sx,
      }}
    >
      {!value && <Box className={classes.placeholder}>{placeholder}</Box>}
      <Select
        value={value}
        disabled={disabled}
        onChange={handleChange}
        displayEmpty
        IconComponent={ExpandMoreIcon}
        inputProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: 320,
              },
              sx: {
                display: "block",
                padding: 0,
                backgroundColor: "#282828",
                color: "white",
              },
            },
            children: {
              sx: {
                fontSize: "12px",
              },
            },
          },
        }}
      >
        {items?.map((item, index) => (
          <MenuItem key={index} value={item} className={classes.menuItem}>
            <Box className={classes.detail}>{item}</Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
