import { Box, IconButton } from "@mui/material";


import config from "../../config/config";
import { Messages } from "../../constants/messages";
import { Errors } from "../../constants/errors";
import useStyles from "./index.styles";

import copyIcon from "../.././assets/imgs/landing/copy.png";
import { toastError, toastSuccess } from "../../utils/utils";

const ClipBoard = () => {
  const { classes } = useStyles();

  const handleCopy = () => {
    navigator.clipboard
      .writeText(config.mafiaAddress)
      .then(() => {
        toastSuccess(Messages.GLOBAL.TOKEN.CLIPBOARD_SUCCESS);
      })
      .catch((err) => {
        toastError(Errors.GLOBAL.TOKEN.CLIPBOARD_FAILED);
      });
  };

  return (
    <Box>
      <Box className={classes.container}>
        <Box>{config.mafiaAddress}</Box>
        <IconButton
          className={classes.copyButton}
          color="primary"
          aria-label="copy to clipboard"
          onClick={handleCopy}
        >
          <Box component="img" src={copyIcon} className={classes.copyIcon} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ClipBoard;
