import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import useSound from "use-sound";

import BackgroundMusic from "../../components/BackgroundMusic";
import CrateOpeningHeader from "../../components/CrateOpeningHeader";
import CrateOpeningPopup from "../../components/CrateOpeningPopup";
import CrateOpeningPrizeList from "../../components/CrateOpeningPrizeList";
import ProfileInventory from "../../components/ProfileInventory";
import TokenListPopup from "../../components/TokenListPopup";

import useStyles from "./index.styles";

import reelSpinningSfx from "../../assets/sfxs/1nd_animation_spinning.mp3"; // Correct path
import secondReelSpinningSfx from "../../assets/sfxs/2nd_animation_spinning.mp3"; // Correct path
import crateOpeningSfx from "../../assets/sfxs/create_opening.mp3"; // Correct path

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CONFIRMATION_DELAY, CRATE_PRICE } from "../../constants/const";
import {
  getCategoryAvailable,
  getNonceStatus,
  getUserStatus,
} from "../../helper/contractFunctions/MafiaInventory";
import { isCrateApprovedForAll } from "../../helper/contractFunctions/OgCrate";
import { ItemInfo } from "../../types/ItemInfo";

import config, { SERVER_URL } from "../../config/config";
import {
  buyCratesAction,
  finishOpenCrateAction,
  getSwapTokenBalance,
  getSwapTokenInfo,
  requestOpenCrateAction,
  setWaitingVRF,
} from "../../reducers/crate.slice";

import {
  approveAllCrate,
  getCrateBalance,
  getInventoryItem,
  getInventoryItemStocks,
  getMyInventoryItems,
} from "../../reducers/profile.slice";

import { ItemCategory } from "../../constants/enum/enum";
import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";
import { dispatchTxAction } from "../../helper/dispatchTxAction";
import { delay, toastError, toastInfo, toastSuccess } from "../../utils/utils";

// const testItemInfo: ItemInfo = {
//   categoryId: ItemCategory.OGNFT,
//   typeId: 7,
//   itemId: 0,
//   cityId: -1,
// };

const CrateOpening = () => {
  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const { myProfile, inventoryItems, crateBalance, ogNFTBalance } =
    useAppSelector((state) => state.profile);

  const { swapTokenInfo, swapTokenBalances } = useAppSelector(
    (state) => state.crate
  );

  const [playCrateOpening] = useSound(crateOpeningSfx, {
    loop: false,
    volume: 0.5,
  });

  const [playReelSpinning, { stop: stopReelSpin }] = useSound(reelSpinningSfx, {
    loop: false,
    volume: 0.5,
  });

  const [playSecondReelSpinning, { stop: stopSecondReelSpin }] = useSound(
    secondReelSpinningSfx,
    {
      loop: false,
      volume: 0.5,
    }
  );

  const [muted, setMuted] = useState(localStorage.getItem("mute") === "true");
  const [openCrateOpeningPopup, setOpenCrateOpeningPopup] = useState(false);
  const [openTokenListPopup, setOpenTokenListPopup] = useState(false);
  const [purchaseCrateCount, setPurchaseCrateCount] = useState("1");
  const [itemInfo, setItemInfo] = useState<ItemInfo>({
    categoryId: 0,
    typeId: 0,
    itemId: 0,
    cityId: -1,
  });

  const [txHash, setTxHash] = useState("");
  const [isUserPending, setIsUserPending] = useState(false);
  const [businessAvailable, setBusinessAvailable] = useState(true);

  const onStartAnimation = () => {
    if (muted) return;
    playCrateOpening();
    playReelSpinning();
  };

  const onEndAnimation = () => {
    setOpenCrateOpeningPopup(true);

    stopReelSpin();

    if (muted) return;
    playSecondReelSpinning();
  };

  const onEndSecondAnimation = () => {
    stopSecondReelSpin();

    if (myProfile.id) {
      dispatch(getInventoryItem({ userId: myProfile.id }));
      dispatch(getMyInventoryItems({ userId: myProfile.id }));
    }
  };

  const handleCustomStorageEvent = (event: any) => {
    if (event.type === "mute") {
      setMuted(event.detail.newValue);
    }
  };

  const handlePurchaseCrate = (swapTokenId: number) => {
    const tokenAmount =
      (parseFloat(purchaseCrateCount) * CRATE_PRICE * 1.05) /
      swapTokenInfo[1][swapTokenId];

    if (!myProfile.name) {
      toastError(Errors.GLOBAL.PROFILE.NOT_CREATED);
      return;
    }

    if (myProfile.address) {
      dispatchTxAction(
        dispatch,
        buyCratesAction({
          swapTokenId: swapTokenId,
          tokenAmount: tokenAmount,
          tokenAddress: swapTokenInfo[0][swapTokenId].tokenAddress,
          amount: parseInt(purchaseCrateCount),
          account: myProfile.address,
        }),
        () => {
          toastSuccess(Messages.FAMILY.INVENTORY.PURCHASED_SUCCESS);
          dispatch(getCrateBalance({ account: myProfile.address || "" }));

          setOpenTokenListPopup(false);
        }
      );
    }
  };

  const handleOpenCrate = async () => {
    if (crateBalance === 0 && !isUserPending) {
      toastInfo(Messages.FAMILY.INVENTORY.NOT_PURCHASE);
      return;
    }

    if (!myProfile.name) {
      toastError(Errors.GLOBAL.PROFILE.NOT_CREATED);
      return;
    }

    if (myProfile.address) {
      const requestOpenCrate = async (account?: string) => {
        if (!account) return;

        dispatchTxAction(
          dispatch,
          requestOpenCrateAction({ account }),
          () => {
            finishOpenCrate(account);
          },
          0,
          Errors.FAMILY.INVENTORY.OPEN_CRATE_FAILED
        );
      };

      const finishOpenCrate = async (account?: string) => {
        if (!account) return;

        const timerId = setInterval(async () => {
          const isFulfilled = await getNonceStatus(account);
          dispatch(setWaitingVRF(true));

          if (isFulfilled) {
            clearInterval(timerId);
            await delay(config.blockTime);

            dispatchTxAction(
              dispatch,
              finishOpenCrateAction({ account }),
              () => {
                toastSuccess(Messages.FAMILY.INVENTORY.OPENED_CRATE);
                dispatch(getCrateBalance({ account: myProfile.address || "" }));
              },
              CONFIRMATION_DELAY
            );

            dispatch(setWaitingVRF(false));
          }
        }, config.blockTime);
      };

      const openCrate = async (account?: string) => {
        if (!account) return;

        const userStatus = await getUserStatus(account);

        setIsUserPending(userStatus.isPending);

        if (userStatus.isPending) {
          finishOpenCrate(account);
        } else {
          requestOpenCrate(account);
        }
      };

      const approveCrate = async (account?: string) => {
        if (!account) return;

        dispatchTxAction(
          dispatch,
          approveAllCrate({ spender: config.inventoryAddress, account }),
          () => {
            openCrate(myProfile.address);
          }
        );
      };

      const isApproved = await isCrateApprovedForAll(
        myProfile.address,
        config.inventoryAddress
      );

      if (isApproved) {
        openCrate(myProfile.address);
      } else {
        approveCrate(myProfile.address);
      }
    }
  };

  useEffect(() => {
    const fetchBalance = async () => {
      const isBusinessAvailable = await getCategoryAvailable(
        ItemCategory.BUSINESS
      );

      setBusinessAvailable(isBusinessAvailable);
    };

    fetchBalance();
  }, []);

  useEffect(() => {
    dispatch(getSwapTokenInfo());
    dispatch(getInventoryItemStocks());

    // Add event listeners
    window.addEventListener("mute", handleCustomStorageEvent);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("mute", handleCustomStorageEvent);
    };
  }, [dispatch]);

  // SSE
  useEffect(() => {
    const eventSource = new EventSource(`${SERVER_URL}/event/inventory`);

    eventSource.onmessage = (e) => {
      if (e.data.includes("welcome")) {
      } else if (e.data.includes("owner")) {
        const res = JSON.parse(e.data);
        const { owner, categoryId, typeId, txhash, id } = res;

        if (owner.toLowerCase() === myProfile.address?.toLowerCase()) {
          const isExist =
            inventoryItems.findIndex(
              (item, index) => item.itemId === parseInt(id)
            ) >= 0;

          if (!isExist) {
            setTxHash(txhash);
            setItemInfo({
              categoryId: parseInt(categoryId),
              typeId: parseInt(typeId),
              itemId: parseInt(id),
              cityId: -1,
            });
          }
        }
      }
    };
    return () => {
      eventSource.close();
    };
  }, [inventoryItems, myProfile.address]);

  useEffect(() => {
    if (!myProfile.address) return;
    if (swapTokenInfo[0].length === 0) return;

    dispatch(getSwapTokenBalance({ account: myProfile.address }));
  }, [dispatch, myProfile.address, swapTokenInfo]);

  useEffect(() => {
    const fetchUserStatus = async () => {
      if (!myProfile.address) return;

      const userStatus = await getUserStatus(myProfile.address);
      setIsUserPending(userStatus.isPending);
    };

    fetchUserStatus();
  }, [myProfile.address]);

  useEffect(() => {
    if (!myProfile.id) return;
    dispatch(getInventoryItem({ userId: myProfile.id }));
  }, [dispatch, myProfile.id]);

  return (
    <Box className={classes.body}>
      <BackgroundMusic muted={muted} />

      <TokenListPopup
        usdAmount={parseFloat(purchaseCrateCount) * CRATE_PRICE}
        swapTokenInfo={swapTokenInfo}
        swapTokenBalances={swapTokenBalances}
        openTokenListPopup={openTokenListPopup}
        setOpenTokenListPopup={setOpenTokenListPopup}
        handleTokenSelection={handlePurchaseCrate}
      />

      <CrateOpeningHeader
        itemInfo={itemInfo}
        ogCrateBalance={crateBalance}
        isUserPending={isUserPending}
        purchaseCrateCount={purchaseCrateCount}
        handleOpenCrate={handleOpenCrate}
        onStartAnimation={onStartAnimation}
        onEndAnimation={onEndAnimation}
        setPurchaseCrateCount={setPurchaseCrateCount}
        setOpenTokenListPopup={setOpenTokenListPopup}
        businessAvailable={businessAvailable}
      />

      <CrateOpeningPrizeList businessAvailable={businessAvailable} />

      <CrateOpeningPopup
        itemInfo={itemInfo}
        txHash={txHash}
        crateBalance={crateBalance}
        handleOpenCrate={handleOpenCrate}
        onEndAnimation={onEndSecondAnimation}
        openCrateOpeningPopup={openCrateOpeningPopup}
        setOpenCrateOpeningPopup={setOpenCrateOpeningPopup}
        setOpenTokenListPopup={setOpenTokenListPopup}
      />

      <Box className={classes.row}>
        <ProfileInventory inventoryType="Items" small />
        <ProfileInventory
          inventoryType="NFTs"
          crateBalance={crateBalance}
          ogNFTBalance={ogNFTBalance}
          small
        />
      </Box>
    </Box>
  );
};

export default CrateOpening;
