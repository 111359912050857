// src/components/BackgroundMusic.tsx
import { useEffect } from "react";
import useSound from "use-sound";
import backgroundMusic from "../../assets/sfxs/bgm.mp3"; // Correct path

const BackgroundMusic = ({ muted }: { muted: boolean }) => {
  const [play, { pause }] = useSound(backgroundMusic, {
    loop: true,
    volume: 0.05,
  });

  useEffect(() => {
    console.log("Start playing background music.");

    if (muted) pause();
    else play();
  }, [play, muted, pause]);

  return <div></div>;
};

export default BackgroundMusic;
