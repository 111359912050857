import { Box, Skeleton } from "@mui/material";

import VaultCard from "../VaultCard";

import useStyles from "./index.styles";
import VaultChart from "../VaultChart";
import clsx from "clsx";

const LandingVault = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.body}>
      <Box className={classes.title}>The Vault</Box>
      <Box className={classes.description}>
        Only the most successful crime families will access the vault. Fight
        your way to the top and claim your right to the throne
      </Box>

      <Box className={classes.cards}>
        <VaultCard type="Vault" />
        <VaultCard type="TokenContract" />
      </Box>

      <Box className={classes.chart}>
        <VaultChart />
        <Box className={classes.chartLabel}>
          <Box className={classes.chartLabelDetail}>
            <Box
              className={clsx(classes.circleSymbol, classes.taxCircle)}
            ></Box>
            <Box className={classes.taxLabel}>Tax man</Box>
          </Box>
          <Box className={classes.chartLabelDetail}>
            <Box
              className={clsx(classes.circleSymbol, classes.crateCircle)}
            ></Box>
            <Box className={classes.crateLabel}>Keys</Box>
          </Box>
          <Box className={classes.chartLabelDetail}>
            <Box
              className={clsx(classes.circleSymbol, classes.marketCircle)}
            ></Box>
            <Box className={classes.marketLabel}>Marketplace</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingVault;
