import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: any) => ({
  body: {
    maxWidth: "1920px",
    margin: "auto",
    boxSizing: "border-box",
  },

  mdLogoWrapper: {
    display: "flex",
    justifyContent: "center",
    width: 300,
    alignItems: "center",

    [theme.breakpoints.down("xl")]: {
      width: 200,
    },

    [theme.breakpoints.down("lg")]: {
      display: "none",
    },

    "& img": {
      width: 64,
      height: 64,
      borderRadius: "50%",
      border: "1px solid",
      borderColor: theme.palette.background.defaultBorder,

      [theme.breakpoints.down("xl")]: {
        width: 48,
        height: 48,
      },
    },

    "&>div": {
      fontSize: 60,
      marginLeft: 16,

      [theme.breakpoints.down("xl")]: {
        fontSize: "32px",
      },
    },
  },
  xsLogoWrapper: {
    width: 50,
  },
  toolbar: {
    justifyContent: "space-between",
    background: "linear-gradient(180deg, black 50%, transparent)",
    padding: "20px 0",
    zIndex: 101,
    position: "relative",

    "& *": {
      fontFamily: "Philosopher!important",
      fontWeight: 900,
    },
  },
  mdLink: {
    marginLeft: "24px",
    marginRight: "24px",
    textDecoration: "none",
    fontSize: "20px",
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    position: "relative",
    minHeight: "24px",
    fontFamily: "Philosopher",
    fontWeight: 900,

    "&.active": {
      color: "white",
    },
    "&:hover>img": {
      width: "24px",
      marginRight: "8px",
    },
    [theme.breakpoints.down("xl")]: {
      marginLeft: "8px",
      marginRight: "8px",
      fontSize: "16px",
    },
    // [theme.breakpoints.up("xl")]: {
    //   marginLeft: "40px",
    //   marginRight: "40px",
    //   fontSize: "30px",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   marginLeft: "24px",
    //   marginRight: "24px",
    //   fontSize: "18px",
    // },
  },

  chatSection: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },

  currency: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginRight: "16px",
    gap: "8px",
  },

  userSection: {
    flexGrow: 0,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: "16px",
    [theme.breakpoints.up("lg")]: {
      marginRight: "8px",
    },
  },

  networkChangeSection: {},

  chatBtn: {
    padding: "8px",
    minWidth: "32px",
    borderRadius: "50%",
  },

  notificationBtn: {
    padding: "8px",
    minWidth: "32px",
    borderRadius: "50%",
    marginRight: "8px",
  },

  userProfileBtn: {
    color: theme.palette.text.primary,
    background: theme.palette.background.gray,
    padding: "8px 24px",
    borderRadius: "8px",
    minWidth: "40px",
    textDecoration: "none",
    display: "flex",

    objectFit: "contain",

    "& span": {
      fontFamily: "Philosopher",
      fontWeight: 900,
    },

    "& img": {
      display: "none",
      marginLeft: 0,
    },

    [theme.breakpoints.down("xl")]: {
      padding: "8px 16px",
    },

    [theme.breakpoints.down("lg")]: {
      "& span": {
        fontSize: 12,
      },
    },

    [theme.breakpoints.down("md")]: {
      "& span": {
        fontSize: "initial",
      },
    },

    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      padding: "8px",

      "& span": {
        display: "none",
      },

      "& img": {
        width: 24,
        height: 24,
        marginLeft: 0,
        display: "initial",
      },
    },
  },

  connectWalletBtn: {
    color: theme.palette.text.primary,
    background: theme.palette.background.gray,

    padding: "8px 16px",
    borderRadius: "8px",
    textTransform: "none",
    minWidth: "48px",
    marginLeft: "8px",

    "& span": {
      fontFamily: "Philosopher",
      fontWeight: 900,
    },

    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      padding: "8px",
      "& span": {
        display: "none",
      },
      "& img": {
        marginLeft: 0,
      },
    },
  },
  sidebar: {
    width: 250,
    background: theme.palette.background.gray,
  },
  sidebarNavLink: {
    textDecoration: "none",
    fontFamily: "Philosopher",
    fontWeight: 900,
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
    gap: "8px",

    "& span": {
      fontFamily: "Philosopher",
      fontWeight: 900,
    },

    "&.active": {
      color: theme.palette.text.primary,
    },
  },
  sidebarPaper: {
    background: theme.palette.background.gray,
  },
  sidebarHeader: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    height: 64,
    background: theme.palette.background.gray,
    gap: 15,

    "& a": {
      color: theme.palette.text.primary,
      fontSize: "18px",
      textDecoration: "none",
      fontFamily: "Philosopher",
      fontWeight: 900,
    },
  },
  sidebarLogo: {
    width: 40,
  },
  sideBarConnectWalletBtn: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.background.lightGray,
    borderRadius: "12px",
    color: theme.palette.text.primary,

    "& span": {
      fontFamily: "Philosopher",
      fontWeight: 900,
    },
  },
  lgNavIcon: {
    width: 0,
    marginRight: "32px",
    transition: "all 0.5s",

    [theme.breakpoints.down("xl")]: {
      marginRight: 32,
    },
  },
  navHoverSpan: {
    position: "absolute",
    background: theme.palette.background.gray,
    width: "150px",
    height: "calc(100% + 30px)",
    top: "-16px",
    borderRadius: "24px",
    transition: "all 0.5s",
    zIndex: "-1",
    opacity: 0,
  },

  notificationPanel: {
    borderRadius: "16px",
    backgroundColor: theme.palette.background.cardBg,
    width: "360px",
    padding: "24px 0 0 0",
    marginTop: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    zIndex: 2,

    fontFamily: "Philosopher",
  },

  notificationHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px 0 32px",

    "& p": {
      margin: "0",
      padding: "0",
      fontSize: "18px",
      fontWeight: "800",
      color: theme.palette.text.primary,
    },
  },

  notificationSettingBtn: {
    minWidth: "0px",
    width: "32px",
    height: "32px",
    padding: "4px",
    borderRadius: "16px",
  },

  notificationList: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "320px",
    overflowY: "scroll",

    "&::-webkit-scrollbar": {
      width: "4px",
    },

    "&::-webkit-scrollbar-track": {
      width: "4px",
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "8px",
      border: "solid 3px rgba(255, 0, 0, 0)",
    },

    "&::-webkit-scrollbar-thumb": {
      width: "4px",
      borderRadius: "8px",
      background: "#34344b",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#4a4a6d",
    },
  },

  notificationEmpty: {
    paddingTop: "16px",
    paddingBottom: "32px",
    display: "flex",
    justifyContent: "center",
    fontSize: "18px",
    color: "rgba(255, 255, 255, 0.3)",
  },

  notificationListItem: {
    paddingTop: "16px",
    paddingBottom: "16px",
    borderBottom: "1px solid #20232d",
    display: "flex",
    gap: "8px",
    paddingLeft: "24px",
    "&.read": {
      opacity: "0.5",
    },
  },

  notificationTypeIcon: {
    width: "48px",
    height: "48px",
    backgroundColor: "#11141b",
    borderRadius: "50%",
    border: "1px solid #393b47",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  notificationContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    maxWidth: "240px",
    color: theme.palette.text.primary,

    "& p:first-of-type": {
      fontSize: "14px",
      margin: "0",
    },

    "& p:last-of-type": {
      fontSize: "12px",
      margin: "0",
      color: "#454b5f",
    },
  },
  badge: {
    position: "absolute",
    right: 0,
    top: 0,
    fontSize: "10px",
    backgroundColor: "#ea5353",
    color: "white",
    borderRadius: "8px",
    padding: "2px 4px",
    transform: "translateY(-80%)",
  },
  mobileSidebarList: {
    position: "relative",
    display: "flex",
    alignItems: "center",

    "&>div:last-of-type": {
      transform: "translateY(0)",
      right: "-40px",
    },
  },
  detailSubTitle: {
    fontSize: "12px",
    color: "white",
  },
  subDetailPart: {
    display: "flex",
    gap: "3px",
    alignItems: "center",
    marginLeft: "8px",
  },
  menuListBody: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
  },
  profileLinkText: {
    textDecoration: "none",
    color: theme.palette.text.secondary,
    fontFamily: "Philosopher",
    fontWeight: 900,

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));
