import { Box } from "@mui/material";

import useStyles from "./index.styles";
import FamilyCreate from "../../components/FamilyCreate";

const FamilyCreatePage = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.body}>
      <FamilyCreate />
    </Box>
  );
};

export default FamilyCreatePage;
