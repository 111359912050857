import { Box, Button } from "@mui/material";

import Spinner from "../Spinner";
import TokenImage from "../TokenImage";

import { useAppSelector } from "../../app/hooks";
import { SwapToken } from "../../types/Contract/SwapToken";
import { toUSDFormat } from "../../utils/utils";

import useStyles from "./index.styles";
import { useEffect } from "react";

const exceptTokens = ["TYRH", "ZKZX"];

interface TokenListPopupProps {
  handleSelectToken: (tokenId: number) => void;
  usdAmount: number;
  selectedTokenId: number;
}

export default function TokenListPopup(props: TokenListPopupProps) {
  const { classes } = useStyles();
  const { handleSelectToken, usdAmount, selectedTokenId } = props;
  const {
    swapTokenInfo,
    swapTokenBalances,
    isLoadingSwapTokenInfo,
    isLoadingSwapTokenBalances,
  } = useAppSelector((state) => state.crate);

  const swapTokenList = swapTokenInfo[0];
  const swapTokenPrice = swapTokenInfo[1];

  const tokensWithUsdValue = swapTokenList.map(
    (token: SwapToken, index: number) => {
      const price = swapTokenPrice[index];
      const balance = parseFloat(swapTokenBalances[token.name] || "0");
      const usdValue = balance * price;
      return { ...token, price, usdValue, balance, tokenId: index }; // Include the token details along with its USD value
    }
  );

  const renderTokens = () => {
    // Then, sort the tokens based on their USD values
    tokensWithUsdValue.sort((a: any, b: any) => b.usdValue - a.usdValue);

    return tokensWithUsdValue.map((token: any, _: any) => {
      const balance = token.balance;
      const usdValue = token.usdValue;
      const index = token.tokenId;

      return exceptTokens.indexOf(token.name) === -1 ? (
        <Box
          className="token-list-item "
          key={index}
          sx={
            usdAmount > usdValue
              ? {
                  pointerEvents: "none",
                  opacity: "0.5",
                }
              : {
                  backgroundColor:
                    selectedTokenId === token.tokenId ? "#004aa154" : "",
                }
          }
        >
          <Box className="token-list-item-coin">
            <TokenImage token={token.name} />
            <span className="token-list-item-name">
              <>{token.name}</>
            </span>
          </Box>

          <Box className="token-list-item-balance">
            <span>{balance.toFixed(4)}</span>
            <span>$ {toUSDFormat(usdValue, 4)}</span>
          </Box>

          <Box className="token-list-item-use">
            <Button onClick={() => handleSelectToken(index)}>Select</Button>
          </Box>
        </Box>
      ) : (
        ""
      );
    });
  };

  useEffect(() => {
    if (selectedTokenId >= 0) {
      const token = tokensWithUsdValue.find(
        (token, index) => token.tokenId === selectedTokenId
      );
      if (!token) return;

      if (usdAmount > token.usdValue) handleSelectToken(-1);
    }
  }, [usdAmount, selectedTokenId, tokensWithUsdValue, handleSelectToken]);

  return (
    <Box className={classes.tokenListPopup}>
      <Box className="token-list-header">
        <span>Asset</span>
        <span>Balance</span>
        <span>Use</span>
      </Box>
      <Box className="token-list-items">
        {isLoadingSwapTokenBalances || isLoadingSwapTokenInfo ? (
          <Box className="token-list-loading">
            <Spinner />
          </Box>
        ) : swapTokenInfo ? (
          renderTokens()
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}
