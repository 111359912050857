import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    padding: "0 16px",

    maxWidth: 1920,
    margin: "auto",
  },

  memberListCard: {
    maxWidth: 1440,
    margin: "auto",

    minHeight: 250,
    marginTop: 64,
    marginBottom: 32,
    padding: 32,
    boxSizing: "border-box",
    borderRadius: 16,
    backgroundColor: theme.palette.background.cardBg,
    border: "1px solid",
    boxShadow:
      "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",

    fontFamily: "Philosopher",
    fontWeight: 900,

    [theme.breakpoints.down("sm")]: {
      marginTop: 32,
    },
  },

  memberListCardHeader: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    color: theme.palette.text.primary,

    "& img": {
      width: 24,
      height: 24,
    },
  },

  memberList: {
    padding: "8px 16px",
    display: "flex",
    flexDirection: "column",
    overflowX: "scroll",
  },

  listHeader: {
    display: "grid",
    color: theme.palette.text.secondary,
    gridTemplateColumns:
      "1fr 1fr 1.5fr 1fr 1fr 1fr 2fr 1.5fr 1.5fr 1fr 1fr 1fr 0.5fr",
    padding: "12px 0 12px 8px",

    minWidth: 1200,
  },

  listItem: {
    display: "grid",
    color: theme.palette.text.primary,
    gridTemplateColumns:
      "1fr 1fr 1.5fr 1fr 1fr 1fr 2fr 1.5fr 1.5fr 1fr 1fr 1fr 0.5fr",
    minWidth: 1200,
    borderBottom: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    padding: "8px 0 8px 8px",
    transitionDuration: "300ms",

    "& a": {
      color: theme.palette.text.linkColor,
      textDecoration: "none",

      display: "flex",
      alignItems: "center",
    },

    "&>div": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    "& button": {
      padding: 0,
      minWidth: "initial",

      "&:disabled svg": {
        color: theme.palette.text.secondary,
      },
    },

    "&:hover": {
      backgroundColor: theme.palette.background.whiteTransparent1,
    },
  },

  payout: {
    "& div": {
      width: 94,
    },
  },

  leader: {
    color: theme.palette.text.brightYellow,
  },

  capo: {
    color: theme.palette.text.purple,
  },

  flag: {
    width: 24,
  },

  genderItem: {
    "& div": {
      minWidth: 48,
    },
  },

  gender: {
    width: 16,
  },

  avatar: {
    width: 48,
    height: 24,
    objectFit: "cover",
    objectPosition: "top",
    borderRadius: 4,
  },

  goldenPlus: {
    width: 10,
    height: "100%",
    objectFit: "contain",
    objectPosition: "top",
  },

  goldenText: {
    background: theme.palette.text.goldenGradient,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "bold",
  },

  deleteDialog: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
      padding: "24px",
    },
    "& .MuiDialogContentText-root": {
      color: "white",
    },
  },
  agreeBut: {
    backgroundColor: "#18a86b",
    color: "white",
    borderRadius: "7px",
  },
  disagreeBut: {
    color: "#646464",
    borderRadius: "7px",
  },
  paper: {
    "& .MuiDialogContentText-root": {
      color: theme.palette.text.primary,
    },
  },
}));

export default styles;
