import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    border: "2px solid #202527",
    borderRadius: "12px",
    fontSize: "16px",
    padding: "0 32px",
    display: "flex",
    gap: "32px",
    alignItems: "center",
    backgroundColor: theme.palette.background.containerBg,
    color: theme.palette.text.containerColor,

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      gap: "8px",
      padding: "0 4px 0 12px",
    },
  },
  copyButton: {},
  copyIcon: {
    width: "20px",
  },
}));

export default styles;
