import { useEffect, useState } from "react";

const useCurrentTime = () => {
  const [currentTime, setCurrentTime] = useState(new Date().getTime() / 1000);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date().getTime() / 1000);
    }, 1000);

    return () => {
      clearTimeout(intervalId);
    };
  }, []);

  return currentTime;
};

export default useCurrentTime;
