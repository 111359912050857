import { Box, Dialog } from "@mui/material";
import React from "react";
import { SwapToken } from "../../types/Contract/SwapToken";
import { toUSDFormat } from "../../utils/utils";

import useStyles from "./index.styles";
interface TokenListPopupProps {
  usdAmount: number;
  swapTokenInfo: [SwapToken[], number[]];
  swapTokenBalances: any;
  openTokenListPopup: boolean;
  setOpenTokenListPopup: React.Dispatch<React.SetStateAction<boolean>>;
  handleTokenSelection: (swapTokenId: number) => void;
}

const TokenListPopup: React.FC<TokenListPopupProps> = ({
  usdAmount,
  swapTokenInfo,
  swapTokenBalances,
  openTokenListPopup,
  setOpenTokenListPopup,
  handleTokenSelection: handlePurchaseCrate,
}) => {
  const { classes } = useStyles();

  const handleClose = () => {
    setOpenTokenListPopup(false);
  };

  return (
    <Dialog
      open={openTokenListPopup}
      onClose={handleClose}
      className={classes.body}
    >
      <Box className={classes.modalContent}>
        <Box className={classes.contentBody}>
          <Box className={classes.tokenListHeader}>
            <Box className={classes.tokenListToken}>
              <Box>Token</Box>
            </Box>

            <Box>Balance</Box>
            <Box>Required</Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              maxHeight: 400,
              overflowY: "scroll",
            }}
          >
            {swapTokenInfo[0].map((tokenInfo, index) => {
              return (
                tokenInfo.isEnabled && (
                  <Box
                    className={classes.tokenListItem}
                    key={index}
                    onClick={() => {
                      handlePurchaseCrate(index);
                    }}
                  >
                    <Box className={classes.tokenListToken}>
                      <Box
                        component="img"
                        src={`/assets/tokens/${tokenInfo.name.toLocaleLowerCase()}.png`}
                      ></Box>
                      <Box>{tokenInfo.name}</Box>
                    </Box>

                    <Box>
                      {toUSDFormat(
                        swapTokenBalances[
                          swapTokenInfo[0][index].name.toUpperCase()
                        ] as number,
                        3
                      )}
                    </Box>

                    <Box>
                      {toUSDFormat(usdAmount / swapTokenInfo[1][index], 6)}
                    </Box>
                  </Box>
                )
              );
            })}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default TokenListPopup;
