import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    maxWidth: 1920,
    margin: "auto",
    padding: "48px 16px",
  },

  prizeList: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    fontFamily: "Philosopher",
    fontWeight: 900,

    maxWidth: 1440,
    padding: "16px 0",
    margin: "auto",
    overflowX: "scroll",
    justifyContent: "space-around",
  },

  prize: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    color: theme.palette.text.primary,
    textAlign: "center",
    fontSize: 20,
    fontWeight: 900,
    minWidth: 100,

    "& img": {
      width: 32,
      height: 32,
      borderRadius: 4,
    },

    [theme.breakpoints.down("sm")]: {
      minWidth: 140,
    },
  },

  prizeInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    height: 32,

    "& p": {
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 12,
    },
  },

  prizeCard: {
    backgroundColor: theme.palette.background.cardBg,
    borderRadius: 16,
    display: "flex",
    flexDirection: "column",
    padding: "24px 8px",
    color: theme.palette.text.primary,
    textAlign: "center",
    fontSize: 13,
    gap: 16,

    [theme.breakpoints.down("sm")]: {
      padding: "24px 8px",
    },
  },

  stock: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
}));

export default styles;
