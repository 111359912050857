import { useEffect, useState } from "react";
import { AUTH_TOKEN_HEADER } from "../constants/const";
import Web3 from "web3";
import config from "../config/config";

const useAuth = (account: string) => {
  const [needToAuth, setNeedToAuth] = useState(false);

  useEffect(() => {
    if (!account) return;

    setNeedToAuth(checkSignature(account));
  }, [account]);

  return needToAuth;
};

export default useAuth;

export const checkSignature = (account: string) => {
  const storedToken = localStorage.getItem(
    `${AUTH_TOKEN_HEADER}${account.toLowerCase()}`
  );
  const storedSignature = storedToken?.split("_")[0];
  const expireAt = parseInt(storedToken?.split("_")[1] || "0");

  if (storedSignature) {
    // Assuming you have the original message that was signed
    const signMsg = `Sign in ${config.domain} with ${account} - expire at ${expireAt}`;

    // Extract the address from the signature
    const web3 = new Web3();
    const signerAddress = web3.eth.accounts.recover(
      JSON.stringify(signMsg),
      storedSignature
    );

    if (signerAddress.toLowerCase() === account.toLowerCase()) {
      if (expireAt > new Date().getTime() / 1000) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  } else {
    return true;
  }
};
