import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    padding: "0 16px",

    maxWidth: 1920,
    margin: "auto",
    minHeight: 663,
  },

  list: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 1440,
    margin: "auto",
    marginTop: 32,
    marginBottom: 32,
    minHeight: "663px",
    backgroundColor: theme.palette.background.cardBgTransparent,
    padding: "24px 32px",
    borderRadius: "8px",
    boxSizing: "border-box",
    overflowX: "scroll",

    boxShadow:
      "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",

    "& a": {
      textDecoration: "none",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "8px",
    },
  },

  listBody: {
    minHeight: 600,
    height: 600,
    overflowY: "auto",

    [theme.breakpoints.down("sm")]: {
      width: 1200,
    },
  },

  listHeader: {
    display: "grid",
    fontFamily: "Philosopher",
    gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr 1.5fr 2fr 4fr",
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 900,
    alignItems: "center",
    padding: "4px 16px",

    "&>div": {
      display: "flex",
      alignItems: "center",
      gap: 16,
    },

    [theme.breakpoints.down("sm")]: {
      width: 1200,
      boxSizing: "border-box",
    },
  },

  clickingOrder: {
    cursor: "pointer",
  },

  listItem: {
    display: "grid",
    fontFamily: "Philosopher",
    gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr 1.5fr 2fr 4fr",
    color: theme.palette.text.secondary,

    alignItems: "center",
    fontSize: 18,
    fontWeight: 900,
    padding: 8,

    borderBottom: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    transitionDuration: "300ms",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#ffffff0d",
      color: theme.palette.text.grayColor,
    },

    "& a": {
      color: theme.palette.text.linkColor,
    },

    "& div": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    "&>div:first-of-type": {
      fontSize: 14,
    },
  },

  itemIcon: {
    width: 32,
    height: 32,
    borderRadius: 8,

    border: "1px solid",
    borderColor: theme.palette.background.defaultBorder,

    background: theme.palette.background.cardBgTransparent,
    boxShadow:
      "inset 0 -3px 10px 0px rgb(0 0 0 / 15%), inset 3px 0 10px 0px rgb(0 0 0 / 15%), inset 0 3px 10px 0px rgb(0 0 0 / 15%), inset -3px 0 10px 0px rgb(0 0 0 / 15%)",
  },

  link: {
    color: theme.palette.text.linkColor,
  },

  name: { color: theme.palette.text.primary },

  usdLabel: {
    color: theme.palette.text.primary,
  },

  currency: {
    color: theme.palette.text.primary,
  },

  currencyIcon: {
    width: 20,
    height: 20,
  },

  buyButton: {
    fontFamily: "Philosopher",
    fontWeight: "bold",
    fontSize: 12,
    padding: "6px 24px",
    background: theme.palette.background.defaultButton,
    color: theme.palette.text.primary,
    transition: "300ms",

    "&:hover": {
      background: theme.palette.background.defaultButton,
      filter: "brightness(1.2)",
    },
  },

  bidButton: {
    fontFamily: "Philosopher",
    fontWeight: "bold",
    fontSize: 12,
    padding: "6px 24px",
    background: theme.palette.background.createButtonGradient,
    color: theme.palette.text.primary,
    transition: "300ms",

    "&:hover": {
      background: theme.palette.background.createButtonGradient,
      filter: "brightness(1.2)",
    },
  },

  pagination: {
    marginTop: 16,
    marginBottom: 32,
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
    "& .MuiPaginationItem-root": {
      borderColor: theme.palette.background.gray,
      color: theme.palette.text.secondary,
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: theme.palette.background.gray,
      color: "white",
    },
  },

  listingStatusExpired: {
    color: "#794c32",
  },

  listingStatusNormal: {
    color: "#578635",
  },

  loading: {
    color: "#646464",
    display: "flex",
    justifyContent: "center",
    padding: "8px 0",
  },

  scrollContainer: {
    "& .infinite-scroll-component ": {
      overflow: "initial",
    },
  },
}));

export default styles;
