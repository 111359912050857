import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  panel: {
    padding: 32,
    borderRadius: 16,
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.cardBgTransparent,

    display: "flex",
    flexDirection: "column",
    gap: 32,
    fontFamily: "Philosopher",
  },

  itemIcon: {
    width: 64,
    height: 64,
    padding: 8,
    borderRadius: 16,
    boxShadow:
      "inset 0 -3px 6px 0px rgb(0 0 0 / 35%), inset 3px 0 6px 0px rgb(0 0 0 / 35%), inset 0 3px 6px 0px rgb(0 0 0 / 35%), inset -3px 0 6px 0px rgb(0 0 0 / 35%)",
  },

  itemNameContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },

  itemLabel: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    fontWeight: "bold",
  },

  itemName: {
    color: theme.palette.text.primary,
    fontSize: 32,
    fontWeight: "bold",
  },

  itemInfoBlockList: {
    display: "grid",
    gap: "32px",
    gridTemplateColumns: "1fr 1fr 1fr",
  },

  itemInfoBlock: {
    borderRadius: 8,
    padding: "12px 12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 8,
    backgroundColor: theme.palette.background.grayHover,

    "&>div:first-of-type": {
      fontWeight: "bold",
      color: theme.palette.text.secondary,
    },

    "&>div:last-of-type": {
      fontWeight: "bold",
      color: theme.palette.text.primary,
    },
  },

  itemInfoPlus: {
    color: "#578635!important",
  },

  itemInfoMinus: {
    color: "#794c32!important",
  },

  itemInfoTotalSupply: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: "bold",
    "& span": {
      color: theme.palette.text.primary,
      marginLeft: 4,
    },
  },

  itemInfoPercents: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },

  itemInfoSlider: {
    display: "flex",
    flexDirection: "column",
    gap: 8,

    "&>div:nth-of-type(1)": {
      display: "flex",
      justifyContent: "space-between",
      color: theme.palette.text.secondary,
      fontWeight: "bold",
      fontSize: 12,
    },

    "&>div:nth-of-type(2)": {
      display: "flex",
      justifyContent: "space-between",
      color: theme.palette.text.primary,
      fontSize: 20,
      fontWeight: "bold",
    },
  },
}));

export default styles;
