import { Box } from "@mui/material";

import { useAppSelector } from "../../app/hooks";
import { toTitleCase, toUSDFormat } from "../../utils/utils";

import moneyIcon from "../../assets/imgs/family/money.png";

import Logo from "../Logo";
import useStyles from "./index.styles";

const FamilyDetailPromotion = () => {
  const { classes } = useStyles();
  const { familyDetail } = useAppSelector((state) => state.family);

  return (
    <Box className={classes.promotionCard}>
      <Box className={classes.promotionCardHeader}>
        <Box className={classes.cardHeader}>
          <Box component="img" src={moneyIcon}></Box>
          <Box>Promotion Money</Box>
        </Box>
        <Box className={classes.leaveFeeSection}>
          <Box>Leave fee</Box>
          <Logo />
          <Box>{toUSDFormat(familyDetail.leaveFee)}</Box>
        </Box>
      </Box>

      <Box className={classes.promotionContent}>
        <Box className={classes.promotionHalfSection}>
          {familyDetail.promotion &&
            Object.keys(familyDetail.promotion).length === 15 &&
            Object.keys(familyDetail.promotion)
              .slice(1, 8)
              .map((key, index) => {
                return (
                  <Box className={classes.detail} key={index}>
                    <Box>{toTitleCase(key)}</Box>
                    <Box>${toUSDFormat(familyDetail.promotion[key])}</Box>
                  </Box>
                );
              })}
        </Box>
        <Box className={classes.promotionHalfSection}>
          {familyDetail.promotion &&
            Object.keys(familyDetail.promotion).length === 15 &&
            Object.keys(familyDetail.promotion)
              .slice(8, 15)
              .map((key, index) => {
                return (
                  <Box className={classes.detail} key={index}>
                    <Box>{toTitleCase(key)}</Box>
                    <Box>${toUSDFormat(familyDetail.promotion[key])}</Box>
                  </Box>
                );
              })}
        </Box>
      </Box>
    </Box>
  );
};

export default FamilyDetailPromotion;
