import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    padding: "0 16px",
    background: "url(/assets/imgs/family/bg1.png) no-repeat",
    backgroundSize: "100% auto",
    backgroundPosition: "bottom",

    WebkitMaskImage:
      "-webkit-gradient(linear, left 95%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",

    maxWidth: 1920,
    margin: "auto",

    [theme.breakpoints.down("md")]: {
      backgroundSize: "auto 100%!important",
      backgroundPosition: "top center!important",
      WebkitMaskImage:
        "-webkit-gradient(linear, left 95%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",

      // WebkitMaskImage: "none",
    },
  },

  familyDetailHeader: {
    width: "100%",
    minHeight: 480,
    fontFamily: "Philosopher",
    maxWidth: 1440,
    margin: "auto",

    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    padding: "120px 0 48px 0",

    [theme.breakpoints.down("lg")]: {
      minHeight: 360,
    },

    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      gap: "32px",
      alignItems: "center",
      minHeight: 480,
    },

    [theme.breakpoints.down("sm")]: {
      padding: "60px 0 48px 0",
    },
  },

  familyInfo: {
    flexGrow: 1,
    display: "flex",
    gap: 16,

    [theme.breakpoints.down("sm")]: {
      gap: 0,
      flexDirection: "column",
    },
  },

  familySymbol: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: "32px 8px 0px 8px",
    color: theme.palette.text.primary,
    alignItems: "center",
    position: "relative",

    "&>div:first-of-type": {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      alignItems: "center",
      justifyContent: "center",
      aspectRatio: "200/280",
      width: "80%",
    },

    "&>div:last-of-type": {
      display: "flex",
      alignItems: "flex-start",
      gap: 4,
      flexDirection: "column",
    },

    "& svg": {
      color: "white",
    },

    "& img": {
      objectFit: "contain",
      width: "100%",

      maxWidth: 250,
      borderRadius: "16px",
      // boxShadow:
      //   "rgba(0, 0, 0, 35%) 0px 1px 3px 10px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    },

    "& button": {
      color: theme.palette.text.primary,
      fontFamily: "Philosopher",
      fontWeight: 900,
      display: "flex",
      gap: 8,
    },
  },

  familyBadge: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "90px!important",
  },

  familyDetail: {
    display: "flex",
    flexDirection: "column",
    gap: 16,

    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },

  familyName: {
    color: theme.palette.text.primary,
    fontSize: 60,
    fontWeight: 900,

    [theme.breakpoints.down("lg")]: {
      fontSize: 36,
    },

    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  familyDetailNumbers: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    padding: "32px 24px 32px 12px",
    backgroundColor: theme.palette.background.cardBgTransparent,
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
    borderRadius: 8,
    maxWidth: 260,

    "&>div": {
      display: "flex",

      "& div:first-of-type": {
        fontWeight: 900,
        width: 120,
        color: theme.palette.text.primary,
      },

      "& div:last-of-type": {
        color: theme.palette.text.secondary,
        display: "flex",
        alignItems: "center",

        "& span": {
          marginLeft: 4,
          fontSize: 9,
          color: theme.palette.text.primary,
          padding: "2px 4px",
          backgroundColor: theme.palette.background.whiteTransparent,
          borderRadius: "50%",
        },
      },
    },
  },

  familyBullet: {
    marginRight: 8,
    width: 16,
    height: 16,
  },

  familyBossInfos: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    padding: "16px 24px",

    "&>div": {
      display: "flex",
      "& div:first-of-type": {
        fontWeight: 900,
        width: 120,
        color: theme.palette.text.primary,
      },

      "& div:nth-of-type(2)": {
        color: theme.palette.text.secondary,
      },

      "& button": {
        color: theme.palette.text.primary,
      },
    },

    "& a": {
      color: theme.palette.text.linkColor,
      fontWeight: 900,
      textDecoration: "none",
    },
  },

  familyBosses: {
    position: "relative",
    flexGrow: 1,

    [theme.breakpoints.down("md")]: {
      height: "420px",
      width: "100%",
      maxWidth: "600px",
    },

    [theme.breakpoints.down("sm")]: {
      height: "320px",
      width: "100%",
      maxWidth: "480px",
    },

    "&>div": {
      width: "33%",
      position: "absolute",
      borderRadius: "8px",
      color: theme.palette.text.secondary,
      fontSize: 18,
      fontWeight: 900,
      textAlign: "center",

      "&:nth-of-type(1)": {
        left: "10%",
        top: "-5%",
      },

      "&:nth-of-type(2)": {
        left: "60%",
        top: "5%",
      },

      "&:nth-of-type(3)": {
        left: "35%",
        top: "15%",

        fontSize: 24,
        color: theme.palette.text.primary,
      },

      "& img": {
        boxShadow:
          "rgb(0, 0, 0) 0px 20px 30px -10px, rgb(0, 0, 0) 0px -5px 20px 0px;",

        width: "100%",
        borderRadius: "8px",
      },

      "& div:nth-of-type(2)": {
        marginTop: 8,
      },
    },
  },

  socialIcons: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },

  payoutInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.primary,
    fontSize: 14,
    gap: 8,

    "& img": {
      width: "16px!important",
      height: "16px!important",
      boxShadow: "none!important",
    },
  },

  vaultTooltip: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Philosopher",
    padding: "16px 24px",
    gap: 8,

    "&>div:first-of-type": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      fontSize: 18,
      fontWeight: 900,
      color: theme.palette.text.primary,
    },

    "&>div:last-of-type": {
      maxWidth: 200,
      textAlign: "center",
      fontSize: 14,
      fontWeight: 900,
      color: theme.palette.text.grayColor,
    },

    "& img": {
      width: 48,
      height: 48,
    },
  },

  crownIcon: {
    position: "absolute",
    right: "5%",
    top: "5%",
    boxShadow: "none!important",
    width: "48px!important",
    maxWidth: "20%",
  },

  goldenPlus: {
    width: 24,
    height: 24,
    objectFit: "contain",
    objectPosition: "top",
    position: "absolute",
  },
}));

export default styles;
