import { Box, Button, Theme, Typography, useTheme } from "@mui/material";
import { useState } from "react";

import { CustomTypeText } from "../../theme";

import ConvertPanel from "../../components/Exchange/ConvertPanel";
import DepositPanel from "../../components/Exchange/DepositPanel";
import KeyPanel from "../../components/Exchange/KeyPanel";
import OTCDesk from "../../components/Exchange/OTC/OTCDesk";
import OTCPanel from "../../components/Exchange/OTC/OTCPanel";

import minusIcon from "../../assets/imgs/exchange/minus.png";
import plusIcon from "../../assets/imgs/exchange/plus.png";
import convertIcon from "../../assets/imgs/exchange/recycle.png";
import tradeIcon from "../../assets/imgs/exchange/transfer.png";
import keyIcon from "../../assets/imgs/inventory/key.png";

import useStyles from "./index.styles";

const Exchange = () => {
  const { classes } = useStyles();
  const theme = useTheme<Theme>();
  const text = theme.palette.text as CustomTypeText;

  const [activePanel, setActivePanel] = useState("convert");

  const handleButtonClick = (panel: string) => {
    setActivePanel(panel); // Toggle active state
  };

  const handleCreateOTC = () => {
    setActivePanel("otcCreate");
  };

  const handleBackToOTCDesk = () => {
    setActivePanel("otcTrade");
  };

  const renderPanel = () => {
    switch (activePanel) {
      case "deposit":
        return <DepositPanel />;
      case "convert":
        return <ConvertPanel />;
      case "otcTrade":
        return <OTCDesk handleCreateOTC={handleCreateOTC} />;
      case "otcCreate":
        return <OTCPanel handleBackToOTCDesk={handleBackToOTCDesk} />;
      case "keys":
        return <KeyPanel />;
      default:
        break;
    }
  };

  return (
    <Box>
      <Box className={classes.body}>
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Typography
              fontFamily={"Philosopher"}
              color={"white"}
              textAlign={"center"}
              fontSize={36}
              fontWeight={700}
            >
              The Exchange office
            </Typography>

            <Typography
              fontFamily={"Philosopher"}
              color={text.grayColor}
              textAlign={"center"}
              fontSize={16}
              fontWeight={700}
            >
              What can I help you with?
            </Typography>
          </Box>

          <Box className={classes.actions}>
            <Box>
              <Button
                onClick={() => handleButtonClick("deposit")}
                className={activePanel === "deposit" ? "active" : ""}
                startIcon={
                  <Box
                    component="img"
                    src={plusIcon}
                    className={classes.buttonIcon}
                  />
                }
                sx={{
                  opacity: 0.5,
                  pointerEvents: "none",
                  width: "100%",
                }}
              >
                Deposit
              </Button>

              <Typography
                fontFamily={"Philosopher"}
                color={text.grayColor}
                textAlign={"center"}
                fontSize={16}
                fontWeight={700}
              >
                Coming soon
              </Typography>
            </Box>

            <Box>
              <Button
                onClick={() => handleButtonClick("withdraw")}
                className={activePanel === "withdraw" ? "active" : ""}
                startIcon={
                  <Box
                    component="img"
                    src={minusIcon}
                    className={classes.buttonIcon}
                  />
                }
                sx={{
                  opacity: 0.5,
                  pointerEvents: "none",
                  width: "100%",
                }}
              >
                Withdraw
              </Button>

              <Typography
                fontFamily={"Philosopher"}
                color={text.grayColor}
                textAlign={"center"}
                fontSize={16}
                fontWeight={700}
              >
                Coming soon
              </Typography>
            </Box>

            <Button
              onClick={() => handleButtonClick("convert")}
              className={activePanel === "convert" ? "active" : ""}
              startIcon={
                <Box
                  component="img"
                  src={convertIcon}
                  className={classes.buttonIcon}
                />
              }
            >
              Convert
            </Button>

            <Box>
              <Button
                onClick={() => handleButtonClick("otcTrade")}
                className={
                  activePanel === "otcTrade" || activePanel === "otcCreate"
                    ? "active"
                    : ""
                }
                startIcon={
                  <Box
                    component="img"
                    src={tradeIcon}
                    className={classes.buttonIcon}
                  />
                }
              >
                OTC Trade
              </Button>
            </Box>

            <Box>
              <Button
                onClick={() => handleButtonClick("keys")}
                className={activePanel === "keys" ? "active" : ""}
                startIcon={
                  <Box
                    component="img"
                    src={keyIcon}
                    className={classes.buttonIcon}
                  />
                }
                sx={{
                  width: "100%",
                }}
              >
                Keys
              </Button>
            </Box>
          </Box>

          {renderPanel()}
        </Box>
      </Box>
    </Box>
  );
};

export default Exchange;
