import { Box, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { Capo } from "../../types/Capo";

import { useAppSelector } from "../../app/hooks";
import { toUSDFormat } from "../../utils/utils";

import useStyles from "./index.styles";

import bulletIcon from "../../assets/imgs/family/bullets.png";
import userIcon from "../../assets/imgs/family/user.png";
import { ItemCategoryInfoList } from "../../constants/const";

const FamilyDetailCapoRegime = ({ capo }: { capo: Capo }) => {
  const { classes } = useStyles();

  const capoWorth = useMemo(() => {
    return (
      capo.capoMembers
        ?.map((member, index) => member.worth || 0)
        .reduce((sum: number, value: number) => sum + value, 0) || 0
    );
  }, [capo.capoMembers]);

  const bulletAirdropAmount = useMemo(() => {
    const index = ItemCategoryInfoList[1].values.findIndex(
      (value: number | string, index: number) =>
        parseInt(value.toString()) > (capoWorth / 3) * 0.3
    );

    if (index === -1 || index === 0) return 0;
    else return parseInt(ItemCategoryInfoList[1].values[index - 1].toString());
  }, [capoWorth]);

  return (
    <Box className={classes.capoRegime}>
      <Box>{capo.capoName}</Box>
      <Box className={classes.capoRegimeCard}>
        <Box className={classes.cardHeader}>
          <Box className={classes.cardInfo}>
            <Tooltip title="Weekly bullet payout">
              <Box>
                <Box component="img" src={bulletIcon}></Box>
                <Box>{toUSDFormat(bulletAirdropAmount, 0)}</Box>
              </Box>
            </Tooltip>
            <Link to={`/profile/${capo.capoBoss}`}>{capo.capoBoss}</Link>
            <Box>
              <Box component="img" src={userIcon}></Box>
              <Box>{toUSDFormat(capoWorth, 0)}</Box>
            </Box>
          </Box>
          <Box className={classes.cardTitle}>Capo regime</Box>
        </Box>

        <Box className={classes.memberList}>
          {[...(capo.capoMembers || [])]
            ?.sort(
              (member1, member2) => (member2.worth || 0) - (member1.worth || 0)
            )
            ?.map((member, index) => {
              return (
                <Box key={index}>
                  <Link
                    to={`/profile/${member.name}`}
                    // target="_blank"
                    className={classes.link}
                  >
                    <Box>{member.name}</Box>
                  </Link>
                  <Box className={classes.worth}>
                    {toUSDFormat(member.worth, 0)}
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

const FamilyDetailCapoRegimeList = () => {
  const { classes } = useStyles();
  const { familyDetail } = useAppSelector((state) => state.family);

  return (
    <Box className={classes.container}>
      <Box className={classes.capoRegimeList}>
        {familyDetail.capoRegime.map((capo, index) => {
          return <FamilyDetailCapoRegime capo={capo} key={index} />;
        })}
      </Box>
    </Box>
  );
};

export default FamilyDetailCapoRegimeList;
