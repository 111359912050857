import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  businessCard: {
    width: "100%",
    margin: "auto",
    height: "350px",
    backgroundColor: theme.palette.background.cardBg,
    borderRadius: 16,
    marginTop: 32,
    padding: 32,
    boxSizing: "border-box",
    border: "1px solid",
    boxShadow:
      "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",

    fontFamily: "Philosopher",
    fontWeight: 900,

    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      padding: 16,
      height: "auto",
    },
  },

  businessCardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  cardTitle: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    color: theme.palette.text.primary,

    "& img": {
      width: 24,
      height: 24,
    },
  },

  ownedTitle: {
    color: "#646464",

    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },

  comingSoon: {
    display: "flex",
    gap: "8px",
    color: "#646464",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "32px",
    },
  },

  divider: {
    width: "100%",
    borderColor: "white",
    fontSize: "20px",
    "&::after, &::before": {
      borderColor: "#646464",
    },
  },

  businessEmpty: {
    color: theme.palette.text.secondary,
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",

    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
    },
  },

  businessContent: {
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
  },

  detailsBody: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    backgroundColor: "#272a2b",
    padding: "32px 8px",
    boxSizing: "border-box",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "32px",
    },
  },

  detail: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    [theme.breakpoints.down("sm")]: {
      gap: "8px",
    },
  },

  detailHeader: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "3fr 2fr 2fr 2fr 2fr 2fr 2fr 1fr",
    padding: "16px 16px 8px 16px",
    boxSizing: "border-box",
    minWidth: 1200,

    [theme.breakpoints.down("sm")]: {
      padding: "16px 8px",
      fontSize: 14,
    },
  },

  detailList: {
    maxHeight: 220,
    overflowY: "scroll",
    width: "100%",
    minWidth: 1200,
  },

  detailListItem: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "3fr 2fr 2fr 2fr 2fr 2fr 2fr 1fr",
    padding: "8px 16px",
    borderBottom: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    boxSizing: "border-box",

    [theme.breakpoints.down("sm")]: {
      padding: "8px 8px",
    },
  },

  detailName: {
    display: "flex",
    gap: 8,
    justifyItems: "center",
    alignItems: "center",
    color: theme.palette.text.primary,
    fontSize: 14,

    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  detailIcon: {
    width: 24,
    height: 24,
  },

  detailOwner: {
    "& a": {
      color: theme.palette.text.linkColor,
      textDecoration: "none",

      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
  },

  detailCity: {
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  detailWorth: {
    color: "green",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));

export default styles;
