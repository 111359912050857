import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  customCheckBox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.text.primary,
    fontWeight: 900,
    cursor: "pointer",
    fontSize: 14,
  },
  checkBox: {
    width: 16,
    height: 16,
    backgroundColor: "#37393c",
    borderRadius: 4,
    border: "1px solid black",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  checkmark: {
    width: 20,
  },
}));

export default styles;
