export const CrateScaleDownDuration = 0.5;

export const ReelScaleUpDuration = 0.75;

export const ReelScaleUpDelay = 0.25;

export const SpeedUpDuration = 3;

export const PlayingStartTime =
  SpeedUpDuration + ReelScaleUpDelay + ReelScaleUpDuration;

export const SpinningSubDuration = 0.75;

export const SpinningCount = 4;

export const SpinningCountMobile = 2;

export const SpeedDownDuration = 8;
