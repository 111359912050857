import { Box, Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import handIcon from "../../assets/imgs/layout/open-hand.png";
import sirenIcon from "../../assets/imgs/layout/siren.png";

import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { ACTIVITY_LIMIT_DURATION } from "../../constants/const";
import useStyles from "./index.styles";
import { setRateLimitExceeded } from "../../reducers/auth.slice";

interface RateLimitPopupProps {
  openRateLimitPopup: boolean;
}

const RateLimitPopup: React.FC<RateLimitPopupProps> = ({
  openRateLimitPopup,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const { activityTimestamps } = useAppSelector((state) => state.auth);

  const [remainTime, setRemainTime] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const remainTime =
        ACTIVITY_LIMIT_DURATION -
        (Math.floor(new Date().getTime() / 1000) -
          (activityTimestamps.at(0) || 0));
      if (remainTime >= 0) {
        setRemainTime(remainTime);
      } else {
        clearInterval(intervalId);
        dispatch(setRateLimitExceeded(false));
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [activityTimestamps, dispatch]);

  return (
    <Dialog open={openRateLimitPopup} className={classes.body}>
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}></Box>

        <Box className={classes.contentBody}>
          <Box
            component="img"
            src={sirenIcon}
            className={classes.sirenIcon}
          ></Box>

          <Box className={classes.title}>Hold up, mobster!</Box>

          <Box className={classes.description}>
            You were going a bit too fast there, You reached the limit.
          </Box>

          <Box className={classes.message}>
            <Box component="img" src={handIcon} alt="Stop hand" /> 10 clicks per
            minute.
          </Box>

          <Box className={classes.countdown}>
            You need to wait {remainTime} seconds
          </Box>

          <Box className={classes.description}>
            We understand during times of crisis, fast movement is necessary.
            Upgrade to Player+ <Link to={"/profile/"}>here</Link> for unlimited
            pageloads
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RateLimitPopup;
