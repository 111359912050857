import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

import Logo from "../Logo";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import config from "../../config/config";
import useWallet from "../../hook/useWallet";

import { CONFIRMATION_DELAY } from "../../constants/const";
import { Messages } from "../../constants/messages";

import { getTokenBalance } from "../../helper/contractFunctions";
import { dispatchTxAction } from "../../helper/dispatchTxAction";

import {
  approveBankAction,
  createBankStakingAction,
} from "../../reducers/family.slice";

import { toastSuccess } from "../../utils/utils";

import money from "../../assets/imgs/family/money.png";

import useStyles from "./index.styles";

interface FamilyStakingPopupProps {
  openStakingModal: boolean;
  setOpenStakingModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const FamilyStakingPopup: React.FC<FamilyStakingPopupProps> = ({
  openStakingModal,
  setOpenStakingModal,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { account } = useWallet();

  const { familyDetail, isCreatingStaking } = useAppSelector(
    (state) => state.family
  );

  const [balance, setBalance] = useState(0);
  const [stakeAmount, setStakeAmount] = useState(0);

  const handleClose = () => {
    setOpenStakingModal(false);
  };

  const handleOnChangeAmount = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setStakeAmount(Number(e.target.value));
  };

  const handleConfirmStake = () => {
    const amount = Number(stakeAmount.toFixed(18));

    const createStaking = () => {
      dispatchTxAction(
        dispatch,
        createBankStakingAction({
          account,
          familyId: familyDetail.id || 0,
          amount: amount,
        }),
        () => {
          toastSuccess(Messages.FAMILY.STAKE.STAKED_SUCCESS);
          setTimeout(() => {
            setOpenStakingModal(false);
          }, 3000);
        }
      );
    };

    const approveStaking = () => {
      dispatchTxAction(
        dispatch,
        approveBankAction({ account, approveAmount: amount }),
        () => {
          createStaking();
        },
        CONFIRMATION_DELAY
      );
    };

    approveStaking();
  };

  useEffect(() => {
    const fetchBalance = async () => {
      if (!account) return;

      const balance = await getTokenBalance(config.mafiaAddress, account);

      setBalance(Number(balance));
    };

    fetchBalance();
  }, [account]);

  return (
    <Dialog
      open={openStakingModal}
      onClose={handleClose}
      className={classes.body}
    >
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        <Box className={classes.contentBody}>
          <Box className={classes.logoSection}>
            {familyDetail.logo ? (
              <Box
                component="img"
                src={familyDetail.logo}
                className={classes.logoImg}
              ></Box>
            ) : (
              <Logo classNames={classes.logoImg} />
            )}
          </Box>
          <Box className={classes.stakingText}>Family Staking</Box>
          <Box className={classes.stakingWarn}>
            <Box className={classes.stakingWarnBody}>
              You will{" "}
              <Box component={"span"} className={classes.notText}>
                NOT
              </Box>{" "}
              risk your tokens by staking them
            </Box>
          </Box>
          <Box className={classes.amountInputSection}>
            <TextField
              placeholder="Select amount..."
              className={classes.stakingInput}
              value={stakeAmount}
              onChange={handleOnChangeAmount}
              type="number"
            />
            <Box
              className={classes.availableValue}
              onClick={() => setStakeAmount(balance)}
            >
              Available: {balance}
              <Logo sx={{ width: 24 }} />
            </Box>
          </Box>
          <Box className={classes.receiveText}>Family bank will receive: </Box>
          <Box className={classes.receiveSection}>
            <Box className={classes.moneyIcon}>
              <Box component={"img"} src={money} width={30}></Box>
            </Box>
            <Box className={classes.receiveAmount}>${stakeAmount}</Box>
            <Box className={classes.dailyIncreaseText}>+ 0.5% daily</Box>
          </Box>
          <Box className={classes.buttonSection}>
            <Button onClick={handleConfirmStake} disabled={isCreatingStaking}>
              {isCreatingStaking ? (
                <CircularProgress size={24} />
              ) : (
                "Start Stake"
              )}
            </Button>
          </Box>
          <Box className={classes.noteText}>
            <Box>
              Note: All staked $MAFIA will get returned upon protocol launch
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default FamilyStakingPopup;
