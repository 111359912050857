export namespace Market {
  export enum ListType {
    FIXED = 0,
    AUCTION = 1,
  }

  export type SortBy = "Type" | "USD" | "Time";

  const sortByMapping: { [key in SortBy]: number } = {
    Type: 0,
    USD: 1,
    Time: 2,
  };

  // Function to get number by SortBy type
  export function getSortByNumber(type: SortBy): number {
    return sortByMapping[type];
  }
}
