import { getProvider, getWeb3, waitForConfirmation } from ".";
import config from "../../config/config";
import MafiaExchangeAbi from "../../abis/MafiaExchange.json";
import { OTCRequestItem } from "../../types/Contract/Exchange/OTCRequestItem";
import { ethers } from "ethers";

export const getMafiaExchangeContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.exchangeAddress;
  const MafiaExchangeContract = new web3.eth.Contract(
    MafiaExchangeAbi,
    contractAddress
  );
  return MafiaExchangeContract;
};

export const getExchangeTotalFee = async () => {
  const MafiaGameBankContract = getMafiaExchangeContract();
  const balance: number = await MafiaGameBankContract.methods
    .totalFees()
    .call();

  return ethers.utils.formatUnits(balance, 18);
};

export const convertItems = async (itemIds: number[], account: string) => {
  const MafiaExchangeContract = getMafiaExchangeContract(true);

  const data = await MafiaExchangeContract.methods
    .convertItem(itemIds)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const createOTCTrade = async (
  itemIds: number[],
  requestItems: OTCRequestItem[],
  account: string
) => {
  const MafiaExchangeContract = getMafiaExchangeContract(true);
  const data = await MafiaExchangeContract.methods
    .createOTCOffer(itemIds, requestItems)
    .send({ from: account });
  await waitForConfirmation(data.transactionHash);

  return data;
};

export const acceptOTCTrade = async (
  offerId: number,
  itemIds: number[],
  account: string
) => {
  const MafiaExchangeContract = getMafiaExchangeContract(true);
  const data = await MafiaExchangeContract.methods
    .acceptOTCOffer(offerId, itemIds)
    .send({ from: account });
  await waitForConfirmation(data.transactionHash);

  return data;
};

export const cancelOTCTrade = async (offerId: number, account: string) => {
  const MafiaExchangeContract = getMafiaExchangeContract(true);
  const data = await MafiaExchangeContract.methods
    .cancelOTCOffer(offerId)
    .send({ from: account });
  await waitForConfirmation(data.transactionHash);

  return data;
};

export const cancelExpiredOTCOffers = async (
  offerIds: number[],
  account: string
) => {
  const MafiaExchangeContract = getMafiaExchangeContract(true);
  const data = await MafiaExchangeContract.methods
    .cancelExpiredOTCOffers(offerIds)
    .send({ from: account });
  await waitForConfirmation(data.transactionHash);

  return data;
};
