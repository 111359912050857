import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    padding: "0 16px",
    background: "url(/assets/imgs/landingbg.png) no-repeat",
    backgroundSize: "100% auto",
    minHeight: 700,
    WebkitMaskImage:
      "-webkit-gradient(linear, left 85%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",
    maxWidth: 1920,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "philosopher",
    gap: "48px",
    paddingBottom: "32px",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // backgroundPosition: "top",
      minHeight: "auto",
      gap: 30,
      padding: "24px 0",
      // backgroundSize: "100% 100%",
    },
    [theme.breakpoints.down("sm")]: {
      // backgroundSize: "auto 100%",

    }
  },

  showCreate: {
    [theme.breakpoints.down("md")]: {
      backgroundSize: "100% 100%",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "auto 100%",
    }
  },

  arrivalText: {
    fontSize: "35px",
    color: "white",
    marginTop: "100px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "80px",
      fontSize: "24px",
    },
  },
  info: {
    display: "flex",
    justifyContent: "center",
    gap: "48px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "8px",
    },
  },
  infoDetail: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    fontSize: "20px",
    color: "white",
    "&>img": {
      width: 30,
      [theme.breakpoints.down("sm")]: {
        width: 20,
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      gap: "8px",
    },
  },
}));

export default styles;
