import { Box, IconButton, TextField, Typography } from "@mui/material";
import { CustomTypeText } from "../../theme";

interface CustomFormFieldProps {
  label: string;
  placeholder: string;
  icon: React.ReactNode;
  classes: any;
  text: CustomTypeText;
  sx: any;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFinish?: React.MouseEventHandler<HTMLButtonElement>;
}

const CustomFormField: React.FC<CustomFormFieldProps> = ({
  label,
  placeholder,
  icon,
  classes,
  text,
  sx,
  value,
  onChange,
  onFinish,
}) => (
  <Box
    sx={{
      mb: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      ...sx,
    }}
  >
    <Typography
      sx={{
        fontFamily: "Philosopher",
        fontSize: 16,
        color: text.secondary,
      }}
    >
      {label}
    </Typography>
    <TextField
      value={value}
      onChange={onChange}
      variant="outlined"
      placeholder={placeholder}
      className={classes.inputNameField}
      InputProps={{
        endAdornment: <IconButton onClick={onFinish}>{icon}</IconButton>,
      }}
    />
  </Box>
);

export default CustomFormField;
