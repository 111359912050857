import { Box, Skeleton } from "@mui/material";
import clsx from "clsx";

import useStyles from "./index.styles";

const CompletedListingLoadingSkeleton = () => {
  const { classes } = useStyles();
  const height = 24;
  return (
    <Box className={clsx(classes.listingPanelItem, classes.completedPanelItem)}>
      <Box>
        <Skeleton
          width={24}
          height={height}
          variant="rounded"
          animation="wave"
        />
        <Skeleton
          width={100}
          height={height}
          variant="rounded"
          animation="wave"
        />
      </Box>

      <Skeleton width={60} height={height} variant="rounded" animation="wave" />

      <Skeleton width={48} height={height} variant="rounded" animation="wave" />

      <Skeleton width={60} height={height} variant="rounded" animation="wave" />

      <Skeleton width={72} height={height} variant="rounded" animation="wave" />
    </Box>
  );
};

export default CompletedListingLoadingSkeleton;
