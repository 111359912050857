import { getProvider, getWeb3, waitForConfirmation } from ".";
import MafiaAbi from "../../abis/Mafia.json";
import config from "../../config/config";

export const getMafiaContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.mafiaAddress;
  const MafiaContract = new web3.eth.Contract(MafiaAbi, contractAddress);
  return MafiaContract;
};

export const swapToVault = async (account: string) => {
  const MafiaContract = getMafiaContract(true);

  const data = await MafiaContract.methods
    .swapToVault()
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);
  return data;
};
