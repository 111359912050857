import { Box, Button, Dialog, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  BUSINESS_CATEGORY_ID,
  City,
  ItemCategoryInfoList,
  OTC_TRADE_MAX_ITEM,
} from "../../../constants/const";
import { ItemCategory } from "../../../constants/enum/enum";
import { Messages } from "../../../constants/messages";
import { getInventoryItemInfo } from "../../../helper/inventory";
import { ItemInfo } from "../../../types/ItemInfo";
import { toastInfo } from "../../../utils/utils";

import checkIcon from "../../../assets/imgs/exchange/check.png";

import useStyles from "./index.styles";

interface OTCItemSelectionProps {
  openOTCItemSelection: boolean;
  handleClose: () => void;
  handleAddItems: (items: ItemInfo[]) => void;
}

const OTCItemSelection: React.FC<OTCItemSelectionProps> = ({
  openOTCItemSelection,
  handleClose,
  handleAddItems,
}: OTCItemSelectionProps) => {
  const { classes } = useStyles();
  const [items, setItems] = useState<number[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const onSelectItem = (itemId: number) => {
    setItems((prev) =>
      prev.includes(itemId)
        ? prev.filter((prevItem) => prevItem !== itemId)
        : [...prev, itemId]
    );
  };

  const onAddItems = () => {
    const selectedItems = items.map((itemId, index) => {
      if (itemId >= 1000) {
        // Selected business item
        return {
          categoryId: BUSINESS_CATEGORY_ID,
          typeId: itemId % 10,
          cityId: Math.floor(itemId / 1000 - 1),
          itemId: itemId,
        } as ItemInfo;
      } else {
        // Selected normal item
        return {
          categoryId: Math.floor(itemId / 10),
          typeId: itemId % 10,
          cityId: 0,
          itemId: itemId,
        } as ItemInfo;
      }
    });

    if (selectedItems.length > OTC_TRADE_MAX_ITEM) {
      toastInfo(Messages.EXCHANGE.BASIC.ITEM_SELECT_LIMIT);
      return;
    }

    handleAddItems(selectedItems);
    handleClose();

    setItems([]);
  };

  return (
    <Dialog
      open={openOTCItemSelection}
      onClose={handleClose}
      className={classes.modalBody}
    >
      <Box className={classes.modalContent}>
        <Box className={classes.contentBody}>
          <TextField
            variant="outlined"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            margin="normal"
          />

          <Box
            sx={{
              height: "360px",
              overflowY: "scroll",
            }}
          >
            {ItemCategoryInfoList.map((itemInfos, index) =>
              itemInfos.values.map((_, i) => {
                if (index === 11 || index === 12)
                  if (i < 5 || i === 6) return "";

                const itemInfo = getInventoryItemInfo(index, i);
                const itemId = index * 10 + i;
                const result = [
                  itemInfo.itemName.toString().toLowerCase(),
                ].find((item) => item.includes(searchTerm.toLowerCase()));

                return itemInfos.id !== ItemCategory.BUSINESS ? (
                  result ? (
                    <Box
                      key={`${index}-${i}`}
                      sx={{
                        padding: "0 16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        transitionDuration: "300ms",

                        "&:hover": {
                          cursor: "pointer",
                          borderRadius: "8px",
                          backgroundColor: "#ffffff0d",
                        },
                      }}
                      onClick={() => {
                        onSelectItem(itemId);
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          padding: "8px",
                        }}
                      >
                        <Box
                          className={classes.inventoryItemIcon}
                          component="img"
                          src={itemInfo.itemIcon}
                        ></Box>
                        <Box>{itemInfo.itemName}</Box>
                      </Box>

                      {items.includes(itemId) && (
                        <Box
                          className={classes.inventoryItemIcon}
                          component="img"
                          src={checkIcon}
                        ></Box>
                      )}
                    </Box>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                );
              })
            )}
            {City.map((city, index) =>
              ItemCategoryInfoList[4].values.map((business, businessIndex) => {
                const itemInfo = getInventoryItemInfo(4, businessIndex, index);
                const itemId = (index + 1) * 1000 + businessIndex; // Changed 'i' to 'businessIndex'
                const result = [
                  itemInfo.itemName.toString().toLowerCase(),
                ].find((item) => item.includes(searchTerm.toLowerCase()));

                return result ? (
                  <Box
                    key={`${index}-${businessIndex}`}
                    sx={{
                      padding: "0 16px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      transitionDuration: "300ms",
                      "&:hover": {
                        cursor: "pointer",
                        borderRadius: "8px",
                        backgroundColor: "#ffffff0d",
                      },
                    }}
                    onClick={() => {
                      onSelectItem(itemId);
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        padding: "8px",
                      }}
                    >
                      <Box
                        className={classes.inventoryItemIcon}
                        component="img"
                        src={itemInfo.itemIcon}
                      />
                      <Box>{itemInfo.itemName}</Box>
                    </Box>

                    {items.includes(itemId) && (
                      <Box
                        className={classes.inventoryItemIcon}
                        component="img"
                        src={checkIcon}
                        alt="Selected" // Added alt attribute for accessibility
                      />
                    )}
                  </Box>
                ) : null; // Return null if no result found
              })
            )}
          </Box>

          <Button className={classes.confirm} onClick={onAddItems}>
            Add Items
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default OTCItemSelection;
