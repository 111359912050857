import { Box, Button, CircularProgress, Skeleton } from "@mui/material";
import millify from "millify";
import { useEffect, useState } from "react";

import useNativePrice from "../../hook/useNativePrice";
import useVaultBalance from "../../hook/useVaultBalance";
import useWallet from "../../hook/useWallet";
import Logo from "../Logo";
import NativeIcon from "../NativeIcon";

import config, { CHAIN } from "../../config/config";
import { ChainType } from "../../constants/enum/enum";
import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";
import { getTokenBalance } from "../../helper/contractFunctions";
import { swapToVault } from "../../helper/contractFunctions/Mafia";
import { toastError, toastSuccess, toUSDFormat } from "../../utils/utils";

import cardBg2 from "../../assets/imgs/landing/contract.png";
import explorerIcon from "../../assets/imgs/landing/explorer.png";
import cardBg1 from "../../assets/imgs/landing/vault.png";

import useStyles from "./index.styles";

const VaultCard = ({ type }: { type: string }) => {
  const { classes } = useStyles();
  const { account, connectWallet } = useWallet();
  const vaultBalance = useVaultBalance();
  const nativePrice = useNativePrice();

  const [nativeBalance, setNativeBalance] = useState(0);
  const [usdValue, setUsdValue] = useState(0);
  const [contractBalance, setContractBalance] = useState(0);
  const [swapping, setSwapping] = useState(false);

  const fetchContractBalance = async () => {
    const balance = await getTokenBalance(
      config.mafiaAddress,
      config.mafiaAddress
    );

    setContractBalance(parseFloat(balance));
  };

  useEffect(() => {
    const balanceInNative = vaultBalance;
    const balanceInUsd = parseFloat((balanceInNative * nativePrice).toFixed(2));

    setNativeBalance(balanceInNative);
    setUsdValue(balanceInUsd);
  }, [vaultBalance, nativePrice]);

  useEffect(() => {
    fetchContractBalance();
  }, []);

  const handleSwapToVault = async () => {
    if (!account) connectWallet();

    // FIXME: Update this swap vault function with redux
    setSwapping(true);
    swapToVault(account)
      .then((tx) => {
        fetchContractBalance();
        toastSuccess(Messages.VAULT.BASIC.CONVERT_SUCCESS, {
          chain: config.chainSymbol,
        });
      })
      .catch((error) => {
        setSwapping(false);

        toastError(Errors.Vault.BASIC.CONVERT_FAILED, {
          chain: config.chainSymbol,
        });
      })
      .finally(() => {
        setSwapping(false);
      });
  };

  return (
    <Box className={classes.card}>
      {type === "Vault" ? (
        <>
          <Box component="img" src={cardBg1}></Box>
          <NativeIcon classNames={classes.binanceIcon} />

          <Box className={classes.balance}>
            {nativeBalance === 0 ? (
              <Skeleton variant="rounded" width={100}></Skeleton>
            ) : (
              millify(nativeBalance, {
                precision: 2,
              })
            )}{" "}
            {config.chainSymbol}
          </Box>
          <Box className={classes.vaultBalanceUsd}>
            <Box className={classes.vaultBalanceUsdText}>
              {usdValue === 0 ? (
                <Skeleton variant="rounded" width={80}></Skeleton>
              ) : (
                "$ " + toUSDFormat(usdValue)
              )}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box component="img" src={cardBg2}></Box>
          <Logo classNames={classes.binanceIcon} />

          <Box className={classes.balance}>
            {toUSDFormat(contractBalance)} $MAFIA
          </Box>
          <Button
            className={classes.vaultBalanceUsd}
            onClick={handleSwapToVault}
          >
            {swapping ? (
              <CircularProgress size={16} />
            ) : (
              <Box className={classes.vaultBalanceUsdText}>Convert now</Box>
            )}
          </Button>
        </>
      )}

      <Box className={classes.explorer}>
        {CHAIN === ChainType.PLS ? (
          <NativeIcon classNames={classes.explorerIcon} />
        ) : (
          <Box
            component="img"
            src={explorerIcon}
            className={classes.explorerIcon}
          ></Box>
        )}

        <Box
          component="a"
          href={`${config.explorerUrl}address/${
            type === "Vault" ? config.vault1Address : config.mafiaAddress
          }`}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.explorerLink}
        >
          <Box>View on explorer</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VaultCard;
