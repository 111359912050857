import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
    },
  },

  modalContent: {
    position: "relative",
  },

  contentBody: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: "32px 64px",
    [theme.breakpoints.down("sm")]: {
      padding: "32px 22px",
    },
    "& img": {
      width: 60,
    },
  },

  logoSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    fontSize: "24px",
    marginTop: "24px",
  },

  logoImg: {
    minWidth: "40px",
  },

  closeIconBody: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 25,
    top: 25,
    "&>button": {
      padding: 0,
      minWidth: "auto",
      "&>svg": {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        transition: ".3s",
        fontSize: "1.5rem",
        "&:hover": {
          color: theme.palette.text.primary,
          transition: ".3s",
        },
      },
    },
  },
  inviteText: {
    fontWeight: 800,
    display: "flex",
    fontSize: "25px",
    justifyContent: "center",
    marginTop: "8px",
  },
  inviteDescription: {
    color: "#717272",
    fontSize: "18px",
    display: "flex",
    gap: "8px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
    },
  },
  link: {
    color: theme.palette.text.linkColor,
    textDecoration: "none",
  },
  buttonSection: {
    display: "flex",
    justifyContent: "center",
    marginTop: "24px",
    gap: "24px",
    "&>button": {
      borderRadius: "8px",
      padding: "8px 16px",
      textTransform: "none",
      minWidth: "200px",
      fontSize: "18px",
      fontFamily: "Philosopher",
      color: theme.palette.text.primary,
      [theme.breakpoints.down("sm")]: {
        minWidth: "auto",
      },
    },
  },
  acceptBut: {
    backgroundColor: "#2d6041",
  },
  declineBut: {
    backgroundColor: "#602d38",
  },
}));

export default styles;
