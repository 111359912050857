import { FamilyAllegianceInfo } from "./../types/FamilyAllegianceInfo";

export const parseFamilyAllegianceInfo = (allegiance: any) => {
  return {
    createdAt: allegiance.created_time,
    creator: allegiance.creator,
    creatorFamilyId: allegiance.creator_family,
    allegianceId: allegiance.id,
    color: allegiance.color,
    name: allegiance.name,
    symbol: allegiance.symbol,
    isDead: allegiance.is_dead,
    memberCount: allegiance.member_count,
  } as FamilyAllegianceInfo;
};
