export const Errors = {
  // G
  GLOBAL: {
    // W
    WEB3: {
      // 0
      REJECTED: {
        code: "GW0",
        message: "Rejected tx confirmation.",
      },

      // 1
      UNKNOWN: {
        code: "GW1",
        message: "Error occurred during tx confirmation.",
      },

      // 2
      APPROVE_FAILED: {
        code: "GW2",
        message: "Failed to approve.",
      },
    },

    // A
    ACCOUNT: {
      // 0
      NOT_CONNECTED: {
        code: "GA0",
        message: "Wallet is not connected, connect wallet first.",
      },
      // 1
      SIGNING_FAILED: {
        code: "GA1",
        message: "Wallet signing Failed.",
      },
    },

    // T
    TOKEN: {
      // 0
      CLIPBOARD_FAILED: {
        code: "WT0",
        message: "Failed to copy address.",
      },
    },

    // P
    PROFILE: {
      // 0
      CREATION_FAILED: {
        code: "GP0",
        message: "Account creation failed.",
      },
      // 1
      NOT_CREATED: {
        code: "GP1",
        message: "Please create your profile first.",
      },

      // 2
      DESCRIPTION_EDIT_FAILED: {
        code: "GP2",
        message: "Failed to update profile description.",
      },

      // 3
      INVENTORY_DISPLAY_SETTING_FAILED: {
        code: "GP3",
        message: "Failed to update profile inventory display setting.",
      },

      // 4
      CLAIMING_KEY_FAILED: {
        code: "GP4",
        message: "Failed to claiming key.",
      },

      // 5
      GETTING_FAILED: {
        code: "GP5",
        message: "Failed to get profile.",
      },
    },
  },

  // F
  FAMILY: {
    // A
    ALLEGIANCE: {
      // 0
      INVITE_INVALID_FAMILY_ID: {
        code: "FA0",
        message: "Invalid family ID provided.",
      },
      // 1
      UPDATE_ALLEGIANCE_SHARING_FAILED: {
        code: "FA1",
        message:
          "Error occurred during update allegiance vault sharing percentage.",
      },
      // 2
      INVALID_IMAGE: {
        code: "FA2",
        message: "Invalid image file",
      },
      // 3
      INVALID_NAME: {
        code: "FA3",
        message: "Please input allegiance name.",
      },
      // 4
      UPDATE_ALLEGIANCE_LOGO_FAILED: {
        code: "FA4",
        message:
          "Error occurred during update allegiance logo.",
      },
    },
    // C
    CAPO_REGIME: {
      //0
      UPDATE_MEMBER_FAILED: {
        code: "FC0",
        message: "Error occurred during update member capo regime.",
      },
    },
    // I
    INVITE: {
      // 0
      LIMIT_EXCEEDED: {
        code: "FI0",
        message: "Family invite limit exceed. Please try again later.",
      },
      // 1
      INVITED_FAILED: {
        code: "FI1",
        message: "Error occurred during inviting",
      },
    },
    // V
    VALIDATE: {
      // 0
      EMPTY_NAME: {
        code: "FV0",
        message: "Please input family name.",
      },
      // 1
      NOT_UPPERCASE: {
        code: "FV1",
        message: "Your family name cannot be in all uppercase letters.",
      },
      // 2
      IS_IN_FAMILY: {
        code: "FV2",
        message: "You are already in a family.",
      },
      // 3
      CREATING_FAILED: {
        code: "FV3",
        message: "Error while creating family.",
      },
      // 4
      LEAVING_FAILED: {
        code: "FV4",
        message: "Error occurred during leave family.",
      },
      // 5
      KICKING_FAILED: {
        code: "FV5",
        message: "Error occurred during kick the member from family.",
      },
      // 6
      INVALID_IMAGE: {
        code: "FV6",
        message: "Invalid image file",
      },
      // 7
      UPDATE_LEAVE_FEE_FAILED: {
        code: "FV7",
        message: "Rejected update family leave fee",
      },
    },
    // N
    INVENTORY: {
      // 0
      TRANSFER_FAILED: {
        code: "FN0",
        message: "Failed to transfer item",
      },
      // 1
      AMOUNT_INVALID: {
        code: "FN1",
        message: "Please input NFT Key amount correctly.",
      },
      // 2
      NFT_TRANSFER_FAILED: {
        code: "FN2",
        message: "Failed to transfer NFT Key.",
      },
      // 3
      NOT_ENOUGH_NFT: {
        code: "FN3",
        message: "You've got only {ogNFTBalance} OG NFTs",
      },
      // 4
      NFT_AMOUNT_INVALID: {
        code: "FN4",
        message: "Please input OG NFT amount correctly.",
      },
      // 5
      OG_NFT_TRANSFER_FAILED: {
        code: "FN5",
        message: "Failed to transfer OG NFT.",
      },
      // 6
      DEPOSIT_NFT_FAILED: {
        code: "FN6",
        message: "Failed to deposit NFT",
      },
      // 7
      DEPOSIT_OG_NFT_FAILED: {
        code: "FN7",
        message: "Failed to deposit OG NFT",
      },
      // 8
      WITHDRAW_NFT_FAILED: {
        code: "FN8",
        message: "Failed to withdraw NFT key",
      },
      // 9
      WITHDRAW_OG_NFT_FAILED: {
        code: "FN9",
        message: "Failed to withdraw OG NFT",
      },
      // 10
      UNWRAP_GAMEKEY_FAILED: {
        code: "FN10",
        message: "Failed to unwrap Game Key item",
      },
      // 11
      UNWRAP_MAFIA_ITEM_FAILED: {
        code: "FN11",
        message: "Failed to unwrap MAFIA token item",
      },
      // 12
      UNWRAP_OG_NFT_FAILED: {
        code: "FN12",
        message: "Failed to unwrap OG NFT ",
      },
      // 13
      BUY_PREMIUM_FAILED: {
        code: "FN13",
        message: "Failed to buy premium",
      },
      // 14
      OPEN_CRATE_FAILED: {
        code: "FN14",
        message: "Failed to request open crate using NFT Key.",
      },

      // 15
      FINISH_NFT_CRATE: {
        code: "FN15",
        message: "Failed to finish use NFT Key.",
      },
    },
  },

  // M
  MARKET: {
    // B
    BASIC: {
      // 0
      NEXT_BID_AMOUNT_INVALID: {
        code: "MB0",
        message: "Next bid have to larger than current bid amount",
      },

      // 1
      NOT_SELECTED_ITEM: {
        code: "MB1",
        message: "Please select item to list.",
      },

      // 2
      USD_INVALID: {
        code: "MB2",
        message: "Input usd amount correctly.",
      },

      // 3
      DURATION_INVALID: {
        code: "MB3",
        message: "Please select listing duration.",
      },

      // 4
      REJECTED_AUCTION: {
        code: "MB4",
        message: "Rejected complete auction.",
      },

      // 5
      REJECTED_BID: {
        code: "MB5",
        message: "Rejected make bid on auction item.",
      },

      INSUFFICIENT_BID: {
        code: "MB6",
        message: "Please increase bid amount.",
      },
    },
  },

  // V
  Vault: {
    // B
    BASIC: {
      // 0
      CONVERT_FAILED: {
        code: "VB0",
        message: "Failed to convert $MAFIA to {chain}.",
      },
    },
  },

  // E
  EXCHANGE: {
    CONVERT: {
      ITEM_NOT_SELECTED: {
        code: "EC0",
        message: "Please select items to convert",
      },
    },

    OTC: {
      TRADE_ITEM_NOT_SELECTED: {
        code: "EO0",
        message: "Please select item to trade.",
      },
      OPTION_ITEM_NOT_SELECTED: {
        code: "EO1",
        message: "Please select item you want to receive.",
      },
      MAXIMUM_FIVE_ITEMS: {
        code: "E02",
        message: "You can request maximum 5 items.",
      },
    },
  },
};
