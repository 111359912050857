import axios from "axios";
import config from "../config/config";
import { useEffect, useState } from "react";

const DEXSCREENER_API_URL = `${config.mafiaPriceUrl}${config.pairAddress}`;

const useMafiaPrice = () => {
  const [price, setPrice] = useState(0);

  useEffect(() => {
    const getMafiaUsdPrice = async () => {
      try {
        const response = await axios.get(DEXSCREENER_API_URL);
        setPrice(response.data.pairs[0].priceUsd);
      } catch (error) {
        console.error("Error fetching Mafia USD price:", error);
        return null;
      }
    };

    getMafiaUsdPrice();
  }, []);

  return price;
};

export default useMafiaPrice;
