import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    fontFamily: "Philosopher",
    gap: "32px",
    padding: "16px",
    background: "url('/assets/imgs/buy/bg.png') no-repeat",
    backgroundColor: theme.palette.background.defaultBg,
    maxWidth: "1920px",
    margin: "auto",
    boxSizing: "border-box",
    minHeight: 900,

    [theme.breakpoints.down("sm")]: {
      background: "url('/assets/imgs/buy/bg.png') no-repeat",
      backgroundColor: theme.palette.background.defaultBg,
      backgroundPosition: "center 0",
    },
  },

  logoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "32px",
  },

  logo: {
    width: "200px",
    height: "200px",

    [theme.breakpoints.down("sm")]: {
      marginTop: "60px",
      width: "120px",
      height: "120px",
    },
  },

  symbol: {
    color: "white",
    fontSize: "48px",
    fontWeight: 700,
  },

  taxInfo: {
    display: "flex",
  },

  buyTax: {
    background: theme.palette.text.primaryGradient,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "bold",
    padding: "0 32px",
    borderRight: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
  },

  sellTax: {
    background: theme.palette.text.secondaryGradient,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "900",
    padding: "0 32px",
  },

  description: {
    color: theme.palette.text.secondary,
    fontWeight: "900",
    textAlign: "center",
    maxWidth: "600px",
  },

  links: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "96px",
    marginTop: "48px",

    "& img": {
      height: "28px",
    },

    [theme.breakpoints.down("md")]: {
      "& img": {
        height: "28px",
      },

      gap: "16px",
    },

    [theme.breakpoints.down("sm")]: {
      "& img": {
        height: "32px",
      },
      flexDirection: "column",
      gap: "12px",
    },
  },

  widgets: {
    display: "flex",
    height: "622px",
    width: "90%",
    gap: "64px",
    maxWidth: "1440px",
    marginTop: "32px",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      height: "initial",
      width: "100%",
    },
  },
}));

export default styles;
