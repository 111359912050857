import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    padding: "0 16px",
    background: "url(/assets/imgs/family/bg1.png) no-repeat",
    backgroundSize: "100% auto",
    backgroundPosition: "bottom",

    WebkitMaskImage:
      "-webkit-gradient(linear, left 95%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",

    maxWidth: 1920,
    margin: "auto",

    [theme.breakpoints.down("md")]: {
      backgroundSize: "auto 100%!important",
      backgroundPosition: "top center!important",
      WebkitMaskImage:
        "-webkit-gradient(linear, left 95%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",

      // WebkitMaskImage: "none",
    },
  },

  headerSection: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    alignItems: "center",
    paddingTop: 80,

    "& img": {
      height: 24,
    },

    "& div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 16,
    },
  },

  headerTitle: {
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 20,
  },

  headerDescription: {
    color: theme.palette.text.brightYellow,
    fontFamily: "Philosopher",
    fontWeight: 900,
  },

  headerChainLinkText: {
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
  },

  animationSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "32px 0",
    position: "relative",
    height: 240,
  },

  crate: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "32px 0",
    position: "absolute",
  },

  crateImage: {
    height: 160,
  },

  reel: {
    width: "100%",
    maxWidth: 1144,
    height: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "absolute",

    overflow: "hidden",
    WebkitMaskImage:
      "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 77%, rgba(0,0,0,0) 95%)",

    scale: 0,
    opacity: 0,
  },

  reelStop: {
    width: 2,
    height: "120px",
    position: "absolute",
    left: "50%",
    top: "50%",
    translate: "-50% -50%",
    background: theme.palette.background.brightRed,
  },

  prizeItemList: {
    display: "flex",
    gap: 16,
  },

  prizeItem: {
    background: theme.palette.background.gray,
    border: "1px solid",
    borderColor: theme.palette.background.blackBorder,
    borderRadius: 8,
    padding: 12,
    boxShadow:
      "inset 0 -3px 6px 0px rgb(0 0 0 / 35%), inset 3px 0 6px 0px rgb(0 0 0 / 35%), inset 0 3px 6px 0px rgb(0 0 0 / 35%), inset -3px 0 6px 0px rgb(0 0 0 / 35%)",

    "& img": {
      width: 60,
      height: 60,
    },
  },

  purchaseSection: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "32px 0",
    gap: 16,
  },

  openButton: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: 8,

    "& button": {
      background: theme.palette.background.createButtonBg,

      color: theme.palette.text.primary,
      fontFamily: "Philosopher",
      fontWeight: 900,
      textTransform: "none",

      borderRadius: 8,
      padding: "8px 32px",
      border: "1px solid",
      borderColor: theme.palette.background.defaultBorder,
    },
    "& img": {
      width: 24,
      height: 24,
    },
  },

  purchaseButton: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: 8,

    "& button": {
      background: "transparent",

      color: theme.palette.text.primary,
      fontFamily: "Philosopher",
      fontWeight: 900,
      textTransform: "none",

      borderRadius: 8,
      padding: "8px 32px",
      border: "1px solid",
      borderColor: theme.palette.background.defaultBorder,
    },
  },

  priceDescription: {
    display: "flex",
    gap: 8,
    alignItems: "center",

    "& img": {
      width: 20,
      height: 20,
    },

    "& p": {
      color: theme.palette.text.secondary,
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 14,
    },
  },

  selectCombo: {},
}));

export default styles;
