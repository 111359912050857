import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "32px",
    fontFamily: "Philosopher",
    background: "url('/assets/imgs/landing/vaultbg.png') no-repeat",
    backgroundColor: theme.palette.background.defaultBg,
    padding: "16px",
    maxWidth: "1920px",
    margin: "auto",
    boxSizing: "border-box",
    minHeight: 900,
  },

  title: {
    fontWeight: 900,
    color: theme.palette.text.primary,
    fontSize: "48px",
  },

  description: {
    fontWeight: 900,
    color: theme.palette.text.secondary,
    fontSize: "16px",
    maxWidth: "600px",
    textAlign: "center",
  },

  cards: {
    display: "flex",
    gap: 32,
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  chart: {
    position: "relative",
    minWidth: "752px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      overflowX: "auto",
      minWidth: "320px",
    },
  },

  chartLabel: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    paddingTop: 24,
    paddingBottom: 24,
  },

  chartLabelDetail: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
  },

  circleSymbol: {
    width: 10,
    height: 10,
    borderRadius: "50%",
  },

  taxCircle: {
    backgroundColor: "#cca822",
  },

  crateCircle: {
    backgroundColor: "#f15c78",
  },

  marketCircle: {
    backgroundColor: "#5cacf1",
  },

  taxLabel: {
    color: "#cca822",
  },

  crateLabel: {
    color: "#f15c78",
  },

  marketLabel: {
    color: "#5cacf1",
  },
}));

export default styles;
