import { getProvider, getWeb3, waitForConfirmation } from ".";

import OgCrateABI from "../../abis/OGCrate(ERC1155).json";

import config from "../../config/config";

export const getOgCrateContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.ogCrateAddress;
  const OgCrateContract = new web3.eth.Contract(OgCrateABI, contractAddress);
  return OgCrateContract;
};

export const crateBalanceOf = async (account: string) => {
  const OgCrateContract = getOgCrateContract();

  const crateBalance: number = await OgCrateContract.methods
    .balanceOf(account, 0)
    .call();

  return crateBalance;
};

export const isCrateApprovedForAll = async (
  account: string,
  spender: string
) => {
  const OgCrateContract = getOgCrateContract();

  const isApproved: boolean = await OgCrateContract.methods
    .isApprovedForAll(account, spender)
    .call();

  return isApproved;
};

export const setCrateApprovalForAll = async (
  operator: string,
  approved: boolean,
  account: string
) => {
  const OgCrateContract = getOgCrateContract(true);

  const data = await OgCrateContract.methods
    .setApprovalForAll(operator, approved)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);
};
