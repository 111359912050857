import { BUSINESS_CATEGORY_ID } from "../constants/const";
import { ItemInfo } from "../types/ItemInfo";

export const parseOTCOfferItems = (payload: any) => {
  return payload.map((item: any) => {
    return {
      ...item,
      offerItemDataList: item.offerItemDataList.map((itemData: any) => {
        return { ...itemData, cityId: itemData.city };
      }),
      requestItems: item.requestItems.map((item: any) => {
        return {
          ...item,
          cityId: item.categoryId === BUSINESS_CATEGORY_ID ? item.cityId : -1,
        };
      }),
    };
  });
};
export const hasAllItems = (
  myInventoryItems: ItemInfo[],
  itemInfos: ItemInfo[]
): [boolean, number[]] => {
  // Create a count map for itemInfos
  const itemCount: Record<string, number> = {};

  // Count occurrences of each item in itemInfos
  for (const item of itemInfos) {
    // if type of ognft and credit is from 0 to 6, format as 0
    let typeId = item.typeId;
    if (
      (item.categoryId === 11 || item.categoryId === 12) &&
      item.typeId >= 0 &&
      item.typeId < 6
    ) {
      typeId = 0;
    }

    const key = `${item.categoryId}-${typeId}-${item.cityId}`;
    itemCount[key] = (itemCount[key] || 0) + 1;
  }

  // Array to hold matching itemIds
  const matchingItemIds: number[] = [];

  // Check if myInventoryItems contains sufficient quantities
  for (const item of myInventoryItems) {
    let typeId = item.typeId;
    if (
      (item.categoryId === 11 || item.categoryId === 12) &&
      item.typeId >= 0 &&
      item.typeId < 6
    ) {
      typeId = 0;
    }
    const key = `${item.categoryId}-${typeId}-${item.cityId}`;
    if (itemCount[key]) {
      matchingItemIds.push(item.itemId);
      itemCount[key]--;
      if (itemCount[key] === 0) {
        delete itemCount[key]; // Remove the key if count reaches zero
      }
    }
  }

  // If itemCount is empty, all items are satisfied
  return [Object.keys(itemCount).length === 0, matchingItemIds];
};
