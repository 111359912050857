import { Box, Button, Theme, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ProfileInventoryItem } from "../ProfileInventory";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  CashExchangeAmount,
  CONFIRMATION_DELAY,
  ItemCategoryInfoList,
} from "../../constants/const";
import { CustomTypeText } from "../../theme";
import { ItemInfo } from "../../types/ItemInfo";

import letterIcon from "../../assets/imgs/exchange/letter.png";
import moneyIcon from "../../assets/imgs/exchange/money.png";
import convertIcon from "../../assets/imgs/exchange/recycle.png";

import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";
import { dispatchTxAction } from "../../helper/dispatchTxAction";
import useGameBankBalance from "../../hook/useGameBankBalance";
import useWallet from "../../hook/useWallet";
import { convertItemsAction } from "../../reducers/exchange.slice";
import {
  getMyInventoryItems,
  updateCashBalance,
} from "../../reducers/profile.slice";
import { toastError, toastSuccess, toUSDFormat } from "../../utils/utils";

import useStyles from "./index.styles";

const ConvertPanel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const theme = useTheme<Theme>();
  const { account } = useWallet();
  const text = theme.palette.text as CustomTypeText;

  const myProfile = useAppSelector((state) => state.profile.myProfile);
  const { myInventoryItems } = useAppSelector((state) => state.profile);
  const { signMsg, signature } = useAppSelector((state) => state.auth);
  const { updateBalance } = useGameBankBalance(account, signMsg, signature);

  const [isAnimating, setIsAnimating] = useState(false);
  const [selectedItems, setSelectedItems] = useState<ItemInfo[]>([]);
  const [volumeBonus, setVolumeBonus] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);
  const [displayCashAmount, setDisplayCashAmount] = useState(0); // State for displaying cash amount

  const sortedItem = useMemo(() => {
    return [...myInventoryItems]
      .filter(
        (item) =>
          item.categoryId !== 8 &&
          item.categoryId !== 9 &&
          item.categoryId !== 10
      )
      .sort(
        (item1, item2) =>
          ItemCategoryInfoList[item2.categoryId].inventoryWorth[item2.typeId] -
            ItemCategoryInfoList[item1.categoryId].inventoryWorth[
              item1.typeId
            ] || item1.categoryId - item2.categoryId
      );
  }, [myInventoryItems]);

  const renderItems = useCallback(() => {
    return (
      <>
        {sortedItem.map((item, index) => {
          const indexInSelectedItems = selectedItems
            .map((item) => item.itemId)
            .indexOf(item.itemId);
          return (
            <ProfileInventoryItem
              onClick={() => {
                if (indexInSelectedItems >= 0)
                  setSelectedItems((items) => {
                    return [
                      ...items.slice(0, indexInSelectedItems),
                      ...items.slice(indexInSelectedItems + 1, items.length),
                    ];
                  });
                else {
                  setSelectedItems((items) => [...items, item]);
                }
              }}
              item={item}
              key={index}
              selected={indexInSelectedItems >= 0}
            />
          );
        })}
      </>
    );
  }, [selectedItems, sortedItem]);

  useEffect(() => {
    let totalCash = 0;
    selectedItems.forEach((item) => {
      const categoryId = item.categoryId;

      if (
        categoryId === 0 ||
        categoryId === 1 ||
        categoryId === 2 ||
        categoryId === 3 ||
        categoryId === 5 ||
        categoryId === 6 ||
        categoryId === 7 ||
        categoryId === 12
      ) {
        const exchangeAmount =
          CashExchangeAmount[categoryId]?.[item.typeId] || 0;

        totalCash += exchangeAmount;
      }
    });

    if (totalCash >= 100000000) {
      setVolumeBonus((totalCash * 8) / 100);
    } else if (totalCash >= 50000000) {
      setVolumeBonus((totalCash * 5) / 100);
    } else if (totalCash >= 15000000) {
      setVolumeBonus((totalCash * 3) / 100);
    }

    setCashAmount(totalCash);
    navigate("/exchange");
  }, [navigate, selectedItems]);

  useEffect(() => {
    let start = displayCashAmount;
    const end = cashAmount;
    const duration = 5000; // Duration of the animation in ms
    const frameDuration = 1000 / 60; // Targeting 60 frames per second
    const totalFrames = Math.round(duration / frameDuration);
    const increment = (end - start) / totalFrames;
    if (cashAmount > 0) setIsAnimating(true);

    const animate = () => {
      start += increment;
      if ((increment > 0 && start >= end) || (increment < 0 && start <= end)) {
        start = end; // Ensure we don't overshoot
        clearInterval(intervalId);

        setIsAnimating(false);
      }
      setDisplayCashAmount(Math.round(start));
    };

    const intervalId = setInterval(animate, frameDuration);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashAmount]);

  const handleConfirmConversion = () => {
    if (!account) return;
    const itemIds = selectedItems.map((item) => item.itemId);
    if (itemIds.length === 0) {
      toastError(Errors.EXCHANGE.CONVERT.ITEM_NOT_SELECTED);
    } else {
      dispatchTxAction(
        dispatch,
        convertItemsAction({ itemIds, account }),
        async () => {
          toastSuccess(Messages.EXCHANGE.CONVERT.ITEM_CONVERTED);
          dispatch(getMyInventoryItems({ userId: myProfile.id || 0 }));
          updateBalance(account, signMsg, signature);
          dispatch(updateCashBalance(account));
          setSelectedItems([]);
        },
        CONFIRMATION_DELAY * 3
      );
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const convertItemId = parseInt(queryParams.get("convertItem") || "0");
    if (convertItemId > 0) {
      const item = myInventoryItems.find(
        (item, index) => item.itemId === convertItemId
      );

      if (item) {
        setSelectedItems((prev) => [...prev, item]);
      }
    }
  }, [location.search, myInventoryItems]);

  return (
    <>
      <Box className={classes.exchangeInfo}>
        <Box
          className={classes.exchangeCard}
          sx={{
            minHeight: 480,
          }}
        >
          <Typography
            fontFamily={"Philosopher"}
            color={"white"}
            textAlign={"center"}
            fontSize={24}
            fontWeight={700}
          >
            You deposit
          </Typography>
          <Typography
            fontFamily={"Philosopher"}
            color={text.grayColor}
            textAlign={"center"}
            fontSize={12}
            fontWeight={700}
          >
            {selectedItems.length} item selected
          </Typography>

          <Box
            sx={{
              mt: 2,
              display: "grid",
              gap: "8px",
              gridTemplateColumns: "repeat(auto-fill, minmax(66px, 1fr))",
              overflowY: "scroll",
              maxHeight: "360px",
              padding: "16px",
            }}
          >
            {renderItems()}
          </Box>
        </Box>

        <Box className={classes.fees}>
          <Box
            className={classes.feeInfo}
            sx={{
              gap: 1,
            }}
          >
            <Typography
              fontFamily={"Philosopher"}
              color={"white"}
              textAlign={"center"}
              fontSize={18}
              fontWeight={700}
            >
              Convert
            </Typography>

            <Box
              component={"img"}
              src={convertIcon}
              sx={{
                width: 32,
                height: 32,
                top: 42,
                filter: "blur(10px)",
                position: "absolute",
              }}
            ></Box>

            <Box
              component={"img"}
              src={convertIcon}
              sx={{
                width: 32,
                height: 32,
                zIndex: 1,
              }}
            ></Box>

            <Typography
              fontFamily={"Philosopher"}
              color={text.grayColor}
              textAlign={"center"}
              fontSize={14}
              fontWeight={700}
            >
              0% fee
            </Typography>
          </Box>
        </Box>

        <Box className={classes.exchangeCard}>
          <Typography
            fontFamily={"Philosopher"}
            color={"white"}
            textAlign={"center"}
            fontSize={24}
            fontWeight={700}
            mt={1}
          >
            Here's your offer!
          </Typography>

          <Typography
            fontFamily={"Philosopher"}
            color={text.grayColor}
            textAlign={"center"}
            fontSize={12}
            fontWeight={700}
            mt={1}
          >
            Take it or leave it
          </Typography>

          <Box
            sx={{
              mt: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 6,
            }}
          >
            <Box
              component="img"
              src={letterIcon}
              sx={{
                width: 64,
                height: 64,
              }}
            ></Box>

            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                backgroundColor: "#121415",
                padding: "24px 40px",
                borderRadius: "16px",
                border: "1px solid #040405",
                minWidth: "160px",
                boxShadow:
                  "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
              }}
            >
              <Box
                component="img"
                src={moneyIcon}
                sx={{
                  width: 32,
                  height: 32,
                }}
              ></Box>
              <Typography
                fontSize={20}
                color={"white"}
                fontFamily={"Philosopher"}
                sx={{
                  textAlign: "center",
                  filter: isAnimating ? "blur(5px)" : "",
                }}
              >
                $ {toUSDFormat(displayCashAmount)}
              </Typography>
            </Box>

            <Button
              className={classes.confirm}
              onClick={handleConfirmConversion}
              disabled={isAnimating}
            >
              Confirm conversion
            </Button>

            <Typography
              fontFamily={"Philosopher"}
              color={text.grayColor}
              textAlign={"center"}
              fontSize={12}
              fontWeight={700}
            >
              Volume bonus: {volumeBonus}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ConvertPanel;
