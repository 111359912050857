import { getProvider, getWeb3, waitForConfirmation } from ".";
import MafiaOGPremiumAbi from "../../abis/MafiaOGPremium.json";
import MafiaOGPremiumPLSAbi from "../../abis/MafiaOGPremiumPLS.json";
import config, { CHAIN } from "../../config/config";
import { ChainType } from "../../constants/enum/enum";
import { UserInfo } from "../../types/Contract/Premium/UserInfo";

export const getMafiaOGPremiumContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.ogPremiumAddress;
  const MafiaOGPremiumContract = new web3.eth.Contract(
    CHAIN === ChainType.BNB ? MafiaOGPremiumAbi : MafiaOGPremiumPLSAbi,
    contractAddress
  );
  return MafiaOGPremiumContract;
};

export const buyPremium = async (account: string) => {
  const MafiaOGPremiumContract = getMafiaOGPremiumContract(true);

  const data = await MafiaOGPremiumContract.methods
    .buyPremium()
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const claimKeys = async (account: string) => {
  const MafiaOGPremiumContract = getMafiaOGPremiumContract(true);

  const data = await MafiaOGPremiumContract.methods
    .claimKeys()
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const getUserInfo = async (account: string) => {
  const MafiaOGPremiumContract = getMafiaOGPremiumContract(false);

  const userInfo: UserInfo = await MafiaOGPremiumContract.methods
    .userInfos(account)
    .call();
  const lastClaimedAt: number = await MafiaOGPremiumContract.methods
    .lastClaimedAt(account)
    .call();

  return {
    userInfo: {
      startedAt: Number(userInfo.startedAt),
      expireAt: Number(userInfo.expireAt),
    },
    lastClaimedAt: Number(lastClaimedAt),
  };
};
