import { MarketListedItem } from "../types/MarketListedItem";

export const parseMarketListedItem = (item: any) => {
  return {
    listingId: item.id,
    categoryId: Math.floor(item.UserInventory.item_id / 10),
    typeId: item.UserInventory.item_id % 10,
    cityId: item.UserInventory.city,
    bidCount: item.bidCount,

    listingInfo: {
      itemId: item.itemId,
      listingType: item.listingType,
      startingPrice: item.startingPrice,
      currentPrice: item.currentPrice,
      timestamp: item.timestamp,
      expiresAt: item.expiresAt,
      token: item.token,
      seller: item.seller,
      buyer: item.buyer,
      status: item.status,
      bids: [],
    },
  } as MarketListedItem;
};

export const parseNewListingEvent = (event: any) => {
  return {
    listingId: Number(event.returnValues.listingId),
    listingToken: event.returnValues.listingToken,
    seller: event.returnValues.seller,
    itemId: Number(event.returnValues.itemId),
    listingType: Number(event.returnValues.listingType),
    startingPrice: Number(event.returnValues.startingPrice),
    timestamp: Number(event.returnValues.timestamp),
    expiresAt: Number(event.returnValues.expiresAt),
  };
};
