import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EditIcon from "@mui/icons-material/Edit";
import EditNoteIcon from "@mui/icons-material/EditNote";
import React, { useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import ColorPicker from "react-pick-color";

import { useAppSelector } from "../../app/hooks";
import { ALLEGIANCE_CREATE_CRATE_COST } from "../../constants/const";
import { storage } from "../../utils/firebase";

import allegianceIcon from "../../assets/imgs/family/allegiance.png";
import keyIcon from "../../assets/imgs/inventory/key.png";

import { CustomTypeBackground, CustomTypeText } from "../../theme";
import useStyles from "./index.styles";
import { toastError } from "../../utils/utils";
import { Errors } from "../../constants/errors";

export interface CreateAllegianceProps {
  crateBalance: number;
  handleCreateAllegiance: (
    name: string,
    logo: string | null,
    color: string
  ) => void;
}

const CreateAllegiance: React.FC<CreateAllegianceProps> = ({
  crateBalance,
  handleCreateAllegiance: createAllegiance,
}) => {
  const theme = useTheme<Theme>();
  const classes = useStyles();
  const { familyDetail } = useAppSelector((state) => state.family);

  const [name, setName] = useState("");
  const [color, setColor] = useState("#fff");
  const [logo, setLogo] = useState<string | null>(null);
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);

  const background = theme.palette.background as CustomTypeBackground;
  const text = theme.palette.text as CustomTypeText;

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | null = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const storageRef = ref(
            storage,
            `custom/${familyDetail.familyInfo?.name}-allegiance-${file.name}`
          );
          const uploadTask = uploadBytesResumable(storageRef, file);

          setIsUploadingLogo(true);
          uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => {},
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((logoUrl) => {
                console.log("File available at", logoUrl);
                setIsUploadingLogo(false);
                setLogo(logoUrl);
              });
            }
          );
        };
        img.onerror = () => {
          toastError(Errors.FAMILY.ALLEGIANCE.INVALID_IMAGE);
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCreateAllegiance = () => {
    if (name.length === 0) {
      toastError(Errors.FAMILY.ALLEGIANCE.INVALID_NAME);
      return;
    }

    createAllegiance(name, logo, color);
  };

  return (
    <Box className={classes.classes.contentBody}>
      <HeaderSection allegianceIcon={allegianceIcon} text={text} />

      <Box className={classes.classes.formFields}>
        <FormField
          label="Allegiance name"
          placeholder="Input your name..."
          icon={<EditNoteIcon />}
          classes={classes}
          text={text}
          name={name}
          setName={setName}
        />
        <UploadField
          label="Allegiance symbol"
          buttonText="Upload"
          icon={<CloudUploadIcon />}
          classes={classes}
          text={text}
          logo={logo}
          isUploadingLogo={isUploadingLogo}
          handleLogoUpload={handleLogoUpload}
        />
        <ColorField
          label="Allegiance color"
          buttonText="Select"
          icon={<EditIcon />}
          classes={classes}
          text={text}
          color={color}
          setColor={setColor}
        />
      </Box>

      <Box className={classes.classes.keyInfos}>
        <KeyInfo
          title="Available keys"
          count={crateBalance}
          keyIcon={keyIcon}
          background={background}
          text={text}
          theme={theme}
        />
        <KeyRequirement
          background={background}
          text={text}
          handleCreateAllegiance={handleCreateAllegiance}
        />
        <KeyAgreement
          keyIcon={keyIcon}
          background={background}
          text={text}
          theme={theme}
        />
      </Box>
    </Box>
  );
};

interface HeaderSectionProps {
  allegianceIcon: string;
  text: CustomTypeText;
}

const HeaderSection: React.FC<HeaderSectionProps> = ({
  allegianceIcon,
  text,
}) => (
  <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
    <Box component="img" src={allegianceIcon} sx={{ height: 32, mb: 1 }} />
    <Typography
      align="center"
      sx={{
        mb: 2,
        fontFamily: "Philosopher",
        fontSize: 24,
        fontWeight: 700,
      }}
    >
      Allegiance
    </Typography>
    <Typography
      align="center"
      sx={{
        mb: 2,
        fontFamily: "Philosopher",
        fontSize: 18,
        fontWeight: 700,
        color: text.secondary,
      }}
    >
      Create new allegiance!
    </Typography>
  </Box>
);

// FIXME: Refactor it to component
interface FormFieldProps {
  label: string;
  placeholder: string;
  icon: React.ReactNode;
  classes: ReturnType<typeof useStyles>;
  text: CustomTypeText;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  placeholder,
  icon,
  classes,
  text,
  name,
  setName,
}) => (
  <Box className={classes.classes.formInput}>
    <Typography
      sx={{
        fontFamily: "Philosopher",
        fontSize: 16,
        color: text.secondary,
      }}
    >
      {label}
    </Typography>
    <TextField
      variant="outlined"
      value={name}
      onChange={(e) => {
        setName(e.target.value);
      }}
      placeholder={placeholder}
      className={classes.classes.inputNameField}
      InputProps={{
        endAdornment: <IconButton>{icon}</IconButton>,
      }}
    />
  </Box>
);

interface UploadFieldProps {
  label: string;
  buttonText: string;
  icon: React.ReactNode;
  classes: ReturnType<typeof useStyles>;
  text: CustomTypeText;
  logo: string | null;
  isUploadingLogo: boolean;
  handleLogoUpload: React.ChangeEventHandler<HTMLInputElement>;
}

const UploadField: React.FC<UploadFieldProps> = ({
  label,
  buttonText,
  icon,
  classes,
  text,
  logo,
  isUploadingLogo,
  handleLogoUpload,
}) => (
  <Box className={classes.classes.formInput}>
    <Typography
      sx={{
        fontFamily: "Philosopher",
        fontSize: 16,
        color: text.secondary,
      }}
    >
      {label}
    </Typography>
    <Button
      variant="outlined"
      component="label"
      className={classes.classes.inputButton}
    >
      {buttonText}
      {isUploadingLogo ? (
        <CircularProgress size={24} />
      ) : logo ? (
        <Box
          component={"img"}
          src={logo}
          sx={{
            width: 24,
            height: 24,
            borderRadius: 1,
          }}
        ></Box>
      ) : (
        icon
      )}
      <input type="file" hidden onChange={handleLogoUpload} />
    </Button>
  </Box>
);

interface ColorFieldProps {
  label: string;
  buttonText: string;
  icon: React.ReactNode;
  classes: ReturnType<typeof useStyles>;
  text: CustomTypeText;
  color: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;
}

const ColorField: React.FC<ColorFieldProps> = ({
  label,
  buttonText,
  icon,
  classes,
  text,
  color,
  setColor,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.classes.formInput}>
      <Typography
        sx={{
          fontFamily: "Philosopher",
          fontSize: 16,
          color: text.secondary,
        }}
      >
        {label}
      </Typography>

      <Button
        variant="outlined"
        component="label"
        className={classes.classes.colorPickerButton}
        onClick={handleClickOpen}
      >
        {color ? color : buttonText}

        {color ? (
          <Box
            className={classes.classes.colorField}
            sx={{
              backgroundColor: color,
            }}
          ></Box>
        ) : (
          icon
        )}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          backgroundColor: "transparent",
        }}
      >
        <DialogContent
          sx={{
            width: 282,
            height: 290,
            padding: 0,
          }}
        >
          <ColorPicker
            color={color}
            onChange={(color) => setColor(color.hex)}
            className={classes.classes.colorPicker}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

interface KeyInfoProps {
  title: string;
  count: number;
  keyIcon: string;
  background: CustomTypeBackground;
  text: CustomTypeText;
  theme: Theme;
}

const KeyInfo: React.FC<KeyInfoProps> = ({
  title,
  count,
  keyIcon,
  background,
  text,
  theme,
}) => (
  <Box
    sx={{
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 1,
    }}
  >
    <Typography
      sx={{
        fontFamily: "Philosopher",
        fontSize: 14,
        color: text.primary,
      }}
    >
      {title}
    </Typography>
    <Box
      sx={{
        background: background.gray,
        border: "1px solid",
        borderColor: background.blackBorder,
        borderRadius: 4,
        padding: theme.spacing(1),
        boxShadow:
          "inset 0 -3px 6px 0px rgb(0 0 0 / 35%), inset 3px 0 6px 0px rgb(0 0 0 / 35%), inset 0 3px 6px 0px rgb(0 0 0 / 35%), inset -3px 0 6px 0px rgb(0 0 0 / 35%)",
      }}
    >
      <Box component="img" src={keyIcon} sx={{ height: 60, width: 60 }} />
    </Box>
    <Typography
      sx={{
        fontFamily: "Philosopher",
        fontSize: 14,
        color: text.primary,
      }}
    >
      {count} keys
    </Typography>
  </Box>
);

interface KeyRequirementProps {
  background: CustomTypeBackground;
  text: CustomTypeText;
  handleCreateAllegiance: () => void;
}

const KeyRequirement: React.FC<KeyRequirementProps> = ({
  background,
  text,
  handleCreateAllegiance,
}) => (
  <Box sx={{ textAlign: "center" }}>
    <Box display={"flex"} gap={1}>
      <Typography
        sx={{
          fontFamily: "Philosopher",
          fontSize: 14,
          color: text.primary,
        }}
      >
        Requires
      </Typography>
      <Typography
        sx={{
          fontFamily: "Philosopher",
          fontSize: 14,
          color: text.brightYellow,
        }}
      >
        Family +
      </Typography>
    </Box>
    <Button
      variant="contained"
      sx={{
        padding: "8px 24px",
        borderRadius: 2,
        mt: 4,
        fontFamily: "Philosopher",
        fontWeight: 700,
        textTransform: "none",
        fontSize: 14,
        background: background.greenGradient,
      }}
      onClick={handleCreateAllegiance}
    >
      Confirm
    </Button>
  </Box>
);

interface KeyAgreementProps {
  keyIcon: string;
  background: CustomTypeBackground;
  text: CustomTypeText;
  theme: Theme;
}

const KeyAgreement: React.FC<KeyAgreementProps> = ({
  keyIcon,
  background,
  text,
  theme,
}) => (
  <Box
    sx={{
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 1,
    }}
  >
    <Typography
      sx={{
        fontFamily: "Philosopher",
        fontSize: 14,
        color: text.primary,
      }}
    >
      Agree to spend
    </Typography>
    <Box
      sx={{
        background: background.gray,
        border: "1px solid",
        borderColor: background.blackBorder,
        borderRadius: 4,
        padding: theme.spacing(1),
        boxShadow:
          "inset 0 -3px 6px 0px rgb(0 0 0 / 35%), inset 3px 0 6px 0px rgb(0 0 0 / 35%), inset 0 3px 6px 0px rgb(0 0 0 / 35%), inset -3px 0 6px 0px rgb(0 0 0 / 35%)",
      }}
    >
      <Box component="img" src={keyIcon} sx={{ height: 60, width: 60 }} />
    </Box>
    <Typography
      sx={{
        fontFamily: "Philosopher",
        fontSize: 14,
        color: theme.palette.error.main,
      }}
    >
      -{ALLEGIANCE_CREATE_CRATE_COST} keys
    </Typography>
  </Box>
);

export default CreateAllegiance;
