import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  businessCard: {
    width: "100%",
    margin: "auto",
    height: "350px",
    backgroundColor: theme.palette.background.cardBg,
    borderRadius: 16,
    marginTop: 32,
    padding: "24px 32px",
    boxSizing: "border-box",
    border: "1px solid",
    boxShadow:
      "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",

    fontFamily: "Philosopher",
    fontWeight: 900,

    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      padding: 16,
      height: "auto",
    },
  },

  businessCardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  cardTitle: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    color: theme.palette.text.primary,

    "& img": {
      height: 32,
    },
  },

  description: {
    color: "#646464",

    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },

  comingSoon: {
    display: "flex",
    gap: "8px",
    color: "#646464",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "32px",
    },
  },

  divider: {
    width: "100%",
    borderColor: "white",
    fontSize: "20px",
    "&::after, &::before": {
      borderColor: "#646464",
    },
  },

  businessEmpty: {
    color: theme.palette.text.secondary,
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",

    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
    },
  },

  businessContent: {
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  detailsBody: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    backgroundColor: "#272a2b",
    padding: "32px 8px",
    boxSizing: "border-box",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "32px",
    },
  },

  detail: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    [theme.breakpoints.down("sm")]: {
      gap: "8px",
    },
  },

  detailHeader: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "3fr 2fr 2fr 2fr",
    padding: "16px 16px 8px 16px",
    boxSizing: "border-box",
    fontSize: 16,

    [theme.breakpoints.down("sm")]: {
      padding: "16px 8px",
      fontSize: 12,
      gridTemplateColumns: "4fr 2fr 2fr 2fr",
    },
  },

  allegianceListItem: {
    display: "grid",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
    gridTemplateColumns: "3fr 2fr 2fr 2fr",
    // justifyItems: "center",
    width: "100%",
    padding: "8px 16px 8px 16px",
    boxSizing: "border-box",

    [theme.breakpoints.down("sm")]: {
      padding: "8px 8px",
      fontSize: 12,
      gridTemplateColumns: "4fr 2fr 2fr 2fr",
    },
  },
  allegianceListFamily: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      "& p": {
        fontSize: 14,
      },
    },
  },
  allegianceIcon: {
    marginRight: theme.spacing(2),
    width: 18,

    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
    },
  },
  link: {
    textDecoration: "none",
  },
}));

export default styles;
