import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: any) => ({
  formControl: {
    minWidth: 60,
    margin: 0,
    backgroundColor: "#282828",
    borderRadius: "8px",

    "& .MuiInputBase-root": {
      height: "24.5px",
      fontSize: "13px",
      color: theme.palette.text.primary,
      // minWidth: "150px",
      "&.Mui-disabled": {
        "& svg": {
          color: "rgba(0, 0, 0, 0.38)!important",
        },
      },

      "& .MuiSelect-select": {
        textAlign: "center",

        "& img": {
          display: "none",
        },
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },

      "& .MuiSvgIcon-root": {
        color: theme.palette.text.primary,
      },
    },
    "& .MuiMenu-paper": {
      padding: "0",
      display: "block",
      minWidth: "auto",
    },
  },
  detail: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
  },
  menuItem: {
    backgroundColor: "#282828",
  },
  paper: {
    "& .MuiDialogContentText-root": {
      color: theme.palette.text.primary,
    },
  },

  placeholder: {
    position: "absolute",
    lineHeight: "24.5px",
    paddingLeft: 16,
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
