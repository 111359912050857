import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog } from "@mui/material";
import React from "react";

import CreateAllegiance from "./CreateAllegiance";
import JoinAllegiance from "./JoinAllegiance";
import ManageAllegiance from "./ManageAllegiance";

import useWallet from "../../hook/useWallet";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import config from "../../config/config";
import { ALLEGIANCE_CREATE_CRATE_COST } from "../../constants/const";
import { Errors } from "../../constants/errors";
import { dispatchTxAction } from "../../helper/dispatchTxAction";

import {
  createAllegianceAction,
  exitAllegianceAction,
  inviteAllegianceAction,
  kickAllegianceMemberAction,
  registerAllegianceAction,
  requestToJoinAllegianceAction,
  signData,
} from "../../reducers/family.slice";
import { approveAllCrate } from "../../reducers/profile.slice";

import { AllegiancePopupContent } from "../../constants/enum/enum";

import { isInAllegiance } from "../../helper/contractFunctions/FamilyAllegiance";
import { isCrateApprovedForAll } from "../../helper/contractFunctions/OgCrate";

import useStyles from "./index.styles";
import { toastError, toastInfo, toastSuccess } from "../../utils/utils";
import { Messages } from "../../constants/messages";

interface FamilyManageAllegiancePopupProps {
  currentContent: AllegiancePopupContent;
  setCurrentContent: React.Dispatch<
    React.SetStateAction<AllegiancePopupContent>
  >;
  openManageModal: boolean;
  setOpenManageModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const FamilyManageAllegiancePopup: React.FC<
  FamilyManageAllegiancePopupProps
> = ({
  currentContent,
  setCurrentContent,
  openManageModal,
  setOpenManageModal,
}) => {
  const { classes } = useStyles();
  const { account } = useWallet();
  const dispatch = useAppDispatch();

  const { myProfile, crateBalance } = useAppSelector((state) => state.profile);
  const familyDetail = useAppSelector((state) => state.family.familyDetail);

  const handleCreateAllegiance = async (
    name: string,
    logo: string | null,
    color: string
  ) => {
    const familyId = myProfile.userFamily?.id;
    if (!familyId) return;
    if (!account) return;

    if (crateBalance < ALLEGIANCE_CREATE_CRATE_COST) {
      toastInfo(Messages.FAMILY.ALLEGIANCE.INSUFFICIENT_NFT);
      return;
    }

    if (!myProfile.userFamily?.premium) {
      toastInfo(Messages.FAMILY.ALLEGIANCE.BE_PREMIUM);
      return;
    }

    const registerAllegiance = () => {
      const signMsg = {
        address: account,
        name,
        symbol: logo ? logo : "",
        color,
      };

      dispatch(signData({ signMsg }))
        .unwrap()
        .then((signature) => {
          toastSuccess(Messages.FAMILY.ALLEGIANCE.REGISTER_SUCCESS);
          dispatch(registerAllegianceAction({ signature, signMsg }));
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const createAllegiance = () => {
      dispatchTxAction(
        dispatch,
        createAllegianceAction({ familyId, account }),
        registerAllegiance
      );
    };

    const approveOGCrate = () => {
      dispatchTxAction(
        dispatch,
        approveAllCrate({ spender: config.familyAllegianceAddress, account }),
        createAllegiance
      );
    };

    const isApproved = await isCrateApprovedForAll(
      account,
      config.familyAllegianceAddress
    );

    if (isApproved) {
      const isCreated = await isInAllegiance(myProfile.userFamily?.id || 0);
      if (isCreated) {
        registerAllegiance();
      } else {
        createAllegiance();
      }
    } else {
      approveOGCrate();
    }
  };

  const handleInviteAllegiance = async (
    familyId: number,
    callback: () => void
  ) => {
    const allegianceId = familyDetail.allegiance?.allegianceId;
    if (!allegianceId) {
      toastError(Errors.FAMILY.ALLEGIANCE.INVITE_INVALID_FAMILY_ID);
      return;
    }

    dispatchTxAction(
      dispatch,
      inviteAllegianceAction({ allegianceId, familyId, account }),
      callback
    );
  };

  const handleRequestAllegiance = async (
    allegianceId: number,
    callback: () => void
  ) => {
    dispatchTxAction(
      dispatch,
      requestToJoinAllegianceAction({ allegianceId, account }),
      callback
    );
  };

  const handleKickMember = async (
    allegianceId: number,
    familyId: number,
    callback: () => void
  ) => {
    dispatchTxAction(
      dispatch,
      kickAllegianceMemberAction({ allegianceId, familyId, account }),
      callback
    );
  };

  const handleExitAllegiance = async (
    allegianceId: number,
    familyId: number,
    callback: () => void
  ) => {
    dispatchTxAction(
      dispatch,
      exitAllegianceAction({ allegianceId, familyId, account }),
      callback
    );
  };

  const renderContent = () => {
    switch (currentContent) {
      case AllegiancePopupContent.Join:
        return (
          <JoinAllegiance
            setCurrentContent={setCurrentContent}
            handleRequestAllegiance={handleRequestAllegiance}
          />
        );
      case AllegiancePopupContent.Create:
        return (
          <CreateAllegiance
            crateBalance={crateBalance}
            handleCreateAllegiance={handleCreateAllegiance}
          />
        );
      case AllegiancePopupContent.Manage:
        return (
          <ManageAllegiance
            setOpenManageModal={setOpenManageModal}
            handleExitAllegiance={handleExitAllegiance}
            handleKickMember={handleKickMember}
            handleInviteAllegiance={handleInviteAllegiance}
          />
        );
    }
  };

  return (
    <Dialog
      open={openManageModal}
      onClose={() => setOpenManageModal(false)}
      className={classes.body}
    >
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={() => setOpenManageModal(false)}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        {renderContent()}
      </Box>
    </Dialog>
  );
};

export default FamilyManageAllegiancePopup;
