import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
    },

    "& .MuiDialog-paper": {
      overflowY: "initial",
    },
  },

  modalContent: {
    position: "relative",
    overflowY: "scroll",
  },

  closeIconBody: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 25,
    top: 25,
    zIndex: 1,

    "&>button": {
      padding: 0,
      minWidth: "auto",
      "&>svg": {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        transition: ".3s",
        fontSize: "1.5rem",
        "&:hover": {
          color: theme.palette.text.primary,
          transition: ".3s",
        },
      },
    },
  },

  contentBody: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: "48px 24px 28px 24px",
    minWidth: 320,
    backgroundSize: "auto 100%",

    [theme.breakpoints.down("sm")]: {
      padding: "48px 16px 28px 16px",
    },
  },

  itemInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 24,
  },

  itemName: {
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 24,
  },

  itemDescription: {
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 18,
  },

  itemIcon: {
    background: "#0e0e0e",
    border: "1px solid",
    borderColor: theme.palette.background.blackBorder,
    borderRadius: 8,
    display: "flex",
    clipPath: "content-box",
    boxShadow:
      "inset 0 -3px 6px 0px rgb(0 0 0 / 35%), inset 3px 0 6px 0px rgb(0 0 0 / 35%), inset 0 3px 6px 0px rgb(0 0 0 / 35%), inset -3px 0 6px 0px rgb(0 0 0 / 35%)",

    "& img": {
      width: 100,
      height: 100,
      padding: 1,
    },

    [theme.breakpoints.down("sm")]: {
      "& img": {
        width: 80,
        height: 80,
      },
    },
  },

  itemActions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  itemActionButton: {
    display: "flex",
    alignItems: "normal",
    gap: "8px",
    padding: "8px 32px",
    textTransform: "none",
    borderRadius: "8px",
    fontFamily: "Philosopher",
    fontWeight: 900,
    marginTop: 8,
    minWidth: 200,
    color: theme.palette.text.primary,
  },

  sellItemButton: {
    background: theme.palette.background.yellowGradient,
  },

  viewItemButton: {
    background: theme.palette.background.blueGradient,
  },

  wrapItemButton: {
    background: theme.palette.background.redGradient,
  },

  useItemButton: {
    background: theme.palette.background.orangeGradient,

    "&:disabled": {
      background: theme.palette.background.grayGradient,
      color: theme.palette.text.secondary,
      opacity: 1,
    },
  },

  confirmSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 32,

    gap: 16,
  },

  confirmButton: {
    backgroundColor: theme.palette.background.defaultButton,
  },

  confirmDescription: {
    color: theme.palette.text.secondary,
  },

  inputBox: {
    borderRadius: "12px",
    boxSizing: "border-box",
    width: "100%",
    padding: "8px 32px",
    backgroundColor: theme.palette.background.blackBorder,
    color: theme.palette.text.primary,
    outline: "none",

    "& input": {
      outline: "none!important",
      border: "none",
      color: theme.palette.text.primary,
      fontFamily: "Philosopher",
      fontSize: "18px",
      fontWeight: 900,

      "&::placeholder": {
        color: theme.palette.text.secondary,
      },
    },

    "& .MuiTextField-root::after": {
      content: "initial",
    },
  },

  smallInputBox: {
    padding: "8px 12px",
  },
}));

export default styles;
