import { Box, Button } from "@mui/material";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";

import InventoryItemActionPopup from "../../components/InventoryItemActionPopup";
import ProfileCard from "../../components/ProfileCard";
import ProfileClaimKeyPopup from "../../components/ProfileClaimKeyPopup";
import ProfileDescription from "../../components/ProfileDescription";
import ProfileInventory from "../../components/ProfileInventory";
import ProfileSetting from "../../components/ProfileSetting";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  claimKeysAction,
  getCrateBalance,
  getInventoryItem,
  getOgNFTBalance,
  getUserPremiumInfo,
} from "../../reducers/profile.slice";
import { ItemInfo } from "../../types/ItemInfo";

import { SERVER_URL } from "../../config/config";
import { PREMIUM_KEY_CLAIM_CYCLE } from "../../constants/const";
import { Messages } from "../../constants/messages";
import { dispatchTxAction } from "../../helper/dispatchTxAction";
import useCurrentTime from "../../hook/useCurrentTime";
import useWallet from "../../hook/useWallet";
import { toastSuccess } from "../../utils/utils";

import secondReelSpinningSfx from "../../assets/sfxs/2nd_animation_spinning.mp3"; // Correct path

import useStyles from "./index.styles";

const Profile = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { account } = useWallet();
  const currentTime = useCurrentTime();

  const { profile, myProfile, crateBalance, ogNFTBalance, premiumInfo } =
    useAppSelector((state) => state.profile);

  const [openCrateOpeningPopup, setOpenCrateOpeningPopup] = useState(false);
  const [transferItemInfo, setTransferItemInfo] = useState<ItemInfo>({
    categoryId: -2,
    cityId: -1,
    typeId: 0,
    itemId: 0,
  });

  const [openProfileClaimKeyPopup, setOpenProfileClaimKeyPopup] =
    useState(false);

  const [claimKeyTx, setClaimKeyTx] = useState("");
  const [claimKeyCount, setClaimKeyCount] = useState(0);
  const [remainClaimCount, setRemainClaimCount] = useState(0);

  const [playSecondReelSpinning, { stop: stopSecondReelSpin }] = useSound(
    secondReelSpinningSfx,
    {
      loop: false,
      volume: 0.5,
    }
  );

  const handleUpgradeClick = () => {
    if (ogNFTBalance > 0) {
      setOpenCrateOpeningPopup(true);
    } else {
      navigate(`/market`);
    }
  };

  const handleClaimKey = () => {
    if (!profile.address) return;

    dispatchTxAction(
      dispatch,
      claimKeysAction({ account: profile.address }),
      () => {
        toastSuccess(Messages.GLOBAL.PROFILE.CLAIMED_KEY_SUCCESS);

        dispatch(getCrateBalance({ account: profile.address || "" }));
        dispatch(getUserPremiumInfo({ account: profile.address || "" }));
      }
    );
  };

  const onEndAnimation = () => {
    stopSecondReelSpin();
    if (myProfile.address)
      dispatch(getCrateBalance({ account: myProfile.address }));
  };

  const claimableKeys = useMemo(() => {
    if (
      premiumInfo.userInfo.startedAt === 0 ||
      premiumInfo.userInfo.expireAt < currentTime
    )
      return 0;

    let lastClaimedAt = premiumInfo.lastClaimedAt;
    let claimableUntil = currentTime;
    if (
      premiumInfo.userInfo.startedAt - PREMIUM_KEY_CLAIM_CYCLE >
      lastClaimedAt
    ) {
      lastClaimedAt = premiumInfo.userInfo.startedAt - PREMIUM_KEY_CLAIM_CYCLE;
    }

    if (
      claimableUntil >
      premiumInfo.userInfo.expireAt - PREMIUM_KEY_CLAIM_CYCLE
    ) {
      claimableUntil = premiumInfo.userInfo.expireAt - PREMIUM_KEY_CLAIM_CYCLE;
    }

    const claimTimes = Math.floor(
      (claimableUntil - lastClaimedAt) / PREMIUM_KEY_CLAIM_CYCLE
    );

    const keyAmount = claimTimes * 2;
    setRemainClaimCount(claimTimes);
    return keyAmount;
  }, [currentTime, premiumInfo]);

  // SSE
  useEffect(() => {
    const eventSource = new EventSource(`${SERVER_URL}/event/keyclaim`);

    eventSource.onmessage = (e) => {
      if (e.data.includes("welcome")) {
      } else if (e.data.includes("user")) {
        const res = JSON.parse(e.data);
        const { user, amount, txHash } = res;

        if (user.toLowerCase() === myProfile.address?.toLowerCase()) {
          playSecondReelSpinning();
          setClaimKeyCount(Number(amount));
          setClaimKeyTx(txHash);
          setRemainClaimCount((remainClaimCount) => remainClaimCount - 1);
          setOpenProfileClaimKeyPopup(true);
          dispatch(getUserPremiumInfo({ account: myProfile.address || "" }));
        }
      }
    };
    return () => {
      eventSource.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, myProfile.address, profile.address]);

  useEffect(() => {
    if (profile.id) dispatch(getInventoryItem({ userId: profile.id }));
  }, [profile.id, dispatch, account]);

  useEffect(() => {
    if (profile.address) {
      dispatch(getCrateBalance({ account: profile.address }));
      dispatch(getOgNFTBalance({ account: profile.address }));
      dispatch(getUserPremiumInfo({ account: profile.address }));
    }
  }, [profile.address, dispatch]);

  const premiumEnabled = useMemo(() => {
    return (
      profile.premium === 1 &&
      premiumInfo.userInfo.startedAt + PREMIUM_KEY_CLAIM_CYCLE * 3 > currentTime
    );
  }, [currentTime, premiumInfo.userInfo.startedAt, profile.premium]);

  return (
    <Box>
      <ProfileCard isPrivateBullet={profile.name !== myProfile.name} />

      {!profile || !myProfile || profile.name !== myProfile.name ? (
        <Box className={classes.row}>
          {profile.description && <ProfileDescription profile={profile} />}
        </Box>
      ) : (
        <Box className={clsx(classes.row, classes.third)}>
          <ProfileDescription profile={profile} editable />

          <ProfileSetting
            profile={profile}
            premiumInfo={premiumInfo}
            claimableKeys={claimableKeys}
            handleClaimKey={handleClaimKey}
          />

          {!premiumEnabled && (
            <Button
              className={classes.upgradeButton}
              onClick={handleUpgradeClick}
            >
              Upgrade to Player+
            </Button>
          )}
        </Box>
      )}

      <Box className={classes.row}>
        <ProfileInventory
          inventoryType="Items"
          small
          profileView
          isPrivate={
            (profile.name !== myProfile.name &&
              profile.inventoryVisibility === 2) ||
            (profile.family !== myProfile.family &&
              profile.inventoryVisibility === 1)
          }
        />
        <ProfileInventory
          inventoryType="NFTs"
          crateBalance={crateBalance}
          ogNFTBalance={ogNFTBalance}
          profileView
          small
        />
      </Box>

      <InventoryItemActionPopup
        itemInfo={transferItemInfo}
        openItemTransferPopup={openCrateOpeningPopup}
        setOpenCrateOpeningPopup={setOpenCrateOpeningPopup}
      />

      <ProfileClaimKeyPopup
        openProfileClaimKeyPopup={openProfileClaimKeyPopup}
        setOpenProfileClaimKeyPopup={setOpenProfileClaimKeyPopup}
        handleClaimKey={() => {}}
        onEndAnimation={onEndAnimation}
        txHash={claimKeyTx}
        remainClaimCount={remainClaimCount}
        claimKeyCount={claimKeyCount}
      />
    </Box>
  );
};

export default Profile;
