import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
    },

    "& .MuiDialog-paper": {
      overflowY: "initial",
    },
  },

  modalContent: {
    position: "relative",
    overflowY: "scroll",
  },

  closeIconBody: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 25,
    top: 25,
    zIndex: 1,

    "&>button": {
      padding: 0,
      minWidth: "auto",
      "&>svg": {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        transition: ".3s",
        fontSize: "1.5rem",
        "&:hover": {
          color: theme.palette.text.primary,
          transition: ".3s",
        },
      },
    },
  },

  contentBody: {
    display: "flex",
    flexDirection: "column",
    gap: 32,
    padding: "48px 60px 48px 60px",
    minWidth: 320,
    backgroundSize: "auto 100%",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "48px 16px 48px 16px",
    },

    "& a": {
      color: theme.palette.text.linkColor,
      textDecoration: "none",
    },
  },

  sirenIcon: {
    width: 48,
    height: 48,
  },

  title: {
    fontSize: 32,
  },

  description: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    maxWidth: 400,
    textAlign: "center",
  },

  message: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
    border: "6px solid",
    borderColor: "#181818",
    padding: "16px 24px",
    borderRadius: 16,
    fontSize: 18,

    "& img": {
      width: 24,
      height: 24,
    },
  },

  countdown: {
    color: theme.palette.text.secondary,
  },
}));

export default styles;
