import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    padding: "0 16px",

    maxWidth: 1920,
    margin: "auto",
    minHeight: 663,
  },

  row: {
    display: "grid",
    maxWidth: 1440,
    margin: "auto",
    marginTop: 32,
    gap: 16,
    gridTemplateColumns: "1fr 1fr",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  third: {
    gridTemplateColumns: "2fr 1fr",
  },
}));

export default styles;
