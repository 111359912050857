import React from "react";
import { Box } from "@mui/material";
import useStyles from "./index.styles";

interface TokenImageProps {
  token: string;
  classNames?: string;
  sx?: object;
}

const TokenImage = (props: TokenImageProps) => {
  const { classes } = useStyles();
  return (
    <Box
      sx={{
        ...props.sx,
      }}
      component="img"
      src={`/assets/tokens/${props.token?.toLowerCase()}.png`}
      alt={props.token}
      className={props.classNames === "middle" ? classes.middle : ""}
    />
  );
};

export default TokenImage;
