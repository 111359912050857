import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog } from "@mui/material";

import shopIcon from "../../assets/imgs/marketplace/shop.png";
import clockIcon from "../../assets/imgs/marketplace/clock.png";

import useStyles from "./index.styles";
import NativeIcon from "../NativeIcon";
import Logo from "../Logo";
import config from "../../config/config";

interface MarketPlaceListPopupProps {
  openMarketRulesPopup: boolean;
  setOpenMarketRulesPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const MarketRulesPopup: React.FC<MarketPlaceListPopupProps> = ({
  openMarketRulesPopup,
  setOpenMarketRulesPopup,
}) => {
  const { classes } = useStyles();

  const handleClose = () => {
    setOpenMarketRulesPopup(false);
  };

  return (
    <Dialog
      open={openMarketRulesPopup}
      onClose={handleClose}
      className={classes.body}
    >
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        <Box className={classes.contentBody}>
          <Box className={classes.title}>
            <Box component="img" src={shopIcon} sx={{ width: 48 }}></Box>
            <Box>Market Place Rules</Box>
          </Box>

          <Box className={classes.description}>
            <Box>Two types of listings. Fixed price & Auctions</Box>
            <Box>Define your starting price on auctions. </Box>
            <Box>Set your ask price on fixed price listings</Box>
            <Box>All auction bids increase time left by 1 minute</Box>
          </Box>

          <Box className={classes.durationInfoSection}>
            <Box>Available listing durations:</Box>

            <Box className={classes.durationOptionList}>
              <Box className={classes.durationOption}>
                <Box component="img" src={clockIcon} width={16}></Box>
                <Box>30 mins</Box>
              </Box>

              <Box className={classes.durationOption}>
                <Box component="img" src={clockIcon} width={16}></Box>
                <Box>3 hours</Box>
              </Box>

              <Box className={classes.durationOption}>
                <Box component="img" src={clockIcon} width={16}></Box>
                <Box>12 hours</Box>
              </Box>

              <Box className={classes.durationOption}>
                <Box component="img" src={clockIcon} width={16}></Box>
                <Box>72 hours</Box>
              </Box>
            </Box>
          </Box>

          <Box className={classes.payoutInfoSection}>
            <Box>Payout options and fees</Box>

            <Box className={classes.payoutOptionList}>
              <Box className={classes.payoutOption}>
                <NativeIcon classNames={classes.switchOptionIconSymbol} />
                <Box className={classes.symbolName}>{config.chainSymbol}</Box>
                <Box className={classes.feeInfo}>20% fee</Box>
              </Box>

              <Box className={classes.payoutOption}>
                <Logo classNames={classes.switchOptionIconSymbol} />
                <Box className={classes.symbolName}>MAFIA</Box>
                <Box className={classes.feeInfo}>0% fee</Box>
              </Box>
            </Box>

            <Box className={classes.feeDescription}>
              100% of the marketplace fees goes to the vault
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MarketRulesPopup;
