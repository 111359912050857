import { ItemCategoryInfoList } from "../../constants/const";

interface InventoryItemNameProps {
  categoryId: number;
  typeId: number;
}

const InventoryItemName = ({ categoryId, typeId }: InventoryItemNameProps) => {
  const category = ItemCategoryInfoList[Math.floor(categoryId)];
  const itemName =
    typeof category.values[typeId] === "string"
      ? category.values[typeId]
      : `${category.name} ${category.values[typeId]}`;

  return <>{itemName}</>;
};

export default InventoryItemName;
