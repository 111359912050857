import { getProvider, getWeb3, waitForConfirmation } from ".";

import FamilyAllegianceAbi from "../../abis/FamilyAllegiance.json";
import FamilyAllegiancePLSAbi from "../../abis/FamilyAllegiancePLS.json";

import config, { CHAIN } from "../../config/config";
import { ChainType } from "../../constants/enum/enum";

export const getFamilyBankContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.familyAllegianceAddress;
  const FamilyBankContract = new web3.eth.Contract(
    CHAIN === ChainType.BNB ? FamilyAllegianceAbi : FamilyAllegiancePLSAbi,
    contractAddress
  );
  return FamilyBankContract;
};

export const createAllegiance = async (familyId: number, account: string) => {
  const FamilyBankContract = getFamilyBankContract(true);
  const data = await FamilyBankContract.methods
    .createAllegiance(familyId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const inviteAllegianceFamily = async (
  allegianceId: number,
  familyId: number,
  account: string
) => {
  const FamilyBankContract = getFamilyBankContract(true);
  const data = await FamilyBankContract.methods
    .inviteFamily(allegianceId, familyId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const acceptAllegianceInvitation = async (
  invitationId: number,
  account: string
) => {
  const FamilyBankContract = getFamilyBankContract(true);
  const data = await FamilyBankContract.methods
    .acceptInvitation(invitationId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const requestToJoinAllegiance = async (
  allegianceId: number,
  account: string
) => {
  const FamilyBankContract = getFamilyBankContract(true);
  const data = await FamilyBankContract.methods
    .requestToJoinAllegiance(allegianceId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const acceptRequest = async (requestId: number, account: string) => {
  const FamilyBankContract = getFamilyBankContract(true);
  const data = await FamilyBankContract.methods
    .acceptRequest(requestId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const rejectRequest = async (requestId: number, account: string) => {
  const FamilyBankContract = getFamilyBankContract(true);
  const data = await FamilyBankContract.methods
    .rejectRequest(requestId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const rejectAllegianceInvitation = async (
  invitationId: number,
  account: string
) => {
  const FamilyBankContract = getFamilyBankContract(true);
  const data = await FamilyBankContract.methods
    .rejectInvitation(invitationId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const kickAllegianceMember = async (
  allegianceId: number,
  familyId: number,
  account: string
) => {
  const FamilyBankContract = getFamilyBankContract(true);
  const data = await FamilyBankContract.methods
    .kickMember(allegianceId, familyId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const exitAllegiance = async (
  allegianceId: number,
  familyId: number,
  account: string
) => {
  const FamilyBankContract = getFamilyBankContract(true);
  const data = await FamilyBankContract.methods
    .exitAllegiance(allegianceId, familyId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

//-------------------------------------------------------------------------------

export const isFamilyDon = async (user: string, familyId: number) => {
  const FamilyBankContract = getFamilyBankContract();
  const data: boolean = await FamilyBankContract.methods
    .isFamilyDon(user, familyId)
    .call();

  return data;
};

export const isFamilyDonByUser = async (user: string) => {
  const FamilyBankContract = getFamilyBankContract();
  const data: boolean = await FamilyBankContract.methods
    .isFamilyDon(user)
    .call();

  return data;
};

export const isAllegianceMember = async (
  allegianceId: number,
  familyId: number
) => {
  const FamilyBankContract = getFamilyBankContract();
  const data: boolean = await FamilyBankContract.methods
    .isAllegianceMember(allegianceId, familyId)
    .call();

  return data;
};

export const isAllegianceCreator = async (
  allegianceId: number,
  familyId: number
) => {
  const FamilyBankContract = getFamilyBankContract();
  const data: boolean = await FamilyBankContract.methods
    .isAllegianceCreator(allegianceId, familyId)
    .call();

  return data;
};

export const isInAllegiance = async (familyId: number) => {
  const FamilyBankContract = getFamilyBankContract();
  const data: boolean = await FamilyBankContract.methods
    .isInAllegiance(familyId)
    .call();

  return data;
};
