import {
  approveToken,
  getProvider,
  getTokenAllowance,
  getWeb3,
  waitForConfirmation,
} from ".";
import MafiaProfileTicketAbi from "../../abis/MafiaProfileTicket.json";
import config from "../../config/config";
import { PROFILE_PRICE_IN_MAFIA } from "../../constants/const";

export const getProfileTicketContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.profileTicketAddress;
  const ProfileTicketContract = new web3.eth.Contract(
    MafiaProfileTicketAbi,
    contractAddress
  );
  return ProfileTicketContract;
};

export const purchaseTicket = async (account: string) => {
  const ProfileTicketContract = getProfileTicketContract(true);

  const data = await ProfileTicketContract.methods
    .purchaseTicket()
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);
};

export const approveTicketContract = async (account: string) => {
  const profilePrice = PROFILE_PRICE_IN_MAFIA;
  const tokenAddress = config.mafiaAddress;
  const contractAddress = config.profileTicketAddress;

  const allowance = await getTokenAllowance(
    tokenAddress,
    account,
    contractAddress
  );

  if (Number(allowance) < profilePrice) {
    await approveToken(tokenAddress, profilePrice, contractAddress, account);
  }
};

export const userTicketId = async (account: string) => {
  const ProfileTicketContract = getProfileTicketContract(false);

  const ticketId = await ProfileTicketContract.methods
    .userTicketId(account)
    .call();

  return ticketId;
};

export const boughtTicket = async (account: string) => {
  const ProfileTicketContract = getProfileTicketContract(false);

  const bought: boolean = await ProfileTicketContract.methods
    .boughtTicket(account)
    .call();

  return bought;
};
