import { Box } from "@mui/material";
import { Link } from "react-router-dom";

import { useAppSelector } from "../../app/hooks";
import { City, ItemCategoryInfoList } from "../../constants/const";
import { toUSDFormat } from "../../utils/utils";

import businessIcon from "../../assets/imgs/family/business.png";

import useStyles from "./index.styles";

const FamilyDetailBusiness = () => {
  const { classes } = useStyles();

  const { familyDetail } = useAppSelector((state) => state.family);

  return (
    <Box className={classes.businessCard}>
      <Box className={classes.businessCardHeader}>
        <Box className={classes.cardTitle}>
          <Box component="img" src={businessIcon}></Box>
          <Box>Business objects</Box>
        </Box>
        <Box className={classes.ownedTitle}>
          {familyDetail.businessObjects?.length} Owned
        </Box>
      </Box>

      {familyDetail.businessObjects?.length === 0 ? (
        <Box className={classes.businessEmpty}>
          <Box className={classes.detailsBody}>
            Family Controls no business objects
          </Box>
        </Box>
      ) : (
        <Box className={classes.businessContent}>
          <Box className={classes.detailHeader}>
            <Box>Type</Box>
            <Box>Owner</Box>
            <Box>City</Box>
            <Box>Protection</Box>
            <Box>Slot</Box>
            <Box>Effectiveness</Box>
            <Box>Profit total</Box>
            <Box>Worth</Box>
          </Box>

          <Box className={classes.detailList}>
            {familyDetail.businessObjects
              ?.slice()
              .sort(
                (a, b) =>
                  ItemCategoryInfoList[b.itemDetail.categoryId].inventoryWorth[
                    b.itemDetail.typeId
                  ] -
                  ItemCategoryInfoList[a.itemDetail.categoryId].inventoryWorth[
                    a.itemDetail.typeId
                  ]
              )
              .map((business, index) => {
                const category =
                  ItemCategoryInfoList[business.itemDetail.categoryId];

                const icon =
                  category.icons.length === 0
                    ? category.icon
                    : category.icons[business.itemDetail.typeId];

                return (
                  <Box className={classes.detailListItem} key={index}>
                    <Box className={classes.detailName}>
                      <Box
                        component="img"
                        className={classes.detailIcon}
                        src={`/assets/imgs/inventory/${icon}`}
                      ></Box>
                      {category.values[business.itemDetail.typeId]}
                    </Box>
                    <Box className={classes.detailOwner}>
                      <Link to={`/profile/${business.owner}`}>
                        {business.owner}
                      </Link>
                    </Box>
                    <Box className={classes.detailCity}>
                      {City[business.city]}
                    </Box>
                    <Box>N/A</Box>
                    <Box>N/A</Box>
                    <Box>N/A</Box>
                    <Box className={classes.detailCity}>N/A</Box>
                    <Box className={classes.detailWorth}>
                      {toUSDFormat(
                        category.inventoryWorth[business.itemDetail.typeId]
                      )}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FamilyDetailBusiness;
