import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
    },

    "& .MuiDialog-paper": {
      overflowY: "initial",
    },
  },

  modalContent: {
    position: "relative",
  },

  contentBody: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: 48,
    minWidth: 320,
    // background: "url(/assets/imgs/inventory/1.webp) no-repeat",
    backgroundSize: "auto 100%",
    // mixBlendMode: "lighten",

    [theme.breakpoints.down("sm")]: {
      padding: "48px 0px",
    },
  },

  closeIconBody: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 25,
    top: 25,
    zIndex: 1,

    "&>button": {
      padding: 0,
      minWidth: "auto",
      "&>svg": {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        transition: ".3s",
        fontSize: "1.5rem",
        "&:hover": {
          color: theme.palette.text.primary,
          transition: ".3s",
        },
      },
    },
  },

  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    left: "50%",
    top: 10,
    transform: "translate(-50%, -50%)",

    "& img": {
      width: 150,
    },

    "& p": {
      width: "100%",
      color: theme.palette.text.secondary,
    },
  },

  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    marginTop: 48,
  },

  prizeItem: {
    padding: 8,
    width: "70%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& img": {
      marginLeft: 8,
      width: 24,
      height: 24,
      borderRadius: 4,
    },

    [theme.breakpoints.down("sm")]: {
      boxSizing: "border-box",
    },
  },

  arrow: {
    width: 20,
    height: 20,
  },

  selected: {
    backgroundColor: "#4c515490",
    borderRadius: 4,
  },

  winContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    gap: 16,
    opacity: 0,
    left: 0,
  },

  grateText: {
    marginTop: 40,
    color: theme.palette.text.brightGray,
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 20,
  },

  prizeName: {
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 32,
  },
  prizeDetail: {
    color: theme.palette.text.brightGray,
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 20,
  },

  prizeIcon: {
    height: 120,
    borderRadius: 8,
  },

  description: {
    color: theme.palette.text.brightGray,
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 18,
  },

  viewTxLink: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
  },

  openCreateButton: {
    background: theme.palette.background.createButtonBg,

    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    textTransform: "none",

    borderRadius: 8,
    padding: "8px 32px",
    border: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    width: 200,
  },

  convertButton: {
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    textTransform: "none",

    borderRadius: 8,
    padding: "8px 32px",
    border: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    width: 200,
  },
}));

export default styles;
