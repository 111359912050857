import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    maxWidth: 1920,
    margin: "auto",
    padding: "0 16px",
  },
  capoRegimeList: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
    fontFamily: "Philosopher",
    fontWeight: 900,

    maxWidth: 1440,
    padding: "16px 0 0 0",
    margin: "auto",
    marginBottom: "16px",
    overflowX: "scroll",
    justifyContent: "space-around",
  },

  capoRegime: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    color: theme.palette.text.primary,
    textAlign: "center",
    fontSize: 20,
    fontWeight: 900,
  },

  capoRegimeCard: {
    backgroundColor: theme.palette.background.cardBg,
    borderRadius: 16,
    display: "flex",
    flexDirection: "column",
    padding: "24px 32px",
    color: theme.palette.text.primary,
    textAlign: "center",
    fontSize: 16,
    gap: 16,
  },

  cardHeader: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },

  cardInfo: {
    display: "flex",
    justifyContent: "space-between",

    "& div": {
      display: "flex",
      color: theme.palette.text.secondary,
      alignItems: "center",
      gap: 4,
      fontSize: 12,

      "& img": {
        width: 16,
        height: 16,
      },
    },

    "& a": {
      color: theme.palette.text.linkColor,
      textDecoration: "none",
    },
  },

  cardTitle: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },

  memberList: {
    display: "flex",

    flexDirection: "column",
    overflowY: "scroll",
    padding: "0 8px",
    gap: "3px",
    height: 214,
    minWidth: 190,
    color: theme.palette.text.secondary,

    "& div": {
      display: "flex",
      justifyContent: "space-between",

      "& div:last-of-type": {
        width: 60,
      },
    },
  },

  link: {
    width: 124,
    color: theme.palette.text.secondary,
    textDecoration: "none",
  },

  worth: {
    textAlign: "right",
    display: "initial!important",
  },
}));

export default styles;
