import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ethers } from "ethers";

import {
  finishOpenCrate,
  requestOpenCrate,
} from "../helper/contractFunctions/MafiaInventory";
import {
  buyCrates,
  getSwapTokens,
} from "../helper/contractFunctions/OgCrateMinter";

import { WalletErrorMsg } from "../constants/const";
import { getTokenBalance } from "../helper/contractFunctions";
import { SwapToken } from "../types/Contract/SwapToken";

export interface CrateState {
  swapTokenInfo: [SwapToken[], number[]];
  isLoadingSwapTokenInfo: boolean;

  swapTokenBalances: any;
  isLoadingSwapTokenBalances: boolean;

  isBuyingCrate: boolean;

  isOpeningCrate: boolean;

  isRequestOpenCrate: boolean;
  isFinishOpenCrate: boolean;
  isWaitingVRF: boolean;
}

const initialState: CrateState = {
  swapTokenInfo: [[], []],
  isLoadingSwapTokenInfo: false,

  swapTokenBalances: {},
  isLoadingSwapTokenBalances: false,

  isBuyingCrate: false,

  isOpeningCrate: false,

  isRequestOpenCrate: false,
  isFinishOpenCrate: false,
  isWaitingVRF: false,
};

export const getSwapTokenBalance = createAsyncThunk(
  "crate/getSwapTokenBalance",
  async ({ account }: { account: string }, { getState }) => {
    const state = (getState() as any).crate as CrateState;

    const balanceArray = await Promise.all(
      state.swapTokenInfo[0].map(async (token) => {
        const balance = await getTokenBalance(token.tokenAddress, account);
        return { [token.name]: balance };
      })
    );

    const balances = balanceArray.reduce((acc, entry) => {
      const key = Object.keys(entry)[0];
      acc[key] = entry[key];
      return acc;
    }, {});

    return balances;
  }
);

export const getSwapTokenInfo = createAsyncThunk(
  "crate/getSwapTokenInfo",
  async () => {
    const data = await getSwapTokens();

    const swapTokenList = data[0].map((tokenInfo) => {
      return {
        name: tokenInfo.name,
        tokenAddress: tokenInfo.tokenAddress,
        isStable: tokenInfo.isStable,
        isEnabled: tokenInfo.isEnabled,
        price: Number(tokenInfo.price),
        decimal: Number(tokenInfo.decimal),
      } as SwapToken;
    });

    const swapTokenPrice = data[1].map((price) => {
      return Number(ethers.utils.formatEther(price));
    });
    return { swapTokenList, swapTokenPrice };
  }
);

export const buyCratesAction = createAsyncThunk(
  "crate/buyCratesAction",
  async ({
    swapTokenId,
    tokenAmount,
    tokenAddress,
    amount,
    account,
  }: {
    swapTokenId: number;
    tokenAmount: number;
    tokenAddress: string;
    amount: number;
    account: string;
  }) => {
    await buyCrates(swapTokenId, tokenAddress, tokenAmount, amount, account);
  }
);

export const requestOpenCrateAction = createAsyncThunk(
  "crate/requestOpenCrateAction",
  async ({ account }: { account: string }) => {
    await requestOpenCrate(account);
  }
);

export const finishOpenCrateAction = createAsyncThunk(
  "crate/finishOpenCrateAction",
  async ({ account }: { account: string }) => {
    await finishOpenCrate(account);
  }
);

export const crateSlice = createSlice({
  name: "crate",
  initialState,
  reducers: {
    setWaitingVRF: (state, { payload }) => {
      state.isWaitingVRF = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSwapTokenBalance.pending, (state) => {
      state.isLoadingSwapTokenBalances = true;
    });
    builder.addCase(getSwapTokenBalance.fulfilled, (state, { payload }) => {
      state.swapTokenBalances = payload;
      state.isLoadingSwapTokenBalances = false;
    });
    builder.addCase(getSwapTokenBalance.rejected, (state, { error }) => {
      state.isLoadingSwapTokenBalances = false;
      console.log(error);
    });

    builder.addCase(getSwapTokenInfo.pending, (state) => {});
    builder.addCase(getSwapTokenInfo.fulfilled, (state, { payload }) => {
      state.swapTokenInfo = [payload.swapTokenList, payload.swapTokenPrice];
    });
    builder.addCase(getSwapTokenInfo.rejected, (state, { error }) => {});

    builder.addCase(buyCratesAction.pending, (state) => {
      state.isBuyingCrate = true;
    });
    builder.addCase(buyCratesAction.fulfilled, (state, { payload }) => {
      state.isBuyingCrate = false;
    });
    builder.addCase(buyCratesAction.rejected, (state, { error }) => {
      state.isBuyingCrate = false;
      console.log(error);
    });

    builder.addCase(requestOpenCrateAction.pending, (state) => {
      state.isOpeningCrate = true;
      state.isRequestOpenCrate = true;
    });
    builder.addCase(requestOpenCrateAction.fulfilled, (state, { payload }) => {
      state.isRequestOpenCrate = false;
    });
    builder.addCase(requestOpenCrateAction.rejected, (state, { error }) => {
      state.isRequestOpenCrate = false;

      if (error.message !== WalletErrorMsg.Indexing)
        state.isOpeningCrate = false;
    });

    builder.addCase(finishOpenCrateAction.pending, (state) => {
      state.isFinishOpenCrate = true;
    });
    builder.addCase(finishOpenCrateAction.fulfilled, (state, { payload }) => {
      state.isFinishOpenCrate = false;
      state.isOpeningCrate = false;
    });
    builder.addCase(finishOpenCrateAction.rejected, (state, { error }) => {
      state.isFinishOpenCrate = false;
      state.isOpeningCrate = false;
    });
  },
});

export const { setWaitingVRF } = crateSlice.actions;

export default crateSlice.reducer;
