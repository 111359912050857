import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import InventoryItemIcon from "../../InventoryItemIcon";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CONFIRMATION_DELAY } from "../../../constants/const";
import { Messages } from "../../../constants/messages";
import { dispatchTxAction } from "../../../helper/dispatchTxAction";
import { getInventoryItemName } from "../../../helper/inventory";
import { hasAllItems } from "../../../helper/profile";
import useCurrentTime from "../../../hook/useCurrentTime";
import useWallet from "../../../hook/useWallet";
import { acceptOTCTradeAction } from "../../../reducers/exchange.slice";
import {
  getExchangeOfferList,
  getMyInventoryItems,
} from "../../../reducers/profile.slice";
import { CustomTypeText } from "../../../theme";
import { ItemInfo } from "../../../types/ItemInfo";
import { OTCOfferInfo } from "../../../types/OTCOfferInfo";
import { convertDateTime, toastSuccess } from "../../../utils/utils";

import exchangeIcon from "../../../assets/imgs/exchange/transfer_1.png";

import useStyles from "./index.styles";

interface OTCAcceptTradeProps {
  acceptingOffer: OTCOfferInfo | undefined;
  openOTCAcceptTrade: boolean;
  handleClose: () => void;
  activeTab: 0 | 1 | 2;
}

const OTCAcceptTrade = ({
  acceptingOffer,
  openOTCAcceptTrade,
  handleClose,
  activeTab,
}: OTCAcceptTradeProps) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const currentTime = useCurrentTime();
  const theme = useTheme<Theme>();
  const { account } = useWallet();
  const text = theme.palette.text as CustomTypeText;

  const { myInventoryItems, myProfile } = useAppSelector(
    (state) => state.profile
  );

  const [isChecked, setIsChecked] = useState(false);
  const [acceptable, setAcceptable] = useState(false);
  const [myItemIds, setMyItemIds] = useState<number[]>([]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleAcceptDeal = () => {
    if (isChecked) {
      if (!acceptingOffer) return;

      dispatchTxAction(
        dispatch,
        acceptOTCTradeAction({
          offerId: acceptingOffer.id,
          account: account,
          itemIds: myItemIds,
        }),
        () => {
          toastSuccess(Messages.EXCHANGE.CONVERT.ITEM_CONVERTED);
          dispatch(getMyInventoryItems({ userId: myProfile.id || 0 }));
          dispatch(getExchangeOfferList(activeTab));
          handleClose();
        },
        CONFIRMATION_DELAY * 2
      );
    } else {
      alert("Please confirm the deal before accepting.");
    }
  };

  useEffect(() => {
    const itemInfos =
      acceptingOffer?.requestItems.map((requestItem, index) => {
        return {
          typeId: requestItem.typeId,
          categoryId: requestItem.categoryId,
          cityId: requestItem.cityId,
          itemId: -1,
        } as ItemInfo;
      }) || [];

    const [acceptable, itemIds] = hasAllItems(myInventoryItems, itemInfos);

    setAcceptable(acceptable);
    setMyItemIds(itemIds);
  }, [acceptingOffer, myInventoryItems]);

  return (
    <Dialog
      open={openOTCAcceptTrade}
      onClose={handleClose}
      className={classes.modalBody}
    >
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        <Box className={classes.contentHeader}>
          <Typography
            fontFamily={"Philosopher"}
            color={"white"}
            textAlign={"center"}
            fontSize={24}
            fontWeight={700}
          >
            OTC Deal
          </Typography>

          <Box>
            <Typography
              fontFamily={"Philosopher"}
              color={text.grayColor}
              textAlign={"center"}
              fontSize={16}
              fontWeight={700}
            >
              {(acceptingOffer?.expireAt || 0) - currentTime < 0
                ? "Expired"
                : convertDateTime(
                    (acceptingOffer?.expireAt || 0) - currentTime
                  )}
            </Typography>
            <Typography
              fontFamily={"Philosopher"}
              color={"white"}
              textAlign={"center"}
              fontSize={16}
              fontWeight={700}
            >
              by {"  "}
              <Link
                to={`profile/${acceptingOffer?.creatorName}`}
                className={classes.link}
              >
                {acceptingOffer?.creatorName}
              </Link>
            </Typography>
          </Box>
        </Box>

        <Box
          className={classes.contentBody}
          sx={{
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "32px",

              flexDirection: {
                xs: "column",
                md: "row",
              },
            }}
          >
            <Box className={classes.offerContent}>
              <Typography
                fontFamily={"Philosopher"}
                color={"white"}
                textAlign={"center"}
                fontSize={16}
                fontWeight={700}
              >
                Creator offers:
              </Typography>

              <Box
                sx={{
                  mt: 2,
                }}
              >
                {acceptingOffer?.offerItemDataList.map((itemData, index) => {
                  const categoryId = itemData.categoryId;
                  const typeId = itemData.typeId;
                  const cityId = itemData.cityId;
                  const itemName = getInventoryItemName(
                    categoryId,
                    typeId,
                    cityId
                  );
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                      key={index}
                    >
                      <Box
                        className={classes.itemIcon}
                        key={index}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <InventoryItemIcon
                          categoryId={categoryId}
                          typeId={typeId}
                        />
                      </Box>

                      <Box
                        sx={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          width: "100%",
                          fontSize: 12,
                          userSelect: "none",
                          color: text.grayColor,
                        }}
                      >
                        {itemName}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Box
              sx={{
                width: "1px",
                backgroundColor: "#2b2b2b",

                display: {
                  sm: "flex",
                  xs: "none",
                },
              }}
            >
              <Box
                component="img"
                src={exchangeIcon}
                width={32}
                height={32}
                sx={{
                  translate: "-50% -50%",
                  top: "50%",
                  position: "absolute",
                  backgroundColor: "#1d1d1d",
                }}
              ></Box>
            </Box>

            <Box className={classes.offerContent}>
              <Typography
                fontFamily={"Philosopher"}
                color={"white"}
                textAlign={"center"}
                fontSize={16}
                fontWeight={700}
              >
                Requires from you:
              </Typography>

              <Box
                sx={{
                  mt: 2,
                }}
              >
                {acceptingOffer?.requestItems.map((item, index) => {
                  const categoryId = item.categoryId;
                  const typeId = item.typeId;
                  const cityId = item.cityId;
                  const itemName = getInventoryItemName(
                    categoryId,
                    typeId,
                    cityId
                  );
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                      key={index}
                    >
                      <Box
                        className={classes.itemIcon}
                        key={index}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <InventoryItemIcon
                          categoryId={categoryId}
                          typeId={typeId}
                        />
                      </Box>

                      <Box
                        sx={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          width: "100%",
                          userSelect: "none",
                          fontSize: 12,
                          color: text.grayColor,
                        }}
                      >
                        {itemName}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>

          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label={
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "Philosopher",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                I confirm that I would like to exchange these items
              </Typography>
            }
          />

          <Button
            className={classes.confirm}
            onClick={handleAcceptDeal}
            disabled={!isChecked || !acceptable}
            sx={{
              marginTop: "16px",
              margin: "auto",
              width: "200px",
              textTransform: "none",
            }}
          >
            {acceptable ? "Accept deal" : "Cannot accept deal"}
          </Button>

          {!acceptable && (
            <Typography
              fontFamily={"Philosopher"}
              fontSize={12}
              color={text.brightGray}
            >
              Missing items
            </Typography>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default OTCAcceptTrade;
