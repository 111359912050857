import { ThemeProvider } from "@emotion/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { ConfirmProvider } from "material-ui-confirm";

import { init, Web3OnboardProvider } from "@web3-onboard/react";

import coinbaseWalletModule from "@web3-onboard/coinbase";
import injectedModule from "@web3-onboard/injected-wallets";
import trustModule from "@web3-onboard/trust";
import walletConnectModule from "@web3-onboard/walletconnect";

import App from "./App";
import { store } from "./app/store";
import config, { CHAIN } from "./config/config";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import "./index.css";
import { ChainType } from "./constants/enum/enum";

TimeAgo.addDefaultLocale(en);

const injected = injectedModule();
const trust = trustModule();
const coinbaseWalletSdk = coinbaseWalletModule();
const walletConnect = walletConnectModule({
  projectId: "05c96d7b6183123d0d09d0b080843541",
  requiredChains: [config.chainId],
  dappUrl: config.domain,
});

const wallets = [injected, trust, coinbaseWalletSdk, walletConnect];
const web3Onboard = init({
  chains: [
    {
      id: config.chainIdHex,
      token: config.chainSymbol,
      label: config.chainName,
      rpcUrl: config.providerList[0],
    },
  ],
  wallets,
  connect: { autoConnectLastWallet: true, autoConnectAllPreviousWallet: true },
  accountCenter: { desktop: { enabled: false }, mobile: { enabled: false } },
  theme: "dark",
  appMetadata: {
    name: config.title,
    icon:
      CHAIN === ChainType.BNB
        ? "https://bnbmafia.io/favicon.ico"
        : "https://pulsemafia.io/favicon.ico",
    description: config.title,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <Web3OnboardProvider web3Onboard={web3Onboard}>
        <ConfirmProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </ConfirmProvider>
      </Web3OnboardProvider>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
