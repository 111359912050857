import { Box, Skeleton } from "@mui/material";

import useStyles from "./index.styles";

const ActiveListingLoadingSkeleton = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.listingPanelItem}>
      <Box>
        <Skeleton width={24} height={24} variant="rounded" animation="wave" />
        <Skeleton width={100} height={24} variant="rounded" animation="wave" />
      </Box>

      <Skeleton width={60} height={24} variant="rounded" animation="wave" />

      <Skeleton width={48} height={24} variant="rounded" animation="wave" />

      <Skeleton width={24} height={24} variant="rounded" animation="wave" />

      <Skeleton width={120} height={24} variant="rounded" animation="wave" />
    </Box>
  );
};

export default ActiveListingLoadingSkeleton;
