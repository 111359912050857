import axios from "axios";
import { useEffect, useState } from "react";

import config, { CHAIN } from "../config/config";
import { ChainType } from "../constants/enum/enum";

const COIN_GECKO_API_URL = config.nativePriceUrl;

const useNativePrice = () => {
  const [price, setPrice] = useState(0);

  useEffect(() => {
    const getNativeUsdPrice = async () => {
      try {
        const response = await axios.get(COIN_GECKO_API_URL);

        CHAIN === ChainType.PLS
          ? setPrice(response.data.pulsechain.usd)
          : setPrice(response.data.binancecoin.usd);
      } catch (error) {
        console.error(`Error fetching ${config.chainSymbol} USD price:`, error);
        return null;
      }
    };

    getNativeUsdPrice();
  }, []);

  return price;
};

export default useNativePrice;
