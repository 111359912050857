import { getProvider, getWeb3, waitForConfirmation } from ".";

import OgNFTAbi from "../../abis/ERC1155.json";

import config from "../../config/config";

export const getOgNFTContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.ogNFTAddress;
  const OgNFTContract = new web3.eth.Contract(OgNFTAbi, contractAddress);
  return OgNFTContract;
};

export const ogNFTBalance = async (account: string) => {
  const OgNFTContract = getOgNFTContract();

  const balance: number = await OgNFTContract.methods
    .balanceOf(account, 0)
    .call();

  return balance;
};

export const isOGNFTApprovedForAll = async (
  account: string,
  address: string
) => {
  const OgNFTContract = getOgNFTContract();

  const isApproved: boolean = await OgNFTContract.methods
    .isApprovedForAll(account, address)
    .call();

  return isApproved;
};

export const setOGNFTApprovalForAll = async (
  operator: string,
  approved: boolean,
  account: string
) => {
  const OgNFTContract = getOgNFTContract(true);

  const data = await OgNFTContract.methods
    .setApprovalForAll(operator, approved)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);
};
