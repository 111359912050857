import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundPosition: "0",
    backgroundColor: theme.palette.background.cardBg,
    width: "360px",
    borderRadius: "16px",
    gap: "16px",

    "& img": {
      width: "100%",
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
    },

    [theme.breakpoints.down("sm")]: {
      width: "90%",
      maxWidth: "360px",
    },
  },

  binanceIcon: {
    width: "24px !important",
    height: "24px",
    padding: "8px",
    borderRadius: "50%",
    backgroundColor: "black",
    marginTop: "-80px",
  },

  balance: {
    fontSize: "32px",
    fontWeight: 900,
    color: "white",
    display: "flex",
    gap: "5px",
  },

  vaultBalanceUsd: {
    fontSize: "14px",
    padding: "16px 24px",
    borderRadius: "12px",
    backgroundColor: theme.palette.background.textBg,
    fontFamily: "Philosopher",
  },

  vaultBalanceUsdText: {
    fontWeight: 900,
    background: theme.palette.text.primaryGradient,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    lineHeight: "16px",
    display: "flex",
    gap: "5px",
  },

  explorer: {
    display: "flex",
    color: "white",
    gap: "8px",
    alignItems: "center",
    marginBottom: "16px",
  },

  explorerIcon: {
    width: "18px!important",
    height: "18px",
  },

  explorerLink: {
    color: "white",
    textDecoration: "none",
  },
}));

export default styles;
