import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  pageHeader: {
    maxWidth: "1440px",
    margin: "auto",
    width: "100%",
    fontFamily: "Philosopher",
    paddingTop: 64,
    color: theme.palette.text.primary,
    fontSize: 36,
    fontWeight: 900,
    display: "flex",
    justifyContent: "space-between",
    padding: "64px 16px 0 16px",
    boxSizing: "border-box",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: 16,
      alignItems: "center",
      textAlign: "center",
    },
  },

  actionList: {
    display: "flex",
    gap: 8,

    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 8,
    },
  },

  marketStatus: {
    display: "flex",
    gap: "16px!important",
    width: 240,
    justifyContent: "flex-end",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      justifyContent: "center",
    },
  },

  statusField: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    "& div:last-of-type": {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
    "& div:first-of-type": {
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
  },

  filter: {
    "& button": {
      width: "100%",
    },

    "&>div": {
      width: "100%",
      display: "initial",
    },
  },

  grayButton: {
    background: "#171a1bee",
    color: theme.palette.text.primary,
    borderRadius: "20px",
    padding: "4px 32px",
    fontFamily: "Philosopher",
    fontWeight: "bold",
    fontSize: 12,
    textTransform: "none",
    lineHeight: "24px",
    position: "relative",

    "&:hover": {
      background: "#242424aa",
    },

    "& img": {
      width: 16,
      height: 16,
    },

    "& svg": {
      color: theme.palette.background.defaultButton,
    },
  },

  grayButtonSelected: {
    background: "#303030ee",
  },

  listItemButton: {
    fontFamily: "Philosopher",
    fontWeight: "bold",
    fontSize: 12,
    borderRadius: 16,
    padding: "4px 32px",
    background: theme.palette.background.defaultButton,
    color: theme.palette.text.primary,
    lineHeight: "24px",
  },
}));

export default styles;
