import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Logo from "../Logo";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useWallet from "../../hook/useWallet";

import {
  acceptRequestAction,
  getAllegianceDetailById,
  rejectRequestAction,
} from "../../reducers/family.slice";

import { dispatchTxAction } from "../../helper/dispatchTxAction";
import { parseFamilyAllegianceInfo } from "../../helper/family";
import { AllegianceRequestInfo } from "../../types/AllegianceRequestInfo";
import { FamilyAllegianceInfo } from "../../types/FamilyAllegianceInfo";

import { Messages } from "../../constants/messages";

import useStyles from "./index.styles";
import { toastSuccess } from "../../utils/utils";

interface AllegianceRequestPopupProps {
  openInviteModal: boolean;
  setOpenInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
  allegianceRequestInfo?: AllegianceRequestInfo;
}

const AllegianceRequestPopup: React.FC<AllegianceRequestPopupProps> = ({
  openInviteModal,
  setOpenInviteModal,
  allegianceRequestInfo,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { account } = useWallet();

  const { familyList } = useAppSelector((state) => state.family);

  const [inviter, setInviter] = useState("");
  const [allegianceInfo, setAllegianceInfo] = useState<FamilyAllegianceInfo>();

  const handleClose = () => {
    setOpenInviteModal(false);
  };

  const handleAcceptRequest = () => {
    if (!account || !allegianceRequestInfo) return;
    const requestId = allegianceRequestInfo.requestId;

    dispatchTxAction(
      dispatch,
      acceptRequestAction({
        account,
        requestId,
      }),
      () => {
        toastSuccess(Messages.FAMILY.ALLEGIANCE.REQUEST_ACCEPT_SUCCESS);

        setOpenInviteModal(false);
      }
    );
  };

  const handleRejectRequest = () => {
    if (!account || !allegianceRequestInfo) return;

    const requestId = allegianceRequestInfo.requestId;

    dispatchTxAction(
      dispatch,
      rejectRequestAction({
        account,
        requestId,
      }),
      () => {
        toastSuccess(Messages.FAMILY.ALLEGIANCE.REQUEST_REJECT_SUCCESS);

        setOpenInviteModal(false);
      }
    );
  };

  useEffect(() => {
    if (!allegianceRequestInfo) return;
    const fetchData = () => {
      const requester =
        familyList.find(
          (family, index) =>
            family.id === allegianceRequestInfo.requesterFamilyId
        )?.name || "";

      const allegianceId = allegianceRequestInfo?.allegianceId;

      dispatch(getAllegianceDetailById({ allegianceId }))
        .unwrap()
        .then((response) => {
          const allegianceInfo = parseFamilyAllegianceInfo(
            response.allegianceData
          );

          setAllegianceInfo(allegianceInfo);
        })
        .catch(() => {});

      setInviter(requester);
    };

    fetchData();
  }, [allegianceRequestInfo, dispatch, familyList]);

  return (
    <Dialog
      open={openInviteModal}
      onClose={handleClose}
      className={classes.body}
    >
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        <Box className={classes.contentBody}>
          <Box className={classes.inviteText}>Allegiance request</Box>
          <Box className={classes.inviteDescription}>
            <Link
              className={classes.link}
              to={`/family/${inviter}`}
              target="_blank"
            >
              {inviter}
            </Link>
            have requested to join your allegiance
          </Box>
          <Box className={classes.logoSection}>
            {allegianceInfo?.symbol ? (
              <Box
                component="img"
                src={allegianceInfo.symbol}
                className={classes.logoImg}
              ></Box>
            ) : (
              <Logo classNames={classes.logoImg} />
            )}

            {allegianceInfo?.name}
          </Box>
          <Box className={classes.buttonSection}>
            <Button className={classes.acceptBut} onClick={handleAcceptRequest}>
              Accept
            </Button>
            <Button
              className={classes.declineBut}
              onClick={handleRejectRequest}
            >
              Decline
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AllegianceRequestPopup;
