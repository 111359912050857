import { Box, Tooltip } from "@mui/material";
import millify from "millify";
import { useCallback, useEffect, useMemo, useState } from "react";

import Logo from "../Logo";
import NativeIcon from "../NativeIcon";

import { useAppSelector } from "../../app/hooks";
import {
  FAMILY_PREMIUM_THRESHOLD,
  PREMIUM_KEY_CLAIM_CYCLE,
} from "../../constants/const";
import { calculateFamilyPayout } from "../../utils/utils";

import useStyles from "./index.styles";
import useCurrentTime from "../../hook/useCurrentTime";

interface FamilyInfoPanelsProps {
  vaultBalance: number;
}

const FamilyInfoPanels: React.FC<FamilyInfoPanelsProps> = ({
  vaultBalance,
}) => {
  const { classes } = useStyles();
  const currentTime = useCurrentTime();

  const [familyPayout, setFamilyPayout] = useState(0);
  const { familyDetail } = useAppSelector((state) => state.family);

  const calculatePremiumPercentage = useCallback(() => {
    const premiumCount = familyDetail.members.filter(
      (member) =>
        member.premium === 1 &&
        (member.premiumStart || 0) + PREMIUM_KEY_CLAIM_CYCLE * 3 > currentTime
    ).length;
    return (premiumCount / familyDetail.members.length) * 100 || 0;
  }, [currentTime, familyDetail.members]);

  const premiumPercentage = useMemo(calculatePremiumPercentage, [
    calculatePremiumPercentage,
  ]);

  useEffect(() => {
    if (familyDetail.familyInfo?.rank && familyDetail.familyInfo?.rank <= 8) {
      const familyPayout = calculateFamilyPayout(
        vaultBalance,
        familyDetail.familyInfo?.rank
      );

      setFamilyPayout(familyPayout || 0);
    }
  }, [familyDetail, vaultBalance]);

  return (
    <Box className={classes.container}>
      <Box className={classes.body}>
        <Box className={classes.item}>
          <Box className={classes.rank}>{familyDetail.familyInfo?.rank}</Box>
          <Box>Rank</Box>
        </Box>
        <Box className={classes.item}>
          <Box className={classes.coming}>Coming soon</Box>
          <Box>HQ size</Box>
        </Box>
        <Box className={classes.item}>
          <Box className={classes.members}>{familyDetail.members.length}</Box>
          <Box>Members</Box>
        </Box>
        <Box className={classes.item}>
          {familyDetail.allegiance ? (
            <Box className={classes.familyAllegiance}>
              {familyDetail.allegiance?.symbol && (
                <Box component="img" src={familyDetail.allegiance.symbol}></Box>
              )}

              {familyDetail.allegiance?.name}
            </Box>
          ) : (
            <Box
              className={classes.familyAllegiance}
              sx={{
                fontSize: 16,
              }}
            >
              -
            </Box>
          )}

          <Box>Allegiance</Box>
        </Box>
        <Box className={classes.item}>
          <Box>
            {millify(familyPayout, { precision: 2 })}
            <NativeIcon sx={{ width: 20 }} />
          </Box>
          <Box>Weekly payout</Box>
        </Box>
        <Box className={classes.item}>
          <Box>{100 - familyDetail.topPercent}%</Box>
          <Box>Family share</Box>
        </Box>
        <Box className={classes.item}>
          <Box>
            {millify(familyDetail.leaveFee)}

            <Logo sx={{ width: 24 }} />
          </Box>
          <Box>Leave fee</Box>
        </Box>
        <Box className={classes.item}>
          <Box
            className={
              premiumPercentage >= FAMILY_PREMIUM_THRESHOLD
                ? classes.memberGoldenText
                : classes.memberText
            }
          >
            {premiumPercentage.toFixed(1)}%
          </Box>

          <Tooltip title="Family+ requires 50% or more">
            <Box className={classes.premiumMembers}>Premium Members</Box>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default FamilyInfoPanels;
