import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    fontFamily: "Philosopher",
    gap: "32px",
    padding: "16px",
    background: "url('/assets/imgs/family/family_create_bg.png') no-repeat",
    backgroundColor: theme.palette.background.defaultBg,
    backgroundSize: "auto 100%",
    backgroundPosition: "100%",
    maxWidth: "1920px",
    margin: "auto",
    boxSizing: "border-box",
    height: "calc(100vh)",
    maxHeight: 1080,

    [theme.breakpoints.down("sm")]: {
      background:
        "url('/assets/imgs/family/family_create_bg_mobile.png') no-repeat",
      backgroundColor: theme.palette.background.defaultBg,
      backgroundPosition: "center 0",
    },
    justifyContent: "center",
  },

  familyCreate: {
    display: "flex",
    flexDirection: "column",
    gap: 32,
    alignItems: "center",
  },

  familyCreateTitle: {
    fontSize: 48,
    color: theme.palette.text.primary,
    fontWeight: 90,

    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
    },
  },

  familyCreateCost: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    color: theme.palette.text.primary,
    fontWeight: 900,

    "&>div:first-of-type": {
      color: theme.palette.text.secondary,
    },

    "& img": {
      width: 32,
      height: 32,
    },
  },

  familyInfo: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 320,
    gap: 16,
    width: "100%",
  },

  inputBox: {
    borderRadius: "12px",
    boxSizing: "border-box",
    width: "100%",
    padding: "12px 32px",
    backgroundColor: theme.palette.background.lightGray,
    color: theme.palette.text.primary,
    outline: "none",

    "& input": {
      outline: "none!important",
      border: "none",
      color: theme.palette.text.primary,
      fontFamily: "Philosopher",
      fontSize: "18px",
      fontWeight: 900,

      "&::placeholder": {
        color: theme.palette.text.secondary,
      },
    },

    "& .MuiTextField-root::after": {
      content: "initial",
    },
  },

  infoInput: {
    position: "relative",

    width: "100%",
    "& svg": {
      position: "absolute",
      right: 12,
      top: 19,
    },
  },

  infoOption: {
    outline: "none",
    position: "relative",

    "& .Mui-focused": {
      borderRadius: "12px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      outline: "none",
      border: "none",
      borderRadius: "12px",
    },

    "& .MuiInputBase-input": {
      border: "none",
      outline: "none",
      borderRadius: "12px",
      padding: "16px 32px",
      backgroundColor: theme.palette.background.lightGray,
      color: theme.palette.text.primary,

      "&:focus": {
        borderRadius: 12,
      },
    },

    "&>svg": {
      position: "absolute",
      right: 36,
      top: 19,
    },
  },

  infoOptionSelect: {
    width: "100%",
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 18,

    "& svg": {
      width: 32,
      height: 32,
      color: "gray",
    },
  },

  infoOptionSelectPaper: {
    borderRadius: 8,
    backgroundColor: theme.palette.background.lightGray,
  },

  infoOptionSelectList: {
    borderRadius: 8,
    backgroundColor: theme.palette.background.lightGray,
    color: theme.palette.text.primary,
  },

  infoButton: {
    "& label": {
      justifyContent: "space-between",
      width: "100%",
      borderRadius: "12px",

      padding: "12px 12px 12px 32px",
      backgroundColor: theme.palette.background.lightGray,
      color: theme.palette.text.secondary,
    },

    "& img": {
      width: 32,
      height: 32,
    },

    "& svg": {
      fontSize: 32,
      width: 32,
      height: 32,
    },
  },

  buttonSection: {
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",

    "&>button": {
      fontFamily: "Philosopher",
      fontWeight: 900,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.defaultButton,
      borderRadius: "8px",
      padding: "8px 16px",
      textTransform: "none",
      minWidth: "150px",

      "&:hover": {},

      "& span": {
        color: "white",
      },
    },
  },
}));

export default styles;
