import { Box } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";

import CreateProfile from "../../components/CreateProfile";
import Dot from "../Dot";

import { toUSDFormat } from "../../utils/utils";

import useStyles from "./index.styles";

import config from "../../config/config";
import NativeIcon from "../NativeIcon";
import millify from "millify";

interface LandingHeaderProps {
  playerCount: number;
  familyCount: number;
  vault: number;
}

const LandingHeader = (props: LandingHeaderProps) => {
  const { classes } = useStyles();
  const [showCreate, setShowCreate] = useState(false);

  const handleShowCreate = () => {
    setShowCreate((showCreate) => !showCreate);
  };

  return (
    <Box className={clsx(classes.body, showCreate ? classes.showCreate : null)}>
      <Box className={classes.arrivalText}>
        A family is awaiting your arrival
      </Box>
      <CreateProfile showCreate={showCreate} setShowCreate={handleShowCreate} />
      <Box className={classes.info}>
        <Box className={classes.infoDetail}>
          <Dot width={10} color="#13d209" isShadow={true} />
          <Box>{toUSDFormat(props.playerCount)} other alive players</Box>
        </Box>
        <Box className={classes.infoDetail}>
          <Dot width={10} color="#d20938" isShadow={true} />
          <Box>{toUSDFormat(props.familyCount)} Crime families</Box>
        </Box>
        <Box className={classes.infoDetail}>
          <Box>Vault</Box>
          <NativeIcon />
          <Box>
            {millify(props.vault, {
              precision: 2,
            })}{" "}
            {config.chainSymbol}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingHeader;
