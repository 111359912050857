import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    fontFamily: "Philosopher",
    justifyContent: "center",

    backgroundSize: "auto 100%",
    backgroundPosition: "100%",

    maxWidth: "1920px",
    margin: "auto",
    boxSizing: "border-box",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      padding: "16px 16px",
    },
  },

  container: {
    gap: "48px",
    display: "flex",
    margin: "auto",
    marginBottom: 16,
    marginTop: 16,
    width: "100%",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginBottom: 16,
      marginTop: 16,
      width: "100%",
    },
  },

  card: {
    width: "100%",
    minHeight: "240px",

    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    backgroundColor: theme.palette.background.cardBgTransparent,
    border: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    height: "240px",
    padding: "24px 24px",
    gap: "12px",
    justifyContent: "flex-start",
    boxShadow:
      "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",

    boxSizing: "border-box",

    [theme.breakpoints.down("md")]: {
      padding: "16px 16px",
    },

    [theme.breakpoints.down("sm")]: {
      gap: 12,
    },
  },

  cardHeader: {
    display: "flex",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  cardHeaderTitle: {
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontSize: 24,
    fontWeight: 900,

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    color: theme.palette.text.secondary,
  },

  settingOptionList: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },

  settingOption: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontSize: 14,
  },

  claimKey: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 4,

    "& p": {
      color: theme.palette.text.secondaryColor,
      fontFamily: "Philosopher",
      fontWeight: 700,
      fontSize: 14,
    },
  },

  claimButton: {
    // FIXME: Update background gradient with theme gradient after merge `update/allegiance` branch
    background:
      "linear-gradient(180deg, rgba(0,173,115,1) 0%, rgba(17,179,132,1) 100%);",

    borderRadius: 16,
    color: theme.palette.text.primary,
    textTransform: "none",
    fontFamily: "Philosopher",
    fontWeight: 700,
    padding: "6px 16px",
    minWidth: 160,
    border: "1px solid #101010",

    "&:disabled": {
      color: theme.palette.text.secondary,
      background: theme.palette.background.lightGray,
    },
  },
}));

export default styles;
