import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: any) => ({
  body: {
    top: 0,
    height: "100vh",
    color: "white",
    zIndex: 100,

    "& .MuiPaper-root": {
      backgroundColor: "#151618",
      color: "white",
    },

    "& .MuiAccordionDetails-root": {
      maxHeight: "calc(100vh - 394px)",
      overflowY: "auto",
    },
  },
  container: {
    width: 450,
    padding: "80px 16px 12px 16px",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Philosopher",
    height: "100%",
    boxSizing: "border-box",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  expandIcon: {
    height: "40px",
    minHeight: "40px",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "16px",

    "& button": {
      width: 24,

      minWidth: 12,

      "& svg": {
        color: "white",
        cursor: "pointer",
        transition: "0.3s",
        fontSize: "20px",
        "&:hover": {
          color: "#5b5b5d",
          transition: "0.3s",
        },
      },
    },
  },

  header: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    padding: "0 10px",
    alignItems: "center",
    position: "relative",
    minHeight: "100px",
  },

  subHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    fontSize: "20px",
    cursor: "pointer",
  },

  messengerImg: {
    width: "30px",
  },

  disabledText: {
    color: "#353940",
  },

  divider: {
    backgroundColor: "#303030",
    height: "50%",
    position: "absolute",
    left: "50%",
  },

  dividerHorizontal: {
    backgroundColor: "#303030",
    width: "80%",
  },

  dividerHorizontalBox: {
    display: "flex",
    justifyContent: "center",
  },

  chatBody: {
    flex: 1,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",

    position: "relative",
  },

  selectFamilyBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "12px",
    position: "relative",
  },

  selectFamily: {
    backgroundColor: "#1d1f21",
    width: "80%",
    position: "absolute",

    "& .MuiAccordionSummary-content": {
      display: "flex",
      justifyContent: "center",
    },

    "& .MuiSvgIcon-root": {
      color: "white",
    },

    "& .MuiCollapse-root": {
      backgroundColor: "#1d1f21",
      borderRadius: "0 0 12px 12px",
    },

    "& .MuiAccordionDetails-root": {
      padding: "0",
    },
  },

  dropdownValue: {
    backgroundColor: "#1d1f21",
    display: "flex",
    justifyContent: "center",
    borderRadius: "12px 12px 0 0",
  },

  fullBorderR: {
    borderRadius: "12px",
  },

  detailsBody: {
    display: "flex",
    flexDirection: "column",
  },

  selectDetail: {
    display: "grid",
    gridTemplateColumns: "1fr 5fr 1fr",
    minHeight: "50px",
    alignItems: "center",
    transition: "0.3s",
    cursor: "pointer",

    "&>div": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },

    "&>div:nth-of-type(3)": {
      color: "#646464",
    },

    "& img": {
      width: "20px",
    },

    "&:hover": {
      backgroundColor: "#27292b",
      transition: "0.3s",
    },
  },

  valueBox: {
    display: "grid",
    gridTemplateColumns: "1fr 5fr 1fr",
    alignItems: "center",
    transition: "0.3s",
    width: "100%",

    "&>div": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },

    "&>div:nth-of-type(3)": {
      color: "#646464",
    },

    "& img": {
      width: "20px",
    },
  },

  chatBodyContainer: {
    overflowY: "auto",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "end",

    "&>div:not(:last-of-type)": {
      borderTop: "1px solid #26292c",
    },
  },

  containerLoading: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "100%",

    "& svg": {
      color: theme.palette.text.secondary,
    },
  },

  containerFamily: {
    marginTop: 60,
    maxHeight: "calc(100vh - 330px)",
  },

  containerMain: {
    maxHeight: "calc(100vh - 250px)",
  },

  chatList: {
    padding: "16px 0",
  },

  role: {
    fontSize: "16px",
    fontWeight: 900,
  },

  logoImg: {
    width: "18px",
    marginTop: "3px",
    verticalAlign: "sub",
  },

  name: {
    fontSize: "16px",
    color: "#1e6276",
    fontWeight: 900,
    "&>a": {
      color: "#1e6276",
      textDecoration: "none",
    },
  },

  chatText: {
    color: "#78797d",
    fontSize: "14px",
  },

  inputBox: {
    width: "100%",
    display: "flex",
    gap: "16px",
    minHeight: "60px",
    alignItems: "center",
  },

  chatInput: {
    flex: 1,
    color: "white",
    backgroundColor: "#36373a",
    borderRadius: "8px",
    minHeight: "40px",
    "& fieldset": {
      outline: "none",
      border: "none",
      minHeight: "40px",
    },
    "& .MuiInputBase-input": {
      color: "white",
      padding: "12px 14px",
      height: 20,

      "&:disabled": {
        opacity: 1,
        WebkitTextFillColor: theme.palette.text.secondary,
        // -webkit-text-fill-color: rgb(255 255 255 / 38%);
      },
    },
  },

  sendBut: {
    textTransform: "none",
    color: "white",
    fontSize: "16px",
    lineHeight: "32px",
    backgroundColor: "#36373a",
    minWidth: "100px",
    borderRadius: "8px",
  },

  emojiIcon: {
    width: "20px",
    cursor: "pointer",
  },
}));
