import { Box } from "@mui/material";
import useStyles from "./index.styles";

import ClipBoard from "../ClipBoard";

import config, { CHAIN } from "../../config/config";
import { MAFIA_BUY_FEE, MAFIA_SELL_FEE } from "../../constants/const";
import { ChainType } from "../../constants/enum/enum";

import dexScreener from "../../assets/imgs/landing/dexscreener.png";
import dexTools from "../../assets/imgs/landing/dextools.png";
import pancake from "../../assets/imgs/landing/pancake.png";
import telegram from "../../assets/imgs/landing/telegram.png";
import pulseX from "../../assets/imgs/PulseX.png";

import Logo from "../Logo";

const LandingInfo = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.body}>
      <Box className={classes.logoContainer}>
        <Logo classNames={classes.logo} />
        <Box className={classes.symbol}>{config.symbol}</Box>
      </Box>
      <ClipBoard />

      <Box className={classes.taxInfo}>
        <Box className={classes.buyTax}>{MAFIA_BUY_FEE}% buy tax</Box>
        <Box className={classes.sellTax}>{MAFIA_SELL_FEE}% sell tax</Box>
      </Box>

      <Box className={classes.description}>
        Become the most dangerous hitman of the underworld, train your skills
        and everyone will fear you. Claim what is yours, but be careful.
        <br />
        <br />
        Life is precious. You only have one
      </Box>

      <Box className={classes.links}>
        <Box
          component="a"
          href={`https://dexscreener.com/${
            CHAIN === ChainType.PLS ? "pulsechain" : "bsc"
          }/${config.mafiaAddress}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box component="img" src={dexScreener}></Box>
        </Box>

        {CHAIN === ChainType.PLS ? (
          <Box
            component="a"
            rel="noopener noreferrer"
            target="_blank"
            href={`https://pulsex.mypinata.cloud/ipfs/bafybeiesh56oijasgr7creubue6xt5anivxifrwd5a5argiz4orbed57qi/#/?outputCurrency=${config.mafiaAddress}`}
          >
            <Box component="img" src={pulseX}></Box>
          </Box>
        ) : (
          <Box
            component="a"
            href={`https://pancakeswap.finance/swap?outputCurrency=${config.mafiaAddress}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box component="img" src={pancake}></Box>
          </Box>
        )}

        <Box
          component="a"
          href={`https://www.dextools.io/app/en/${
            CHAIN === ChainType.PLS ? "pulse" : "bnb"
          }/pair-explorer/${config.pairAddress}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box component="img" src={dexTools}></Box>
        </Box>

        <Box
          component="a"
          href={
            CHAIN === ChainType.PLS
              ? `https://t.me/PLSmafia`
              : `https://t.me/BNBMafiagame`
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box component="img" src={telegram}></Box>
        </Box>
      </Box>

      {/* <Box className={classes.widgets}>
        <ChartWidget />
      </Box> */}
    </Box>
  );
};

export default LandingInfo;
