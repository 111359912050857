import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import { Badge, Drawer } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import isMobile from "is-mobile";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Web3 from "web3";

import AllegianceInvitationPopup from "../../components/AllegianceInvitationPopup";
import AllegianceRequestPopup from "../../components/AllegianceRequestPopup";
import BackOverlay from "../../components/BackOverlay";
import ChatPanel from "../../components/ChatPanel";
import FamilyInvitationMemberPopup from "../../components/FamilyInvitationMemberPopup";
import Logo from "../../components/Logo";
import RateLimitPopup from "../../components/RateLimitPopup";
import NotificationPanel from "./NotificationPanel";
import SideBar from "./SideBar";

import {
  activityTracking,
  clearAuth,
  requestAuthorize,
  setAuth,
} from "../../reducers/auth.slice";
import { getFamilyList } from "../../reducers/family.slice";
import { clearNotificationBadge } from "../../reducers/notification.slice";
import {
  getCrateBalance,
  getOgNFTBalance,
  getProfileNames,
  updateCashBalance,
} from "../../reducers/profile.slice";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import config, { CHAIN } from "../../config/config";
import { NavBarPages } from "../../constants/const";
import { ChainType } from "../../constants/enum/enum";
import { switchNetwork } from "../../helper/network";
import { AllegianceInvitationInfo } from "../../types/AllegianceInvitationInfo";
import { AllegianceRequestInfo } from "../../types/AllegianceRequestInfo";
import { FamilyInvitation } from "../../types/FamilyInvitation";
import { shortAddress, toUSDFormat } from "../../utils/utils";

import useAuth, { checkSignature } from "../../hook/useAuth";
import useGameBankBalance from "../../hook/useGameBankBalance";
import useProfile from "../../hook/useProfile";
import useWallet from "../../hook/useWallet";

import bnbIcon from "../../assets/imgs/bnb.png";
import familyIcon from "../../assets/imgs/layout/family-gray.png";
import profileIcon from "../../assets/imgs/layout/user.png";
import plsIcon from "../../assets/imgs/pls.png";

import { useStyles } from "./index.styles";

const MainLayout = (props: React.PropsWithChildren) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLink = useLocation();

  const { wallet, connectWallet, disconnectWallet, account, isConnected } =
    useWallet();
  const needToAuth = useAuth(account);
  const { myProfile, profileName } = useProfile(account);

  const { notifications, notificationCount } = useAppSelector(
    (state) => state.notification
  );
  const { cashBalance } = useAppSelector((state) => state.profile);
  const { signMsg, signature, isAuthorizing } = useAppSelector(
    (state) => state.auth
  );
  const { updateBalance } = useGameBankBalance(account, signMsg, signature);

  const rateExceeded = useAppSelector((state) => state.auth.rateExceeded);

  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openAllegianceInviteModal, setOpenAllegianceInviteModal] =
    useState(false);
  const [openAllegianceRequestModal, setOpenAllegianceRequestModal] =
    useState(false);

  const [invitationInfo, setInvitationInfo] = useState<FamilyInvitation>();
  const [allegianceInvitationInfo, setAllegianceInvitationInfo] =
    useState<AllegianceInvitationInfo>();

  const [allegianceRequestInfo, setAllegianceRequestInfo] =
    useState<AllegianceRequestInfo>();

  const [anchorElNotification, setAnchorElNotification] =
    useState<null | HTMLElement>(null);

  const [showSidebar, toggleSideBar] = useState<boolean>(false);
  const [showNotification, toggleNotification] = useState<boolean>(false);

  const navbarRef = useRef<React.ElementRef<"div">>(null);
  const currentNavRef = useRef<any>(null);

  const [openChat, setOpenChat] = useState(false);

  const [muted, setMuted] = useState(localStorage.getItem("mute") === "true");

  const handleMouseMove = (e: React.MouseEvent) => {
    const hoverSpan = document.getElementById("navbar-hover-span");
    if (!navbarRef.current || !hoverSpan) return;
    const childs = navbarRef.current.getElementsByTagName("a");

    for (let i = 0; i < childs.length; i++) {
      const rect = childs[i].getClientRects()[0];

      if (rect.left < e.clientX && rect.right > e.clientX) {
        if (currentNavRef.current === childs[i]) return;
        const navbarRect = navbarRef.current.getClientRects()[0];
        const iconElem = childs[i].getElementsByTagName("img")[0];
        const iconRect = iconElem.getClientRects()[0];
        hoverSpan.style.left =
          iconRect.left - navbarRect.left - (25 - iconRect.width) + "px";
        hoverSpan.style.width = rect.width + 50 + "px";
        hoverSpan.style.opacity = "1";
        currentNavRef.current = childs[i];
        return;
      }
    }

    hoverSpan.style.opacity = "0";
  };

  const handleMouseLeave = () => {
    currentNavRef.current = null;
    const hoverSpan = document.getElementById("navbar-hover-span");
    hoverSpan && (hoverSpan.style.opacity = "0");
  };

  const handleShowNotification = (event: React.MouseEvent<HTMLElement>) => {
    toggleNotification((previousOpen) => !previousOpen);
    setAnchorElNotification(event.currentTarget);

    if (notifications.length === 0) return;

    dispatch(clearNotificationBadge());
  };

  const toggleMute = () => {
    const muted = localStorage.getItem("mute") === "true";

    if (muted) {
      localStorage.setItem("mute", "false");
    } else {
      localStorage.setItem("mute", "true");
    }

    setMuted(!muted);

    window.dispatchEvent(
      new CustomEvent("mute", { detail: { key: "mute", newValue: !muted } })
    );
  };

  const onConnectWallet = useCallback(() => {
    setOpenChat(false);
    connectWallet();
  }, [connectWallet]);

  useEffect(() => {
    if (wallet) {
      const checkNetwork = () => {
        const web3 = new Web3(wallet.provider);
        (window as any).provider = web3;

        (window as any).wallet = wallet;

        if (wallet.chains[0].id !== `0x${config.chainIdHex.toString(16)}`) {
          disconnectWallet();
          switchNetwork(wallet.provider)
            .then(() => {
              onConnectWallet();
            })
            .catch((error) => {
              disconnectWallet();
            });
        }
      };

      checkNetwork();
    }
  }, [wallet, dispatch, disconnectWallet, onConnectWallet]);

  useEffect(() => {
    if (!account) {
      dispatch(clearAuth());
      return;
    }

    if (checkSignature(account)) {
      if (isAuthorizing) return;
      dispatch(requestAuthorize({ account }));
    } else {
      dispatch(setAuth(account));
    }

    dispatch(activityTracking());
  }, [account, dispatch, isAuthorizing, needToAuth]);

  useEffect(() => {
    dispatch(getCrateBalance({ account: account }));
    dispatch(getOgNFTBalance({ account: account }));
  }, [account, dispatch]);

  useEffect(() => {
    dispatch(getFamilyList());
    dispatch(getProfileNames());
  }, [dispatch, rateExceeded]);

  const NewBadge = () => {
    return <Box className={classes.badge}>NEW</Box>;
  };

  useEffect(() => {
    updateBalance(account, signMsg, signature);
    // if (account) dispatch(updateCashBalance(account));
  }, [account, dispatch, signMsg, signature, updateBalance]);

  return (
    <Box className={classes.body}>
      <AppBar
        position="static"
        sx={{ background: "none", boxShadow: "none", zIndex: 1 }}
      >
        <Toolbar disableGutters className={classes.toolbar}>
          <Box className={classes.mdLogoWrapper}>
            <Logo
              onClick={() => navigate("/")}
              sx={{
                width: { md: 90, xl: 120 },
                cursor: "pointer",
              }}
            />

            <Box>MAFIA</Box>
          </Box>

          <Box sx={{ flexGrow: 1, display: { md: "flex", lg: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => toggleSideBar(true)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              PaperProps={{ className: classes.sidebarPaper }}
              open={showSidebar}
              onClose={() => toggleSideBar(false)}
            >
              <SideBar
                toggleSideBar={toggleSideBar}
                onConnectWallet={onConnectWallet}
              />
            </Drawer>
          </Box>

          <Logo
            sx={{
              display: { lg: "none", md: "block", sm: "none", xs: "none" },
              width: 48,
            }}
          />

          <Box
            ref={navbarRef}
            sx={{
              flexGrow: 1,
              display: {
                xs: "none",
                lg: "flex",
                justifyContent: "space-around",
              },
              justifyContent: "center",
              position: "relative",
            }}
            onMouseMove={(e) => handleMouseMove(e)}
            onMouseLeave={() => handleMouseLeave()}
          >
            {NavBarPages.map(
              (page, index) =>
                (!page.plsDisabled ||
                  (page.plsDisabled && CHAIN === ChainType.BNB)) && (
                  <NavLink
                    key={index}
                    to={page.path || currentLink}
                    className={classes.mdLink}
                    target={page.blank ? "_blank" : undefined}
                  >
                    <Box
                      className={classes.lgNavIcon}
                      component={"img"}
                      src={`/assets/imgs/layout/${page.icon}`}
                    ></Box>
                    {page.subNav ? (
                      <>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <React.Fragment>
                              <Box
                                {...bindTrigger(popupState)}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onMouseEnter={popupState.open}
                              >
                                {page.title}
                                {page.new && <NewBadge />}
                                <ExpandMoreIcon />
                              </Box>
                              <Menu
                                {...bindMenu(popupState)}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                                sx={{
                                  "& .MuiPaper-root": {
                                    backgroundColor: "#222222",
                                    minWidth: "120px",
                                  },
                                  "& .MuiMenuItem-gutters": {
                                    minHeight: "48px",
                                  },
                                  mt: 2,
                                }}
                              >
                                {page.subNav?.map((sub, index) => (
                                  <MenuItem
                                    onClick={popupState.close}
                                    key={index}
                                  >
                                    <NavLink
                                      to={sub.path || ""}
                                      onClick={(e) => {
                                        if (sub.blank) {
                                          e.preventDefault(); // Prevent default behavior for NavLink
                                          window.open(sub.path, "_blank"); // Open in a new tab
                                        }
                                      }}
                                      style={{
                                        textDecoration: "none",
                                        color: "white",
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box
                                        component={"img"}
                                        src={`/assets/imgs/layout/${sub.icon}`}
                                        width={21}
                                      ></Box>
                                      {sub.title}
                                    </NavLink>
                                  </MenuItem>
                                ))}
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </>
                    ) : (
                      <>
                        {page.title}
                        {page.new && <NewBadge />}
                      </>
                    )}
                  </NavLink>
                )
            )}
            <Box
              component="span"
              className={classes.navHoverSpan}
              id="navbar-hover-span"
            />
          </Box>

          {account && (
            <Box className={classes.currency}>
              <Box
                component={"img"}
                width={24}
                src="/assets/imgs/layout/currency.png"
              ></Box>
              {toUSDFormat(cashBalance)}
            </Box>
          )}

          <Box className={classes.userSection}>
            {isConnected ? (
              <Box className={classes.menuListBody}>
                <NavLink
                  to={`profile/${profileName}`}
                  className={classes.userProfileBtn}
                >
                  <Tooltip title={<>{account}</> || ""}>
                    <Typography component="span" sx={{ fontWeight: 700 }}>
                      {Object.keys(myProfile).length === 0
                        ? isMobile()
                          ? shortAddress(account, 3, 3)
                          : shortAddress(account)
                        : myProfile.name}
                    </Typography>
                  </Tooltip>

                  <Box
                    component="img"
                    width={18}
                    sx={{ marginLeft: "8px", verticalAlign: "sub" }}
                    src={"/assets/imgs/layout/user.png"}
                  ></Box>
                </NavLink>
              </Box>
            ) : (
              <Tooltip title={isConnected ? "Disconnect" : "Connect"}>
                <Button
                  className={classes.connectWalletBtn}
                  onClick={onConnectWallet}
                >
                  {isMobile() ? (
                    <>
                      <Box
                        component={"img"}
                        width={24}
                        src="/assets/imgs/layout/wallet.png"
                      />
                    </>
                  ) : (
                    <Typography component="span" sx={{ fontWeight: 700 }}>
                      Connect Wallet
                    </Typography>
                  )}
                </Button>
              </Tooltip>
            )}

            <Button className={classes.connectWalletBtn}>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Box
                      {...bindTrigger(popupState)}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        width={24}
                        src="/assets/imgs/layout/gear.png"
                      ></Box>
                    </Box>

                    <Menu
                      {...bindMenu(popupState)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      sx={{
                        "& .MuiPaper-root": {
                          backgroundColor: "#222222",
                          minWidth: "120px",
                        },
                        "& .MuiMenuItem-gutters": {
                          minHeight: "48px",
                        },
                        mt: 1.5,
                      }}
                    >
                      <MenuItem onClick={popupState.close}>
                        <Tooltip title="Chain Select" placement="left">
                          <Box
                            sx={{
                              display: "flex",
                              gap: "24px",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Box
                              component="img"
                              src={bnbIcon}
                              alt="BNB"
                              sx={{ width: 24, height: 24 }}
                              onClick={() => {
                                window.location.href = "https://bnbmafia.io";
                              }}
                            />
                            <Box
                              component="img"
                              src={plsIcon}
                              alt="PLS"
                              sx={{ width: 24, height: 24 }}
                              onClick={() => {
                                window.location.href = "https://pulsemafia.io";
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          popupState.close();
                          toggleMute();
                        }}
                      >
                        <Tooltip title="Mute Options" placement="left">
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                              color: "white",
                              alignItems: "center",
                              fontFamily: "Philosopher",
                              fontWeight: 700,
                            }}
                          >
                            <Box
                              component={"img"}
                              src={
                                !muted
                                  ? "/assets/imgs/layout/mute.png"
                                  : "/assets/imgs/layout/volume.png"
                              }
                              width={21}
                              sx={{ cursor: "pointer" }}
                            ></Box>
                            {!muted ? "Mute" : "Unmute"}
                          </Box>
                        </Tooltip>
                      </MenuItem>

                      {myProfile.family && myProfile.family > 0 && (
                        <MenuItem
                          onClick={() => {
                            popupState.close();
                          }}
                        >
                          <Tooltip title="Go to Family" placement="left">
                            <NavLink
                              to={`family/${myProfile.userFamily?.name}`}
                              className={classes.profileLinkText}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "8px",
                                  color: "white",
                                  alignItems: "center",
                                  fontFamily: "Philosopher",
                                  fontWeight: 700,
                                }}
                              >
                                <Box
                                  component={"img"}
                                  src={familyIcon}
                                  width={21}
                                  sx={{ cursor: "pointer" }}
                                ></Box>
                                My family
                              </Box>
                            </NavLink>
                          </Tooltip>
                        </MenuItem>
                      )}

                      {myProfile && myProfile.name && (
                        <MenuItem onClick={popupState.close}>
                          <Tooltip title="Go to Profile" placement="left">
                            <Box>
                              <NavLink
                                to={`profile/${myProfile?.name}`}
                                style={{
                                  fontFamily: "Philosopher",
                                  fontWeight: 700,
                                  textDecoration: "none",
                                  color: "white",
                                  display: "flex",
                                  gap: "8px",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  component={"img"}
                                  src={profileIcon}
                                  width={21}
                                ></Box>
                                My Profile
                              </NavLink>
                            </Box>
                          </Tooltip>
                        </MenuItem>
                      )}

                      <MenuItem
                        onClick={() => {
                          popupState.close();
                          isConnected ? disconnectWallet() : onConnectWallet();
                        }}
                      >
                        <Tooltip
                          title={isConnected ? "Disconnect" : "Connect"}
                          placement="left"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                              fontFamily: "Philosopher",
                              fontWeight: 700,
                            }}
                          >
                            <Box
                              component="img"
                              width={24}
                              src="/assets/imgs/layout/power-off-gray.png"
                            ></Box>
                            {isConnected ? "Disconnect" : "Connect"}
                          </Box>
                        </Tooltip>
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </Button>

            {isConnected && (
              <>
                <Button
                  className={classes.notificationBtn}
                  aria-describedby={"transition-popper"}
                  onClick={handleShowNotification}
                >
                  <Badge
                    badgeContent={notificationCount}
                    color="error"
                    sx={{ cursor: "pointer" }}
                  >
                    <Box
                      component="img"
                      src="/assets/imgs/layout/notification.png"
                      width={21}
                    />
                  </Badge>
                </Button>

                <NotificationPanel
                  account={account}
                  showNotification={showNotification}
                  anchorElNotification={anchorElNotification}
                  toggleNotification={toggleNotification}
                  setInvitationInfo={setInvitationInfo}
                  setOpenInviteModal={setOpenInviteModal}
                  setAllegianceInvitationInfo={setAllegianceInvitationInfo}
                  setOpenAllegianceInviteModal={setOpenAllegianceInviteModal}
                  setAllegianceRequestInfo={setAllegianceRequestInfo}
                  setOpenAllegianceRequestModal={setOpenAllegianceRequestModal}
                />
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      <Box>{props.children}</Box>

      <FamilyInvitationMemberPopup
        openInviteModal={openInviteModal}
        setOpenInviteModal={setOpenInviteModal}
        invitationInfo={invitationInfo}
      />

      <AllegianceInvitationPopup
        openInviteModal={openAllegianceInviteModal}
        setOpenInviteModal={setOpenAllegianceInviteModal}
        allegianceInvitationInfo={allegianceInvitationInfo}
      />

      <AllegianceRequestPopup
        openInviteModal={openAllegianceRequestModal}
        setOpenInviteModal={setOpenAllegianceRequestModal}
        allegianceRequestInfo={allegianceRequestInfo}
      />

      <RateLimitPopup openRateLimitPopup={rateExceeded} />

      <BackOverlay />

      <ChatPanel open={openChat} setOpen={(open) => setOpenChat(open)} />

      <ToastContainer />
    </Box>
  );
};

export default MainLayout;
