import { ethers } from "ethers";

import {
  approveToken,
  getProvider,
  getTokenAllowance,
  getWeb3,
  waitForConfirmation,
} from ".";

import MafiaInventoryMarketplaceABI from "../../abis/MafiaInventoryMarketplace.json";

import config from "../../config/config";
import { GAME_CASH_ADDRESS, WalletErrorMsg } from "../../constants/const";
import { SwapToken } from "../../types/Contract/SwapToken";
import { delay } from "../../utils/utils";

export const getMafiaInventoryMarketContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.marketAddress;
  const MafiaInventoryMarketContract = new web3.eth.Contract(
    MafiaInventoryMarketplaceABI,
    contractAddress
  );
  return MafiaInventoryMarketContract;
};

export const getSwapTokens = async () => {
  const MafiaInventoryMarketContract = getMafiaInventoryMarketContract();

  let swapTokenInfo: [SwapToken[], number[]];
  swapTokenInfo = await MafiaInventoryMarketContract.methods
    .getSwapTokens()
    .call();

  return swapTokenInfo;
};

export const getListingInfo = async (listingId: number) => {
  const MafiaInventoryMarketContract = getMafiaInventoryMarketContract();

  const listingInfo = await MafiaInventoryMarketContract.methods
    .getListingInfo(listingId)
    .call();

  return listingInfo;
};

export const createListing = async (
  itemId: number,
  startingPrice: number,
  listingType: number,
  listingToken: string,
  duration: number,
  account: string
) => {
  const web3 = getWeb3();
  const MafiaInventoryMarketContract = getMafiaInventoryMarketContract(true);

  const data = await MafiaInventoryMarketContract.methods
    .createListing(
      itemId,
      web3.utils.toWei(startingPrice.toString(), "ether"),
      listingType,
      listingToken,
      duration
    )
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const purchaseFixedItem = async (
  listingId: number,
  swapTokenId: number,
  tokenAddress: string,
  tokenAmount: number,
  account: string
) => {
  const MafiaInventoryMarketContract = getMafiaInventoryMarketContract(true);

  if (tokenAddress === ethers.constants.AddressZero) {
    const nativeAmount = ethers.utils.parseUnits(
      tokenAmount.toFixed(18),
      "ether"
    );

    const data = await MafiaInventoryMarketContract.methods
      .purchaseFixedItem(listingId, swapTokenId)
      .send({ value: nativeAmount.toString(), from: account });

    await waitForConfirmation(data.transactionHash);

    return data;
  } else if (tokenAddress === GAME_CASH_ADDRESS) {
    const data = await MafiaInventoryMarketContract.methods
      .purchaseFixedItem(listingId, swapTokenId)
      .send({ from: account });

    await waitForConfirmation(data.transactionHash);

    return data;
  } else {
    const tokenAllowance = await getTokenAllowance(
      tokenAddress,
      account,
      config.marketAddress
    );

    try {
      if (Number(tokenAllowance) < tokenAmount) {
        await approveToken(
          tokenAddress,
          tokenAmount,
          config.marketAddress,
          account
        );

        await delay(config.blockTime);
      }
    } catch (error: any) {
      if (error.message === WalletErrorMsg.Indexing) {
        await delay(config.blockTime);
      } else if (error.message === WalletErrorMsg.Rejected) {
        throw error;
      } else {
        throw error;
      }
    }

    const data = await MafiaInventoryMarketContract.methods
      .purchaseFixedItem(listingId, swapTokenId)
      .send({ from: account });

    await waitForConfirmation(data.transactionHash);

    return data;
  }
};

export const bidOnAuctionItem = async (
  listingId: number,
  swapTokenId: number,
  price: number,
  tokenAddress: string,
  tokenAmount: number,
  account: string
) => {
  const MafiaInventoryMarketContract = getMafiaInventoryMarketContract(true);
  const newPrice = ethers.utils.parseUnits(price.toFixed(18), "ether");

  if (tokenAddress === ethers.constants.AddressZero) {
    const nativeAmount = ethers.utils.parseUnits(
      tokenAmount.toFixed(18),
      "ether"
    );

    const data = await MafiaInventoryMarketContract.methods
      .bidOnAuctionItem(listingId, swapTokenId, newPrice.toString())
      .send({ value: nativeAmount.toString(), from: account });

    await waitForConfirmation(data.transactionHash);

    return data;
  } else if (tokenAddress === GAME_CASH_ADDRESS) {
    const data = await MafiaInventoryMarketContract.methods
      .bidOnAuctionItem(listingId, swapTokenId, newPrice.toString())
      .send({ from: account });

    await waitForConfirmation(data.transactionHash);

    return data;
  } else {
    const tokenAllowance = await getTokenAllowance(
      tokenAddress,
      account,
      config.marketAddress
    );

    try {
      if (Number(tokenAllowance) < tokenAmount) {
        await approveToken(
          tokenAddress,
          tokenAmount,
          config.marketAddress,
          account
        );

        await delay(config.blockTime);
      }
    } catch (error: any) {
      if (error.message === WalletErrorMsg.Indexing) {
        await delay(config.blockTime);
      } else if (error.message === WalletErrorMsg.Rejected) {
        throw error;
      } else {
        throw error;
      }
    }

    const data = await MafiaInventoryMarketContract.methods
      .bidOnAuctionItem(listingId, swapTokenId, newPrice.toString())
      .send({ from: account });

    await waitForConfirmation(data.transactionHash);

    return data;
  }
};

export const finishAuctionItem = async (listingId: number, account: string) => {
  const MafiaInventoryMarketContract = getMafiaInventoryMarketContract(true);

  const data = await MafiaInventoryMarketContract.methods
    .finishAuctionItem(listingId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const cancelListing = async (listingId: number, account: string) => {
  const MafiaInventoryMarketContract = getMafiaInventoryMarketContract(true);

  const data = await MafiaInventoryMarketContract.methods
    .cancelListing(listingId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};
