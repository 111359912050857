import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      minWidth: "700px",
      display: "flex",
      flexDirection: "column",
      padding: "32px 40px",
      borderRadius: "8px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
      [theme.breakpoints.down("md")]: {
        minWidth: "auto",
        padding: "32px 16px",
      },
    },
  },

  scrollContainer: {
    maxHeight: "80vh",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "60vh",
    },
  },
  topHeaderTitle: {
    fontSize: "17px",
    color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "center",
  },
  header: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    marginTop: "24px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
  },
  confirmChangeButBody: {
    display: "flex",
    justifyContent: "center",
  },
  confirmChangeBut: {
    display: "flex",
    alignItems: "normal",
    gap: "8px",
    padding: "8px 16px",
    textTransform: "none",
    borderRadius: "8px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.defaultButton,
  },
  titleBody: {
    fontSize: "25px",
    display: "flex",
    justifyContent: "center",
  },
  closeIconBody: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 16,
    top: 16,
    "&>svg": {
      color: theme.palette.text.secondary,
      cursor: "pointer",
      transition: ".3s",
      fontSize: "40px",
      "&:hover": {
        color: theme.palette.text.primary,
        transition: ".3s",
      },
    },
  },
  rolesBody: {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    paddingBottom: "32px",
    borderBottom: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
  },
  rolesHalfSection: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "8px",
  },
  roleDetail: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    maxWidth: "250px",
    alignItems: "center",
    gap: "8px",
  },
  roleText: {
    color: theme.palette.text.secondary,
  },
  roleDropdown: {},
  sectionTitle: {
    fontSize: "20px",
    color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "center",
    marginTop: "48px",
  },
  rolesSection: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "32px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
  },
  brandSection: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "32px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
    },
  },
  bankBody: {
    borderBottom: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    gap: "40px",
    display: "flex",
    flexDirection: "column",
  },
  bankSection: {
    display: "flex",
    justifyContent: "center",
    gap: "48px",
    paddingBottom: "32px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  bankLeftSection: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "center",
    minWidth: "250px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
    },
  },
  totalAmountCard: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    backgroundColor: "#282828",
    borderRadius: "8px",
    padding: "24px 24px",
    alignItems: "center",
    width: "100%",
    fontSize: "20px",
    boxSizing: "border-box",
    "&>div:nth-of-type(1)": {
      color: theme.palette.text.primary,
    },
    "&>div:nth-of-type(2)": {
      color: theme.palette.text.secondary,
    },
  },
  withdrawSection: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "8px",
    width: "100%",
    justifyContent: "space-around",
  },
  amountBut: {
    backgroundColor: "#282828",
    padding: "8px 0",
    color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
  },
  withdrawBut: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    gap: "8px",
    cursor: "pointer",
  },
  bankRightSection: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    border: "1px solid #282828",
    padding: "32px 32px",
    borderRadius: "16px",
  },
  depositHeader: {
    color: theme.palette.text.primary,
  },
  depositDetails: {
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
  },
  depositDetail: {
    display: "grid",
    gap: "16px",
    gridTemplateColumns: "2fr 3fr",
  },
  brandingBody: {
    borderBottom: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    paddingBottom: "32px",
  },
  brandHalfSection: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "center",
  },
  uploadSection: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "240px",
      gap: "12px",
    },
  },
  socialEditPart: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  uploadPart: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "24px",
  },
  uploadButton: {
    display: "flex",
    flexDirection: "column",
    color: theme.palette.text.secondary,
    textTransform: "none",
    gap: "16px",
    backgroundColor: "#282828",
    borderRadius: "16px",
    padding: "8px 16px",
    height: "80px",
  },
  previewLogo: {
    width: "50px",
    height: "80px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "&>img": {
      height: "100%",
      borderRadius: "8px",
    },

    "& span": {
      color: "white",
    },

    [theme.breakpoints.down("sm")]: {
      width: "100px",
    },
  },
  socials: {},
  socialDetail: {},
  emptyLogo: {
    width: "100%",
    height: "100%",
    backgroundColor: "#282828",
    borderRadius: "8px",
  },
  previewBg: {
    width: "100px",
    height: "80px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "&>img": {
      height: "100%",
      borderRadius: "8px",
    },

    "& span": {
      color: "white",
    },

    [theme.breakpoints.down("sm")]: {
      width: "100px",
    },
  },
  resolutionText: {
    color: theme.palette.text.secondary,
  },
  moneyBody: {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    borderBottom: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
  },
  moneyHalfSection: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    paddingBottom: "48px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "16px",
    },
  },
  moneyDetail: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr",
    alignItems: "center",
  },
  moneyDetailText: {
    color: theme.palette.text.secondary,
  },
  editingSection: {
    display: "flex",
    backgroundColor: "#282828",
    alignItems: "center",
    borderRadius: "8px",
    padding: "0px 8px",
    maxWidth: "150px",
    boxSizing: "border-box",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
    },
  },
  moneyTextEdit: {
    "& div": {
      color: theme.palette.text.primary,
    },

    "& .MuiInputBase-input": {
      padding: "3px 0",
      color: theme.palette.text.primary,
      textAlign: "center",
    },
    "& fieldset": {
      outline: "none",
      border: "none",
    },

    "& label": {
      color: "white",
      top: 15,

      "&.Mui-focused": {
        color: "white",
      },
    },
  },
  socialTextEdit: {
    "& .MuiInputBase-input": {
      padding: "6px 3px",
      color: theme.palette.text.primary,
      fontSize: "15px",
      [theme.breakpoints.down("sm")]: {},
    },
    "& fieldset": {
      outline: "none",
      border: "none",
    },
  },
  amountInput: {
    "& .MuiInputBase-input": {
      padding: "0",
      color: "white",
      textAlign: "center",
      width: "100%",
      backgroundColor: "transparent",
      borderRadius: "8px",
      fontFamily: "Philosopher",
    },
    "& fieldset": {
      outline: "none",
      border: "none",
    },
  },
  successor: {
    marginTop: "40px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
    },
  },
  capo: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
    },
  },
  leaveFeeBody: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "center",
    paddingTop: 48,
    paddingBottom: 48,
    borderBottom: "1px solid",
    borderColor: theme.palette.background.defaultBorder,

    "&>div:nth-of-type(1)": {
      color: "#646464",
      fontSize: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "32px",
      paddingBottom: "32px",
    },
  },
  leaveFeeInputBody: {
    position: "relative",
    backgroundColor: "#282828",
    minWidth: "250px",
    padding: "8px 16px",
    justifyContent: "center",
    display: "flex",
    borderRadius: "8px",
  },

  editIcon: {
    position: "absolute",
    right: 10,
    display: "flex",
    alignItems: "center",
    height: "20px",
  },

  leaveFeeInput: {
    "& .MuiInputBase-input": {
      padding: "0",
      color: "white",
      textAlign: "center",
      width: "100px",
      backgroundColor: "transparent",
      borderRadius: "8px",
      fontFamily: "Philosopher",

      "&::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },

      "&::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
    },
    "& fieldset": {
      outline: "none",
      border: "none",
    },
  },
  customInputBody: {
    display: "flex",
    alignItems: "center",

    "& img": {
      width: 24,
      height: 24,
    },
  },

  sharingBody: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "center",
    paddingTop: 36,
    paddingBottom: 36,
    borderBottom: "1px solid",
    borderColor: theme.palette.background.defaultBorder,

    [theme.breakpoints.down("sm")]: {
      paddingTop: "32px",
      paddingBottom: "32px",
    },
  },

  sharingBodyNativeIcon: {
    width: 40,
    height: 40,
  },

  sharingBodyTitle: {
    color: theme.palette.text.primary,
    fontSize: "24px",
  },

  sharingBodyDescription: {
    color: theme.palette.text.secondary,
  },

  customInput: {
    backgroundColor: "#282828",
    padding: "8px 0",
    color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
  },

  textField: {
    "& .MuiInputBase-input": {
      padding: "0",
      color: "white",
      textAlign: "center",
      width: "100%",
      backgroundColor: "transparent",
      borderRadius: "8px",
      fontFamily: "Philosopher",
    },
    "& fieldset": {
      outline: "none",
      border: "none",
    },
  },

  sharingInfo: {
    display: "flex",
    alignItems: "center",
    gap: 32,
  },

  sharingInfoName: {
    fontSize: 20,
    width: 96,
    textAlign: "center",
  },

  sharingAmount: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    width: 110,
    justifyContent: "space-between",
  },

  sharingInfoIcon: {
    width: 24,
    height: 24,
  },
}));

export default styles;
