import { ethers } from "ethers";
import { approveToken, getProvider, getTokenAllowance, getWeb3 } from ".";

import OgCrateMinterABI from "../../abis/OGCrateMinter.json";

import config from "../../config/config";
import { WalletErrorMsg } from "../../constants/const";
import { delay } from "../../utils/utils";
import { SwapToken } from "./../../types/Contract/SwapToken";

export const getOgCrateMinter = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.crateMinterAddress;
  const OgCrateMinter = new web3.eth.Contract(
    OgCrateMinterABI,
    contractAddress
  );
  return OgCrateMinter;
};

export const getSwapTokens = async () => {
  const OgCrateMinterContract = getOgCrateMinter();

  let swapTokenInfo: [SwapToken[], number[]];
  swapTokenInfo = await OgCrateMinterContract.methods.getSwapTokens().call();

  return swapTokenInfo;
};

export const buyCrates = async (
  swapTokenId: number,
  tokenAddress: string,
  tokenAmount: number,
  crateAmount: number,
  account: string
) => {
  const OgCrateMinterContract = getOgCrateMinter(true);
  const web3 = getProvider();

  if (tokenAddress === ethers.constants.AddressZero) {
    const nativeAmount = web3.utils.toWei(tokenAmount.toFixed(18), "ether");

    await OgCrateMinterContract.methods
      .buyCrates(swapTokenId, crateAmount)
      .send({
        value: nativeAmount,
        from: account,
      });
  } else {
    const tokenAllowance = await getTokenAllowance(
      tokenAddress,
      account,
      config.crateMinterAddress
    );
    try {
      if (Number(tokenAllowance) < tokenAmount) {
        await approveToken(
          tokenAddress,
          tokenAmount,
          config.crateMinterAddress,
          account
        );

        await delay(config.blockTime);
      }
    } catch (error: any) {
      if (error.message === WalletErrorMsg.Indexing) {
        await delay(config.blockTime);
      } else if (error.message === WalletErrorMsg.Rejected) {
        throw error;
      } else {
        throw error;
      }
    }

    await OgCrateMinterContract.methods
      .buyCrates(swapTokenId, crateAmount)
      .send({ from: account });
  }
};
