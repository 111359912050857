import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Skeleton,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { ethers } from "ethers";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import millify from "millify";
import React, { useEffect, useState } from "react";

import LeaderDropDown from "../CustomDropdown/LeaderDropDown";
import Logo from "../Logo";
import NativeIcon from "../NativeIcon";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import config from "../../config/config";
import {
  BG_HEIGHT,
  BG_WIDTH,
  LOGO_HEIGHT,
  LOGO_WIDTH,
} from "../../constants/const";
import { FamilyRole } from "../../constants/enum/enum";
import useWallet from "../../hook/useWallet";
import { InputValues } from "../../types/PromotionValues";
import { storage } from "../../utils/firebase";
import {
  arraysAreEqual,
  calculateFamilyPayout,
  toastError,
  toastInfo,
  toastSuccess,
  toTitleCase,
  toUSDFormat,
} from "../../utils/utils";

import {
  getFamilyDetailByName,
  getFamilyPromotion,
  setFamilyLeaveFee,
  signData,
  updateFamilyBrandingAction,
  updateFamilyLeaveFeeAction,
  updateFamilyPromotionAction,
  updateFamilySharingAction,
} from "../../reducers/family.slice";

import discordLogo from "../../assets/imgs/family/discord.png";
import telegramLogo from "../../assets/imgs/family/telegram.png";
import twitterLogo from "../../assets/imgs/family/twitter.png";

import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";

import {
  getMaxFamilyLeaveFee,
  getMinFamilyLeaveFee,
} from "../../helper/contractFunctions/MafiaFamily";
import { dispatchTxAction } from "../../helper/dispatchTxAction";

import useStyles from "./index.styles";

const Input = styled("input")({
  display: "none",
});

interface FamilyManageFamilyPopupProps {
  vaultBalance: number;
  openManageModal: boolean;
  setOpenManageModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const FamilyManageFamilyPopup: React.FC<FamilyManageFamilyPopupProps> = ({
  vaultBalance,
  openManageModal,
  setOpenManageModal,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { familyDetail } = useAppSelector((state) => state.family);
  const { account } = useWallet();

  const [logo, setLogo] = useState<string | null>(null);
  const [bg, setBg] = useState<string | null>(null);
  const [telegramInfo, setTelegramInfo] = useState("");
  const [discordInfo, setDiscordInfo] = useState("");
  const [twitterInfo, setTwitterInfo] = useState("");
  const [leaveFee, setLeaveFee] = useState(0);
  const [minLeaveFee, setMinLeaveFee] = useState(0);
  const [maxLeaveFee, setMaxLeaveFee] = useState(0);

  const [teamPayoutAmount, setTeamPayoutAmount] = useState(0);
  const [topPercent, setTopPercent] = useState(0);
  const [memberPercent, setMemberPercent] = useState(0);

  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [isUploadingBg, setIsUploadingBg] = useState(false);

  const [initialPromotionValues, setInitialPromotionValues] =
    useState<InputValues>();
  const [promotionValues, setPromotionValues] = useState<InputValues>();

  const handleClose = () => {
    setOpenManageModal(false);
  };

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | null = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const { width, height } = img;
          if (width > LOGO_WIDTH || height > LOGO_HEIGHT) {
            toastInfo(Messages.FAMILY.BASIC.IMAGE_SIZE_INVALID, {
              width: LOGO_WIDTH.toString(),
              height: LOGO_HEIGHT.toString(),
            });
            return;
          } else {
            const storageRef = ref(
              storage,
              `logo/${familyDetail.familyInfo?.name}-logo-${file.name}`
            );
            const uploadTask = uploadBytesResumable(storageRef, file);

            setIsUploadingLogo(true);
            uploadTask.on(
              "state_changed",
              (snapshot) => {},
              (error) => {},
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((logoUrl) => {
                  console.log("File available at", logoUrl);
                  setIsUploadingLogo(false);
                  setLogo(logoUrl);
                });
              }
            );
          }
        };
        img.onerror = () => {
          toastError(Errors.FAMILY.VALIDATE.INVALID_IMAGE);
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBackgroundUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file: File | null = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      const img = new Image();
      reader.onload = (e) => {
        const { width, height } = img;
        if (width > BG_WIDTH || height > BG_HEIGHT) {
          toastInfo(Messages.FAMILY.BASIC.BACK_SIZE_INVALID, {
            width: BG_WIDTH.toString(),
            height: BG_HEIGHT.toString(),
          });
          return;
        } else {
          const storageRef = ref(
            storage,
            `custom/${familyDetail.familyInfo?.name}-bg-${file.name}`
          );
          const uploadTask = uploadBytesResumable(storageRef, file);

          setIsUploadingBg(true);
          uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => {},
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((bgUrl) => {
                console.log("File available at", bgUrl);
                setIsUploadingBg(false);
                setBg(bgUrl);
              });
            }
          );
        }
        img.onerror = () => {
          toastError(Errors.FAMILY.VALIDATE.INVALID_IMAGE);
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  const renderFamilyDropDown = (role: FamilyRole) => {
    let members = familyDetail.members;

    let disabled = false;
    let selectedValue;
    if (role !== FamilyRole.Don) {
      members = members.filter(
        (member) => member.name !== familyDetail.don?.name
      );
    }

    switch (role) {
      case FamilyRole.Don:
        selectedValue = familyDetail.don?.name;
        break;
      case FamilyRole.Consigliere:
        selectedValue = familyDetail.consigliere?.name;
        break;
      case FamilyRole.Capodecina:
        selectedValue = familyDetail.capodecina?.name;
        break;
      case FamilyRole.Successor:
        selectedValue = familyDetail.successor?.name;
        break;
      case FamilyRole.Capo1:
        selectedValue = familyDetail.capoRegime.find(
          (capo) => capo.capoId === 1
        )?.capoBoss;
        break;
      case FamilyRole.Capo2:
        selectedValue = familyDetail.capoRegime.find(
          (capo) => capo.capoId === 2
        )?.capoBoss;
        break;
      case FamilyRole.Capo3:
        selectedValue = familyDetail.capoRegime.find(
          (capo) => capo.capoId === 3
        )?.capoBoss;
        break;
      case FamilyRole.Capo4:
        selectedValue = familyDetail.capoRegime.find(
          (capo) => capo.capoId === 4
        )?.capoBoss;
        break;
      case FamilyRole.Capo5:
        selectedValue = familyDetail.capoRegime.find(
          (capo) => capo.capoId === 5
        )?.capoBoss;
        break;
    }

    return (
      <Box
        className={
          role === FamilyRole.Successor
            ? clsx(classes.roleDetail, classes.successor)
            : classes.roleDetail
        }
      >
        <Box className={classes.roleText}>{FamilyRole[role]}</Box>
        <Box className={classes.roleDropdown}>
          <LeaderDropDown
            disabled={disabled}
            sx={{ width: "150px" }}
            value={selectedValue || ""}
            role={FamilyRole[role]}
            leaderIndex={role}
            members={members}
            account={account}
          />
        </Box>
      </Box>
    );
  };

  const handleChangeTelegramInfo = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTelegramInfo(event.target.value);
  };

  const handleChangeDiscordInfo = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDiscordInfo(event.target.value);
  };

  const handleChangeTwitterInfo = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTwitterInfo(event.target.value);
  };

  const handleChangeLeaveFee = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLeaveFee(Number(event.target.value));
  };

  const validateTelegramInfo = () => {
    const regex = /^@?[a-zA-Z][a-zA-Z0-9_]{4,31}$/;

    if (!telegramInfo) return true;
    if (telegramInfo.length > 0) {
      if (regex.test(telegramInfo)) {
        return true;
      } else {
        toastInfo(Messages.FAMILY.BASIC.TELEGRAM_INVALID);
        return false;
      }
    }
  };

  const validateDiscordInfo = () => {
    // Updated regex pattern for the new Discord username requirements
    const regex = /^https:\/\/discord\.gg\/[a-zA-Z0-9]+$/;

    // Check if discordInfo is empty or undefined
    if (!discordInfo) return true;

    // Validate the username against the regex pattern
    if (regex.test(discordInfo)) {
      return true;
    } else {
      // Display an error message if the username does not meet the criteria
      toastInfo(Messages.FAMILY.BASIC.DISCORD_INVALID);
      return false;
    }
  };

  const validateTwitterInfo = () => {
    const regex = /^@[A-Za-z0-9_]{4,15}$/;

    if (!twitterInfo) return true;
    if (twitterInfo.length > 0) {
      if (regex.test(twitterInfo)) {
        return true;
      } else {
        toastInfo(Messages.FAMILY.BASIC.TWITTER_INVALID);
        return false;
      }
    }
  };

  const handleConfirmChange = () => {
    if (!account) return;

    const checkFamilyBrandingChanges = () => {
      const checkChanges = () => {
        if (familyDetail.telegram !== telegramInfo) return true;
        if (familyDetail.discord !== discordInfo) return true;
        if (familyDetail.twitter !== twitterInfo) return true;

        if (familyDetail.logo !== logo) return true;
        if (familyDetail.background !== bg) return true;

        return false;
      };

      if (checkChanges()) {
        if (
          !validateTelegramInfo() ||
          !validateDiscordInfo() ||
          !validateTwitterInfo()
        ) {
          return;
        }

        const signMsg = {
          logo: logo,
          background: bg ? bg : "",
          telegram: telegramInfo,
          discord: discordInfo,
          twitter: twitterInfo,
        };

        dispatch(signData({ signMsg }))
          .unwrap()
          .then((signature) => {
            dispatch(
              updateFamilyBrandingAction({
                signature,
                signMsg,
                familyId: familyDetail.id || 0,
              })
            )
              .unwrap()
              .then(() => {
                toastSuccess(Messages.FAMILY.BASIC.BRANDING_UPDATED);

                dispatch(
                  getFamilyDetailByName(familyDetail.familyInfo?.name || "")
                );
              })
              .catch(() => {});
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    const checkPromotionChanges = () => {
      if (
        arraysAreEqual(
          Object.values(initialPromotionValues || {}),
          Object.values(promotionValues || {})
        )
      ) {
        console.log("Promotion value unchanged");
      } else {
        const signMsg = {
          ...promotionValues,
        };

        dispatch(signData({ signMsg }))
          .unwrap()
          .then((signature) => {
            dispatch(
              updateFamilyPromotionAction({
                signature,
                signMsg: signMsg,
                familyId: familyDetail.id || 0,
              })
            )
              .unwrap()
              .then(() => {
                toastSuccess(Messages.FAMILY.BASIC.PROMOTION_MONEY_UPDATED);

                dispatch(getFamilyPromotion(familyDetail.id || 0));
              })
              .catch(() => {});
          })
          .catch((error) => {
            console.log(error);
          });

        console.log("Promotion value changed");
      }
    };

    const checkLeaveFamilyFee = () => {
      if (familyDetail.leaveFee !== leaveFee) {
        if (!familyDetail.id) return;

        if (leaveFee < minLeaveFee || leaveFee > maxLeaveFee) {
          toastInfo(Messages.FAMILY.BASIC.LEAVE_FEE_INVALID);

          return;
        }

        dispatchTxAction(
          dispatch,
          updateFamilyLeaveFeeAction({
            account,
            familyId: familyDetail.id,
            value: leaveFee,
          }),
          () => {
            toastInfo(Messages.FAMILY.BASIC.UPDATE_LEAVE_FEE_SUCCESS);

            dispatch(setFamilyLeaveFee(leaveFee));
          }
        );
      }
    };

    const checkFamilySharingChange = () => {
      if (familyDetail.topPercent !== topPercent) {
        const signMsg = {
          familyId: familyDetail.id,
          newPercent: topPercent,
        };

        dispatch(signData({ signMsg }))
          .unwrap()
          .then((signature) => {
            dispatch(
              updateFamilySharingAction({
                signature,
                signMsg,
              })
            )
              .unwrap()
              .then(() => {
                toastSuccess(Messages.FAMILY.BASIC.SHARING_UPDATED);

                dispatch(
                  getFamilyDetailByName(familyDetail.familyInfo?.name || "")
                );
              })
              .catch(() => {});
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    checkFamilyBrandingChanges();
    checkPromotionChanges();
    checkLeaveFamilyFee();
    checkFamilySharingChange();
  };

  const handlePromotionMoneyChange = (key: string, value: string) => {
    setPromotionValues((prevValues) => ({
      ...prevValues,
      [key]: Number(value),
    }));
  };

  const handleTopPercentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPercent = parseInt(event.target.value);
    setTopPercent(newPercent);
    setMemberPercent(100 - newPercent);
  };

  const handleMemberPercentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPercent = parseInt(event.target.value);
    setTopPercent(100 - newPercent);
    setMemberPercent(newPercent);
  };

  useEffect(() => {
    if (familyDetail) {
      setBg(familyDetail.background);
      setLogo(familyDetail.logo);

      setTelegramInfo(familyDetail.telegram || "");
      setDiscordInfo(familyDetail.discord || "");
      setTwitterInfo(familyDetail.twitter || "");

      setLeaveFee(familyDetail.leaveFee);

      setTopPercent(familyDetail.topPercent);
      setMemberPercent(100 - familyDetail.topPercent);
    }

    if (familyDetail && familyDetail.promotion) {
      const initialPromotionValues: InputValues = {};
      Object.keys(familyDetail.promotion)
        .slice(1, 15)
        .forEach((key) => {
          initialPromotionValues[key] = familyDetail.promotion[key];
        });
      setPromotionValues(initialPromotionValues);
      setInitialPromotionValues(initialPromotionValues);
    }
  }, [familyDetail]);

  useEffect(() => {
    if (familyDetail.familyInfo?.rank && familyDetail.familyInfo?.rank <= 8) {
      const teamPayoutAmount = calculateFamilyPayout(
        vaultBalance,
        familyDetail.familyInfo?.rank
      );

      setTeamPayoutAmount(teamPayoutAmount);
    }
  }, [familyDetail, vaultBalance]);

  useEffect(() => {
    const fetchFamilyLeaveFeeMinMax = async () => {
      const minLeaveFee = await getMinFamilyLeaveFee();
      const maxLeaveFee = await getMaxFamilyLeaveFee();

      setMinLeaveFee(Number(ethers.utils.formatEther(minLeaveFee)));
      setMaxLeaveFee(Number(ethers.utils.formatEther(maxLeaveFee)));
    };

    fetchFamilyLeaveFeeMinMax();
  }, []);

  return (
    <Dialog
      open={openManageModal}
      onClose={handleClose}
      className={classes.body}
    >
      <Box className={classes.closeIconBody}>
        <Button onClick={handleClose}>
          <CloseIcon htmlColor="gray" />
        </Button>
      </Box>
      <Box className={classes.topHeaderTitle}>
        {familyDetail.familyInfo?.name}
      </Box>
      <Box className={classes.header}>
        <Box className={classes.confirmChangeButBody}>
          <Button
            className={classes.confirmChangeBut}
            onClick={handleConfirmChange}
          >
            <CheckIcon />
            Confirm changes
          </Button>
        </Box>
        <Box className={classes.titleBody}>Manage family</Box>
      </Box>

      <Box className={classes.scrollContainer}>
        <Box className={classes.rolesBody}>
          <Box className={classes.sectionTitle}>Family roles</Box>
          <Box className={classes.rolesSection}>
            <Box className={classes.rolesHalfSection}>
              {renderFamilyDropDown(FamilyRole.Don)}
              {renderFamilyDropDown(FamilyRole.Consigliere)}
              {renderFamilyDropDown(FamilyRole.Capodecina)}
              {renderFamilyDropDown(FamilyRole.Successor)}
            </Box>

            <Box className={clsx(classes.rolesHalfSection, classes.capo)}>
              {renderFamilyDropDown(FamilyRole.Capo1)}
              {renderFamilyDropDown(FamilyRole.Capo2)}
              {renderFamilyDropDown(FamilyRole.Capo3)}
              {renderFamilyDropDown(FamilyRole.Capo4)}
              {renderFamilyDropDown(FamilyRole.Capo5)}
            </Box>
          </Box>
        </Box>

        <Box className={classes.bankBody}>
          <Box className={classes.sectionTitle}>Family bank</Box>
          <Box className={classes.bankSection}>
            <Box className={classes.bankLeftSection}>
              <Box className={classes.totalAmountCard}>
                <Box>Total amount</Box>
                <Box>${toUSDFormat(familyDetail.bankInfo?.bank)}</Box>
              </Box>
              <Box className={classes.withdrawSection}>
                <Box className={classes.amountBut}>
                  <TextField
                    placeholder="Amount"
                    className={classes.amountInput}
                  ></TextField>
                </Box>
                <Box className={classes.withdrawBut}>
                  <Box
                    component={"img"}
                    src="/assets/imgs/family/withdraw.png"
                  ></Box>
                  Withdraw
                </Box>
              </Box>
            </Box>
            <Box className={classes.bankRightSection}>
              <Box className={classes.depositHeader}>Latest deposits</Box>
              <Box className={classes.depositDetails}>
                {familyDetail.bankInfo?.latestStaking.map((staking, index) => {
                  return (
                    <Box className={classes.depositDetail} key={index}>
                      <Box>{staking.name}</Box>
                      <Box>{staking.amount} $MAFIA</Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.brandingBody}>
          <Box className={classes.sectionTitle}>Family branding</Box>
          <Box className={classes.brandSection}>
            <Box className={classes.brandHalfSection}>
              <Box className={classes.uploadSection}>
                <Box>Logo</Box>
                <Box className={classes.uploadPart}>
                  <label htmlFor="logo-upload">
                    <Input
                      accept="image/*"
                      id="logo-upload"
                      type="file"
                      onChange={handleLogoUpload}
                    />
                    <Button className={classes.uploadButton} component="span">
                      <Box
                        component={"img"}
                        src="/assets/imgs/family/upload.png"
                      ></Box>
                      Upload
                    </Button>
                  </label>

                  <Box className={classes.previewLogo}>
                    {isUploadingLogo ? (
                      <CircularProgress size={24} />
                    ) : logo ? (
                      <Box component={"img"} src={logo}></Box>
                    ) : (
                      <Skeleton
                        className={classes.emptyLogo}
                        variant="rectangular"
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box className={classes.resolutionText}>500 {"\u00D7"} 800px</Box>
            </Box>
            <Box className={classes.brandHalfSection}>
              <Box className={classes.uploadSection}>
                <Box>Background</Box>
                <Box className={classes.uploadPart}>
                  <label htmlFor="bg-upload">
                    <Input
                      accept="image/*"
                      id="bg-upload"
                      type="file"
                      onChange={handleBackgroundUpload}
                    />
                    <Button className={classes.uploadButton} component="span">
                      <Box
                        component={"img"}
                        src="/assets/imgs/family/upload.png"
                      ></Box>
                      Upload
                    </Button>
                  </label>
                  <Box className={classes.previewBg}>
                    {isUploadingBg ? (
                      <CircularProgress size={24} />
                    ) : bg ? (
                      <Box component={"img"} src={bg}></Box>
                    ) : (
                      <Skeleton
                        className={classes.emptyLogo}
                        variant="rectangular"
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box className={classes.resolutionText}>
                1920 {"\u00D7"} 750px
              </Box>
            </Box>
            <Box className={classes.brandHalfSection}>
              <Box className={classes.uploadSection}>
                <Box>Socials</Box>
                <Box className={classes.socialEditPart}>
                  <Box className={classes.editingSection}>
                    <TextField
                      className={classes.socialTextEdit}
                      placeholder="Telegram"
                      value={telegramInfo}
                      onChange={handleChangeTelegramInfo}
                    ></TextField>
                    <Box component={"img"} src={telegramLogo} width={20}></Box>
                  </Box>
                  <Box className={classes.editingSection}>
                    <TextField
                      className={classes.socialTextEdit}
                      placeholder="Discord"
                      value={discordInfo}
                      onChange={handleChangeDiscordInfo}
                    ></TextField>
                    <Box component={"img"} src={discordLogo} width={20}></Box>
                  </Box>
                  <Box className={classes.editingSection}>
                    <TextField
                      className={classes.socialTextEdit}
                      placeholder="X Twitter"
                      value={twitterInfo}
                      onChange={handleChangeTwitterInfo}
                    ></TextField>
                    <Box component={"img"} src={twitterLogo} width={20}></Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.moneyBody}>
          <Box className={classes.sectionTitle}>Promotion money</Box>
          <Box className={classes.rolesSection}>
            <Box className={classes.moneyHalfSection}>
              {familyDetail.promotion &&
                Object.keys(familyDetail.promotion).length === 15 &&
                Object.keys(familyDetail.promotion)
                  .slice(1, 8)
                  .map((key, index) => {
                    return (
                      <Box className={classes.moneyDetail} key={index}>
                        <Box className={classes.moneyDetailText}>
                          <Box>{toTitleCase(key)}</Box>
                        </Box>
                        <Box className={classes.editingSection}>
                          <TextField
                            value={
                              promotionValues && Number(promotionValues[key])
                            }
                            onChange={(e) =>
                              handlePromotionMoneyChange(key, e.target.value)
                            }
                            label="$"
                            InputLabelProps={{
                              shrink: true, // This helps manage the label positioning
                            }}
                            className={classes.moneyTextEdit}
                          ></TextField>
                          <Box
                            component={"img"}
                            src="/assets/imgs/family/editing.png"
                          ></Box>
                        </Box>
                      </Box>
                    );
                  })}
            </Box>
            <Box className={classes.moneyHalfSection}>
              {familyDetail.promotion &&
                Object.keys(familyDetail.promotion).length === 15 &&
                Object.keys(familyDetail.promotion)
                  .slice(8, 15)
                  .map((key, index) => {
                    return (
                      <Box className={classes.moneyDetail} key={index}>
                        <Box className={classes.moneyDetailText}>
                          <Box>{toTitleCase(key)}</Box>
                        </Box>
                        <Box className={classes.editingSection}>
                          <TextField
                            value={
                              promotionValues && Number(promotionValues[key])
                            }
                            onChange={(e) =>
                              handlePromotionMoneyChange(key, e.target.value)
                            }
                            label="$"
                            InputLabelProps={{
                              shrink: true, // This helps manage the label positioning
                            }}
                            className={classes.moneyTextEdit}
                          ></TextField>
                          <Box
                            component={"img"}
                            src="/assets/imgs/family/editing.png"
                          ></Box>
                        </Box>
                      </Box>
                    );
                  })}
            </Box>
          </Box>
        </Box>

        <Box className={classes.leaveFeeBody}>
          <Box>Leave family fee</Box>
          <Box className={classes.leaveFeeInputBody}>
            <Box className={classes.customInputBody}>
              <Logo />
              <Box className={classes.leaveFeeInput}>
                <TextField
                  value={leaveFee}
                  onChange={handleChangeLeaveFee}
                  type="number"
                />
              </Box>
              <Box>$MAFIA</Box>
            </Box>
            <Box className={classes.editIcon}>
              <Box
                component={"img"}
                src="/assets/imgs/family/editing.png"
              ></Box>
            </Box>
          </Box>

          <Box>{`Min ${minLeaveFee}, Max ${maxLeaveFee}`}</Box>
        </Box>

        <Box className={classes.sharingBody}>
          <NativeIcon classNames={classes.sharingBodyNativeIcon} />
          <Box className={classes.sharingBodyTitle}>Family Sharing</Box>
          <Box className={classes.sharingBodyDescription}>
            How to split weekly vault earnings?
          </Box>

          <Box className={classes.sharingInfo}>
            <Box className={classes.sharingInfoName}>Top</Box>

            <Box className={classes.customInput}>
              <TextField
                value={topPercent}
                className={classes.textField}
                onChange={handleTopPercentChange}
              ></TextField>
            </Box>

            <Box className={classes.sharingAmount}>
              <Box>
                {millify((teamPayoutAmount * topPercent) / 100, {
                  precision: 2,
                })}{" "}
                {config.chainSymbol}
              </Box>
              <NativeIcon classNames={classes.sharingInfoIcon} />
            </Box>
          </Box>

          <Box className={classes.sharingInfo}>
            <Box className={classes.sharingInfoName}>Members</Box>

            <Box className={classes.customInput}>
              <TextField
                value={memberPercent}
                className={classes.textField}
                onChange={handleMemberPercentChange}
              ></TextField>
            </Box>

            <Box className={classes.sharingAmount}>
              {millify((teamPayoutAmount * memberPercent) / 100, {
                precision: 2,
              })}{" "}
              {config.chainSymbol}
              <NativeIcon classNames={classes.sharingInfoIcon} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default FamilyManageFamilyPopup;
