import { useEffect, useState } from "react";
import config from "../config/config";
import { getTokenBalance } from "../helper/contractFunctions";

const useMafiaBalance = (address: string) => {
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const balance = await getTokenBalance(config.mafiaAddress, address);

        setBalance(parseFloat(balance));
        console.log(balance);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchBalance();
  }, [address]);

  return balance;
};

export default useMafiaBalance;
