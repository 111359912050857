import { Box } from "@mui/material";
import { ItemCategoryInfoList } from "../../constants/const";

interface InventoryItemIconProps {
  className?: string;
  sx?: object;
  categoryId: number;
  typeId: number;
}

const InventoryItemIcon = ({
  categoryId,
  typeId,
  sx,
  className,
}: InventoryItemIconProps) => {
  const category = ItemCategoryInfoList[Math.floor(categoryId)];
  const icon =
    category.icons && category.icons.length > 0
      ? category.icons[typeId]
      : category.icon;

  return (
    <Box
      sx={{
        ...sx,
      }}
      className={className}
      component="img"
      src={`/assets/imgs/inventory/${icon}`}
    ></Box>
  );
};

export default InventoryItemIcon;
